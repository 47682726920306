export const objectCreateAttributeHelper = (items) => {
    let obj = {

    }

    if (Array.isArray(items)) {
        items.forEach(item => {
            const { name, value } = item;
            obj[name] = value.trim()
        })
    }

    return obj
}