import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Label,
  Input,
  Button,
  Form,
  FormFeedback,
  Alert,
  FormGroup,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import * as Yup from "yup"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import {
  getSingleUser,
  submitEditSingleUser,
  getSingleRoleAssignUserData,
  getRoleForUserAssign,
} from "services/UserServices"

const EditUser = () => {
  document.title = `Edit User |  ${process.env.REACT_APP_SITE_NAME} - Admin Portal`

  // const navigate = useNavigate()
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const location = useLocation()
  const userId = location.state?.userId // Access userId safely
  const [status, setStatus] = useState() // Manage status state
  const [load, setLoad] = useState()
  const [roleAssign, setRoleAssign] = useState([])
  const [fetchedUserRole, setFetchedUserRole] = useState([])
  const [selectedRoles, setSelectedRoles] = useState({}) // To track the checked state of each role

  // Simulated existing user data for editing
  const existingUser = {
    fname: "",
    lname: "",
    email: "",
    primary_ph_no: "",
    addl_ph_no: "",
    address: "",
    gender: "",
    pan_no: "",
    aadhar_no: "",
  }

  const validation = useFormik({
    initialValues: existingUser,
    validationSchema: Yup.object({
      fname: Yup.string().required("First name is required"),
      lname: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      whatsapp_no: Yup.string()
        .matches(/^[0-9]{10}$/, "Whatsapp number must be 10 digits")
        .notRequired(),
      primary_ph_no: Yup.string()
        .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
        .notRequired(),
      addl_ph_no: Yup.string()
        .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
        .notRequired(),
      address: Yup.string().required("Address is required"),
      gender: Yup.string().required("Gender is required"),
      pan_no: Yup.string()
        .matches(
          /^[A-Z]{5}[0-9]{4}[A-Z]$/,
          "PAN number must follow the format: 5 uppercase letters, 4 digits, and 1 uppercase letter"
        )
        .notRequired(),
      aadhar_no: Yup.string()
        .length(12, "Aadhaar number must be 12 digits")
        .notRequired(),
    }),
    onSubmit: async values => {
      const newUser = {
        company_id: 1, // Example company ID
        first_name: values.fname,
        middle_name: "",
        last_name: values.lname,
        primary_ph_no: values.primary_ph_no,
        addl_ph_no: values.addl_ph_no,
        address: values.address,
        gender: values.gender,
        pan_no: values.pan_no,
        aadhar_no: values.aadhar_no,
        email: values.email,
        whatsapp_no: values.whatsapp_no,
        user_type: 2, // Add user type
        status: status ? 1 : 0, // Include the status value in the submission (true=1, false=0)
        image: "",
      }
      const errors = await validation.validateForm()
      if (Object.keys(errors).length > 0) {
        // If there are errors, display them in a toast and do not proceed with the submission
        const errorMessages = Object.values(errors)
          .flat() // Flatten the array of error messages
          .join(", ") // Combine all error messages into a single string
        toast.error(errorMessages, { autoClose: 1500 }) // Show toast for validation errors
        console.log("Validation errors:", errors)
        return // Stop the submission process
      }
      // const selectedRolesData = roleAssign.map(role => ({
      //   role_id: role.id,
      //   user_id: userId,
      //   status: selectedRoles[role.id] ? 1 : 0,
      // }))

      // Create selectedRolesData based on fetchedUserRole
      const selectedRolesData = fetchedUserRole.map(role => {
        const isChecked = selectedRoles[role.role_id] ? 1 : 0 // Check if the role is selected

        return {
          id: role.id, // Assuming fetchedUserRole has the `id` field
          role_id: role.role_id,
          user_id: userId,
          status: isChecked,
        }
      })
      console.log("New user data :", newUser)

      try {
        const response = await submitEditSingleUser(newUser, userId)

        // Log the successful response
        console.log("User Updated successfully:", response)
        // toast.success("User Info Updated successfully!", { autoClose: 1500 });
        console.log(
          "second api calling- selected roles with id:",
          selectedRolesData
        )

        // Second API
        try {
          // Prepare the headers with the Bearer token
          const authToken = localStorage.getItem("authUser")
          const headers = {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          }

          // Log the data being sent
          console.log(
            "Selected Roles Data before sending:",
            JSON.stringify(selectedRolesData, null, 2)
          )

          // Make the API call using Fetch
          const response = await fetch(
            `${process.env.REACT_APP_BASEPATH}/role-module/user-role-assign`,
            {
              method: "POST",
              headers: headers,
              body: JSON.stringify(selectedRolesData), // Convert to JSON
            }
          )

          // Check if the response is ok
          if (response.ok) {
            const moduleResponse = await response.json() // Parse JSON response
            console.log("Module Response Data:", moduleResponse)
            toast.success("User and Roles Updated Successfully", {
              autoClose: 1500,
            })
            // window.location.href = "/manage-user"
            setTimeout(() => {
              window.history.back(); // Navigate back to the previous page
            }, 2000);
            // Redirect or handle success as needed
            // history.push("/users")
          } else {
            const errorData = await response.json()
            console.log("Error in submitting permissions:", errorData)
            console.log("Status Code:", response.status)
            const errorMessage = Array.isArray(errorData.message)
              ? errorData.message.join(", ")
              : errorData.message || "An unknown error occurred."
            toast.error(`Error in Assigning User Role: ${errorMessage}`, {
              autoClose: 1500,
            })
          }
        } catch (error) {
          toast.error("Error in Api Calling", { autoClose: 1500 })
          console.error("Error in API call:", error.message)
        }
      } catch (err) {
        const errorMessage = Array.isArray(err.response.data.message)
          ? err.response.data.message.join(", ")
          : err.response.data.message || "An unknown error occurred."

        console.log("Error adding user:", errorMessage)
        toast.error(errorMessage, { autoClose: 1500 })
        setSuccess(null)
      }
    },
  })
  useEffect(() => {
    console.log("Editing User with ID:", userId)
    // Fetch and populate user data based on the ID
    document.title = `Manage User | ${process.env.REACT_APP_SITE_NAME} Admin Panel`
    fetchSingleUserData(userId)
    getUserRole()
    fetchRolesForUser()
  }, [userId])

  const fetchSingleUserData = async () => {
    console.log("User if fetchsingleuser", userId)
    setLoad(true)
    try {
      await getSingleUser(userId)
        .then(response => {
          console.log("Response Data Single User:", response)

          validation.setValues({
            fname: response?.first_name || "",
            lname: response?.last_name || "",
            email: response?.email || "",
            primary_ph_no: response?.primary_ph_no || "",
            addl_ph_no: response?.addl_ph_no || "",
            address: response?.address || "",
            gender: response?.gender || "",
            pan_no: response?.pan_no || "",
            aadhar_no: response?.aadhar_no || "",
            whatsapp_no: response?.whatsapp_no || "",
          })
          setStatus(response?.status === 1 ? true : false)

          // setStatus(response?.status === 1); // Set the status based on the fetched data
          setLoad(false)
        })
        .catch(err => {
          console.log(err)
          setLoad(false)
        })
    } catch (error) {
      console.error("Error fetching user data:", error.message)

      setLoad(false)
      setError("Failed to fetch user data. Please try again.") // Set error message for user
    }
  }
  const fetchRolesForUser = async () => {
    try {
      let params = {
        status: 1,
      }
      await getRoleForUserAssign(params)
        .then(response => {
          if (response) {
            console.log("Roles For Assigned Users is-", response)
            setRoleAssign(response)
          }
        })
        .catch(err => console.log(err))
    } catch (error) {
      // setLoading(false)
      console.log(error)
    }
  }
  // Getting For User-Role
  const getUserRole = async () => {
    try {
      let params = {
        user_id: userId,
      }
      // /role-module/user-role-assign?user_id=89
      const url = `${process.env.REACT_APP_BASEPATH}/role-module/user-role-assign?user_id=${params.user_id}`
      // console.log("URL:", url);

      const authToken = localStorage.getItem("authUser")
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
          "Cache-Control": "no-cache", // Instructs the browser not to cache
          Pragma: "no-cache", // Older HTTP 1.0 equivalent of no-cache
          Expires: "0", // Set expiration to 0 to prevent caching
        },
      })

      if (response.ok) {
        const data = await response.json()
        console.log("Fetched /role-module/user-role-assign?user_id=89", data)
        setFetchedUserRole(data)

        // Update selected roles based on fetchedUserRole
        const updatedSelectedRoles = {}
        data.forEach(role => {
          updatedSelectedRoles[role.role_id] = role.status // Set status of role_id
        })
        setSelectedRoles(prev => ({
          ...prev,
          ...updatedSelectedRoles,
        }))
      } else {
        console.error("Failed to fetch data. Status Code:", response.status)
        const errorData = await response.json()
        console.error("Error Details:", errorData)
      }
    } catch (error) {
      console.log("Error fetching user role data:", error)
    }
  }
  const handleCheckboxChange = roleId => {
    setSelectedRoles(prev => ({
      ...prev,
      [roleId]: !prev[roleId], // Toggle the checked state
    }))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Manage User" breadcrumbItem="Edit User" />

          <Row>
            <Col lg="12">
              <Card>
              <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">
                      Edit User
                    </h5>
                    <div className="flex-shrink-0">
                      <Link
                        to="/manage-user"
                        className="btn btn-primary me-1"
                      >
                        Back
                      </Link>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  {load ? (
                    "Loading "
                  ) : (
                    <>
                      <Form
                        className="form-horizontal"
                        onSubmit={validation.handleSubmit}
                      >
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="fname">
                                First Name{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                name="fname"
                                id="fname"
                                placeholder="Enter Name"
                                value={validation.values.fname}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.fname &&
                                  validation.errors.fname
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.fname &&
                                validation.errors.fname && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.fname}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="lname">
                                Last Name <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                name="lname"
                                id="lname"
                                placeholder="Enter Name"
                                value={validation.values.lname}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.lname &&
                                  validation.errors.lname
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.lname &&
                                validation.errors.lname && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.lname}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="email">
                                Email <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Enter Email"
                                value={validation.values.email}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                                validation.errors.email && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="gender">
                                Gender <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="select"
                                name="gender"
                                id="gender"
                                value={validation.values.gender}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.gender &&
                                  validation.errors.gender
                                    ? true
                                    : false
                                }
                              >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </Input>
                              {validation.touched.gender &&
                                validation.errors.gender && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.gender}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="whatsapp_no">
                                Whatsapp Number (Optional)
                              </Label>
                              <Input
                                type="text"
                                name="whatsapp_no"
                                id="whatsapp_no"
                                placeholder="Enter Number "
                                value={validation.values.whatsapp_no}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.whatsapp_no &&
                                  validation.errors.whatsapp_no
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.whatsapp_no &&
                                validation.errors.whatsapp_no && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.whatsapp_no}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="primary_ph_no">
                                Primary Phone Number (Optional)
                              </Label>
                              <Input
                                type="text"
                                name="primary_ph_no"
                                id="primary_ph_no"
                                placeholder="Enter Number"
                                value={validation.values.primary_ph_no}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.primary_ph_no &&
                                  validation.errors.primary_ph_no
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.primary_ph_no &&
                                validation.errors.primary_ph_no && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.primary_ph_no}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>

                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="addl_ph_no">
                                Additional Phone Number (Optional)
                              </Label>
                              <Input
                                type="text"
                                name="addl_ph_no"
                                id="addl_ph_no"
                                placeholder="Enter Number"
                                value={validation.values.addl_ph_no}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.addl_ph_no &&
                                  validation.errors.addl_ph_no
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.addl_ph_no &&
                                validation.errors.addl_ph_no && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.addl_ph_no}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="pan_no">PAN Number</Label>
                              <Input
                                type="text"
                                name="pan_no"
                                id="pan_no"
                                placeholder="Enter Number"
                                value={validation.values.pan_no}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.pan_no &&
                                  validation.errors.pan_no
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.pan_no &&
                                validation.errors.pan_no && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.pan_no}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="aadhar_no">Aadhaar Number</Label>
                              <Input
                                type="text"
                                name="aadhar_no"
                                id="aadhar_no"
                                placeholder="Enter  Number"
                                value={validation.values.aadhar_no}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.aadhar_no &&
                                  validation.errors.aadhar_no
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.aadhar_no &&
                                validation.errors.aadhar_no && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.aadhar_no}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label htmlFor="address">
                                Address <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="textarea"
                                name="address"
                                id="address"
                                placeholder="Enter Address"
                                value={validation.values.address}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.address &&
                                  validation.errors.address
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.address &&
                                validation.errors.address && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.address}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                        </Row>
                        {/* Checkbox */}
                        <Row>
                          <Label htmlFor="role">Role</Label>
                          {roleAssign.map(role => (
                            <Col md={3} key={role.id}>
                              <div className="mb-3">
                                <Input
                                  type="checkbox"
                                  id={`role_${role.id}`}
                                  checked={selectedRoles[role.id] || false}
                                  onChange={() => handleCheckboxChange(role.id)}
                                />
                                <Label htmlFor={`role_${role.id}`}>
                                  &nbsp;{role.role_name}
                                </Label>
                              </div>
                            </Col>
                          ))}
                        </Row>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label>Status</Label>
                              <FormGroup switch>
                                <Label>
                                  {status ? "" : ""}
                                  <Input
                                    name="status"
                                    type="switch"
                                    role="switch"
                                    checked={status}
                                    onClick={() => setStatus(!status)} // Toggle the status
                                    value={status}
                                  />
                                </Label>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                        {/* <button onClick={navigate("/manage-user")} className="btn btn-primary w-md">Back</button> */}

                        <button type="submit" className="btn btn-primary w-md">
                          Update User
                        </button>

                        {/* <Button type="submit" color="primary">
                  Update User
                </Button> */}
                      </Form>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer position="top-right" autoClose={1500} />
      </div>
    </React.Fragment>
  )
}

export default EditUser
