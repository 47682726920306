import React, { useEffect, useMemo, useState } from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import {
  getVendorTypeAction as onGetVendorTypeList,
  updateVendorTypeAction as onUpdateVendorTypeList,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Badge,
  Button,
  FormGroup,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
import { toast, ToastContainer } from "react-toastify"
import { Link } from "react-router-dom"
import { getVendorType, updateVendorType } from "services/MasterServices"

const VendorType = () => {
  //meta title
  document.title = `Vendor Type |  ${process.env.REACT_APP_SITE_NAME} - Admin Panel`

  const [modal, setModal] = useState(false)
  const [vendorTypeState, setVendorTypeState] = useState(null)
  const [vendorTypeList, setVendorTypeList] = useState([])
  const [searchQuery, setSearchQuery] = useState("") // New state for search input

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      type_name: (vendorTypeState && vendorTypeState.type_name) || "",
      seq_no: (vendorTypeState && vendorTypeState.seq_no) || "",
      status: vendorTypeState && vendorTypeState.status === 1 ? true : false,
    },
    validationSchema: Yup.object({
      type_name: Yup.string().trim().required("Please Enter Name"),
      seq_no: Yup.string().trim().required("Please Enter Sequence Number"),
    }),
    onSubmit: async values => {
      const payload = {
        type_name: values["type_name"].trim(),
        seq_no: values["seq_no"],
        status: values["status"] ? 1 : 0,
      }
      // save new Job

      try {
        await updateVendorType(vendorTypeState.id, payload)
          .then(async response => {
            console.log(response, "response")
            toast.success(`Vendor Type is updated Successfully`, {
              autoClose: 2000,
            })

            getVendorTypeList()
            validation.resetForm()

            toggle()
          })
          .catch(err => {
            console.log(err)
            if (err?.status === 400 && err?.response?.data?.message) {
              toast.error(err?.response?.data?.message, { autoClose: 2000 })
            } else {
              toast.error(`Vendor Type is updated Failed`, { autoClose: 2000 })
            }
          })
      } catch (error) {
        toast.error(`Vendor Type is updated Failed`, { autoClose: 2000 })
      }
    },
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getVendorTypeList()
  }, [])

  const getVendorTypeList = async () => {
    try {
      setLoading(true)
      await getVendorType()
        .then(response => {
          setLoading(false)
          setVendorTypeList(response)
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
    } catch (error) {
      setLoading(false)
    }
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setVendorTypeState(null)
    } else {
      setModal(true)
    }
  }

  const handleJobClick = arg => {
    setVendorTypeState(arg)

    toggle()
  }
  const filteredVendorList = vendorTypeList.filter(m =>
    m.type_name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const columns = useMemo(
    () => [
      {
        header: "Sl.No.",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          return (
            <Link to="#" className="text-body fw-bold">
              {cellProps.row.index + 1}
            </Link>
          )
        },
      },
      {
        header: "Type Name",
        accessorKey: "type_name",
        enableColumnFilter: false,
        enableSorting: false,
        filterFn: "includesString",
      },
      {
        header: "Sequence No",
        accessorKey: "seq_no",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: cellProps => {
          switch (cellProps.row.original.status) {
            case 1:
              return <span className="badge badge-soft-success">Active</span>
            case 0:
              return <span className="badge badge-soft-danger">Inactive</span>
          }
        },
      },
      {
        header: () => <div style={{ textAlign: "right" }}>Action</div>,
        id: "vendor-type-action",
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    handleJobClick(jobData)
                  }}
                  id={`edittooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Master" breadcrumbItem="Vendor Type" />
          {
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                      <h5 className="mb-0 card-title flex-grow-1">
                        Vendor Type
                      </h5>
                    </div>
                  </CardBody>
                  <CardBody>
                    <Row className="mb-2">
                      <Col sm={4}>
                        <Input
                          type="text"
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={e => setSearchQuery(e.target.value)}
                          className="form-control search-box me-2 mb-2 d-inline-block"
                        />
                      </Col>
                    </Row>
                    <TableContainer
                      columns={columns}
                      data={filteredVendorList || []}
                      // isCustomPageSize={true}
                      // isGlobalFilter={true}
                      // isJobListGlobalFilter={true}
                      loading={loading}
                      setLoading={setLoading}
                      isPagination={true}
                      // SearchPlaceholder="Keyword Search"
                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline border-top"
                      pagination="pagination"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {"Edit Vendor Type"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>
                        Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="type_name"
                        type="text"
                        placeholder="Please enter name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.type_name || ""}
                        invalid={
                          validation.touched.type_name &&
                          validation.errors.type_name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.type_name &&
                      validation.errors.type_name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.type_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>
                        Sequence Number <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="seq_no"
                        type="number"
                        placeholder="Please enter sequence number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.seq_no || ""}
                        invalid={
                          validation.touched.seq_no && validation.errors.seq_no
                            ? true
                            : false
                        }
                      />
                      {validation.touched.seq_no && validation.errors.seq_no ? (
                        <FormFeedback type="invalid">
                          {validation.errors.seq_no}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label>Status</Label>
                      <FormGroup switch>
                        <Label className="">
                          {/* {validation.values.status === true?'Active':'Inactive'} */}
                          <Input
                            name="status"
                            type="switch"
                            role="switch"
                            checked={validation.values.status === true}
                            onClick={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.status || true}
                          />
                        </Label>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="primary"
                        type="submit"
                        className="save-user"
                      >
                        Update
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default VendorType
