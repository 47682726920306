import React, { useEffect, useMemo, useState } from "react";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
    getJobList as onGetJobList,
    addNewJobList as onAddNewJobList,
    updateJobList as onUpdateJobList,
    deleteJobList as onDeleteJobList,
} from "store/actions";


import {
    Col,
    Row,
    UncontrolledTooltip,
    Card,
    CardBody,
    Button,
    ModalHeader,
    Modal,
    ModalBody,
    CardTitle,
    UncontrolledAccordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody,
    Table,
    Label,
    Input,

} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import Paginations from "components/Common/Pagination";
import moment from "moment";
import { isEmpty } from "lodash";
import { deleteVendor, getVendorList } from "services/VendorServices";
import SearchPage from "components/Common/SearchPage";
import { objectCreateAttributeHelper } from "helpers/globalHelper";
import { getCategoryDropdown, getVendorType } from "services/MasterServices";

const initialFilter = [
    {
        label: 'Vendor Name',
        value: "",
        placeholder: "Search Vendor",
        type: "text",
        name: "vendor_name",
        // disable: false
    },
    // {
    //     label: 'Category',
    //     value: "",
    //     name: "category",
    //     // placeholder:"Search Vendor",
    //     options: [
    //         {
    //             id: 1,
    //             label: "mango"
    //         },
    //         {
    //             id: 2,
    //             label: "banana"
    //         },

    //     ],
    //     type: "select",
    //     // disable: false
    // }
]

const VendorList = () => {

    //meta title
    document.title = `Vendor List |  ${process.env.REACT_APP_SITE_NAME} - Admin Panel`;


    const [vendors, setVendors] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [job, setJob] = useState(null);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState([])
    const [searchFilter, setSearchFilter] = useState(initialFilter);
    const [isAdvanceSearchOpen, setIsAdvanceSearchOpen] = useState(false)
    const [searchParams, setSearchParams] = useState({
        vendor_type: null,
        category_id: null, main_category_id: null
    })
    const [vendorTypeList, setVendorTypeList] = useState([])

    const [categoryDropdownList, setCategoryDropdownList] = useState([])
    const [subCategoryDropdownList, setSubCategoryDropdownList] = useState([])



    useEffect(() => {
        getVendorListRecord()
    }, [limit, page])

    useEffect(() => {
        getVendorTypeRecord();
        getCategoryList()
    }, [])




    const getVendorListRecord = async (search) => {
        try {
            setLoading(true)


            let params = {
                page: page,
                limit: limit,
                ...search
            }

            // if (isAdvanceSearchOpen) {
            //     let advanceKey = objectCreateAttributeHelper(searchFilter)
            //     params = { ...params, ...advanceKey }
            // }

            await getVendorList(params).then(response => {

                console.log(response)

                if (response.data) {

                    const { meta, data } = response;
                    setVendors(data ?? [])
                    // setTotalCount(Math.ceil(meta?.itemCount / meta?.limit));
                    setTotalCount(meta?.itemCount);

                }
                setLoading(false)

            }).catch(err => console.log(err))

        } catch (error) {
            setLoading(false)
        }
    }


    const getVendorTypeRecord = async () => {
        try {
            let params = {
                status: 'active'
            }
            await getVendorType(params).then(response => {
                console.log(response)
                setVendorTypeList(response)
            }).catch(err => console.log(err))
        } catch (error) {

        }
    }



    const onhandleSearchAdvance = () => {
        console.log(searchFilter, "searchFilter")
        setPage(1)

        getVendorListRecord()
    }

    const onHandleResetAdvance = () => {
        setSearchFilter(initialFilter);
        setPage(1)
        getVendorListRecord()
    }


    //delete Job
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (job) => {
        setJob(job);
        setDeleteModal(true);
    };

    const handleDeletejob = async () => {
        if (job && job.id) {

            try {
                await deleteVendor(job.id).then(response => {
                    console.log(response)
                    // if(response){
                    toast.success("Vendor is deleted successfully.", { autoClose: 500 });
                    // }
                    getVendorListRecord()
                }).catch(err => console.log(err))
            } catch (error) {
                toast.error("Something went wrong.", { autoClose: 500 });
            }


            setDeleteModal(false);
        }
    };


    const handleSearch = () => {
        setPage(1);
        getVendorListRecord(search)
    }

    const handleModal = (data) => {
        setModal(true);
        let temp = data
        setSelectedItem(temp)
    }

    const toggle = () => setModal(!modal);


    const columns = useMemo(
        () => [
            {
                header: 'Sl.No.',
                accessorKey: "id",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    return <Link to="#" className="text-body fw-bold">{(page - 1) * limit + (cellProps.row.index + 1)}</Link>
                }
            },
            {
                header: "Vendor Name",
                accessorKey: "vendor_name",
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: 'Is Gst',
                enableColumnFilter: false,
                enableSorting: false,
                accessorKey: "is_gst",
                cell: (cellProps) => {
                    switch (cellProps.row.original.is_gst) {
                        case 1:
                            return <span className="badge badge-soft-success">Active</span>;
                        case 0:
                            return <span className="badge badge-soft-danger">Inactive</span>;
                    }
                },
            },
            {
                header: 'Is Split Gst',
                enableColumnFilter: false,
                enableSorting: false,
                accessorKey: "is_gst_split",
                cell: (cellProps) => {
                    switch (cellProps.row.original.is_gst_split) {
                        case 1:
                            return <span className="badge badge-soft-success">Active</span>;
                        case 0:
                            return <span className="badge badge-soft-danger">Inactive</span>;
                    }
                },
            },
            {
                header: 'Payment Term',
                enableColumnFilter: false,
                enableSorting: false,
                accessorKey: "paymentTerms.term_name"
            },

            {
                header: 'Status',
                accessorKey: "status",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    switch (cellProps.row.original.status) {
                        case 1:
                            return <span className="badge badge-soft-success">Active</span>;
                        case 0:
                            return <span className="badge badge-soft-danger">Inactive</span>;
                    }
                },
            },
            {
                header: () => <div style={{ textAlign: "right" }}>Action</div>,
                id: "client-action",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">


                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link to={void (0)} className="btn btn-sm btn-soft-primary" id={`viewtooltip-${cellProps.row.original.id}`} onClick={() => handleModal(cellProps.row.original)}>
                                    <i className="mdi mdi-eye-outline" />
                                </Link>
                            </li>
                            <UncontrolledTooltip placement="top" target={`viewtooltip-${cellProps.row.original.id}`}>
                                View Vendor Contact Details
                            </UncontrolledTooltip>
                            <li>
                                <Link
                                    to={`/vendor/edit/${cellProps.row.original.id}`}
                                    className="btn btn-sm btn-soft-info"

                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            {/* <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        onClickDelete(jobData);
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete-outline" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li> */}
                        </ul>
                    );
                }
            },
        ],
        [page]
    );


    const handleCategory = (e) => {
        setSearchParams(prev => ({ ...prev, main_category_id: e.target.value }))

        getSubcategoryList(e.target.value)
    }



    const getCategoryList = async () => {
        let params = {
            status: 'active',
            parent_type: 'parent',

        }

        try {
            await getCategoryDropdown({ params }).then(response => {
                console.log(response)

                setCategoryDropdownList(response)


            }).catch(err => console.log(err))
        } catch (error) {
            console.log(error)
        }


    }


    const getSubcategoryList = async (subcatId) => {
        let params = {
            status: 'active',
            parent_id: subcatId

        }

        try {
            await getCategoryDropdown({ params }).then(response => {
                console.log(response)

                setSubCategoryDropdownList(response)


            }).catch(err => console.log(err))
        } catch (error) {
            console.log(error)
        }


    }


    const handleSubmit = () => {
        let { category_id, vendor_type ,vendor_name} = Object.entries(searchParams).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {});
        let tempPayload = {}

        if (vendor_name) {
            tempPayload = { ...tempPayload, vendor_name }
        }
        if (vendor_type) {
            tempPayload = { ...tempPayload, vendor_type }
        }
        if (category_id) {
            tempPayload = { ...tempPayload, category_id }
        }
        getVendorListRecord(tempPayload);

        setPage(1)
    }


    const handleReset = () => {
        setPage(1)
        setSearchParams({
            vendor_name: "",
            vendor_type: "",
            category_id: "", main_category_id: ""
        })

        getVendorListRecord()


    }


    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeletejob}
                onCloseClick={() => setDeleteModal(false)}
                mainHeader="Are you sure you want to delete this vendor?"
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Manage Vendor" />
                    {
                        // isLoading ? <Spinners setLoading={setLoading} />
                        //     :
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody className="border-bottom">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0 card-title flex-grow-1">Vendor</h5>
                                            <div className="flex-shrink-0">
                                                <Link to="/vendor/add" className="btn btn-primary me-1">Add</Link>

                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        {/* <Row className="mb-2">
                                            <Col sm={4}>
                                                <input value={search} onChange={e => {
                                                    setSearch(e.target.value);
                                                    setPage(1)
                                                }} type="text" placeholder="Keyword Search" className="form-control search-box me-2 mb-2 d-inline-block" />{" "}

                                            </Col>
                                            <Col sm={2}>     <Button type="button" onClick={handleSearch} color="primary">Search</Button></Col>
                                        </Row> */}


                                        <Card>
                                            <CardBody>
                                                <Row>
                                                <Col md={3}>
                                                        <Label>
                                                            Vendor Name
                                                        </Label>
                                                        <Input type="text" value={searchParams.vendor_name}
                                                        placeholder="Search vendor name"
                                                        onChange={(e) => setSearchParams(prev => ({ ...prev, vendor_name: e.target.value }))}/>
                                                      
                                                    </Col>
                                                    <Col md={3}>
                                                        <Label>
                                                            Vendor Type
                                                        </Label>
                                                        <Input type="select" value={searchParams.vendor_type} onChange={(e) => setSearchParams(prev => ({ ...prev, vendor_type: e.target.value }))}>
                                                            <option value={""}> Select</option>
                                                            {
                                                                vendorTypeList?.map((item, ind) => <option key={`vendortype-${ind}`} value={item?.id}>{item?.type_name}</option>)
                                                            }
                                                        </Input>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Label>
                                                            Category
                                                        </Label>
                                                        <Input type="select" value={searchParams.main_category_id} onChange={handleCategory}>
                                                            <option value={""}> Select</option>
                                                            {
                                                                categoryDropdownList?.map((item, ind) => <option key={`categoryDropdownList-${ind}`} value={item?.id}>{item?.category_name}</option>)
                                                            }
                                                        </Input>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Label>
                                                            Subcategory
                                                        </Label>
                                                        <Input type="select" value={searchParams.category_id} onChange={(e) => setSearchParams(prev => ({ ...prev, category_id: e.target.value }))}>
                                                            <option value={""}> Select</option>
                                                            {
                                                                subCategoryDropdownList?.map((item, ind) => <option key={`categoryDropdownList-${ind}`} value={item?.id}>{item?.category_name}</option>)
                                                            }
                                                        </Input>
                                                    </Col>
                                                </Row>

                                                <Row
                                                    className='mt-1 flex-row-reverse'
                                                >

                                                    <Col md={12} >

                                                        <div className="d-flex flex-row-reverse">
                                                            <div className="p-2">   <Button color={"primary"} onClick={handleSubmit}>Submit</Button></div>
                                                            <div className="p-2"> <Button color={"primary"} onClick={handleReset}>Reset</Button></div>
                                                        </div>

                                                    </Col>
                                                    <Col sm={2}  >

                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>


                                        {/* <SearchPage searchFilter={searchFilter} setSearchFilter={setSearchFilter} setIsAdvanceSearchOpen={setIsAdvanceSearchOpen} isAdvanceSearchOpen={isAdvanceSearchOpen} handleSubmit={onhandleSearchAdvance} handleReset={onHandleResetAdvance} /> */}


                                        <TableContainer
                                            columns={columns}
                                            data={vendors || []}
                                            loading={loading}
                                            setLoading={setLoading}

                                            isGlobalFilter={false}
                                            // isJobListGlobalFilter={true}
                                            isPagination={false}
                                            SearchPlaceholder="Search for ..."
                                            tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline border-top"

                                        />

                                        {
                                            totalCount > 0 &&
                                            <Paginations
                                                perPageData={limit}
                                                data={vendors}
                                                currentPage={page}
                                                setCurrentPage={setPage}
                                                isShowingPageLength={!loading}
                                                totalCount={totalCount}
                                                paginationClass="pagination"
                                                paginationDiv="dataTables_paginate paging_simple_numbers pagination-rounded"

                                            />
                                        }

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }

                </div>
            </div>

            <Modal isOpen={modal} toggle={toggle} size="lg" >
                {console.log(selectedItem,"selectedItem")}
                <ModalHeader toggle={toggle}>Contact Details {!isEmpty(selectedItem) ? ` of ${selectedItem?.vendor_name}` : ''}</ModalHeader>
                <ModalBody>

                    <div className="table-responsive">
                        <Table className="table table-striped mb-0">
                            <thead>
                                <tr>
                                    <th>Sl.No.</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th> Primary Number</th>
                                    <th> Secondary Number</th>
                                    <th> WhatsApp </th>
                                    <th> Address </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    selectedItem?.vendorContact?.length > 0 ? selectedItem?.vendorContact?.map((item, index) => <tr key={`contct-${index}`}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{item?.contact_fname} {" "} {item?.contact_lname}</td>
                                        <td>{item?.contact_email}</td>
                                        <td>{item?.contact_primary_ph_no}</td>
                                        <td>{item?.contact_addl_ph_no}</td>
                                        <td>{item?.whatsapp_no}</td>
                                        <td>{item?.contact_address}</td>
                                    </tr>)

                                        : <tr key={`contct-noavailble`}>
                                            <td colSpan={6}>No contact available</td>

                                        </tr>

                                }

                            </tbody>
                        </Table>
                    </div>

                    {/* <UncontrolledAccordion defaultOpen="aa-0">

                {
                      selectedItem?.clientContact?.length>0 ?  selectedItem?.clientContact?.map((item, index) => <AccordionItem key={`accord-${index}`}>
                        <AccordionHeader targetId={`aa-${index}`}>{index+1}. {item?.fname} {" "} {item?.lname} </AccordionHeader>
                        <AccordionBody accordionId={`aa-${index}`}>
                          <div className="text-muted">
                          <div className="d-flex">
                                    <strong> Email :</strong><p className="px-2">{item?.email}</p>
                                </div>
                                <div className="d-flex">
                                    <strong>  Primary Phone Number :</strong><p className="px-2">{item?.ph_no1}</p>
                                </div>
                                <div className="d-flex">
                                    <strong> Secondary Phone Number :</strong><p className="px-2">{item?.ph_no2}</p>
                                </div>
                                <div className="d-flex">
                                    <strong>  Whatsapp Number :</strong><p className="px-2">{item?.whatsapp_number}</p>
                                </div>
                                <div className="d-flex">
                                    <strong>  Birthday :</strong><p className="px-2">{item?.bday? moment(item?.bday).format('DD MMM, YYYY'):'N/A'}</p>
                                </div>
                                <div className="d-flex">
                                    <strong>  Anniversary Day :</strong><p className="px-2">{item?.aday? moment(item?.aday).format('DD MMM, YYYY'):'N/A'}</p>
                                </div>
                                

                          </div>
                        </AccordionBody>
                      </AccordionItem>
                        
                        
                        
                     


                         )

                         : 
                         <AccordionItem className="notshowAcoordionIcon">
                         <AccordionHeader >No Contact Available </AccordionHeader>
                         
                       </AccordionItem>
                         

                    }
                       
                   
                      </UncontrolledAccordion> */}





                </ModalBody>

            </Modal>
            <ToastContainer />
        </React.Fragment>
    );
}


export default VendorList;