

export const get_file_full_path = (asset) => {
    return process.env.REACT_APP_STORAGEPATH + `/product_media/${asset}`
}


export const  generateUniqueNumber = (existingArray,min=100, max=1000) =>{
    while (true) {
    //   const randomNumber =  Math.floor(Math.random() * (30 - 20)) + 20;

     const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  
      if (!existingArray.find(item=>item.id === randomNumber)) {
        return randomNumber;
      }
    }
  };


  export const paymentTypeList = [
    {
        label:"Sale",
        value:"s"
    },
    {
        label:"Purchase",
        value:"p"
    },
    {
        label:"Refund",
        value:"r"
    }
  ]