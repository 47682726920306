import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSearchParams, useParams, Link } from 'react-router-dom';
import { Spinner } from "reactstrap";

// Services
import { getShipmentsByOrder } from "services/OrderService"
import { saveOrder, updateOrder, deleteOrderItem, getOrderById } from "services/OrderService"

const deliveriesTab = (props) => {

    const params = useParams()
    const [isLoading, setLoading] = useState(true)
    const [orders, setOrders] = useState([])

    const getShipmentsByOorderID = async () => {
        setLoading(true)
        const id = params.id
        try {
            if (id) {
                await getOrderById(id)
                    .then(res => {
                        const result = flattenShipmentData(res);
                        setOrders(result)
                    })
            }
        } catch (error) {
            console.log(error);
        }
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    const flattenShipmentData = (res) => {
        const flattened = [];
        const lineItems = res.orderLineItem
        const shipments = res.orderShipments
        shipments.forEach(shipment => {
            shipment.orderDelivery.forEach(delivery => {
                const line_item = lineItems.find(iitem => iitem.id == Number(delivery.line_item_id))
                
                flattened.push({
                    line_item_id: delivery.line_item_id,
                    line_item_title: line_item.line_item_title,
                    line_item_sku: line_item.line_item_sku,
                    delivery_date: moment(shipment.delivery_date).format('DD/MM/YYYY'),
                    quantity: delivery.quantity,
                    shipment_id: shipment.id,
                    tracking: shipment.shipping_tracking_info,
                    shipping_amount: shipment.shipping_amount,
                    delivery_method: shipment.delivery_method,
                    status: shipment.status,
                    shipping_note: shipment.shipping_note,
                    order_id: shipment.order_id
                });
            });
        });

        return flattened.reverse();
    };

    useEffect(() => {
        getShipmentsByOorderID()
    }, [])

    return (
        <React.Fragment>
            {isLoading && <div style={{ background: 'rgba(0,0,0,0.3)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 99999 }}>
                <Spinner color="primary" className='position-absolute top-50 start-50' style={{ zIndex: 99 }} />
            </div>}
            <table className="table table-striped">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Date</th>
                    <th scope="col">Product</th>
                    <th scope="col">SKU</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Method</th>
                    <th scope="col">Tracking Info</th>
                    <th scope="col">Amount</th>
                    </tr>
                </thead>
            <tbody>
                    {orders.length > 0 && orders.map((order, i) => <tr key={i}>
                        <th scope="row">{ i + 1 }</th>
                        <td>{ order.delivery_date }</td>
                        <td>{ order.line_item_title }</td>
                        <td>{ order.line_item_sku }</td>
                        <td>{ order.quantity }</td>
                        <td>{ order.delivery_method }</td>
                        <td>{ order.line_item_title }</td>
                        <td>₹{ order.shipping_amount }</td>
                    </tr>)}
                    {orders.length <= 0 && <tr>
                        <th scope="row" colSpan={8} style={{ textAlign: 'center', padding: '30px 0' }}>
                            {isLoading ? 'Loading data...' : 'No deliveries found'}
                        </th>
                    </tr>}
            </tbody>

            </table>
        </React.Fragment>
    )
}

export default deliveriesTab