import Breadcrumb from 'components/Common/Breadcrumb'
import Spinners from 'components/Common/Spinner'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import { getCategoryDropdown } from 'services/MasterServices'
import { createMediaProduct, getSingleProduct, updateProduct } from 'services/ProductServices'





const MediaType = ({ fromType, handleClose }) => {
    const { id } = useParams()

    const [loading, setLoading] = useState(false)
    const [mainLoading, setMainLoading] = useState(false)
    const [status, setStatus] = useState(true)
    const inputRefs = React.useRef([]);
    const navigate = useNavigate()












    const handleSubmit = async (e) => {
        e.preventDefault()


        let submitFlag = true;







        if (submitFlag) {
            try {

                setLoading(true)
                await formRows.forEach(async item => {



                    const { file, isPrimary, status } = item

                    if (file) {
                        let payload = {
                            file_name: "ffaf",
                            file: file,
                            prod_id: id,
                            media_type: "image",
                            is_primary: isPrimary ? 1 : 0,

                            status: status ? 1 : 0,

                        }



                        const headers = {
                            'Content-Type': 'multipart/form-data'
                        };


                        await createMediaProduct(payload, headers).then(response => {
                            console.log(response)
                            if (response) {

                                // toast.success("Product Media is uploaded successfully.", { autoClose: 2000 })


                            }
                            // setLoading(false)
                        }).catch(err => {
                            console.log(err);
                            toast.error(`Product is uploaded Failed`, { autoClose: 2000 });



                        })

                    }


                })

                toast.success("Product Media is uploaded successfully.", { autoClose: 2000 })

                if (fromType === 1) {
                    setTimeout(() => {

                        handleClose()
                        setLoading(false)
                    }, 3000);
                } else {
                    setTimeout(() => {

                        navigate('/product')
                        setLoading(false)
                    }, 2000);

                }




            } catch (error) {
                console.log(error)
                toast.error(`Something went wrong.`, { autoClose: 2000 });
                setLoading(false)
            }
        }
    }


    // Example
    const [formRows, setFormRows] = useState([
        { id: 1, file: "", status: true, isPrimary: false }
    ]);
    const onAddFormRow = () => {
        const newRow = { id: Math.floor(Math.random() * (30 - 20)) + 20, file: "", status: true, isPrimary: false };
        if (formRows.length < 5) {

            setFormRows([...formRows, newRow]);
        }
    };

    const onDeleteFormRow = (id) => {
        const updatedRows = formRows.filter((row) => row.id !== id);
        setFormRows(updatedRows);
    };

    // Function to handle changes in input fields
    const handleInputChange = (id, name, value, index) => {

        if (name === 'file') {
            const file = value.files[0];
          

            if (file && (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg')) {
                toast.error("Please upload a PNG, JPG, JPEG file only", {
                    autoClose: 3000,
                    position: 'top-right',
                    hideProgressBar: true
                });
                inputRefs.current[index].value = null;
                return;
            }

            // if(file.size > 3 * 1024 * 1024){
            //     inputRefs.current[index].value = null;
            //     toast.error("The size of your file should be less than 3MB.", {
            //       position: "top-right",
            //       autoClose: 3000,
            //       theme: "colored",
            //       hideProgressBar: true,
            //     });
             
            //     return; // Return early if file extension is not allowed
            //   }


            const updatedRows = formRows.map(row => {
                if (row.id === id) {
                    return { ...row, [name]: file };
                }
                return row;
            });
            setFormRows(updatedRows);
        } else {
            console.log(value.checked)
            const { checked, type } = value

            const updatedRows = formRows.map(row => {
                if (row.id === id) {
                    return { ...row, [name]: !value.checked };
                }
                return row;
            });
            setFormRows(updatedRows);

        }




    };







    return (

        <Form className="repeater" onSubmit={handleSubmit} >
            <Row>
                <Col md={12}>
                    <div>
                        {(formRows || []).map((formRow, key) => (
                            <Row key={key}>




                                <Col lg={6} className="mb-3">
                                    <label htmlFor="resume">Media</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="file"
                                        name={`file${formRow.id}`}
                                        value={formRow.resume}
                                        accept="image/png, image/jpg, image/jpeg"
                                        ref={(element) => inputRefs.current[key] = element}
                                        onChange={(e) => handleInputChange(formRow.id, 'file', e.target, key)}
                                        onClick={(event) => {
                                            event.target.value = null
                                        }
                                        }
                                    />
                                </Col>

                                <Col lg={2} className="mb-3">
                                    <label htmlFor="status">Status</label>

                                    <FormGroup switch>
                                        <Label className="">
                                             {/* {formRow.status === true ? 'Active' : 'Inactive'} */}
                                            <Input
                                                name="status"
                                                type="switch"
                                                role="switch"
                                                checked={formRow.status}

                                                onClick={(e) => handleInputChange(formRow.id, 'status', e.target)}

                                                value={status}


                                            />

                                        </Label>
                                    </FormGroup>
                                </Col>


                                <Col lg={2} className="mb-3">
                                    <label htmlFor="isPrimary">Is Primary</label>

                                    <FormGroup switch>
                                        <Label className=""> 
                                            {/* {formRow.isPrimary === true ? 'Active' : 'Inactive'} */}
                                            <Input
                                                name="isPrimary"
                                                type="switch"
                                                role="switch"
                                                checked={formRow.isPrimary}

                                                onClick={(e) => handleInputChange(formRow.id, 'isPrimary', e.target)}

                                                value={status}


                                            />

                                        </Label>
                                    </FormGroup>
                                </Col>






                                <Col lg={2} className="align-self-center">
                                    <div className="d-grid">
                                        <input
                                            type="button"
                                            className="btn btn-primary"
                                            value="Delete"
                                            onClick={() => onDeleteFormRow(formRow.id)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </div>
                    <input
                        type="button"
                        className="btn btn-primary mt-3 mt-lg-0"
                        value="Add"
                        disabled={formRows.length >= 5}
                        onClick={() => onAddFormRow()}
                    />

                </Col>
                <Col md={12}>
                    <div className="my-3 text-end">
                        <button type="submit" className="btn btn-primary w-md" disabled={loading}>
                            Submit
                        </button>
                    </div>
                </Col>

            </Row>

        </Form>


    )
}

export default MediaType