import Breadcrumb from 'components/Common/Breadcrumb'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import { getClientType, postClient } from 'services/ClientManagementServices'
import { getPaymentTerm } from 'services/PaymentTermServices'
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'

const countryList = ['India', 'USA', 'UK'];

const AddClient = () => {
    // const [formData, setFormData] = useState({
    //     cTypeId: '',
    //     paymentTermId: '',
    //     clientName: '',
    //     address: '',
    //     status: true,
    // })

    const [clientTypeRecordList, setClientTypeRecordList] = useState([]);
    const [paymentTermRecordList, setPaymentTermRecordList] = useState([]);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()



    //meta title
    document.title = `Client Add  | ${process.env.REACT_APP_SITE_NAME} Admin Panel`;

    //Grid form
    const formik = useFormik({
        initialValues: {
            cTypeId: "",
            paymentTermId: "",
            clientName: "",
            address: "",
            reg_date: "",
            country: "",
            status: true
        },
        validationSchema: Yup.object({
            cTypeId: Yup.string().required("This field is required"),
            paymentTermId: Yup.string().required("This field is required"),
            clientName: Yup.string().required("This field is required"),
            country: Yup.string().required("This field is required"),
            reg_date: Yup.string().required("This field is required"),

        }),

        onSubmit: async (values) => {
            // console.log("value", values.password);
            let payload = {
                ctype_id: Number(values.cTypeId),
                default_payment_terms_id: Number(values.paymentTermId),
                client_name: values.clientName,
                address: values.address,
                reg_date: values.reg_date ?  moment(values.reg_date , "DD-MM-YYYY").format("YYYY-MM-DD"): values.reg_date,
                country: values.country,
                status: values.status ? 1 : 0
            }
            console.log(payload,"payload",values.reg_date,"---", moment(values.reg_date , "DD-MM-YYYY").format("YYYY-MM-DD"))

            if (formRows.length > 0 && formRows[0]?.fname !== "") {

                let tempArray = formRows.map(function (item) {

                    if (item?.fname === "") {
                        return null;
                    }
                    delete item.id;

                    return {...item,
                        bday:item.bday!=""? moment(item.bday , "DD-MM-YYYY").format("YYYY-MM-DD"):item.bday,
                        aday:item.aday!=""? moment(item.aday , "DD-MM-YYYY").format("YYYY-MM-DD"):item.aday
                    
                    };
                }).filter(a => a);


                payload['clientContacts'] = tempArray

            }



            try {

                if (payload.clientContacts === null || payload.clientContacts === undefined || payload['clientContacts'].length == 0) {
                    toast.error("Please fill at least 1 Contact Detail.", { autoClose: 2000 });
                    return;
                }
                setLoading(true)
                await postClient(payload).then(response => {
                    console.log(response)
                    setLoading(false)
                    toast.success("Client is created successfully.", { autoClose: 2000 });
                    navigate('/client')
                }).catch(err => {
                    toast.error("Something went wrong.", { autoClose: 2000 });
                    console.log(err);
                    setLoading(false)

                })
            } catch (error) {
                toast.error("Something went wrong.", { autoClose: 2000 });
                setLoading(false)
            }
        },
    });

    useEffect(() => {
        getCientTypeRecord();
        getPaymentTermRecord();
    }, [])

    const getCientTypeRecord = async () => {
        try {
            let params = {
                status: "active"
            }
            await getClientType(params).then(response => {
                console.log(response)
                setClientTypeRecordList(response)
            }).catch(err => console.log(err))
        } catch (error) {

        }

    }
    const getPaymentTermRecord = async () => {
        try {
            let params = {
                status: "active"
            }
            await getPaymentTerm(params).then(response => {
                console.log(response)
                setPaymentTermRecordList(response)
            }).catch(err => console.log(err))
        } catch (error) {
            console.log(error)
        }

    }


    const [formRows, setFormRows] = useState([
        { id: 1, fname: "", lname: "", email: "", ph_no1: "", ph_no2: "", whatsapp_number: "", bday: "", aday: "" }
    ]);
    const onAddFormRow = () => {
        const newRow = { id: Math.floor(Math.random() * (30 - 20)) + 20, fname: "", lname: "", email: "", ph_no1: "", ph_no2: "", whatsapp_number: "", bday: "", aday: "" };
        setFormRows([...formRows, newRow]);
    };

    const onDeleteFormRow = (id) => {
        const updatedRows = formRows.filter((row) => row.id !== id);
        setFormRows(updatedRows);
    };

    // Function to handle changes in input fields
    const handleInputChange = (id, name, value) => {
        const updatedRows = formRows.map(row => {
            if (row.id === id) {
                return { ...row, [name]: value };
            }
            return row;
        });
        setFormRows(updatedRows);
    };


    // const handleChange = (e) => {
    //     const { name, value, type, checked } = e.target;

    //     setFormData(prev => ({ ...prev, [name]: type === 'checkbox' ? checked : value }))
    // }

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumb title="Manage Client" to={'/client'} breadcrumbItem="Add Client" />

                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody className="border-bottom">
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 card-title flex-grow-1">Add Client</h5>
                                    <div className="flex-shrink-0">
                                        <Link to="/client" className="btn btn-primary me-1">Back</Link>

                                    </div>
                                </div>
                            </CardBody>
                            <CardBody>
                                <Form onSubmit={formik.handleSubmit}>


                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-firstname-Input">Client Name <span className='text-danger'>*</span></Label>
                                                <Input
                                                    type="text"
                                                    name="clientName"
                                                    className="form-control"
                                                    id="formrow-cname-Input"
                                                    placeholder="Enter Client Name"
                                                    value={formik.values.clientName}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    invalid={
                                                        formik.touched.clientName && formik.errors.clientName ? true : false
                                                    }
                                                />
                                                {
                                                    formik.errors.clientName && formik.touched.clientName ? (
                                                        <FormFeedback type="invalid">{formik.errors.clientName}</FormFeedback>
                                                    ) : null
                                                }
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-ctype-Input">Client Type <span className='text-danger'>*</span></Label>
                                                <Input
                                                    type="select"
                                                    name="cTypeId"
                                                    className="form-control"
                                                    id="formrow-ctype-Input"
                                                    placeholder="Enter Client Type"
                                                    value={formik.values.cTypeId}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    invalid={
                                                        formik.touched.cTypeId && formik.errors.cTypeId ? true : false
                                                    }
                                                >
                                                    <option value={""}>Select</option>
                                                    {
                                                        clientTypeRecordList?.map((item, index) => <option value={item.id} key={`cliewnt-type-${index}`}>{item.type_name}</option>)
                                                    }
                                                </Input>
                                                {
                                                    formik.errors.cTypeId && formik.touched.cTypeId ? (
                                                        <FormFeedback type="invalid">{formik.errors.cTypeId}</FormFeedback>
                                                    ) : null
                                                }
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-country-Input">Country <span className='text-danger'>*</span></Label>
                                                <Input
                                                    type="select"
                                                    name="country"
                                                    className="form-control"
                                                    id="formrow-ctype-Input"
                                                    placeholder="Please choose Country"
                                                    value={formik.values.country}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    invalid={
                                                        formik.touched.country && formik.errors.country ? true : false
                                                    }
                                                >
                                                    <option value={""}>Select</option>
                                                    {
                                                        countryList?.map((item, index) => <option value={item} key={`country-type-${index}`}>{item}</option>)
                                                    }
                                                </Input>
                                                {
                                                    formik.errors.country && formik.touched.country ? (
                                                        <FormFeedback type="invalid">{formik.errors.country}</FormFeedback>
                                                    ) : null
                                                }
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-paymentTermId-Input">Payment Term <span className='text-danger'>*</span></Label>
                                                <Input
                                                    type="select"
                                                    name="paymentTermId"
                                                    className="form-control"
                                                    id="formrow-paymentTermId-Input"
                                                    placeholder="Enter Payment Term"
                                                    value={formik.values.paymentTermId}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    invalid={
                                                        formik.touched.paymentTermId && formik.errors.paymentTermId ? true : false
                                                    }
                                                >
                                                    <option value={""}>Select</option>
                                                    {
                                                        paymentTermRecordList?.map((item, index) => <option key={`payment-tem-${index}`} value={item?.id}>{item?.term_name}</option>)
                                                    }
                                                </Input>
                                                {
                                                    formik.errors.paymentTermId && formik.touched.paymentTermId ? (
                                                        <FormFeedback type="invalid">{formik.errors.paymentTermId}</FormFeedback>
                                                    ) : null
                                                }
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                {console.log(formik.values.reg_date, "formik.values.reg_date")}
                                                <Label htmlFor="formrow-reg_date-Input">Registration Date <span className='text-danger'>*</span></Label>
                                                {/* <Input
                                                    type="date"
                                                    name="reg_date"
                                                    row={3}
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={formik.values.reg_date}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    invalid={
                                                        formik.touched.reg_date && formik.errors.reg_date ? true : false
                                                    }
                                                /> */}
                                                <DatePicker

                                                    value={formik.values.reg_date || null}
                                                    className={`form-control ${formik.errors.reg_date && formik.touched.reg_date ? 'is-invalid' : ''}`}

                                                    name="reg_date"


                                                    format="dd-MM-y"

                                                    placeholder="DD/MM/YYYY"

                                                    onChange={(e) => formik.setFieldValue('reg_date', moment(e).format('DD-MM-YYYY'))}


                                                />
                                                {
                                                    formik.errors.reg_date && formik.touched.reg_date ? (
                                                        <FormFeedback className='is-invalid d-block'>{formik.errors.reg_date}</FormFeedback>
                                                    ) : null
                                                }
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-address-Input">Address </Label>
                                                <Input
                                                    type="textarea"
                                                    name="address"
                                                    row={3}
                                                    className="form-control"
                                                    id="formrow-address-Input"
                                                    placeholder="Enter Client Address"
                                                    value={formik.values.address}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}

                                                />

                                            </div>
                                        </Col>

                                    </Row>


                                    <Row className='my-4'>
                                        <Col md={12}>


                                            <h6 className="mb-4 card-title">Contact Details</h6>
                                            {/* <Form className="repeater" encType="multipart/form-data" > */}
                                            <div>
                                                {(formRows || []).map((formRow, key) => (
                                                    <Card outline color="primary" className="border" key={`card-${key}`}>
                                                        <CardBody>

                                                            <Row >
                                                                <Col lg={4} className="mb-3">
                                                                    <label htmlFor="fname">First Name </label>
                                                                    <input
                                                                        type="text"
                                                                        id="fname"
                                                                        name={`fname_${formRow.id}`}
                                                                        value={formRow.fname}
                                                                        onChange={(e) => handleInputChange(formRow.id, 'fname', e.target.value)}
                                                                        className="form-control"
                                                                        placeholder="Enter Your First Name"
                                                                    />
                                                                </Col>
                                                                <Col lg={4} className="mb-3">
                                                                    <label htmlFor="lname">Last Name</label>
                                                                    <input
                                                                        type="text"
                                                                        id="lname"
                                                                        name={`lname_${formRow.id}`}
                                                                        value={formRow.lname}
                                                                        onChange={(e) => handleInputChange(formRow.id, 'lname', e.target.value)}
                                                                        className="form-control"
                                                                        placeholder="Enter Your Last Name"
                                                                    />
                                                                </Col>

                                                                <Col lg={4} className="mb-3">
                                                                    <label htmlFor="email">Email</label>
                                                                    <input
                                                                        type="email"
                                                                        id="email"
                                                                        name={`email_${formRow.id}`}
                                                                        value={formRow.email}
                                                                        onChange={(e) => handleInputChange(formRow.id, 'email', e.target.value)}
                                                                        className="form-control"
                                                                        placeholder="Enter Your Email ID"
                                                                    />
                                                                </Col>

                                                                <Col lg={4} className="mb-3">
                                                                    <label htmlFor="ph_no1">Primary Phone Number</label>
                                                                    <input
                                                                        type="text"
                                                                        id="ph_no1"
                                                                        name={`ph_no1-${formRow.id}`}
                                                                        value={formRow.subject}
                                                                        onChange={(e) => handleInputChange(formRow.id, 'ph_no1', e.target.value)}
                                                                        className="form-control"
                                                                        placeholder="Enter Your Primary Phone Number"
                                                                    />
                                                                </Col>
                                                                <Col lg={4} className="mb-3">
                                                                    <label htmlFor="ph_no2">Secondary Phone Number</label>
                                                                    <input
                                                                        type="text"
                                                                        id="ph_no2"
                                                                        name={`ph_no2-${formRow.id}`}
                                                                        value={formRow.subject}
                                                                        onChange={(e) => handleInputChange(formRow.id, 'ph_no2', e.target.value)}
                                                                        className="form-control"
                                                                        placeholder="Enter Your Secondary Phone Number"
                                                                    />
                                                                </Col>
                                                                <Col lg={4} className="mb-3">
                                                                    <label htmlFor="whatsapp_number">WhatsApp Number</label>
                                                                    <input
                                                                        type="text"
                                                                        id="whatsapp_number"
                                                                        name={`whatsapp_number-${formRow.id}`}
                                                                        value={formRow.whatsapp_number}
                                                                        onChange={(e) => handleInputChange(formRow.id, 'whatsapp_number', e.target.value)}
                                                                        className="form-control"
                                                                        placeholder="Enter Your Whatsapp Number"
                                                                    />
                                                                </Col>
                                                                <Col lg={4} className="mb-3">
                                                                    <label htmlFor="bday">Birthday</label>
                                                                    {/* <input
                                                                        type="date"
                                                                        id="bday"
                                                                        name={`bday-${formRow.id}`}
                                                                        value={formRow.bday}
                                                                        onChange={(e) => handleInputChange(formRow.id, 'bday', e.target.value)}
                                                                        className="form-control"

                                                                    /> */}

                                                                    <DatePicker

                                                                        value={formRow.bday || null}
                                                                        className={`form-control`}
                                                                        name={`bday-${formRow.id}`}

                                                                        id="bday"
                                                                        format="dd-MM-y"

                                                                        placeholder="DD/MM/YYYY"


                                                                        onChange={(e) => handleInputChange(formRow.id, 'bday', moment(e).format('DD-MM-YYYY'))}


                                                                    />
                                                                </Col>
                                                                <Col lg={4} className="mb-3">
                                                                    <label htmlFor="aday">Anniversary Day</label>
                                                                    {/* <input
                                                                        type="date"
                                                                        id="aday"
                                                                        name={`aday-${formRow.id}`}
                                                                        value={formRow.aday}
                                                                        onChange={(e) => handleInputChange(formRow.id, 'aday', e.target.value)}
                                                                        className="form-control"

                                                                    /> */}
                                                                    <DatePicker

                                                                        value={formRow.aday || null}
                                                                        className={`form-control`}
                                                                        name={`aday-${formRow.id}`}

                                                                        id="aday"
                                                                        format="dd-MM-y"

                                                                        placeholder="DD/MM/YYYY"


                                                                        onChange={(e) => handleInputChange(formRow.id, 'aday', moment(e).format('DD-MM-YYYY'))}


                                                                    />
                                                                </Col>


                                                                {formRows.length > 1 &&
                                                                    <Col lg={4} className="align-self-center">
                                                                        <div className="d-grid">
                                                                            <input
                                                                                type="button"
                                                                                className="btn btn-primary"
                                                                                value="Delete"
                                                                                onClick={() => onDeleteFormRow(formRow.id)}
                                                                            />
                                                                        </div>
                                                                    </Col>

                                                                }

                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                ))}
                                            </div>
                                            <input
                                                type="button"
                                                className="btn btn-primary mt-3 mt-lg-0"
                                                value="Add"
                                                onClick={() => onAddFormRow()}
                                            />
                                            {/* </Form> */}






                                        </Col>
                                    </Row>

                                    <div className="mb-3">
                                        <Label>Status</Label>
                                        <FormGroup switch>
                                            <Label className=""> 
                                                {/* {formik.values.status === true ? 'Active' : 'Inactive'} */}
                                                <Input
                                                    name="status"
                                                    type="switch"
                                                    role="switch"
                                                    // checked={formik.values.status === true}

                                                    onClick={formik.handleChange}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.status || true}


                                                />

                                            </Label>

                                        </FormGroup>


                                    </div>
                                    <div>
                                        <button type="submit" className="btn btn-primary w-md" disabled={loading}>
                                            Submit
                                        </button>
                                    </div>
                                </Form>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <ToastContainer />
            </div>
        </div>
    )
}

export default AddClient