import Breadcrumb from 'components/Common/Breadcrumb'
import Spinners from 'components/Common/Spinner'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import { getCategoryDropdown } from 'services/MasterServices'
import { createMediaProduct, getSingleProduct, updateProduct } from 'services/ProductServices'
import MediaType from './MediaType'





const AddMediaType = () => {
    const { id } = useParams()

    const [loading, setLoading] = useState(false)
    const [mainLoading, setMainLoading] = useState(false)
    const [status, setStatus] = useState(true)
    const [errorMessage, setErrorMessage] = useState(false)
    const [selectedFiles, setselectedFiles] = useState([]);
    const inputRefs = React.useRef([]);
    const navigate = useNavigate()





    //meta title
    document.title = `Media Type  | ${process.env.REACT_APP_SITE_NAME} Admin Panel`;








    useEffect(() => {
        getSingleProductRecord()
    }, [])

    const getSingleProductRecord = async () => {
        try {
            setMainLoading(true)


            await getSingleProduct(id).then(response => {
                console.log(response)

                setStatus(response.status ? true : false)
            }).catch(err => console.log(err))
        } catch (error) {
            setMainLoading(false)
        }
    }





    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrorMessage(true)

        let submitFlag = true;







        if (submitFlag) {
            try {

                setLoading(true)
              await  formRows.forEach(async item=>{

                    

                    const { file, isPrimary,status} = item

                    if(file){
                        let payload = {
                            file_name:"ffaf",
                            file:file,
                            prod_id:id,
                            media_type:"image",
                            is_primary:isPrimary?1:0,
        
                            status: status ? 1 : 0,
        
                        }
    
    
    
                        const headers = {
                            'Content-Type': 'multipart/form-data'
                        };
        
                     
                        await createMediaProduct( payload,headers).then(response => {
                            console.log(response)
                            if (response) {
        
                                toast.success("Product Media is uploaded successfully.", { autoClose: 2000 })
                               
        
                            }
                            setLoading(false)
                        }).catch(err => {
                            console.log(err);
                            toast.error(`Product is uploaded Failed`, { autoClose: 2000 });
        
                            setLoading(false)
        
                        })

                    }

                    
                })


                setTimeout(() => {
        
                    navigate('/product')
                }, 2000);
              

        
            } catch (error) {
                console.log(error)
                toast.error(`Something went wrong.`, { autoClose: 2000 });
                setLoading(false)
            }
        }
    }


    // Example
    const [formRows, setFormRows] = useState([
        { id: 1, file: "", status: true, isPrimary: false }
    ]);
    const onAddFormRow = () => {
        const newRow = { id: Math.floor(Math.random() * (30 - 20)) + 20, file: "", status: true, isPrimary: false };
        setFormRows([...formRows, newRow]);
    };

    const onDeleteFormRow = (id) => {
        const updatedRows = formRows.filter((row) => row.id !== id);
        setFormRows(updatedRows);
    };

    // Function to handle changes in input fields
    const handleInputChange = (id, name, value, index) => {

        if (name === 'file') {
            const file = value.files[0];
            // setUploadFile(file)

            if (file && (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg')) {
                toast.error("Please upload a PNG, JPG, JPEG file only", {
                    autoClose: 3000,
                    position: 'top-right',
                    hideProgressBar: true
                });
                inputRefs.current[index].value = null;
                return;
            }


            const updatedRows = formRows.map(row => {
                if (row.id === id) {
                    return { ...row, [name]: file };
                }
                return row;
            });
            setFormRows(updatedRows);
        } else {
            console.log(value.checked)
            const { checked, type } = value

            const updatedRows = formRows.map(row => {
                if (row.id === id) {
                    return { ...row, [name]: !value.checked };
                }
                return row;
            });
            setFormRows(updatedRows);

        }




    };







    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumb title="Manage Product" breadcrumbItem="Product Media Type" />
                {
                    mainLoading ? <Spinners setLoading={setMainLoading} />
                        :
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody className="border-bottom">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0 card-title flex-grow-1">Product Media Type</h5>
                                            <div className="flex-shrink-0">
                                                <Link to="/product" className="btn btn-primary me-1">Back</Link>

                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                     <MediaType/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                }

                <ToastContainer />
            </div>
        </div>
    )
}

export default AddMediaType