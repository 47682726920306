import { del, get, patch, post } from "helpers/api_helper";


// Modules
export const getModule = ({params}) => get(`/module`,{params});

export const createModules = (payload)  => post('/module',payload);

export const updateModules = async (id,payload) => await patch(`/module/${id}`,payload);

export const deleteModules = async (id) =>await del(`/module/${id}`);

//Sub Modules
export const getModuleDropdown = ({params}) => get(`/module`,{params});


export const getSubModule = () => get(`/module`,);

export const createSubModules = (payload)  => post('/module',payload);

export const updateSubModules = async (id,payload) => await patch(`/module/${id}`,payload);

export const deleteSubModules = async (id) =>await del(`/module/${id}`);

//
// export const getsubModuleList = ({params}) => get(`/module`,{params});


// role
export const createRole = (rolePayload)  => post('/role',rolePayload);
export const UpdateRole = (roleId,rolePayload)  => patch(`role/${roleId}`,rolePayload);

export const createRoleModule = (finalPermissions)  => post('/role-module',finalPermissions);
export const getRoleList = () => get(`/role`,);
export const getSingleRoleModule = ({params}) => get(`/role-module`,{params});
export const getRoleNameModule = async (id) =>await get(`/role/${id}`);
