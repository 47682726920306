import React, { useEffect, useState } from "react";
import { useSearchParams, useParams, Link, useNavigate } from 'react-router-dom';
import useEventBus from "hooks/useEventBus";

// Tabs
import ManageProducts from './manage-products'
import ManageShippment from './manage-shippment'
import DeliveriesTab from "./deliveries";
import InvoiceTab from "./Invoice";

const tabs = (props) => {

    const navigate = useNavigate();
    const [ dispatchDownloadInvoice ] = useEventBus('download_invoice')
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams()

    const [page_tabs, setTabs] = useState([
        {
            name: 'Products',
            is_active: true
        }, {
            name: 'Shipments',
            is_active: false
        }, , {
            name: 'Order Deliveries',
            is_active: false
        }, {
            name: 'Invoice',
            is_active: false
        }
    ])

    const setActiveTabs = () => {
        const tab_param = searchParams.get('tab_id');
        const modified_tabs = page_tabs.map((tab, i) => {
            const index = i + 1
            if (index == tab_param) {
                tab.is_active = true
            } else {
                tab.is_active = false
            }
            return tab
        })
        setTabs(modified_tabs)
    }

    const getActiveTabIndex = () => {
        const tab_param = searchParams.get('tab_id')
        return (tab_param ?? params.tab_id + 1) || 1
    }

    const onTabChange = (index) => {
        const tab_index = index + 1 
        setSearchParams({'tab_id': tab_index})    
    }

    const generatePDF = (e) => {
        e.preventDefault()
        const current_tab = getActiveTabIndex()
        if(current_tab == 5){
            dispatchDownloadInvoice()
        }
    }

    useEffect(() => {
        setActiveTabs()
    }, [ params ])

    return (
        <React.Fragment>
            <div className="d-flex justify-content-between align-items-center border-bottom">
                <ul className="nav nav-tabs">
                    {page_tabs.map(({ name, component }, i) => (
                        <React.Fragment key={i}>
                            <li className={`nav-item ${getActiveTabIndex() == (i + 1) ? 'active' : ''}`}>
                                <a className="nav-link" aria-current="page" href="javascript:void(0)" onClick={() => onTabChange(i)}>{ name }</a>
                            </li>
                        </React.Fragment>
                    ))}
                </ul>
                <div className="pe-3 d-flex align-items-center">
                    <Link className="text-dark" to={`/orders`} ><i className="mdi mdi-arrow-left" /> Go Back</Link>
                    <Link className="btn btn-sm btn-outline-dark ms-3" to={`/orders/${params.id}`} ><i className="mdi mdi-pencil" /> Edit</Link>
                    {getActiveTabIndex() == 5 && <a href="javascript:void(0)" className="btn btn-sm btn-primary ms-2" onClick={generatePDF}>
                        <i className="mdi mdi-download" /> Download PDF
                    </a>}
                </div>
            </div>
            { getActiveTabIndex() == 1 && <ManageProducts />}
            { getActiveTabIndex() == 2 && <ManageShippment />}
            { getActiveTabIndex() == 4 && <DeliveriesTab /> }
            { getActiveTabIndex() == 5 && <InvoiceTab /> }
        </React.Fragment>
    )
}

export default tabs