import { del, get, patch, post } from "helpers/api_helper";

export const getOrders = (params) => get('/order', params)

export const getOrderById = (id) => get(`/order/${id}`)

export const saveOrder = (payload) => post('/order', payload)

export const updateOrder = (id, payload) => patch(`/order/${id}`, payload)

export const deleteOrderItem = (id) => del(`/order/${id}`)

export const addShippmentToOrder = async (payload) => await post(`/order-shipment`, payload)

export const getShipmentsByOrder = async (id) => await get(`/order-shipment/${id}`)
