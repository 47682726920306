//Order

import { del, get, patch, post } from "helpers/api_helper";

export const getOrderList = (params) => get('/order',{params});

export const getPaymentInfoOfClient = (id) => get(`payment/last-payment-info/${id}`);

export const getPaymentOrderList = (params)  =>get(`/payment`,{params})

export const createPaymentOrder  = (payload) => post(`/payment`,payload);

export const getSinglePaymentOrder = (id)  => get(`/payment/${id}`);

export const updatePaymentOrder = (id,payload) => patch(`/payment/${id}`,payload)

export const deletePaymentOrder = (id) => del(`/payment/${id}`);

export const availableBalance = (id) =>get(`/payment/balance/${id}`);

export const deletePaymentOrderById = (id) =>del(`/payment/remove-payment-detail/${id}`)