import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import withRouter from "../../components/Common/withRouter"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { getProfile } from "services/EditProfileServices"
import { toast, ToastContainer } from "react-toastify"

const EditProfile = () => {
  const navigate = useNavigate()
  const avatar = ""
  const name = ""
  const email = ""
  const idx = ""

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const validation = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: email,
      primary_ph_no: "",
      addl_ph_no: "",
      address: "",
      gender: "",
      pan_no: "",
      aadhar_no: "",
    },
    validationSchema: Yup.object({
      fname: Yup.string().required("First name is required"),
      lname: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      primary_ph_no: Yup.string()
        .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
        .notRequired(),
      addl_ph_no: Yup.string()
        .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
        .notRequired(),
      address: Yup.string().required("Address is required"),
      gender: Yup.string().required("Gender is required"),
      pan_no: Yup.string()
        .matches(
          /^[A-Z]{5}[0-9]{4}[A-Z]$/,
          "PAN number must follow the format: 5 uppercase letters, 4 digits, and 1 uppercase letter"
        )
        .notRequired(),
      aadhar_no: Yup.string()
        .length(12, "Aadhaar number must be 12 digits")
        .notRequired(),
    }),
    onSubmit: async values => {
      // Log the updated data
      console.log("Updated user data:", values)

      // Prepare the data to be sent to the API
      const updatedData = {
        company_id: 1, // Add the company ID
        first_name: values.fname, // Map first name
        middle_name: "", // Add middle name
        last_name: values.lname, // Map last name
        primary_ph_no: values.primary_ph_no, // Map primary phone number
        addl_ph_no: values.addl_ph_no, // Map additional phone number
        address: values.address, // Map address
        gender: values.gender, // Map gender
        image: "", // Add image (if applicable)
        pan_no: values.pan_no, // Map PAN number
        aadhar_no: values.aadhar_no, // Map Aadhaar number
        user_type: 1, // Add user type
        email: values.email, // Map email
        status: 1, // Add status
      }

      console.log("Updated user data ready for API:", updatedData)

      try {
        // Retrieve access token from local storage
        const authUser = JSON.parse(localStorage.getItem("authUser"))
        const accessToken = authUser?.access_token
        console.log("Access token retrieved:", accessToken)

        // Check if access token exists
        if (!accessToken) {
          console.error("Access token not found. Please log in again.")
          throw new Error("Access token not found. Please log in again.")
        }

        // Make the POST request to update user info
        const response = await fetch(
          `${process.env.REACT_APP_BASEPATH}/user/edit-profile`,
          {
            method: "POST", // Use the appropriate method
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`, // Pass the Bearer token
            },
            body: JSON.stringify(updatedData), // Send updated values
          }
        )

        // Log the response status
        console.log("Response status:", response.status)

        // Check if response is ok
        if (!response.ok) {
          const errorMessage = "Failed to update user data"
          console.error("API response error:", errorMessage)
          throw new Error(errorMessage) // Throw error if response is not ok
        }
        toast.success("Profile Updated successfully.", { autoClose: 1500 })

        // Optionally handle the response
        const result = await response.json()
        console.log("Update response:", result)
        // Update form values with the updated data

        // Set success message
        setSuccess("Profile updated successfully!")
        
        setError(null) // Clear any previous errors

        setTimeout(() => {
          navigate("/") // Navigate to the desired route
        }, 2000) // Delay of 4 seconds before navigation
      } catch (error) {
        toast.error("Failed to update profile. Please try again.", { autoClose: 1500 })

        console.error("Error updating user data:", error.message)
        setError("Failed to update profile. Please try again.")
        setSuccess(null) // Clear any previous success messages

      }

    },
  })

  // Set document title
  useEffect(() => {
    document.title = `Profile | ${process.env.REACT_APP_SITE_NAME} Admin Panel`
    fetchUserData()
  }, [navigate])
  const fetchUserData = async () => {
    try {
      await getProfile()
        .then(response => {
          validation.setValues({
            fname: response.first_name || "",
            lname: response.last_name || "",
            email: response.email || "",
            primary_ph_no: response.primary_ph_no || "",
            addl_ph_no: response.addl_ph_no || "",
            address: response.address || "",
            gender: response.gender || "",
            pan_no: response.pan_no || "",
            aadhar_no: response.aadhar_no || "",
          })
        })
        .catch(err => {
          console.log(err)
        })
    } catch (error) {
      console.error("Error fetching user data:", error.message)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Profile" breadcrumbItem="Edit Profile" />

          <Card>
          <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">Edit Role</h5>
                    <div className="flex-shrink-0">
                      <Link to="/" className="btn btn-primary me-1">
                        Back
                      </Link>
                    </div>
                  </div>
                </CardBody>
                <CardBody className="border-bottom">
              {/* <div className="d-flex mb-4">
                <div className="ms-3">
                  <img
                    src={avatar}
                    alt="Profile Avatar"
                    className="avatar-md rounded-circle img-thumbnail"
                  />
                </div>
                <div className="flex-grow-1 align-self-center">
                  <div className="text-muted">
                    <h5>{name}</h5>
                    <p className="mb-1">{email}</p>
                    <p className="mb-0">Id no: #{idx}</p>
                  </div>
                </div>
              </div> */}
              {/* {error && <Alert color="danger">{error}</Alert>}
              {success && <Alert color="success">{success}</Alert>} */}
              <Form
                className="form-horizontal"
                onSubmit={validation.handleSubmit}
              >
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="fname">
                        First Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="fname"
                        className="form-control"
                        id="fname"
                        placeholder="Enter First Name"
                        value={validation.values.fname}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.fname && validation.errors.fname
                            ? true
                            : false
                        }
                      />
                      {validation.touched.fname && validation.errors.fname && (
                        <FormFeedback type="invalid">
                          {validation.errors.fname}
                        </FormFeedback>
                      )}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="lname">
                        Last Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="lname"
                        className="form-control"
                        id="lname"
                        placeholder="Enter Last Name"
                        value={validation.values.lname}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.lname && validation.errors.lname
                            ? true
                            : false
                        }
                      />
                      {validation.touched.lname && validation.errors.lname && (
                        <FormFeedback type="invalid">
                          {validation.errors.lname}
                        </FormFeedback>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="primary_ph_no">
                        Primary Phone Number (Optional)
                      </Label>
                      <Input
                        type="text"
                        name="primary_ph_no"
                        className="form-control"
                        id="primary_ph_no"
                        placeholder="Enter Number "
                        value={validation.values.primary_ph_no}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.primary_ph_no &&
                          validation.errors.primary_ph_no
                            ? true
                            : false
                        }
                      />
                      {validation.touched.primary_ph_no &&
                        validation.errors.primary_ph_no && (
                          <FormFeedback type="invalid">
                            {validation.errors.primary_ph_no}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="addl_ph_no">
                        Additional Phone Number (Optional)
                      </Label>
                      <Input
                        type="text"
                        name="addl_ph_no"
                        className="form-control"
                        id="addl_ph_no"
                        placeholder="Enter Number"
                        value={validation.values.addl_ph_no}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.addl_ph_no &&
                          validation.errors.addl_ph_no
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addl_ph_no &&
                        validation.errors.addl_ph_no && (
                          <FormFeedback type="invalid">
                            {validation.errors.addl_ph_no}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="email">
                        Email <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter Email"
                        value={validation.values.email}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                        readOnly
                      />
                      {validation.touched.email && validation.errors.email && (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="gender">
                        Gender <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="select"
                        name="gender"
                        className="form-control"
                        id="gender"
                        value={validation.values.gender}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.gender && validation.errors.gender
                            ? true
                            : false
                        }
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </Input>
                      {validation.touched.gender &&
                        validation.errors.gender && (
                          <FormFeedback type="invalid">
                            {validation.errors.gender}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="pan_no">PAN Number</Label>
                      <Input
                        type="text"
                        name="pan_no"
                        className="form-control"
                        id="pan_no"
                        placeholder="Enter PAN Number"
                        value={validation.values.pan_no}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.pan_no && validation.errors.pan_no
                            ? true
                            : false
                        } // This ensures the error is shown
                      />
                      {validation.touched.pan_no &&
                        validation.errors.pan_no && (
                          <FormFeedback type="invalid">
                            {validation.errors.pan_no}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="aadhar_no">Aadhaar Number</Label>
                      <Input
                        type="text"
                        name="aadhar_no"
                        className="form-control"
                        id="aadhar_no"
                        placeholder="Enter Aadhaar Number"
                        value={validation.values.aadhar_no}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.aadhar_no &&
                          validation.errors.aadhar_no
                            ? true
                            : false
                        }
                      />
                      {validation.touched.aadhar_no &&
                        validation.errors.aadhar_no && (
                          <FormFeedback type="invalid">
                            {validation.errors.aadhar_no}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="address">Address</Label>
                      <Input
                        type="textarea"
                        name="address"
                        className="form-control"
                        id="address"
                        placeholder="Enter Address"
                        value={validation.values.address}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        rows="4" // This sets the textarea to have 3 rows (multiline)
                        invalid={
                          validation.touched.address &&
                          validation.errors.address
                            ? true
                            : false
                        }
                      />
                      {validation.touched.address &&
                        validation.errors.address && (
                          <FormFeedback type="invalid">
                            {validation.errors.address}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                </Row>

                <button type="submit" className="btn btn-primary w-md">
                  Submit
                </button>
              </Form>
            </CardBody>
          </Card>
        </Container>
        <ToastContainer position="top-right" autoClose={1500} />

      </div>
    </React.Fragment>
  )
}

export default EditProfile
