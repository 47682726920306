import React, { useEffect, useMemo, useState } from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

// Import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  FormGroup,
  Button,
} from "reactstrap"
import { toast, ToastContainer } from "react-toastify"
import { Link } from "react-router-dom"
import {
  deleteDeductionSettingList,
  getDeductionSettingList,
} from "services/MasterServices"

const DeductionSettings = () => {
  // Meta title
  document.title = `Setting | ${process.env.REACT_APP_SITE_NAME} - Admin Panel`

  const [modal, setModal] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [deduction, setDeduction] = useState(null)
  const [deductionList, setDeductionList] = useState([])
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState()
  const [searchQuery, setSearchQuery] = useState("")

  // Validation schema for creating and editing deduction settings
  const validationSchema = Yup.object({
    variable: Yup.string().trim().required("Enter variable"),
    title: Yup.string().trim().required("Enter title"),
    upper_bound: Yup.number()
      .required("Enter Upper Bound Number")
      .typeError("Upper bound must be a number")
      .transform(value => (isNaN(value) ? undefined : value)), // Ensure it's treated as undefined if not a number
    lower_bound: Yup.number()
      .required("Enter Lower Bound Number")
      .typeError("Lower bound must be a number")
      .transform(value => (isNaN(value) ? undefined : value)), // Ensure it's treated as undefined if not a number
    value: Yup.number()
      .required("Enter value")
      .typeError("Value must be a number")
      .transform(value => (isNaN(value) ? undefined : value)), // Ensure it's treated as undefined if not a number
    status: Yup.boolean().required("Please select status"),
  })

  // Formik for creating deduction
  const validationCreate = useFormik({
    enableReinitialize: true,
    initialValues: {
      variable: "",
      title: "",
      upper_bound: "",
      lower_bound: "",
      value: "",
      status: true,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      console.log("Create Deduction Payload:", values)
      // Logic to create a new deduction
      // e.g., await createDeduction(values);
      toggle()
      toast.success("Deduction created successfully!")
    },
  })

  // Formik for editing deduction
  const validationEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      variable: (deduction && deduction.variable) || "",
      title: (deduction && deduction.title) || "",
      upper_bound: (deduction && deduction.upper_bound) || "",
      lower_bound: (deduction && deduction.lower_bound) || "",
      value: (deduction && deduction.value) || "",
      status: deduction && deduction.status,
    },
    validationSchema: validationSchema,
    onSubmit: async value => {
      // Create the payload with status as 0 or 1
      const id = edit
      const payload = {
        setting_var: value.variable,
        var_title: value.title,
        lbound: value.lower_bound,
        ubound: value.upper_bound,
        var_val: value.value,
        status: value.status ? 1 : 0, // Convert boolean to 1 or 0
      }

      console.log("Editing payload:", payload)
      console.log("Editing Deduction ID:", edit)
      try {
        // Prepare the headers with the Bearer token
        const authToken = localStorage.getItem("authUser")
        const headers = {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
          // Optional headers (uncomment if needed)
          // "Cache-Control": "no-cache",
          // "Pragma": "no-cache",
          // "Expires": "0",
        }

        // Make the API call using Fetch with the `edit` variable
        const response = await fetch(
          `${process.env.REACT_APP_BASEPATH}/setting/${edit}`,
          {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(payload), // Convert to JSON
          }
        )

        // Check if the response is ok (status code 200-299)
        if (response.ok) {
          const settingResponse = await response.json() // Parse JSON response
          console.log("Settings:", settingResponse)
          toast.success("Setting Updated Successfully", {
            autoClose: 1500,
          })
          toggleEdit()
          getDeductionSettingAllList()
          validationEdit.resetForm()
        } else {
          const errorData = await response.json() // Parse error response
          console.log("Error in submitting settings:", errorData)
          console.log("Status Code:", response.status)

          // Construct the error message
          const errorMessage = Array.isArray(errorData.message)
            ? errorData.message.join(", ")
            : errorData.message || "An unknown error occurred."
          //   toast.error(`${errorMessage}`, {
          //     autoClose: 1500,
          //   });
          toast.error("Variable name already exits", {
            autoClose: 1500,
          })
        }
      } catch (error) {
        // Handle errors that occur during the fetch operation
        toast.error("Error in API Calling", { autoClose: 1500 })
        console.error("Error in API call:", error.message)
      }
    },
  })

  const toggle = () => {
    setModal(!modal)
    if (modal) {
      validationCreate.resetForm()
    }
  }

  const toggleEdit = () => {
    setModalEdit(!modalEdit)
    if (modalEdit) {
      validationEdit.resetForm()
    }
  }

  const handleEditClick = jobData => {
    console.log("Editing Deduction ID:", jobData.id) // Log the ID
    setEdit(jobData.id)
    validationEdit.setValues({
      variable: jobData.setting_var,
      title: jobData.var_title,
      upper_bound: jobData.ubound,
      lower_bound: jobData.lbound,
      value: jobData.var_val,
      status: jobData.status === 1, // Assuming 1 is for active
    })
    // toggleEdit() // Open the edit modal
    setTimeout(toggleEdit, 0);
  }

  useEffect(() => {
    getDeductionSettingAllList()
  }, [])

  const getDeductionSettingAllList = async () => {
    try {
      let params = {}

      await getDeductionSettingList({ params })
        .then(response => {
          console.log(response)

          if (response) {
            setDeductionList(response)
          }
        })
        .catch(err => {
          console.log(err)
        })
    } catch (error) {
      console.log(error)
    }
  }
  const filteredDeductionList = useMemo(() => {
    if (!deductionList) return []
    return deductionList.filter(deduction => {
      const query = searchQuery.toLowerCase()
      return (
        deduction.setting_var.toLowerCase().includes(query) ||
        deduction.var_title.toLowerCase().includes(query) ||
        deduction.ubound.toString().includes(query) ||
        deduction.lbound.toString().includes(query) ||
        deduction.var_val.toString().includes(query)
      )
    })
  }, [deductionList, searchQuery])
  const columns = useMemo(
    () => [
      {
        header: "Sl.No",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: cellProps => {
          return (
            <span className="text-body fw-bold">{cellProps.row.index + 1}</span>
          )
        },
      },
      {
        header: "Variable",
        accessorKey: "setting_var",
        enableColumnFilter: false,
        enableSorting: false,
        filterFn: "includesString",
      },
      {
        header: "Title",
        accessorKey: "var_title",
        enableColumnFilter: false,
        enableSorting: false,
        filterFn: "includesString",
      },
      {
        header: "Upper Bound",
        accessorKey: "ubound",
        enableColumnFilter: false,
        enableSorting: false,
        filterFn: "includesString",
      },
      {
        header: "Lower Bound",
        accessorKey: "lbound",
        enableColumnFilter: false,
        enableSorting: false,
        filterFn: "includesString",
      },
      {
        header: "Value",
        accessorKey: "var_val",
        enableColumnFilter: false,
        enableSorting: false,
        filterFn: "includesString",
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: cellProps => {
          switch (cellProps.row.original.status) {
            case 1:
              return <span className="badge badge-soft-success">Active</span>
            case 0:
              return <span className="badge badge-soft-danger">Inactive</span>
          }
        },
      },
      {
        header: () => <div style={{ textAlign: "right" }}>Action</div>,
        id: "category-action",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">
              <li>
                <Link
                  to=""
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    handleEditClick(jobData)
                  }}
                  id={`edittooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Master"
            breadcrumbItem="Setting"
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">
                      Setting
                    </h5>
                    {/* <div className="flex-shrink-0">
                      <Button color="primary" onClick={toggle}>
                        Add Deduction
                      </Button>
                    </div> */}
                  </div>
                </CardBody>

                <CardBody>
                  <Row className="mb-2">
                    <Col sm={4}>
                      <Input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        className="form-control search-box me-2 mb-2 d-inline-block"
                      />
                    </Col>
                  </Row>

                  {filteredDeductionList.length > 0 ? (
                    <TableContainer
                      columns={columns}
                      data={filteredDeductionList} // Use filtered list for display
                      // isCustomPageSize={true}
                      // isGlobalFilter={true}
                      // isJobListGlobalFilter={true}
                      // loading={loading}
                      // setLoading={setLoading}
                      isPagination={true}
                      // SearchPlaceholder="Search for Role"

                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline  border-top"
                      pagination="pagination"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  ) : (
                    <div className="text-center mt-3">
                      <p>No matching records found.</p>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Add Deduction Modal */}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Add Deduction
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={validationCreate.handleSubmit}>
                <Row>
                  <Col className="col-12">
                    <FormGroup>
                      <Label>
                        Variable <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="variable"
                        type="text"
                        onChange={validationCreate.handleChange}
                        onBlur={validationCreate.handleBlur}
                        value={validationCreate.values.variable}
                        invalid={
                          validationCreate.touched.variable &&
                          !!validationCreate.errors.variable
                        }
                      />
                      <FormFeedback>
                        {validationCreate.errors.variable}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>
                        Title <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="title"
                        type="text"
                        onChange={validationCreate.handleChange}
                        onBlur={validationCreate.handleBlur}
                        value={validationCreate.values.title}
                        invalid={
                          validationCreate.touched.title &&
                          !!validationCreate.errors.title
                        }
                      />
                      <FormFeedback>
                        {validationCreate.errors.title}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>
                        Upper Bound <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="upper_bound"
                        type="text"
                        onChange={validationCreate.handleChange}
                        onBlur={validationCreate.handleBlur}
                        value={validationCreate.values.upper_bound}
                        invalid={
                          validationCreate.touched.upper_bound &&
                          !!validationCreate.errors.upper_bound
                        }
                      />
                      <FormFeedback>
                        {validationCreate.errors.upper_bound}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>
                        Lower Bound <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="lower_bound"
                        type="text"
                        onChange={validationCreate.handleChange}
                        onBlur={validationCreate.handleBlur}
                        value={validationCreate.values.lower_bound}
                        invalid={
                          validationCreate.touched.lower_bound &&
                          !!validationCreate.errors.lower_bound
                        }
                      />
                      <FormFeedback>
                        {validationCreate.errors.lower_bound}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>
                        Value <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="value"
                        type="text"
                        onChange={validationCreate.handleChange}
                        onBlur={validationCreate.handleBlur}
                        value={validationCreate.values.value}
                        invalid={
                          validationCreate.touched.value &&
                          !!validationCreate.errors.value
                        }
                      />
                      <FormFeedback>
                        {validationCreate.errors.value}
                      </FormFeedback>
                    </FormGroup>

                    <div className="mb-3">
                      <Label>Status</Label>
                      <FormGroup switch>
                        <Label className="">
                          {/* {validationCreate.values.status === true ? "Active" : "Inactive"} */}
                          <Input
                            name="status"
                            type="switch"
                            role="switch"
                            checked={validationCreate.values.status === true}
                            onClick={validationCreate.handleChange}
                            onBlur={validationCreate.handleBlur}
                            value={validationCreate.values.status || true}
                          />
                        </Label>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Button color="primary" type="submit">
                  Create Deduction
                </Button>
              </Form>
            </ModalBody>
          </Modal>

          {/* Edit Deduction Modal */}
          <Modal isOpen={modalEdit} toggle={toggleEdit}>
            <ModalHeader toggle={toggleEdit} tag="h4">
              Edit Setting
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={validationEdit.handleSubmit}>
                <Row>
                  <Col className="col-12">
                    <FormGroup>
                      <Label>
                        Variable <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="variable"
                        placeholder="Enter name"
                        type="text"
                        onChange={validationEdit.handleChange}
                        onBlur={validationEdit.handleBlur}
                        value={validationEdit.values.variable}
                        invalid={
                          validationEdit.touched.variable &&
                          !!validationEdit.errors.variable
                        }
                      />
                      <FormFeedback>
                        {validationEdit.errors.variable}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>
                        Title <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="title"
                        type="text"
                        placeholder="Enter name"
                        onChange={validationEdit.handleChange}
                        onBlur={validationEdit.handleBlur}
                        value={validationEdit.values.title}
                        invalid={
                          validationEdit.touched.title &&
                          !!validationEdit.errors.title
                        }
                      />
                      <FormFeedback>{validationEdit.errors.title}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Lower Bound <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="lower_bound"
                        type="number"
                        placeholder="Enter number"
                        onChange={validationEdit.handleChange}
                        onBlur={validationEdit.handleBlur}
                        value={validationEdit.values.lower_bound}
                        invalid={
                          validationEdit.touched.lower_bound &&
                          !!validationEdit.errors.lower_bound
                        }
                      />
                      <FormFeedback>
                        {validationEdit.errors.lower_bound}
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Upper Bound <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="upper_bound"
                        type="number"
                        placeholder="Enter number"
                        onChange={validationEdit.handleChange}
                        onBlur={validationEdit.handleBlur}
                        value={validationEdit.values.upper_bound}
                        invalid={
                          validationEdit.touched.upper_bound &&
                          !!validationEdit.errors.upper_bound
                        }
                      />
                      <FormFeedback>
                        {validationEdit.errors.upper_bound}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>
                        Value (%)<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="value"
                        type="number"
                        placeholder="Enter number"
                        onChange={validationEdit.handleChange}
                        onBlur={validationEdit.handleBlur}
                        value={validationEdit.values.value}
                        invalid={
                          validationEdit.touched.value &&
                          !!validationEdit.errors.value
                        }
                      />
                      <FormFeedback>{validationEdit.errors.value}</FormFeedback>
                    </FormGroup>

                    <div className="mb-3">
                      <Label>Status</Label>
                      <FormGroup switch>
                        <Label className="">
                          <Input
                            name="status"
                            type="switch"
                            role="switch"
                            checked={validationEdit.values.status === true}
                            onChange={validationEdit.handleChange}
                            onBlur={validationEdit.handleBlur}
                            value={validationEdit.values.status || true}
                          />
                        </Label>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Button color="primary" type="submit">
                  Update
                </Button>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default DeductionSettings
