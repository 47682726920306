/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";

export default function ClientInformationCard(props) {

    const { selectedClient } = props
    
    return (
        <React.Fragment>
            {selectedClient && selectedClient.id && <div className="border p-2 my-2">
                <h6 className="fw-bold">CLIENT DETAILS:</h6>
                <Row>
                    <Col lg={2}>Name</Col>
                    <Col lg={10}>: { selectedClient.client_name}</Col>
                </Row>
                <Row>
                    <Col lg={2}>Address</Col>
                    <Col lg={10}>: { selectedClient.address}</Col>
                </Row>
                {/* <Row>
                    <Col lg={2}>Ph No.</Col>
                    <Col lg={10}>: { selectedClient.address}</Col>
                </Row> */}
                <Row>
                    <Col lg={2}>Country</Col>
                    <Col lg={10}>: { selectedClient.country ?? '-'}</Col>
                </Row>
            </div>}
        </React.Fragment>
    );
};