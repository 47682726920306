import React, { Fragment, useEffect, useState } from 'react'
import { experienceData, jobListCandidate, jobType } from 'common/data';
import { Card, CardBody, Col, Container, Row, Collapse, Label, Input, Button } from 'reactstrap';


// Global Filter
const DebouncedInput = ({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [debounce, onChange, value]);

    return (
        <React.Fragment>
            <Col sm={4}>
                <input {...props} value={value} onChange={e => setValue(e.target.value)} />
            </Col>
        </React.Fragment>
    );
};

const SearchPage = ({ searchFilter, setSearchFilter ,handleSubmit,handleReset,setIsAdvanceSearchOpen,isAdvanceSearchOpen}) => {

    const toggle = () => setIsAdvanceSearchOpen(!isAdvanceSearchOpen);


    
const handleChange = (e) =>{
    const { name ,value } = e.target;

    console.log(name,"----",value)

    setSearchFilter(prevItems => prevItems.map(item => 
        item.name === name ? { ...item, value: value } : item
      ));
}



    return (

        <Card>
            <CardBody>


                <Row className="g-3">

                    {/* <Col lg={11}>
                        <hr />
                    </Col>

                    <Col lg={1}>
                        <Button onClick={toggle} className="btn btn-primary float-right">
                            <i className="bx bx-filter-alt align-middle"></i></Button>

                    </Col> */}


                    <Collapse isOpen={isAdvanceSearchOpen && searchFilter.length} id="collapseExample">
                        <div>

                            <Row className="g-3">

                                {
                                    searchFilter?.map((item, index) => {


                                        if (item?.type === "text") {

                                            return <Fragment key={`Addvance-${index}`}>



                                                <Col md={3} sm={6}>


                                                    <Label htmlFor={item?.name}>{item?.label}</Label>
                                                    <Input {...item} onChange={handleChange}/>



                                                </Col>


                                            </Fragment>
                                        }

                                        if (item?.type === "select" && Array.isArray(item.options)) {

                                            return <Fragment key={`Addvance-${index}`}>



                                                <Col md={3} sm={6}>


                                                    <Label htmlFor={item?.name}>{item?.label}</Label>
                                                    <Input {...item} onChange={handleChange}>
                                                        <option value="">Select</option>
                                                        {
                                                            item.options?.map((ite, ind) => <option key={`AA-${ind}`} value={ite.value}> {ite.label}</option>

                                                            )


                                                        }</Input>



                                                </Col>


                                            </Fragment>
                                        }



                                    }

                                    )
                                }

                            </Row>

                            <Row
                                className='mt-3 flex-row-reverse'
                            >

                                <Col md={12} >

                                    <div className="d-flex flex-row-reverse">
                                        <div className="p-2">   <Button color={"primary"} onClick={handleSubmit}>Submit</Button></div>
                                        <div className="p-2"> <Button color={"primary"} onClick={handleReset}>Reset</Button></div>
                                    </div>

                                </Col>
                                <Col sm={2}  >

                                </Col>
                            </Row>
                        </div>
                    </Collapse>

                </Row>



            </CardBody>

        </Card>

    )
}

export default SearchPage