import { del, get, patch, post } from "helpers/api_helper";

export const getShippingProviderList = async(params) => await get('/shipping-provider',{params});

export const getSingleShippingProvider = async(id) => await get(`/shipping-provider/${id}`);

export const createShippingProvider = (payload)  => post('/shipping-provider',payload);

export const updateShippingProvider = async (id,payload) => await patch(`/shipping-provider/${id}`,payload);

export const deleteShippingProvider = async (id) =>await del(`/shipping-provider/${id}`);
