/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button, Container, Toast, ToastHeader, ToastBody, Fade, FormGroup, Label, Input } from "reactstrap";
import { Link, useParams, useNavigate } from 'react-router-dom';
import moment from "moment";
import DatePicker from 'react-date-picker';
import { toast as toastfy, ToastContainer } from "react-toastify";
import { Spinner } from "reactstrap";

// Services
import { getCategoryDropdown } from "services/MasterServices"; 
import { getClientList } from "services/ClientManagementServices"
import { saveOrder, updateOrder, deleteOrderItem, getOrderById } from "services/OrderService"
import { getPaymentMethod } from "services/MasterServices";
import { getPaymentTerm } from "services/MasterServices";

// Components
import ClientInformationCard from "./components/ClientInformationCard";
import ProductDetails from "./components/ProductDetails";

function makeid(length=20) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

const NEW_PRODUCT = {
    uuid: makeid(),
    new_item: true,
    id: undefined,
    prod_id : undefined,
    line_item_sku : undefined,
    line_item_title : undefined,
    line_item_description : undefined,
    quantity : 1,
    line_item_unit_price : 0,
    gross_sale_price : undefined,
    line_item_order_desc : undefined,
    shopify_order_id : undefined,
    delivery_status: 1,
    status: 1,
    order_date : undefined,
    files: []
}

const ClientCart = (props) => {
    
    const navigate = useNavigate();
    const params = useParams()
    const [clients, setClients] = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])
    const [paymentTerms, setPaymentTerms] = useState([])
    const [allCategories, setAllCategories] = useState([])
    const [selectedClient, setSelectedClients] = useState([])
    const [isEditing, setIsEditing] = useState(false)
    const [toast, setToast] = useState({ title: '', description: '', show: false })
    const [isLoading, setLoading] = useState(true)
    const [shippingType, setShippingType] = useState(1)
    const [gstApplicable, setGstApplicable] = useState(0)
    const [sgstApplicable, setSgstApplicable] = useState(0)
    const [cartItems, setCart] = useState({
        order_date: moment(new Date()).format("YYYY-MM-DD"),
        order_instruction: '',
        status: 1,
        client_id: undefined,
        ordered_delivery_date: undefined,
        shipping_amount: 0,
        shipping_note: '',
        shipping_tracking_info: '',
        payment_method_id: null,
        cgst: 0,
        igst: 0,
        sgst: 0,
        payment_method_id: null,
        payment_term_id: null,
        net_order_amount: null,
        orderLineItem: []
    })
    
    const getClients = async () => {
        try {
            let params = {
                page: 1,
                limit: 200,
                search: ''
            }
            await getClientList(params).then(response => {
                if (response.data) {
                    const { meta, data } = response;
                    setClients(data)
                }
            }).catch(err => console.log(err))

        } catch (error) {
            // setLoading(false)
            console.log(error);
        }
    }

    const getPaymentMethods = async () => {
        try {
            let params = {
                page: 1,
                limit: 200,
                search: ''
            }
            await getPaymentMethod(params).then(response => {
                if (response) {
                    setPaymentMethods(response)
                }
            }).catch(err => console.log(err))

        } catch (error) {
            setPaymentMethods([])
            console.log(error);
        }
    }

    const getPaymentTerms = async () => {
        try {
            let params = {
                page: 1,
                limit: 200,
                search: ''
            }
            await getPaymentTerm(params).then(response => {
                if (response) {
                    setPaymentTerms(response)
                }
            }).catch(err => console.log(err))

        } catch (error) {
            setPaymentMethods([])
            console.log(error);
        }
    }

    const getOrderByID = async () => {
        const id = params.id
        if (id) {
            await getOrderById(id)
                .then(res => {
                    setIsEditing(true)
                    const formated_response = {
                        id: res.id,
                        order_date: res.order_date,
                        status: Number(res.status),
                        client_id: res.client_id,
                        ordered_delivery_date: res.ordered_delivery_date,
                        order_instruction: res.order_instruction,
                        shipping_amount: Number(res.shipping_amount),
                        shipping_note: res.shipping_note,
                        shipping_tracking_info: res.shipping_tracking_info,
                        cgst: res.cgst,
                        igst: res.igst,
                        sgst: res.sgst,
                        payment_method_id: Number(res.payment_method_id),
                        payment_term_id: Number(res.payment_term_id),
                        net_order_amount: Number(res.net_order_amount),
                        orderLineItem: res.orderLineItem.map(item => ({
                            ...item,
                            gross_sale_price: Number(item.gross_sale_price),
                            new_item: false
                        }))
                    }
                    setShippingType(Number(res.shipping_amount) > 0 ? false : true)
                    setGstApplicable(Number(formated_response.cgst) > 0 ? 1 : 0)
                    setSgstApplicable(Number(formated_response.sgst) > 0 ? 1 : 0)
                    setCart(formated_response)
                })
        }
    }

    const getCategories = async () => {
        try {
            let params = {
                page: 1,
                limit: 200,
                search: '',
            }
            await getCategoryDropdown({params}).then(response => {
                if (response) {
                    setAllCategories(response)
                    // const master_category = response.filter((item) => {
                    //     return item.parent_id == null
                    // })
                    // setCategories(master_category)
                }
            }).catch(err => console.log(err))

        } catch (error) {
            console.log(error);
        }
    }

    const onClientChange = (event) => {
        let client_id = event.target.value
        const client = clients.find(c => c.id == client_id)
        setSelectedClients(client)
        setCart({
            ...cartItems,
            client_id: Number(client_id)
        })
    }

    const onOrderDateChange = event => {
        let value = moment(event).format('YYYY-MM-DD')
        setCart({ ...cartItems, order_date:value })
    }

    const onDeliveryDateChange = (event) => {
        let value = moment(event).format('YYYY-MM-DD')
        setCart({ ...cartItems, ordered_delivery_date:value })
    }

    const toggleToast = () => {
        setToast({...toast, show: setToast? false: true })
    }

    const onAddProduct = () => {
        if (cartItems.client_id == undefined) {
            // setToast({ ...toast, show: true, title:'Error', description:'Please select cllient before adding any product.' })
            toastfy.error('Please select cllient before adding any product.', { autoClose: 4000 })
            return
        }

        setCart({ ...cartItems, orderLineItem: [ ...cartItems.orderLineItem, {...NEW_PRODUCT, uuid: makeid()} ]})
    }

    const onProductSave = (product) => {
        let products = cartItems.orderLineItem
        var foundIndex = products.findIndex(x => x.uuid == product.uuid);

        products[foundIndex] = product
        setCart({ ...cartItems, orderLineItem: products })
    }

    const onProductDiscard = async (product) => {
        try {
            if ('id' in product && product.id != undefined) {
                await deleteOrderItem(product.id)
                    .then(response => {
                        let products = cartItems.orderLineItem.filter(p => p.id !== product.id)
                        setCart({...cartItems, orderLineItem:products})
                }).catch(err => console.error(err))
            } else {
                const products = cartItems.orderLineItem.filter(p => p.uuid !== product.uuid)
                setCart({...cartItems, orderLineItem:products})
            }
        } catch (error) {
            console.error(error);
        }
    }

    const onShippingTypeChange = (e) => {
        const value = e.target.checked
        setShippingType(value ? false : true)
        setCart({...cartItems, shipping_amount: 0})
    }

    const calculateTotalSalePrice = () => {
        return cartItems.orderLineItem.reduce((sum, item) => sum + Number(item.gross_sale_price || 0), 0);
    }

    const calculateTotalSalePriceWithTaxes = () => {
        return Number(calculateTotalSalePrice() + Number(cartItems.shipping_amount) + Number(cartItems.cgst) + Number(cartItems.igst) + Number(cartItems.sgst)).toFixed(2)
    }

    const calculateGST = (value, field, percent = 5) => {
        const total_sale_amount = calculateTotalSalePrice()
        const tax_amount = (total_sale_amount * percent) / 100
        let cartItem = cartItems

        switch (field) {
            case 'cgst':
                setGstApplicable( gstApplicable ? 0 : 1 )
                break;
        
            default:
                setSgstApplicable( sgstApplicable ? 0 : 1 )
                break;
        }

        if(value == true){
            cartItem[field] = tax_amount
        }else{
            cartItem[field] = 0
        }

        console.log(field, cartItem[field]);
        
        setCart(cartItem)
    }

    const validateForm = () => {
        // Validate each order item
        const invalidItem = cartItems.orderLineItem.find(item => 
            !item.line_item_sku ||
            !item.line_item_title ||
            !item.line_item_description ||
            item.quantity < 1 ||
            item.line_item_unit_price < 1 ||
            item.gross_sale_price < 1
        );

        // Required field validation
        const requiredFields = {
            client_id: 'Please select client',
            ordered_delivery_date: 'Please select expected delivery date',
            order_date: 'Please select order date',
            shipping_amount: 'Please add shipping amount',
            // shipping_tracking_info: 'Please add shipping tracking detail'
        };

        for (const [field, message] of Object.entries(requiredFields)) {
            if(field == 'shipping_amount'){
                if (shippingType == false && !cartItems[field]) 
                    return 'Please add shipping amount';
            }else{
                if (!cartItems[field]) return message;
            }
        }

        // Validate order items exist
        if (!Array.isArray(cartItems.orderLineItem) || cartItems.orderLineItem.length < 1) {
            return 'Please add at least one product to continue';
        }

        
        

        if (invalidItem) {
            return 'You might have forgot to save the product or missed the required fields, please check again';
        }

        return null;
    }

    const onSaveOrder = async () => {
        const validation_errors = validateForm()
        if (validation_errors != null) {
            toastfy.error(validation_errors, { autoClose: 2000 })
            return null
        }

        setLoading(true)
        try {
            if (isEditing && 'id' in cartItems) {
                const payload = {
                    order_date: cartItems.order_date,
                    status: Number(cartItems.status),
                    client_id: cartItems.client_id,
                    ordered_delivery_date: cartItems.ordered_delivery_date,
                    order_instruction: cartItems.order_instruction,
                    shipping_amount: Number(cartItems.shipping_amount),
                    shipping_note: cartItems.shipping_note,
                    shipping_tracking_info: cartItems.shipping_tracking_info,
                    cgst: Number(cartItems.cgst),
                    igst: Number(cartItems.igst),
                    sgst: Number(cartItems.sgst),
                    payment_method_id: Number(cartItems.payment_method_id),
                    payment_term_id: Number(cartItems.payment_term_id),
                    net_order_amount: Number(calculateTotalSalePriceWithTaxes()),
                    orderLineItem: cartItems.orderLineItem.map(item => ({
                        id                   : Number(item.id),
                        prod_id              : item.prod_id ? Number(item.prod_id) : null,
                        line_item_sku        : item.line_item_sku,
                        line_item_title      : item.line_item_title,
                        line_item_description: item.line_item_description,
                        quantity             : Number(item.quantity),
                        line_item_unit_price : Number(item.line_item_unit_price),
                        gross_sale_price     : Number(item.gross_sale_price),
                        line_item_order_desc : item.line_item_order_desc,
                        shopify_order_id     : item.shopify_order_id,
                        delivery_status      : Number(item.delivery_status),
                        status               : Number(item.status),
                        order_date           : item.order_date,
                    }))
                }
                await updateOrder(cartItems.id, payload).then(response => {
                    setIsEditing(true)
                    response['orderLineItem'] = response.orderLineItem.map(item => ({ ...item, uuid: makeid() }))
                    setCart(response)
                    toastfy.success('Order updated successfully.', { autoClose: 2000 })
                })
            } else {
                const payload = {
                    order_date: cartItems.order_date,
                    status: Number(cartItems.status),
                    client_id: cartItems.client_id,
                    ordered_delivery_date: cartItems.ordered_delivery_date,
                    order_instruction: cartItems.order_instruction,
                    shipping_amount: Number(cartItems.shipping_amount),
                    shipping_note: cartItems.shipping_note,
                    shipping_tracking_info: cartItems.shipping_tracking_info,
                    cgst: Number(cartItems.cgst),
                    igst: Number(cartItems.igst),
                    sgst: Number(cartItems.sgst),
                    payment_method_id: Number(cartItems.payment_method_id),
                    payment_term_id: Number(cartItems.payment_term_id),
                    net_order_amount: Number(calculateTotalSalePriceWithTaxes()),
                    orderLineItem: cartItems.orderLineItem.map(item => ({
                        prod_id              : item.prod_id ? Number(item.prod_id) : null,
                        line_item_sku        : item.line_item_sku,
                        line_item_title      : item.line_item_title,
                        line_item_description: item.line_item_description,
                        quantity             : Number(item.quantity),
                        line_item_unit_price : Number(item.line_item_unit_price),
                        gross_sale_price     : Number(item.gross_sale_price),
                        line_item_order_desc : item.line_item_order_desc,
                        shopify_order_id     : item.shopify_order_id,
                        delivery_status      : Number(item.delivery_status),
                        status               : Number(item.status),
                        order_date           : item.order_date,
                    }))
                }
                await saveOrder(payload).then(response => {
                    setIsEditing(true)
                    response['orderLineItem'] = response.orderLineItem.map(item => ({ ...item, uuid: makeid() }))
                    setCart(response)
                    toastfy.success('Order created successfully.', { autoClose: 2000 })
                    navigate("/orders")
                })
            }
           
        } catch (error) {
            console.error(error);
        }
        setLoading(false)
    }
    
    const initialize_component = async () => {
        await getPaymentMethods()
        await getPaymentTerms()
        await getClients()
        await getCategories()
        await getOrderByID()
        setLoading(false)
    }

    useEffect(() => {
        initialize_component()
    }, [])

    useEffect(() => {
        if (cartItems.client_id) {
            onClientChange({target:{value: cartItems.client_id}})
        }
    }, [cartItems.client_id])
    
    return (
        <React.Fragment>
            <Fade>
                {isLoading && <div style={{ background: 'rgba(0,0,0,0.3)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 99999 }}>
                    <Spinner color="primary" className='position-absolute top-50 start-50' style={{ zIndex: 99 }} />
                </div>}
            </Fade>
            <ToastContainer />
            <div className="page-content">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <div className="mb-2 mt-2 d-flex justify-content-between align-items-center">
                                        <div>
                                            <h4 className="mb-0">Manage Order</h4>
                                        </div>
                                        <div>
                                            <a href="javascript:0)" onClick={() => navigate(-1)} className="text-black" ><i className="mdi mdi-arrow-left" /> Go Back</a>
                                        </div>
                                    </div>
                                    <hr />
                                    
                                    {/* Error Toast :: TODO: Please replace this component with any good notification style */}
                                    {toast.show && <Fade tag='div' className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
                                        <Toast isOpen={true}>
                                            <ToastHeader toggle={toggleToast} className="text-danger">
                                                {/* <img src={logo} alt="" className="me-2" height="18" /> */}
                                                {toast.title}
                                            </ToastHeader>
                                            <ToastBody>
                                                {toast.description}
                                            </ToastBody>
                                        </Toast>
                                    </Fade>}
                                    <Row>
                                        <Col lg={12}>
                                            <ClientInformationCard selectedClient={selectedClient} />
                                        </Col>
                                        <Col lg={12} >
                                            <label htmlFor="name" className="w-full d-flex justify-content-between align-items-end">
                                                <div>
                                                    Select Client <span className="text-danger">*</span>
                                                </div>
                                                {/* <Button size="sm" disabled outline color="primary">
                                                    <i className="mdi mdi-plus" /> Add
                                                </Button> */}
                                            </label>
                                            <select
                                                className="form-control"
                                                name=""
                                                value={cartItems.client_id}
                                                onChange={onClientChange}
                                                disabled={cartItems.client_id}
                                            >
                                                <option>Select Client...</option>
                                                {clients.map((client, i) => <option value={client.id} key={i}>{client.client_name}</option>)}
                                            </select>
                                        </Col>
                                        <Col lg={6} className="mt-3">
                                            <label htmlFor="name" className="w-full d-flex justify-content-between align-items-end">
                                                <div>
                                                    Order Date <span className="text-danger">*</span>
                                                </div>
                                            </label>
                                            <DatePicker format="dd-MM-y" onChange={onOrderDateChange} value={cartItems.order_date}/>
                                        </Col> 
                                        <Col lg={6} className="mt-3">
                                            <label htmlFor="name" className="w-full d-flex justify-content-between align-items-end">
                                                <div>
                                                    Expected Delivery Date <span className="text-danger">*</span>
                                                </div>
                                            </label>
                                            <DatePicker format="dd-MM-y" minDate={new Date()} onChange={onDeliveryDateChange} value={cartItems.ordered_delivery_date}/>
                                        </Col> 
                                        <Col lg={12} className="mt-3">
                                            <label htmlFor="name" className="w-full d-flex justify-content-between align-items-end">
                                                <div>Order Instruction</div>
                                            </label>
                                            <textarea className="form-control" onChange={(e) => setCart({ ...cartItems, order_instruction: e.target.value })} rows={5} value={cartItems.order_instruction}></textarea>
                                        </Col>                                       
                                    </Row>

                                    <hr style={{marginTop: '50px'}} />

                                    <Row>
                                        <Col lg={12} className="mt-3 mb-2">
                                            <div className="w-full d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h4 className="mb-0">Products</h4>
                                                </div>
                                                <div>
                                                    <Button onClick={onAddProduct} size="sm" outline color="primary">
                                                        <i className="mdi mdi-plus" /> Add
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                        {cartItems.orderLineItem && cartItems.orderLineItem.length < 1 ? <Col lg={12} className="mt-3 mb-2">
                                            <div className="border p-2">
                                                <small className="text-danger">No products to show</small>
                                            </div>
                                        </Col> :
                                            <Col lg={12}>
                                                {cartItems.orderLineItem.map((product, i) => <ProductDetails key={product.id} product={product} allcategories={allCategories} onSave={onProductSave} onDiscard={onProductDiscard} editing={isEditing} /> )}
                                        </Col> }
                                    </Row>

                                    <hr />
                                    <Row className="pb-3">
                                        <Col lg={12}>
                                            <h4>Shipping Details</h4>
                                        </Col>
                                        <Col lg={12}>
                                            <Row>
                                                <Col lg={6}>
                                                    <FormGroup switch className="mt-3">
                                                        <Label>
                                                        Free Shipping
                                                        <Input
                                                            name="status"
                                                            type="switch"
                                                            role="switch"
                                                            checked={shippingType}
                                                            onClick={onShippingTypeChange}
                                                            value={shippingType}
                                                        />
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                {shippingType == false && <Col lg={6}>
                                                    <label htmlFor="name" className="w-full d-flex justify-content-between align-items-end">
                                                        <div>Shipping Charge <span className="text-danger">*</span></div>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        onChange={(e) => setCart({...cartItems, shipping_amount: e.target.value})}
                                                        value={cartItems.shipping_amount}
                                                    />
                                                </Col>}
                                            </Row>
                                        </Col>
                                        <Col lg={12} className="mt-3">
                                            <label htmlFor="name" className="w-full d-flex justify-content-between align-items-end">
                                                <div>Shiping Tracking Info</div>
                                            </label>
                                            <input type="text" onChange={(e) => setCart({...cartItems, shipping_tracking_info: e.target.value})} value={cartItems.shipping_tracking_info} className="form-control" />
                                        </Col>
                                        <Col lg={12} className="mt-3">
                                            <label htmlFor="name" className="w-full d-flex justify-content-between align-items-end">
                                                <div>Shipping Note</div>
                                            </label>
                                            <textarea onChange={(e) => setCart({...cartItems, shipping_note: e.target.value})} value={cartItems.shipping_note} className="form-control" rows={5}></textarea>
                                        </Col>
                                    </Row>

                                    <hr />
                                    <Row >
                                        <Col lg={12}>
                                            <h4>Payment Details</h4>
                                        </Col>
                                        <Col lg={12}>
                                            <div className="border p-2 my-2">
                                                <h6 className="fw-bold">ORDER SUMMARY:</h6>
                                                <Row>
                                                    <Col lg={2}>Sub Total</Col>
                                                    <Col lg={10}>: ₹{ calculateTotalSalePrice() }</Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={2}>Shiping Charges</Col>
                                                    <Col lg={10}>: ₹{ cartItems.shipping_amount}</Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={2}>GST</Col>
                                                    <Col lg={10}>: ₹{ Number(cartItems.cgst).toFixed(2) }</Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={2}>SGST</Col>
                                                    <Col lg={10}>: ₹{ Number(cartItems.sgst).toFixed(2) }</Col>
                                                </Row>
                                                <hr className="mb-1 mt-1" />
                                                <Row >
                                                    <Col lg={2} className="fw-bold">Total</Col>
                                                    <Col lg={10} className="fw-bold">: ₹{ calculateTotalSalePriceWithTaxes() }</Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col lg={6} className="mt-3">
                                            <label htmlFor="name" className="w-full d-flex justify-content-between align-items-end">
                                                <div>Select Payment Method <span className="text-danger">*</span></div>
                                            </label>
                                            <select
                                                className="form-control"
                                                onChange={e => setCart({...cartItems, payment_method_id: e.target.value})}
                                                value={cartItems.payment_method_id}
                                            >
                                                { paymentMethods.map((p, key) => <option key={key} value={p.id}>{p.method_name}</option>) }
                                            </select>
                                        </Col>
                                        <Col lg={6} className="mt-3">
                                            <label htmlFor="name" className="w-full d-flex justify-content-between align-items-end">
                                                <div>Select Payment Term <span className="text-danger">*</span></div>
                                            </label>
                                            <select
                                                className="form-control"
                                                onChange={e => setCart({...cartItems, payment_term_id: e.target.value})}
                                                value={cartItems.payment_term_id}
                                            >
                                                { paymentTerms.map((p, key) => <option key={key} value={p.id}>{p.term_name}</option>) }
                                            </select>
                                        </Col>
                                        <Col lg={6} className="mt-3">
                                            <label htmlFor="name" className="w-full d-flex justify-content-between align-items-end">
                                                <div>Is GST applicable? <span className="text-danger">*</span></div>
                                            </label>
                                            <select
                                                className="form-control"
                                                onChange={(e)=> calculateGST(e.target.value, 'cgst')}
                                                value={gstApplicable}
                                            >
                                                <option value={0}>No</option>
                                                <option value={1}>Yes</option>
                                            </select>
                                        </Col>
                                        <Col lg={6} className="mt-3">
                                            <label htmlFor="name" className="w-full d-flex justify-content-between align-items-end">
                                                <div>Is SGST applicable? <span className="text-danger">*</span></div>
                                            </label>
                                            <select
                                                className="form-control"
                                                onChange={(e)=> calculateGST(e.target.value, 'sgst', 2.5)}
                                                value={sgstApplicable}
                                            >
                                                <option value={0}>No</option>
                                                <option value={1}>Yes</option>
                                            </select>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col lg={12}>
                                            <div className="d-flex justify-content-end">
                                                <div className="btn-group">
                                                    <Button disabled={isLoading} className="pull-right d-flex align-items-center" color="primary" onClick={onSaveOrder}>
                                                        {isLoading ? <Spinner size="sm"></Spinner> : <i className="mdi mdi-content-save-outline" /> } &nbsp;
                                                        {isLoading ? ' Loading...' : isEditing ? 'Update Order' : 'Save Order'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default ClientCart