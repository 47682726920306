import React, { useEffect, useMemo, useState } from "react";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
    getJobList as onGetJobList,
    addNewJobList as onAddNewJobList,
    updateJobList as onUpdateJobList,
    deleteJobList as onDeleteJobList,
} from "store/actions";


import {
    Col,
    Row,
    UncontrolledTooltip,
    Card,
    CardBody,
    Button,
    ModalHeader,
    Modal,
    ModalBody,
    CardTitle,
    UncontrolledAccordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody,
    Table,
    Label,
    Input,
    FormGroup,
    FormFeedback,
    Form,

} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import Paginations from "components/Common/Pagination";
import moment from "moment";
import { isEmpty } from "lodash";
import { createShippingProvider, deleteShippingProvider, getShippingProviderList, updateShippingProvider } from "services/ShippingProviderServices";

const ShippingProviderList = () => {

    //meta title
    document.title = `Shipping Provider List |  ${process.env.REACT_APP_SITE_NAME} - Admin Panel`;


    const [shippingProviders, setShippingProviders] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [job, setJob] = useState(null);
    const [search, setSearch] = useState('');
    const [modalLoading, setModalLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState("")

    const [status, setStatus] = useState(true)
    const [shippingCompName, setShippingCompName] = useState('')
    const [phNo, setPhNo] = useState('')
    const [errorMessage, setErrorMessage] = useState(false)



    useEffect(() => {
        getShippingProviderListRecord()
    }, [limit, page,search])


    const getShippingProviderListRecord = async () => {
        try {
            setLoading(true)

            let params = {
                page: page,
                limit: limit,
                search: search ?? ''
            }

            await getShippingProviderList(params).then(response => {

                console.log(response)

                if (response.data) {

                    const { meta, data } = response;
                    setShippingProviders(data ?? [])
                    // setTotalCount(Math.ceil(meta?.itemCount / meta?.limit));
                    setTotalCount(meta?.itemCount);

                }
                setLoading(false)

            }).catch(err => console.log(err))

        } catch (error) {
            setLoading(false)
        }
    }






    //delete Job
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (job) => {
        setJob(job);
        setDeleteModal(true);
    };

    const handleDeletejob = async () => {
        if (job && job.id) {

            try {
                await deleteShippingProvider(job.id).then(response => {
                    console.log(response)
                    // if(response){
                    toast.success("Shipping provider is deleted successfully.", { autoClose: 500 });
                    // }
                    getShippingProviderListRecord()
                }).catch(err => console.log(err))
            } catch (error) {
                toast.error("Something went wrong.", { autoClose: 500 });
            }


            setDeleteModal(false);
        }
    };


    const handleSearch = () => {
        setPage(1);
        getShippingProviderListRecord(search)
    }

    const handleModal = (data) => {
        setModal(true);
        let { id, ph_no, shipping_comp_name, status } = data;

        setShippingCompName(shipping_comp_name);
        setPhNo(ph_no)
        setStatus(status === 1 ? true : false)
        setSelectedItem(id)
    }

    const toggle = () => {

        if (modal) {
            setModal(false);
            setSelectedItem('');
            setShippingCompName('');
            setPhNo("")
            setStatus(true)
            setErrorMessage(false)

        } else {
            setModal(true);
        }
    }


    const columns = useMemo(
        () => [
            {
                header: 'Sl.No.',
                accessorKey: "id",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    return <Link to="#" className="text-body fw-bold">{(page - 1) * limit + (cellProps.row.index + 1)}</Link>
                }
            },
            {
                header: "Company Name",
                accessorKey: "shipping_comp_name",
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: 'Phone Number',
                accessorKey: "ph_no",
                enableColumnFilter: false,
                enableSorting: false,


            },

            {
                header: 'Status',
                accessorKey: "status",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    switch (cellProps.row.original.status) {
                        case 1:
                            return <span className="badge badge-soft-success">Active</span>;
                        case 0:
                            return <span className="badge badge-soft-danger">Inactive</span>;
                    }
                },
            },
            {
                header: () => <div style={{ textAlign: "right" }}>Action</div>,
                id: "client-action",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">



                            <li>
                                <Link
                                    // to={`/shipping-provider/edit/${cellProps.row.original.id}`}
                                    to={void (0)}
                                    className="btn btn-sm btn-soft-info"

                                    onClick={() => handleModal(cellProps.row.original)}

                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        onClickDelete(jobData);
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete-outline" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        [page,search]
    );


    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrorMessage(true)

        let submitFlag = true;

        if (shippingCompName.trim() === "") {
            submitFlag = false
        }
        if (phNo === "") {
            submitFlag = false
        }



        if (submitFlag) {
            try {
                let payload = {

                    shipping_comp_name: shippingCompName.trim(),
                    ph_no: phNo,
                    status: status ? 1 : 0,

                }

                setModalLoading(true)


                if (selectedItem !== "") {

                    await updateShippingProvider(selectedItem, payload).then(async response => {
                        console.log(response)
                        if (response) {
                            toast.success("Shipping Provider is updated successfully.", { autoClose: 2000 })
                          await  getShippingProviderListRecord()
                            toggle()
                        }
                        setModalLoading(false)

                    }).catch(err => {
                        console.log(err);
                        if(err?.status===400 && err?.response?.data?.message){  
                            toast.error( err?.response?.data?.message, { autoClose: 2000 });
                        }else{

                            toast.error(`Shipping Provider is updated Failed`, { autoClose: 2000 });
                        }

                        setModalLoading(false)

                    })

                } else {

                    await createShippingProvider(payload).then(async response => {
                        console.log(response)
                        if (response) {

                            toast.success("Shipping Provider is added successfully.", { autoClose: 2000 })
                         await   getShippingProviderListRecord()
                            toggle()
                        }
                        setModalLoading(false)

                    }).catch(err => {
                        console.log(err);
                        if(err?.status===400 && err?.response?.data?.message){  
                            toast.error( err?.response?.data?.message, { autoClose: 2000 });
                        }else{

                            toast.error(`Shipping Provider is added  Failed`, { autoClose: 2000 });
                        }

                        setModalLoading(false)

                    })
                }
            } catch (error) {
                console.log(error)
                toast.error(`Something went wrong.`, { autoClose: 2000 });
                setModalLoading(false)
            }
        }
    }


    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeletejob}
                onCloseClick={() => setDeleteModal(false)}
                mainHeader="Are you sure you want to delete this shipping provider?"
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Master" breadcrumbItem="Shipping Provider" />
                    {
                        // isLoading ? <Spinners setLoading={setLoading} />
                        //     :
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody className="border-bottom">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0 card-title flex-grow-1">Shipping Provider</h5>
                                            <div className="flex-shrink-0">
                                                {/* <Link to="/shipping-provider/add" className="btn btn-primary me-1">Add</Link> */}
                                                <Link to={void (0)} className="btn btn-primary me-1" onClick={() => setModal(true)}>Add</Link>

                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        <Row className="mb-2">
                                            <Col sm={4}>
                                                <input value={search} onChange={e => {
                                                    setSearch(e.target.value);
                                                    setPage(1)
                                                }} type="text" placeholder="Keyword Search" className="form-control search-box me-2 mb-2 d-inline-block" />{" "}

                                            </Col>
                                            {/* <Col sm={2}>     <Button type="button" onClick={handleSearch} color="primary">Search</Button></Col> */}
                                        </Row>
                                        <TableContainer
                                            columns={columns}
                                            data={shippingProviders || []}
                                            loading={loading}
                                            setLoading={setLoading}

                                            isGlobalFilter={false}
                                            // isJobListGlobalFilter={true}
                                            isPagination={false}
                                            SearchPlaceholder="Search for ..."
                                            tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline border-top"

                                        />

                                        {
                                            totalCount > 0 &&
                                            <Paginations
                                                perPageData={limit}
                                                data={shippingProviders}
                                                currentPage={page}
                                                setCurrentPage={setPage}
                                                isShowingPageLength={!loading}
                                                totalCount={totalCount}
                                                paginationClass="pagination"
                                                paginationDiv="dataTables_paginate paging_simple_numbers pagination-rounded"

                                            />
                                        }

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }

                </div>
            </div>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    {
                        selectedItem !== '' ? 'Edit' : 'Add'
                    }     Shipping Provider
                </ModalHeader>

                <ModalBody>
                    <Form onSubmit={handleSubmit}>


                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">Company Name <span className='text-danger'>*</span></Label>
                                    <Input
                                        type="text"
                                        name="title"
                                        className="form-control"
                                        id="formrow-cname-Input"
                                        placeholder="Enter Company Name"
                                        value={shippingCompName}
                                        onChange={(e) => setShippingCompName(e.target.value)}
                                        invalid={
                                            errorMessage && shippingCompName === ''
                                        }
                                    />
                                    {
                                        errorMessage && shippingCompName === '' ? (
                                            <FormFeedback type="invalid">{'This field is required'}</FormFeedback>
                                        ) : null
                                    }
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-paymentTermId-Input">Phone Number <span className='text-danger'>*</span></Label>
                                    <Input
                                        type="text"
                                        name="sku"
                                        className="form-control"
                                        id="formrow-paymentTermId-Input"
                                        placeholder="Enter Number"
                                        value={phNo}
                                        onChange={(e) => e.target.value.length <15 &&  setPhNo(e.target.value)}

                                        invalid={
                                            errorMessage && phNo === ""
                                        }
                                    />


                                    {
                                        errorMessage && phNo === "" ? (
                                            <FormFeedback type="invalid">{'This field is required'}</FormFeedback>
                                        ) : null
                                    }
                                </div>
                            </Col>



                        </Row>




                        <div className="mb-3">
                            <Label>Status</Label>
                            <FormGroup switch>
                                <Label className="">
                                     {/* {status === true ? 'Active' : 'Inactive'} */}
                                    <Input
                                        name="status"
                                        type="switch"
                                        role="switch"
                                        checked={status}

                                        onClick={() => setStatus(!status)}

                                        value={status}


                                    />

                                </Label>

                            </FormGroup>


                        </div>
                        <div className="text-end">
                            <button type="submit" className="btn btn-primary w-md" disabled={modalLoading}>
                                {
                                    selectedItem !== '' ? 'Update' : 'Submit'
                                }
                            </button>
                        </div>
                    </Form>
                </ModalBody>


            </Modal>


            <ToastContainer />
        </React.Fragment>
    );
}


export default ShippingProviderList;