import Breadcrumb from 'components/Common/Breadcrumb'
import Spinners from 'components/Common/Spinner'

import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import { getClient, getClientType, postClient, updateClient } from 'services/ClientManagementServices'
import { getPaymentTerm } from 'services/PaymentTermServices'
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'

const countryList = ['India', 'USA', 'UK'];

const EditClient = () => {
    // const [formData, setFormData] = useState({
    //     cTypeId: '',
    //     paymentTermId: '',
    //     clientName: '',
    //     address: '',
    //     status: true,
    // })

    const [clientTypeRecordList, setClientTypeRecordList] = useState([]);
    const [paymentTermRecordList, setPaymentTermRecordList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [mainLoading, setMainLoading] = useState(true)
    const [client, setClient] = useState({});
    const navigate = useNavigate();
    const { id } = useParams()
    const [cTypeId, setCTypeId] = useState('')
    const [paymentTermId, setPaymentTermId] = useState('')
    const [clientName, setClientName] = useState('')
    const [address, setAddress] = useState('')
    const [reg_date, setReg_date] = useState('')
    const [country, setCountry] = useState('')
    const [status, setStatus] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false);




    //meta title
    document.title = `Client Add  | ${process.env.REACT_APP_SITE_NAME} Admin Panel`;

    //Grid form


    useEffect(() => {
        getCientTypeRecord();
        getPaymentTermRecord();
        getSingleClient()
    }, [])



    const getCientTypeRecord = async () => {
        try {
            let params = {
                status: "active"
            }
            await getClientType(params).then(response => {
                console.log(response)
                setClientTypeRecordList(response)
            }).catch(err => console.log(err))
        } catch (error) {

        }

    }
    const getPaymentTermRecord = async () => {
        try {
            let params = {
                status: "active"
            }
            await getPaymentTerm(params).then(response => {
                console.log(response)
                setPaymentTermRecordList(response)
            }).catch(err => console.log(err))
        } catch (error) {
            console.log(error)
        }

    }


    const getSingleClient = async () => {
        try {
            setMainLoading(true)
            await getClient(id)
                .then(response => {
                    console.log(response)
                    const { client_name, ctype_id, default_payment_terms_id, address, reg_date, status, country } = response;

                    setClientName(client_name ?? '')
                    setCTypeId(ctype_id ?? '');
                    setPaymentTermId(default_payment_terms_id ?? '');
                    setAddress(address ?? "");
                    setReg_date(reg_date ? moment(reg_date?.split('T')[0], "YYYY-MM-DD").format("DD-MM-YYYY") ?? "" : '');
                    setStatus(status ? true : false)
                    setCountry(country ?? "")

                    setClient(response)

                    if (response.clientContact) {
                        let tempArrr = response.clientContact?.map(item => ({ ...item, bday:item.bday?  moment(item.bday.split('T')[0]?.split('T')[0], "YYYY-MM-DD").format("DD-MM-YYYY")   : '', aday:item.aday?  moment(item.aday.split('T')[0]?.split('T')[0], "YYYY-MM-DD").format("DD-MM-YYYY")   : '',})) || []
                        setFormRows([...tempArrr])
                    }


                    setMainLoading(false)
                }).catch(err => {
                    console.log(err)
                    setMainLoading(false)
                    toast.error("Something went wrong.", { autoClose: 500 })
                })
        } catch (error) {
            toast.error("Something went wrong.", { autoClose: 500 })
        }
    }

    const [formRows, setFormRows] = useState([
        { id: 1, fname: "", lname: "", email: "", ph_no1: "", ph_no2: "", whatsapp_number: "", bday: "", aday: "" }
    ]);
    const onAddFormRow = () => {
        const newRow = { id: Math.floor(Math.random() * (30 - 20)) + 20, fname: "", lname: "", email: "", ph_no1: "", ph_no2: "", whatsapp_number: "", bday: "", aday: "" };
        setFormRows([...formRows, newRow]);
    };

    const onDeleteFormRow = (id) => {
        const updatedRows = formRows.filter((row) => row.id !== id);
        setFormRows(updatedRows);
    };

    // Function to handle changes in input fields
    const handleInputChange = (id, name, value) => {
        const updatedRows = formRows.map(row => {
            if (row.id === id) {
                return { ...row, [name]: value };
            }
            return row;
        });
        setFormRows(updatedRows);
    };


    // const handleChange = (e) => {
    //     const { name, value, type, checked } = e.target;

    //     setFormData(prev => ({ ...prev, [name]: type === 'checkbox' ? checked : value }))
    // }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(true)
        let submitFlag = true;
        if (paymentTermId === '' || cTypeId === '' || clientName === '' || address === '' || reg_date === '' || country === '' ||
            paymentTermId === null || cTypeId === null || clientName === null || address === null || reg_date === null || country === null ||
            paymentTermId === undefined || cTypeId === undefined || clientName === undefined || address === undefined || reg_date === undefined || country === undefined) {
            submitFlag = false;
        }


        if (submitFlag) {
            let payload = {
                ctype_id: Number(cTypeId),
                default_payment_terms_id: Number(paymentTermId),
                client_name: clientName,
                address: address,
                reg_date: reg_date ?  moment(reg_date , "DD-MM-YYYY").format("YYYY-MM-DD"):reg_date,
                country: country,
                status: status ? 1 : 0
            }

            console.log(formRows, "formRows")

            if (formRows.length > 0 && formRows[0]?.fname !== "") {

                let tempArray = formRows.map(function (item) {
                    const { id, fname, lname, email, ph_no1, ph_no2, whatsapp_number, bday, aday } = item;

                
                

                    return { id, fname, lname, email, ph_no1, ph_no2, whatsapp_number,    
                         bday:bday!=""? moment(bday , "DD-MM-YYYY").format("YYYY-MM-DD"):bday,

                        aday:aday!=""? moment(aday , "DD-MM-YYYY").format("YYYY-MM-DD"):aday };
                }).filter(a => a);
                payload['clientContacts'] = tempArray

            }



            try {

                console.log(payload, " payload")

                if (payload.clientContacts === null || payload.clientContacts === undefined || payload['clientContacts'].length == 0) {
                    toast.error("Please fill at least 1 Contact Detail.", { autoClose: 2000 });
                    return;
                }


                setLoading(true)
                await updateClient(id, payload).then(response => {
                    console.log(response)
                    toast.success("Client is updated successfully.", { autoClose: 2000 });

                    setTimeout(() => {
                        setLoading(false)
                        navigate('/client')

                    }, 2000);
                }).catch(err => {
                    toast.error("Something went wrong.", { autoClose: 2000 });
                    console.log(err);
                    setLoading(false)

                })
            } catch (error) {
                toast.error("Something went wrong.", { autoClose: 2000 });
                setLoading(false)
            }
        }
    }

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumb title="Manage Client" to={'/client'} breadcrumbItem="Edit Client" />


                {
                    mainLoading ? <Spinners setLoading={setMainLoading} />

                        :

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody className="border-bottom">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0 card-title flex-grow-1">Edit Client</h5>
                                            <div className="flex-shrink-0">
                                                <Link to="/client" className="btn btn-primary me-1">Back</Link>

                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        <Form onSubmit={handleSubmit}>


                                            <Row>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Client Name <span className='text-danger'>*</span></Label>
                                                        <Input
                                                            type="text"
                                                            name="clientName"
                                                            className="form-control"
                                                            id="formrow-cname-Input"
                                                            placeholder="Enter Client Name"
                                                            value={clientName}
                                                            onChange={(e) => setClientName(e.target.value)}

                                                            invalid={
                                                                errorMessage && clientName === '' ? true : false
                                                            }
                                                        />
                                                        {
                                                            errorMessage && clientName === '' ? (
                                                                <FormFeedback type="invalid">{"This field is required"}</FormFeedback>
                                                            ) : null
                                                        }
                                                    </div>

                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-ctype-Input">Client Type <span className='text-danger'>*</span></Label>
                                                        <Input
                                                            type="select"
                                                            name="cTypeId"
                                                            className="form-control"
                                                            id="formrow-ctype-Input"
                                                            placeholder="Enter Client Type"
                                                            value={cTypeId}
                                                            onChange={e => setCTypeId(e.target.value)}

                                                            invalid={

                                                                errorMessage && cTypeId === ""
                                                            }
                                                        >
                                                            <option value={""}>Select</option>
                                                            {
                                                                clientTypeRecordList?.map((item, index) => <option value={item.id} key={`cliewnt-type-${index}`}>{item.type_name}</option>)
                                                            }
                                                        </Input>
                                                        {
                                                            errorMessage && cTypeId === "" ? (
                                                                <FormFeedback type="invalid">{"This field is required"}</FormFeedback>
                                                            ) : null
                                                        }
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-country-Input">Country <span className='text-danger'>*</span></Label>
                                                        <Input
                                                            type="select"
                                                            name="country"
                                                            className="form-control"
                                                            id="formrow-ctype-Input"
                                                            placeholder="Please choose Country"
                                                            value={country}
                                                            onChange={(e) => setCountry(e.target.value)}

                                                            invalid={
                                                                errorMessage && country === ""
                                                            }
                                                        >
                                                            <option value={""}>Select</option>
                                                            {
                                                                countryList?.map((item, index) => <option value={item} key={`country-type-${index}`}>{item}</option>)
                                                            }
                                                        </Input>
                                                        {
                                                            errorMessage && country === "" ? (
                                                                <FormFeedback type="invalid">{"This field is required"}</FormFeedback>
                                                            ) : null
                                                        }
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-paymentTermId-Input">Payment Term <span className='text-danger'>*</span></Label>
                                                        <Input
                                                            type="select"
                                                            name="paymentTermId"
                                                            className="form-control"
                                                            id="formrow-paymentTermId-Input"
                                                            placeholder="Enter Payment Term"
                                                            value={paymentTermId}
                                                            onChange={e => setPaymentTermId(e.target.value)}

                                                            invalid={
                                                                errorMessage && paymentTermId === ""
                                                            }
                                                        >
                                                            <option value={""}>Select</option>
                                                            {
                                                                paymentTermRecordList?.map((item, index) => <option key={`payment-tem-${index}`} value={item?.id}>{item?.term_name}</option>)
                                                            }
                                                        </Input>
                                                        {
                                                            errorMessage && paymentTermId === "" ? (
                                                                <FormFeedback type="invalid">{"This field is required"}</FormFeedback>
                                                            ) : null
                                                        }
                                                    </div>
                                                </Col>


                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-reg_date-Input">Registration Date <span className='text-danger'>*</span></Label>
                                                        {/* <Input
                                                            type="date"
                                                            name="reg_date"
                                                            row={3}
                                                            className="form-control"
                                                            id="formrow-reg_date-Input"
                                                            value={reg_date}
                                                            onChange={(e)=>setReg_date(e.target.value)}
                                                          
                                                            invalid={
                                                                errorMessage && reg_date === "" ? true : false
                                                            }
                                                        /> */}
                                                        <DatePicker

                                                            value={reg_date || null}
                                                            className={`form-control ${errorMessage && reg_date === "" ? 'is-invalid' : ''}`}

                                                            name="reg_date"


                                                            format="dd-MM-y"

                                                            placeholder="DD/MM/YYYY"

                                                            onChange={(e) => setReg_date(moment(e).format('DD-MM-YYYY'))}


                                                        />
                                                        {
                                                            errorMessage && reg_date === "" ? (
                                                                <FormFeedback className='is-invalid d-block'>{"This field is required"}</FormFeedback>
                                                            ) : null
                                                        }
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-address-Input">Address</Label>
                                                        <Input
                                                            type="textarea"
                                                            name="address"
                                                            row={3}
                                                            className="form-control"
                                                            id="formrow-address-Input"
                                                            placeholder="Enter Client Address"
                                                            value={address}
                                                            onChange={(e) => setAddress(e.target.value)}


                                                        />

                                                    </div>
                                                </Col>

                                            </Row>


                                            <Row className='my-4'>
                                                <Col md={12}>


                                                    <h6 className="mb-4 card-title">Contact Details</h6>

                                                    <div>
                                                        {(formRows || []).map((formRow, key) => (
                                                            <Card outline color="primary" className="border" key={`card-${key}`}>
                                                                <CardBody>

                                                                    <Row >
                                                                        <Col lg={4} className="mb-3">
                                                                            <label htmlFor="fname">First Name</label>
                                                                            <input
                                                                                type="text"
                                                                                id="fname"
                                                                                name={`fname_${formRow.id}`}
                                                                                value={formRow.fname}
                                                                                onChange={(e) => handleInputChange(formRow.id, 'fname', e.target.value)}
                                                                                className="form-control"
                                                                                placeholder="Enter Your First Name"
                                                                            />
                                                                        </Col>
                                                                        <Col lg={4} className="mb-3">
                                                                            <label htmlFor="lname">Last Name</label>
                                                                            <input
                                                                                type="text"
                                                                                id="lname"
                                                                                name={`lname_${formRow.id}`}
                                                                                value={formRow.lname}
                                                                                onChange={(e) => handleInputChange(formRow.id, 'lname', e.target.value)}
                                                                                className="form-control"
                                                                                placeholder="Enter Your Last Name"
                                                                            />
                                                                        </Col>

                                                                        <Col lg={4} className="mb-3">
                                                                            <label htmlFor="email">Email</label>
                                                                            <input
                                                                                type="email"
                                                                                id="email"
                                                                                name={`email_${formRow.id}`}
                                                                                value={formRow.email}
                                                                                onChange={(e) => handleInputChange(formRow.id, 'email', e.target.value)}
                                                                                className="form-control"
                                                                                placeholder="Enter Your Email ID"
                                                                            />
                                                                        </Col>

                                                                        <Col lg={4} className="mb-3">
                                                                            <label htmlFor="ph_no1">Primary Phone Number</label>
                                                                            <input
                                                                                type="text"
                                                                                id="ph_no1"
                                                                                name={`ph_no1-${formRow.id}`}
                                                                                value={formRow.ph_no1}
                                                                                onChange={(e) => handleInputChange(formRow.id, 'ph_no1', e.target.value)}
                                                                                className="form-control"
                                                                                placeholder="Enter Your Primary Phone Number"
                                                                            />
                                                                        </Col>
                                                                        <Col lg={4} className="mb-3">
                                                                            <label htmlFor="ph_no2">Secondary Phone Number</label>
                                                                            <input
                                                                                type="text"
                                                                                id="ph_no2"
                                                                                name={`ph_no2-${formRow.id}`}
                                                                                value={formRow.ph_no2}
                                                                                onChange={(e) => handleInputChange(formRow.id, 'ph_no2', e.target.value)}
                                                                                className="form-control"
                                                                                placeholder="Enter Your Secondary Phone Number"
                                                                            />
                                                                        </Col>
                                                                        <Col lg={4} className="mb-3">
                                                                            <label htmlFor="whatsapp_number">WhatsApp Number</label>
                                                                            <input
                                                                                type="text"
                                                                                id="whatsapp_number"
                                                                                name={`whatsapp_number-${formRow.id}`}
                                                                                value={formRow.whatsapp_number}
                                                                                onChange={(e) => handleInputChange(formRow.id, 'whatsapp_number', e.target.value)}
                                                                                className="form-control"
                                                                                placeholder="Enter Your Whatsapp Number"
                                                                            />
                                                                        </Col>
                                                                        <Col lg={4} className="mb-3">
                                                                            <label htmlFor="bday">Birthday</label>
                                                                            {/* <input
                                                                                type="date"
                                                                                id="bday"
                                                                                name={`bday-${formRow.id}`}
                                                                                value={formRow.bday}
                                                                                onChange={(e) => handleInputChange(formRow.id, 'bday', e.target.value)}
                                                                                className="form-control"

                                                                            /> */}

                                                                            <DatePicker

                                                                                value={formRow.bday || null}
                                                                                className={`form-control`}
                                                                                name={`bday-${formRow.id}`}

                                                                                id="bday"
                                                                                format="dd-MM-y"

                                                                                placeholder="DD/MM/YYYY"


                                                                                onChange={(e) => handleInputChange(formRow.id, 'bday', moment(e).format('DD-MM-YYYY'))}


                                                                            />
                                                                        </Col>
                                                                        <Col lg={4} className="mb-3">
                                                                            <label htmlFor="aday">Anniversary Day</label>
                                                                            {/* <input
                                                                                type="date"
                                                                                id="aday"
                                                                                name={`aday-${formRow.id}`}
                                                                                value={formRow.aday}
                                                                                onChange={(e) => handleInputChange(formRow.id, 'aday', e.target.value)}
                                                                                className="form-control"

                                                                            /> */}


                                                                            <DatePicker

                                                                                value={formRow.aday || null}
                                                                                className={`form-control`}
                                                                                name={`aday-${formRow.id}`}

                                                                                id="aday"
                                                                                format="dd-MM-y"

                                                                                placeholder="DD/MM/YYYY"


                                                                                onChange={(e) => handleInputChange(formRow.id, 'aday', moment(e).format('DD-MM-YYYY'))}


                                                                            />
                                                                        </Col>

                                                                        {
                                                                            formRows.length > 1 &&
                                                                            <Col lg={4} className="align-self-center">
                                                                                <div className="d-grid">
                                                                                    <input
                                                                                        type="button"
                                                                                        className="btn btn-primary"
                                                                                        value="Delete"
                                                                                        onClick={() => onDeleteFormRow(formRow.id)}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        }




                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        ))}
                                                    </div>
                                                    <input
                                                        type="button"
                                                        className="btn btn-primary mt-3 mt-lg-0"
                                                        value="Add"
                                                        onClick={() => onAddFormRow()}
                                                    />







                                                </Col>
                                            </Row>

                                            <div className="mb-3">

                                                <Label htmlFor="formrow-status-Input">Status</Label>
                                                <FormGroup switch>
                                                    <Label className="">
                                                         {/* {status === true ? 'Active' : 'Inactive'} */}
                                                        <Input
                                                            name="status"
                                                            type="switch"
                                                            role="switch"
                                                            checked={status === true}

                                                            onClick={() => setStatus(!status)}



                                                        />

                                                    </Label>

                                                </FormGroup>



                                            </div>
                                            <div>
                                                <button type="submit" className="btn btn-primary w-md" disabled={loading}>
                                                    Update
                                                </button>
                                            </div>
                                        </Form>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                }

                <ToastContainer />
            </div>
        </div>
    )
}

export default EditClient