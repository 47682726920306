import React, { useEffect, useMemo, useState } from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"

import {
  getPaymentTerm as onGetPaymentTermList,
  addNewPaymentTermList as onAddNewPaymentTermList,
  updatePaymentTermList as onUpdatePaymentTermList,
  deletePaymentTermList as onDeletePaymentTermList,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Button,
  FormGroup,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
import { toast, ToastContainer } from "react-toastify"
import { Link } from "react-router-dom"
import {
  createPaymentTerm,
  deletePaymentTerm,
  getPaymentTerm,
  updatePaymentTerm,
} from "services/MasterServices"

const PaymentTerm = () => {
  //meta title
  document.title = `Payment Term |  ${process.env.REACT_APP_SITE_NAME} Admin Panel`

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [paymentTerm, setPaymentTerm] = useState(null)
  const [paymentTerms, setPaymentTerms] = useState([])
  const [searchQuery, setSearchQuery] = useState("") // New state for search input

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      term_name: (paymentTerm && paymentTerm.term_name) || "",
      term_for: (paymentTerm && paymentTerm.term_for) || "",
      days: (paymentTerm && paymentTerm.days) || "",
      status: !!isEdit
        ? paymentTerm && paymentTerm.status === 1
          ? true
          : false
        : true,
    },
    validationSchema: Yup.object({
      term_name: Yup.string().trim().required("Please Enter Name."),
      term_for: Yup.string().required("Please Enter Payment For."),
      days: Yup.string().required("Please Enter days."),
    }),
    onSubmit: async values => {
      if (isEdit) {
        const payload = {
          term_name: values.term_name.trim(),
          status: values.status ? 1 : 0,
          days: values.days,
          term_for: values.term_for,
        }
        // update Job

        try {
          await updatePaymentTerm(paymentTerm.id, payload)
            .then(response => {
              console.log(response, "response")
              toast.success(`Payment Term is updated Successfully`, {
                autoClose: 2000,
              })
              validation.resetForm()
              getPaymentTermList()
              toggle()
            })
            .catch(err => {
              console.log(err)
              if (err?.status === 400 && err?.response?.data?.message) {
                toast.error(err?.response?.data?.message, { autoClose: 2000 })
              } else {
                toast.error(`Payment Term is updated Failed`, {
                  autoClose: 2000,
                })
              }
            })
        } catch (error) {
          toast.error(`Payment Term is updated Failed`, { autoClose: 2000 })
        }
      } else {
        const payload = {
          term_name: values.term_name,
          status: values.status ? 1 : 0,
          days: values.days,
          term_for: values.term_for,
        }
        // save new Job

        try {
          await createPaymentTerm(payload)
            .then(response => {
              console.log(response, "response")
              toast.success(`Payment Term is Added Successfully`, {
                autoClose: 2000,
              })
              validation.resetForm()
              getPaymentTermList()
              toggle()
            })
            .catch(err => {
              console.log(err)
              if (err?.status === 400 && err?.response?.data?.message) {
                toast.error(err?.response?.data?.message, { autoClose: 2000 })
              } else {
                toast.error(`Payment Term is Added Failed`, { autoClose: 2000 })
              }
            })
        } catch (error) {
          toast.error(`Payment Term is Added Failed`, { autoClose: 2000 })
        }
      }
    },
  })

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getPaymentTermList()
  }, [])

  const getPaymentTermList = async () => {
    try {
      setLoading(true)
      await getPaymentTerm()
        .then(response => {
          console.log(response)

          if (response) {
            setPaymentTerms(response)
            setLoading(false)
          }
        })
        .catch(err => {
          console.log(err)

          setLoading(false)
        })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setPaymentTerm(null)
      setIsEdit(false)
    } else {
      setModal(true)
    }
    validation.resetForm()
  }

  const handlePaymentTermClick = arg => {
    console.log(arg, "arg")
    setPaymentTerm(arg)

    setIsEdit(true)

    toggle()
  }

  //delete Job
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = job => {
    setPaymentTerm(job)
    setDeleteModal(true)
  }

  const handleDeletePaymentTerm = async () => {
    if (paymentTerm && paymentTerm.id) {
      try {
        await deletePaymentTerm(paymentTerm.id)
          .then(response => {
            getPaymentTermList()
            setDeleteModal(false)
            toast.success(`Payment Term is Deleted Successfully`, {
              autoClose: 2000,
            })
          })
          .catch(err => {
            console.log(err)

            toast.error(`Payment Term is Deleted Failed`, { autoClose: 2000 })
          })
      } catch (error) {
        toast.error(`Payment Term is Deleted Failed`, { autoClose: 2000 })
      }
    }
  }
  const filteredPaymentTerm = paymentTerms.filter(m => {
    const searchLower = searchQuery.toLowerCase()
    return (
      m.term_name.toLowerCase().includes(searchLower) ||
      m.term_for.toLowerCase().includes(searchLower) ||
      m.days.toString().includes(searchLower) // Convert days to string for comparison
    )
  })
  const columns = useMemo(
    () => [
      {
        header: "Sl.No.",
        accessorKey: "s.no.",
        enableColumnFilter: false,

        cell: cellProps => {
          return (
            <Link to="#" className="text-body fw-bold">
              {cellProps.row.index + 1}
            </Link>
          )
        },
      },
      {
        header: "Term Name",
        accessorKey: "term_name",
        enableColumnFilter: false,
        enableSorting: false,
        filterFn: "includesString",
      },
      {
        header: "Term For",
        accessorKey: "term_for",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Days",
        accessorKey: "days",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          switch (cellProps.row.original.status) {
            case 1:
              return <span className="badge badge-soft-success">Active</span>
            case 0:
              return <span className="badge badge-soft-danger">Inactive</span>
          }
        },
      },
      {
        header: () => <div style={{ textAlign: "right" }}>Action</div>,
        id: "payment-term-action",
        enableColumnFilter: false,
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    handlePaymentTermClick(jobData)
                  }}
                  id={`edittooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    onClickDelete(jobData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePaymentTerm}
        onCloseClick={() => setDeleteModal(false)}
        mainHeader="Are you sure you want to delete this payment term?"
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Master" breadcrumbItem="Payment Term" />
          {
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                      <h5 className="mb-0 card-title flex-grow-1">
                        Payment Term
                      </h5>
                      <div className="flex-shrink-0">
                        <Link
                          to={void 0}
                          onClick={() => setModal(true)}
                          className="btn btn-primary me-1"
                        >
                          Add{" "}
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                  <Row className="mb-2">
                      <Col sm={4}>
                        <Input
                          type="text"
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={e => setSearchQuery(e.target.value)}
                          className="form-control search-box me-2 mb-2 d-inline-block"
                        />
                      </Col>
                    </Row>

                    <TableContainer
                      columns={columns}
                      data={filteredPaymentTerm || []}
                      // isCustomPageSize={true}
                    //   isGlobalFilter={true}
                      // isJobListGlobalFilter={true}
                      isPagination={true}
                      loading={loading}
                      setLoading={setLoading}
                    //   SearchPlaceholder="Keyword Search"
                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline  border-top"
                      pagination="pagination"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit" : "Add"} Payment Term
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>
                        {" "}
                        Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="term_name"
                        type="text"
                        placeholder="Enter name."
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.term_name || ""}
                        invalid={
                          validation.touched.term_name &&
                          validation.errors.term_name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.term_name &&
                      validation.errors.term_name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.term_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>
                        {" "}
                        Term for <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="term_for"
                        type="select"
                        placeholder="Enter payment term for."
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.term_for || ""}
                        invalid={
                          validation.touched.term_for &&
                          validation.errors.term_for
                            ? true
                            : false
                        }
                      >
                        <option value="">Select</option>
                        <option value="sell">Sell</option>
                        <option value="purchase">Purchase</option>
                      </Input>
                      {validation.touched.term_for &&
                      validation.errors.term_for ? (
                        <FormFeedback type="invalid">
                          {validation.errors.term_for}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>
                        Days <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="days"
                        type="text"
                        placeholder="Enter days."
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.days || ""}
                        invalid={
                          validation.touched.days && validation.errors.days
                            ? true
                            : false
                        }
                      />
                      {validation.touched.days && validation.errors.days ? (
                        <FormFeedback type="invalid">
                          {validation.errors.days}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Status</Label>
                      <FormGroup switch>
                        <Label className="">
                          {/* {validation.values.status === true?'Active':'Inactive'} */}
                          <Input
                            name="status"
                            type="switch"
                            role="switch"
                            checked={validation.values.status === true}
                            onClick={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.status}
                          />
                        </Label>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="primary"
                        type="submit"
                        className="save-user"
                      >
                        {!!isEdit ? "Update" : "Submit"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default PaymentTerm
