import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Spinner, CardBody, Button, Container, Toast, ToastHeader, ToastBody, Fade } from "reactstrap";
import { Link, useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';
import useEventBus from "hooks/useEventBus";

// Services
import { getOrderById } from "services/OrderService";

export default function OrderInvoice(props) {
    const params = useParams();
    const [is_loading, setLoading] = useState(true);
    const [order, setOrder] = useState(null);
    const invoiceRef = useRef(null);

    // Events :: Listen for events
    useEventBus('download_invoice', (e) => {
        generatePDF()
    });

    useEffect(() => {
        getOrderByID();
    }, [params.id]);

    const getOrderByID = async () => {
        setLoading(true);
        const id = params.id;
        if (id) {
            await getOrderById(id)
                .then(res => {
                    setOrder(res);
                })
                .catch(err => {
                    console.error("Error fetching order:", err);
                });
        }
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    const calculateSubTotal = () => {
        if (!order?.orderLineItem) return 0;
        return order.orderLineItem.reduce((sum, item) => sum + Number(item.gross_sale_price || 0), 0);
    }

    const calculateTotal = () => {
        if (!order?.orderLineItem) return 0;
        
        // Calculate subtotal from line items
        const subtotal = calculateSubTotal();
        
        // Add shipping and taxes
        const shippingAmount = Number(order.shipping_amount || 0);
        // const igst = Number(order.igst || 0);
        const sgst = Number(order.sgst || 0);
        const cgst = Number(order.cgst || 0);

        // Return the total
        return subtotal + shippingAmount + sgst + cgst;
    }

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        });
    }

    const generatePDF = async () => {
        // Get the invoice element
        const invoice = document.getElementById('invoiceTable');
        
        if (!invoice) {
            console.error("Invoice element not found");
            return;
        }

        try {
            // Create canvas from the invoice element
            const canvas = await html2canvas(invoice, {
                scale: 2, // Higher scale for better quality
                useCORS: true,
                logging: false,
                backgroundColor: '#ffffff'
            });

            // Get image data
            const imgData = canvas.toDataURL('image/jpeg', 1.0);

            // Calculate dimensions
            const imgWidth = 210; // A4 width in mm
            const pageHeight = 297; // A4 height in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;

            // Create PDF
            const doc = new jsPDF('p', 'mm', 'a4');
            let position = 0;

            // Add image to first page
            doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            // Add new pages if the content exceeds one page
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            // Open PDF in new window
            doc.output("dataurlnewwindow");
        } catch (error) {
            console.error("Error generating PDF:", error);
        }
    };

// Rest of your component code remains the same

    return (
        <React.Fragment>
            {is_loading && <div style={{ background: 'rgba(0,0,0,0.3)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 99999 }}>
                <Spinner color="primary" className='position-absolute top-50 start-50' style={{ zIndex: 99 }} />
            </div>}
            <Container>
                <Card style={{minHeight: '100vh', marginTop: '5%'}}>
                    {/* <div style={{ margin: "2% 5%", display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                        <div style={{marginRight: '20px'}}>
                            <Link to="/orders"><i className="mdi mdi-arrow-left" /> Go Back</Link>
                        </div>
                        <button className="btn btn-primary" onClick={generatePDF}>
                            <i className="mdi mdi-download" /> Download PDF
                        </button>
                    </div> */}
                    <table style={{
                        width: '90%',
                        padding: '1%',
                        margin: '5%',
                        fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                        fontSize: '17px',
                        lineHeight: '27px',
                        color: '#474747',
                        border: '1px solid #ccc',
                        marginTop: 0
                    }} align="center" id="invoiceTable">
                        <tbody>
                            <tr>
                                <td>
                                    <table style={{ width: '94%', margin:'3%' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{
                                                    fontSize: '35px',
                                                    fontWeight: 'bold',
                                                    lineHeight: '38px'
                                                }}>
                                                    YOUR <br />LOGO
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    No.{order?.order_no}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '3%' }}>
                                    <h1 style={{
                                        fontSize: '75px',
                                        padding: '25px 0',
                                        margin: 0,
                                        marginTop: '35px',
                                        color: '#1e1e1e',
                                        lineHeight: '80px'
                                    }}>
                                        INVOICE
                                    </h1>
                                    <p>
                                        <strong style={{ color: '#1e1e1e' }}>Date:</strong> {formatDate(order?.order_date)}
                                    </p>
                                    <p>
                                        <strong style={{ color: '#1e1e1e' }}>Billing</strong>
                                        <br />
                                        {order?.client?.client_name}<br />
                                        {order?.client?.address}<br />
                                        {order?.client?.country || '-'}
                                    </p>

                                    <table style={{ width: '100%' }}>
                                        <thead>
                                            <tr style={{
                                                background: '#e7e6e9',
                                                color: 'rgb(85, 85, 85)'
                                            }}>
                                                <td style={{
                                                    padding: '10px 15px',
                                                    fontSize: '18px'
                                                }}>
                                                    Item
                                                </td>
                                                <td style={{
                                                    padding: '10px 15px',
                                                    textAlign: 'center',
                                                    fontSize: '18px'
                                                }}>
                                                    SKU
                                                </td>
                                                <td style={{
                                                    padding: '10px 15px',
                                                    textAlign: 'center',
                                                    fontSize: '18px'
                                                }}>
                                                    Quantity
                                                </td>
                                                <td style={{
                                                    padding: '10px 15px',
                                                    textAlign: 'right',
                                                    fontSize: '18px'
                                                }}>
                                                    Price(₹)
                                                </td>
                                                <td style={{
                                                    padding: '10px 15px',
                                                    textAlign: 'right',
                                                    fontSize: '18px'
                                                }}>
                                                    Amount(₹)
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {order?.orderLineItem.map((item, index) => (
                                                <tr key={item.id}>
                                                    <td style={{
                                                        padding: '10px 15px'
                                                    }}>
                                                        {item.line_item_title}
                                                    </td>
                                                    <td style={{
                                                        padding: '10px 15px',
                                                        textAlign: 'center'
                                                    }}>
                                                        {item.line_item_sku}
                                                    </td>
                                                    <td style={{
                                                        padding: '10px 15px',
                                                        textAlign: 'center'
                                                    }}>
                                                        {item.quantity}
                                                    </td>
                                                    <td style={{
                                                        padding: '10px 15px',
                                                        textAlign: 'right'
                                                    }}>
                                                        {Number(item.line_item_unit_price).toFixed(2)}
                                                    </td>
                                                    <td style={{
                                                        padding: '10px 15px',
                                                        textAlign: 'right'
                                                    }}>
                                                        {Number(item.gross_sale_price || 0).toFixed(2)}
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td colSpan="4" style={{
                                                    padding: '10px 15px',
                                                    textAlign: 'right',
                                                    borderTop: '2px solid #ccc',
                                                    borderBottom: '2px solid #ccc'
                                                }}>
                                                    <span style={{
                                                        fontSize: '14px',
                                                        fontWeight: '600'
                                                    }}>
                                                        Sub Total(₹):
                                                    </span>
                                                </td>
                                                <td style={{
                                                    padding: '10px 15px',
                                                    textAlign: 'right',
                                                    borderTop: '2px solid #ccc',
                                                    borderBottom: '2px solid #ccc'
                                                }}>
                                                    <span style={{
                                                        fontSize: '14px',
                                                        fontWeight: '600'
                                                    }}>
                                                        {calculateSubTotal().toFixed(2)}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4" style={{
                                                    padding: '10px 15px',
                                                    textAlign: 'right',
                                                    // borderTop: '2px solid #ccc',
                                                    // borderBottom: '2px solid #ccc'
                                                }}>
                                                    <span style={{
                                                        fontSize: '14px',
                                                        fontWeight: '500'
                                                    }}>
                                                        Shipping Charges(₹):
                                                    </span>
                                                </td>
                                                <td style={{
                                                    padding: '10px 15px',
                                                    textAlign: 'right',
                                                    // borderTop: '2px solid #ccc',
                                                    // borderBottom: '2px solid #ccc'
                                                }}>
                                                    <span style={{
                                                        fontSize: '14px',
                                                        fontWeight: '500'
                                                    }}>
                                                        {Number(order?.shipping_amount || 0).toFixed(2)}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4" style={{
                                                    padding: '10px 15px',
                                                    textAlign: 'right',
                                                    // borderTop: '2px solid #ccc',
                                                    // borderBottom: '2px solid #ccc'
                                                }}>
                                                    <span style={{
                                                        fontSize: '14px',
                                                        fontWeight: '500'
                                                    }}>
                                                        GST(₹):
                                                    </span>
                                                </td>
                                                <td style={{
                                                    padding: '10px 15px',
                                                    textAlign: 'right',
                                                    // borderTop: '2px solid #ccc',
                                                    // borderBottom: '2px solid #ccc'
                                                }}>
                                                    <span style={{
                                                        fontSize: '14px',
                                                        fontWeight: '500'
                                                    }}>
                                                        {Number(order?.cgst || 0).toFixed(2)}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4" style={{
                                                    padding: '10px 15px',
                                                    textAlign: 'right',
                                                    // borderTop: '2px solid #ccc',
                                                    // borderBottom: '2px solid #ccc'
                                                }}>
                                                    <span style={{
                                                        fontSize: '14px',
                                                        fontWeight: '500'
                                                    }}>
                                                        SGST(₹):
                                                    </span>
                                                </td>
                                                <td style={{
                                                    padding: '10px 15px',
                                                    textAlign: 'right',
                                                    // borderTop: '2px solid #ccc',
                                                    // borderBottom: '2px solid #ccc'
                                                }}>
                                                    <span style={{
                                                        fontSize: '14px',
                                                        fontWeight: '500'
                                                    }}>
                                                        {Number(order?.sgst || 0).toFixed(2)}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4" style={{
                                                    padding: '10px 15px',
                                                    textAlign: 'right',
                                                    borderTop: '2px solid #ccc',
                                                    borderBottom: '2px solid #ccc'
                                                }}>
                                                    <span style={{
                                                        fontSize: '18px',
                                                        fontWeight: 'bold'
                                                    }}>
                                                        TOTAL(₹):
                                                    </span>
                                                </td>
                                                <td style={{
                                                    padding: '10px 15px',
                                                    textAlign: 'right',
                                                    borderTop: '2px solid #ccc',
                                                    borderBottom: '2px solid #ccc'
                                                }}>
                                                    <span style={{
                                                        fontSize: '18px',
                                                        fontWeight: 'bold'
                                                    }}>
                                                        {calculateTotal().toFixed(2)}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p style={{ marginTop: "2rem" }}>
                                        <strong style={{ color: '#1e1e1e' }}>Payment Method</strong>: {order?.paymentMethod?.method_name || 'Not Specified'}<br />
                                        <strong style={{ color: '#1e1e1e' }}>Payment Term</strong>: {order?.paymentTerm?.term_name || 'Not Specified'}<br />
                                        {order?.order_instruction && (
                                            <>
                                                <strong style={{ color: '#1e1e1e' }}>Note</strong>: {order?.order_instruction}
                                            </>
                                        )}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Card>
            </Container>
        </React.Fragment>
    );
}