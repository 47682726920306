import Breadcrumb from 'components/Common/Breadcrumb'
import Spinners from 'components/Common/Spinner'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import { getClient, getClientType, postClient, updateClient } from 'services/ClientManagementServices'
import { getPaymentTerm } from 'services/PaymentTermServices'
import { getSingleVendor, updateVendor } from 'services/VendorServices'
import Select from "react-select";
import { getCategoryDropdown, getVendorType } from 'services/MasterServices'

const EditVendor = () => {
    // const [formData, setFormData] = useState({
    //     cTypeId: '',
    //     paymentTermId: '',
    //     clientName: '',
    //     address: '',
    //     status: true,
    // })

    const [clientTypeRecordList, setClientTypeRecordList] = useState([]);
    const [paymentTermRecordList, setPaymentTermRecordList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [mainLoading, setMainLoading] = useState(true)
    const [client,setClient] = useState({});
    const navigate = useNavigate();
    const { id } = useParams()
    const [errorMessage, setErrorMessage] = useState(false);
    const [vendorName, setVendorName] = useState("");
    const [paymentTermId, setPaymentTermId] = useState("");
    const [upiId, setUpiId] = useState("");
    const [categoryServiceDesc, setCategoryServiceDesc] = useState("");
    const [gstNo, setGstNo] = useState("");
    const [isGst, setIsGst] = useState(false);
    const [isSplitGst, setIsSplitGst] = useState(false);
    const [status, setStatus] = useState(true);
    const [vendorTypeList, setVendorTypeList] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);
    const [selectedCategories, setselectedCategories] = useState(null);
    const [arrChange, setArrChange] = useState(false);
    var categoriesTemp = [];



    //meta title
    document.title = `Vendor  Edit  | ${process.env.REACT_APP_SITE_NAME} Admin Panel`;

 

    useEffect(() => {
        getCategoryList()
        getCientTypeRecord();
        getPaymentTermRecord();
        getVendorTypeRecord()
        
    }, [])



    const getCientTypeRecord = async () => {
        try {
            let params = {
                status: "active"
            }
            await getClientType(params).then(response => {
                console.log(response)
                setClientTypeRecordList(response)
            }).catch(err => console.log(err))
        } catch (error) {

        }

    }
    const getPaymentTermRecord = async () => {
        try {
            let params = {
                status: "active"
            }
            await getPaymentTerm(params).then(response => {
                console.log(response)
                setPaymentTermRecordList(response)
            }).catch(err => console.log(err))
        } catch (error) {
            console.log(error)
        }

    }



    const getSingleVendorList= async () => {
        try {
            setMainLoading(true)
            await getSingleVendor(id)
            .then(response=>{
                console.log(response)

                const  { vendor_name,is_gst,is_gst_split,gst_no,default_payment_terms_id,upi_id,category_service_desc,status } = response

                setVendorName(vendor_name)
                setPaymentTermId(default_payment_terms_id)
                setUpiId(upi_id)
                setCategoryServiceDesc(category_service_desc)
                setGstNo(gst_no)
                setIsGst(is_gst===1?true:false)
                setIsSplitGst(is_gst_split===1?true:false)
                setStatus(status===1?true:false)

                setClient(response)


                

                if(response.vendorContact){
                    let tempArrr= response.vendorContact || []
                    setFormRows([...tempArrr])
                }

                if(response.vendorTypeRels){
                    let tempArrr= response.vendorTypeRels.map(item=>({id:item.id,vendor_type_id:item.vendor_type_id,is_primary:item.is_primary?true:false})) || []
                    setVendorFormRows([...tempArrr])
                }

                if(response.vendorCategoryServ){
                   

                    let arrPush= []

                    categoriesTemp.forEach(item=>{
                        if(Array.isArray(item.options) && item.options.length>0){
                          item?.options.map(ite=>
                             response.vendorCategoryServ.map(itemRes=>{if(itemRes.cat_id === ite?.value){
                                arrPush.push(ite)
                             }})
                           );

                        
                       
                        }

                    })
                    console.log(arrPush,"arrPush",categoriesTemp)
                    setselectedCategories(arrPush)


                   
                    

                }

              
                setMainLoading(false)
            }).catch(err=>{
                console.log(err)
                setMainLoading(false)
                toast.error("Something went wrong.",{autoClose:500})
            })
        } catch (error) {
            toast.error("Something went wrong.",{autoClose:500})
        }
    } 

   const getVendorTypeRecord = async () => {
        try {
            let params = {
                status: 'active'
            }
            await getVendorType(params).then(response => {
                console.log(response,"response")
                setVendorTypeList(response)
            }).catch(err => console.log(err))
        } catch (error) {

        }
    }

    const [formRows, setFormRows] = useState([
        { id: 1, contact_fname: "", contact_lname: "", contact_address: "", contact_primary_ph_no: "", contact_addl_ph_no: "", contact_email: "", whatsapp_no: "" }
    ]);
    const onAddFormRow = () => {
        setArrChange(true)
        const newRow = { id: Math.floor(Math.random() * (30 - 20)) + 20, contact_fname: "", contact_lname: "", contact_address: "", contact_primary_ph_no: "", contact_addl_ph_no: "", contact_email: "", whatsapp_no: "" };
        setFormRows([...formRows, newRow]);
    };

    const onDeleteFormRow = (id) => {
        setArrChange(true)
        const updatedRows = formRows.filter((row) => row.id !== id);
        setFormRows(updatedRows);
    };

    // Function to handle changes in input fields
    const handleInputChange = (id, name, value) => {
        setArrChange(true)
        const updatedRows = formRows.map(row => {
            if (row.id === id) {
                return { ...row, [name]: value };
            }
            return row;
        });
        setFormRows(updatedRows);
    };


    const [vendorFormRows, setVendorFormRows] = useState([
        { id: 1, vendor_type_id: "", is_primary: false }
    ]);
    const onAddVendorFormRow = () => {
        setArrChange(true)
        const newRow = { id: "vendor" + Math.floor(Math.random() * (30 - 20)) + 20, vendor_type_id: "", is_primary: false };
        setVendorFormRows([...vendorFormRows, newRow]);
    };

    const onDeleteVendorFormRow = (id) => {
        setArrChange(true)
        const updatedRows = vendorFormRows.filter((row) => row.id != id);
        setVendorFormRows(updatedRows);
    };

    // Function to handle changes in input fields
    const handleInputVendorFormChange = (id, name, value) => {
        setArrChange(true)
        const updatedRows = vendorFormRows.map(row => {
            if (row.id == id) {
                return { ...row, [name]: name === 'is_primary' ? !value : value };
            }
            return row;
        });
        setVendorFormRows(updatedRows);
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(true)
        let submitFlag = true;


        if (vendorName === "") {
            submitFlag = false;
        }
        if (paymentTermId === "") {
            submitFlag = false;
        }


        if (submitFlag) {
            let payload = {
                vendor_name: vendorName,
                is_gst: isGst?1:0,
                is_gst_split: isSplitGst?1:0,
                gst_no: gstNo,
                default_payment_terms_id: Number(paymentTermId),
                // upi_id: upiId,
                category_service_desc: categoryServiceDesc,
                status: status?1:0
            }

            if (arrChange && formRows.length > 0 && formRows[0]?.contact_fname !== "") {

                let tempArray = formRows.map(function (item) {

                    if (item?.contact_fname === "") {
                        return null;
                    }
                    delete item.id;
                    return item;
                }).filter(a => a);


                payload['vendorContacts'] = tempArray

            }


            if (arrChange && vendorFormRows.length > 0 && vendorFormRows[0]?.vendor_type_id !== "") {


                let tempArray = vendorFormRows.map(function (item) {

                    if (item?.vendor_type_id === "") {
                        return null;
                    }
                    // delete item.id; 
                    return { vendor_type_id:Number( item.vendor_type_id), is_primary: item.is_primary
                        ? 1 : 0 , vendor_id:Number(id)};
                }).filter(a => a);


                payload['vendorTypes'] = tempArray
            }

            if ( arrChange && selectedCategories.length > 0) {


                let tempArray = selectedCategories.map(function (item) {


                    return { cat_id: item.value, vendor_id:Number(id) };
                }).filter(a => a);


                payload['vendorcategories'] = tempArray
            }

            setLoading(true)
            try {
                await updateVendor(id,payload).then(response => {
                    console.log(response)

                    if (response) {

                        toast.success("Vendor is updated successfully.", { autoClose: 2000 })
                        setTimeout(() => {

                            navigate('/vendor')
                        }, 2000);

                    }
                    setLoading(false)
                }
                ).catch(err=>{
                    console.log(err)
                    toast.error(`Vendor is updated Failed`, { autoClose: 2000 });
                })
            } catch (error) {
                setLoading(false)
            }
        }

    }


    const handleCategories = async (selectedMulti) => {

        console.log(selectedMulti,"selectedMulti")
        setArrChange(true)

        setselectedCategories(selectedMulti);

    }



    const getCategoryList = async () => {
        let params = {
            status: 'active',
            parent_type: 'parent',

        }

        try {
            await getCategoryDropdown({ params }).then(async response => {
                console.log(response)

                let data = response;
                let tempArr = [];

                data.forEach((item) => {

                    const { category_name, subcategories } = item;

                   
                    if (subcategories && subcategories.length > 0) {
                        let tempOption = [];
                        console.log(subcategories, "tempArrsubcategories")
                        subcategories.forEach(element => {
                            if (element.status === 1) {
                                tempOption.push({ label: element.category_name, value: element.id })
                            }
                        })

                      let  temp = {
                         
                            label: category_name,
                            options: tempOption
                        }
                        tempArr.push(temp)
                    }



                })

                console.log(tempArr, "tempArr")
                categoriesTemp=tempArr

                setCategoriesList(tempArr)

            await    getSingleVendorList();


            }).catch(err => console.log(err))
        } catch (error) {
            console.log(error)
        }


    }


    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumb title="Manage Vendor"  to={`/vendor`} breadcrumbItem="Edit Vendor" />


            {
             mainLoading   ? <Spinners setLoading={setMainLoading} />

             :

                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody className="border-bottom">
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 card-title flex-grow-1">Edit Vendor</h5>
                                    <div className="flex-shrink-0">
                                        <Link to="/vendor" className="btn btn-primary me-1">Back</Link>

                                    </div>
                                </div>
                            </CardBody>
                            <CardBody>
                                <Form onSubmit={handleSubmit}>


                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-firstname-Input">Vendor Name <span className='text-danger'>*</span></Label>
                                                <Input
                                                    type="text"
                                                    name="vendorName"
                                                    className="form-control"
                                                    id="formrow-cname-Input"
                                                    placeholder="Enter Vendor Name"
                                                    value={vendorName}
                                                    onChange={(e) => setVendorName(e.target.value)}
                                                    invalid={
                                                        errorMessage && vendorName === "" ? true : false
                                                    }
                                                />
                                                {
                                                    errorMessage && vendorName === "" ? (
                                                        <FormFeedback type="invalid">{"This field is required"}</FormFeedback>
                                                    ) : null
                                                }
                                            </div>
                                        </Col>

                                        <Col md={3}>
                                            <div className="mb-3">
                                                <Label>Is Gst</Label>
                                                <FormGroup switch>
                                                    <Label className="">
                                                         {/* {isGst === true ? 'Active' : 'Inactive'} */}
                                                        <Input
                                                            name="isGst"
                                                            type="switch"
                                                            role="switch"
                                                            checked={isGst}

                                                            onClick={() => setIsGst(!isGst)}

                                                            value={isGst}


                                                        />

                                                    </Label>

                                                </FormGroup>


                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="mb-3">
                                                <Label>Is Split Gst</Label>
                                                <FormGroup switch>
                                                    <Label className=""> 
                                                        {/* {isSplitGst === true ? 'Active' : 'Inactive'} */}
                                                        <Input
                                                            name="isSplitGst"
                                                            type="switch"
                                                            role="switch"
                                                            checked={isSplitGst}

                                                            onClick={() => setIsSplitGst(!isSplitGst)}

                                                            value={isSplitGst}


                                                        />

                                                    </Label>

                                                </FormGroup>


                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-firstname-Input">Gst Number</Label>
                                                <Input
                                                    type="text"
                                                    name="vendorName"
                                                    className="form-control"
                                                    id="formrow-cname-Input"
                                                    placeholder="Enter Gst Number"
                                                    value={gstNo}
                                                    onChange={(e) => setGstNo(e.target.value)}

                                                />

                                            </div>
                                        </Col>

                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-paymentTermId-Input">Payment Term</Label>
                                                <Input
                                                    type="select"
                                                    name="paymentTermId"
                                                    className="form-control"
                                                    id="formrow-paymentTermId-Input"
                                                    placeholder="Enter Payment Term"
                                                    value={paymentTermId}
                                                    onChange={(e) => setPaymentTermId(e.target.value)}

                                                    invalid={
                                                        errorMessage && paymentTermId === "" ? true : false
                                                    }
                                                >
                                                    <option value={""}>Select</option>
                                                    {
                                                        paymentTermRecordList?.map((item, index) => <option key={`payment-tem-${index}`} value={item?.id}>{item?.term_name}</option>)
                                                    }
                                                </Input>
                                                {
                                                    errorMessage && paymentTermId === "" ? (
                                                        <FormFeedback type="invalid">{"This field is required"}</FormFeedback>
                                                    ) : null
                                                }
                                            </div>
                                        </Col>

                                        {/* <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-firstname-Input">Upi Id</Label>
                                                <Input
                                                    type="text"
                                                    name="upiId"
                                                    className="form-control"
                                                    id="formrow-cname-Input"
                                                    placeholder="Enter Upi Id"
                                                    value={upiId}
                                                    onChange={(e) => setUpiId(e.target.value)}

                                                />

                                            </div>
                                        </Col> */}

                                        <Col md={6}>
                                            <div className="mb-3">
                                                <label className="control-label">
                                                    Vendor Category
                                                </label>
                                                <Select
                                                    value={selectedCategories}
                                                    isMulti={true}
                                                    placeholder={"Please choose vendor category"}
                                                    onChange={
                                                        handleCategories
                                                    }
                                                    options={categoriesList}
                                                    className="select2-selection"
                                                />
                                            </div>


                                        </Col>


                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-firstname-Input">Category Service Description</Label>
                                                <Input
                                                    type="textarea"
                                                    name="category_service_desc"
                                                    className="form-control"
                                                    id="formrow-cname-Input"
                                                    placeholder="Enter Category Service Description"
                                                    value={categoryServiceDesc}
                                                    rows={3}
                                                    onChange={(e) => setCategoryServiceDesc(e.target.value)}

                                                />

                                            </div>
                                        </Col>


                                    </Row>


                                    
                                    <Row className='my-4'>
                                        <Col md={12}>


                                            <h6 className="mb-4 card-title">Vendor Type Details</h6>

                                            <div>
                                                {(vendorFormRows || []).map((formRow, key) => (
                                                    <Card outline color="primary" className="border" key={`card-${key}`}>
                                                        <CardBody>

                                                            <Row >
                                                                <Col lg={4} className="mb-3">
                                                                    <label htmlFor="vendor_type_id">Vendor Type </label>
                                                                    <Input
                                                                        type="select"
                                                                        id="vendor_type_id"
                                                                        name={`vendor_type_id${formRow.id}`}
                                                                        value={formRow.vendor_type_id}
                                                                        onChange={(e) => handleInputVendorFormChange(formRow.id, 'vendor_type_id', e.target.value)}
                                                                        className="form-control"

                                                                    >
                                                                        <option value={""}>Select</option>

                                                                        {
                                                                            vendorTypeList?.map((item, index) => <option key={`vendor-${index}`} value={item?.id}>{item?.type_name}</option>)
                                                                        }

                                                                    </Input>
                                                                </Col>
                                                                <Col lg={4} className="mb-3">

                                                                    <label htmlFor="is_primary">Is Primary</label>

                                                                    <FormGroup switch>
                                                                        <Label className=""> 
                                                                            {/* {formRow.is_primary === true ? 'Active' : 'Inactive'} */}
                                                                            <Input
                                                                                name="is_primary"
                                                                                type="switch"
                                                                                role="switch"
                                                                                checked={formRow.is_primary}
                                                                                value={true}

                                                                                onClick={(e) => handleInputVendorFormChange(formRow.id, 'is_primary', e.target.checked)}




                                                                            />

                                                                        </Label>
                                                                    </FormGroup>
                                                                </Col>




                                                                {vendorFormRows.length > 1 &&
                                                                    <Col lg={4} className="align-self-center">
                                                                        <div className="d-grid">
                                                                            <input
                                                                                type="button"
                                                                                className="btn btn-primary"
                                                                                value="Delete"
                                                                                onClick={() => onDeleteVendorFormRow(formRow.id)}
                                                                            />
                                                                        </div>
                                                                    </Col>

                                                                }

                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                ))}
                                            </div>
                                            <input
                                                type="button"
                                                className="btn btn-primary mt-3 mt-lg-0"
                                                value="Add"
                                                onClick={() => onAddVendorFormRow()}
                                            />







                                        </Col>
                                    </Row>


                                    <Row className='my-4'>
                                        <Col md={12}>


                                            <h6 className="mb-4 card-title">Contact Details</h6>

                                            <div>
                                                {(formRows || []).map((formRow, key) => (
                                                    <Card outline color="primary" className="border" key={`card-${key}`}>
                                                        <CardBody>

                                                            <Row >
                                                                <Col lg={4} className="mb-3">
                                                                    <label htmlFor="contact_fname">First Name </label>
                                                                    <input
                                                                        type="text"
                                                                        id="contact_fname"
                                                                        name={`contact_fname${formRow.id}`}
                                                                        value={formRow.contact_fname}
                                                                        onChange={(e) => handleInputChange(formRow.id, 'contact_fname', e.target.value)}
                                                                        className="form-control"
                                                                        placeholder="Enter Your First Name"
                                                                    />
                                                                </Col>
                                                                <Col lg={4} className="mb-3">
                                                                    <label htmlFor="contact_lname">Last Name</label>
                                                                    <input
                                                                        type="text"
                                                                        id="contact_lname"
                                                                        name={`contact_lname-${formRow.id}`}
                                                                        value={formRow.contact_lname}
                                                                        onChange={(e) => handleInputChange(formRow.id, 'contact_lname', e.target.value)}
                                                                        className="form-control"
                                                                        placeholder="Enter Your Last Name"
                                                                    />
                                                                </Col>

                                                                <Col lg={4} className="mb-3">
                                                                    <label htmlFor="contact_email">Email</label>
                                                                    <input
                                                                        type="email"
                                                                        id="contact_email"
                                                                        name={`contact_email${formRow.id}`}
                                                                        value={formRow.contact_email}
                                                                        onChange={(e) => handleInputChange(formRow.id, 'contact_email', e.target.value)}
                                                                        className="form-control"
                                                                        placeholder="Enter Your Email ID"
                                                                    />
                                                                </Col>

                                                                <Col lg={4} className="mb-3">
                                                                    <label htmlFor="contact_primary_ph_no">Primary Phone Number</label>
                                                                    <input
                                                                        type="text"
                                                                        id="contact_primary_ph_no"
                                                                        name={`contact_primary_ph_no-${formRow.id}`}
                                                                        value={formRow.contact_primary_ph_no}
                                                                        onChange={(e) => handleInputChange(formRow.id, 'contact_primary_ph_no', e.target.value)}
                                                                        className="form-control"
                                                                        placeholder="Enter Your Primary Phone Number"
                                                                    />
                                                                </Col>
                                                                <Col lg={4} className="mb-3">
                                                                    <label htmlFor="contact_addl_ph_no">Additional Phone Number</label>
                                                                    <input
                                                                        type="text"
                                                                        id="contact_addl_ph_no"
                                                                        name={`contact_addl_ph_no-${formRow.id}`}
                                                                        value={formRow.contact_addl_ph_no}
                                                                        onChange={(e) => handleInputChange(formRow.id, 'contact_addl_ph_no', e.target.value)}
                                                                        className="form-control"
                                                                        placeholder="Enter Your Additional Phone Number"
                                                                    />
                                                                </Col>
                                                                <Col lg={4} className="mb-3">
                                                                    <label htmlFor="whatsapp_no">WhatsApp Number</label>
                                                                    <input
                                                                        type="text"
                                                                        id="whatsapp_no"
                                                                        name={`whatsapp_no-${formRow.id}`}
                                                                        value={formRow.whatsapp_no}
                                                                        onChange={(e) => handleInputChange(formRow.id, 'whatsapp_no', e.target.value)}
                                                                        className="form-control"
                                                                        placeholder="Enter Your Whatsapp Number"
                                                                    />
                                                                </Col>
                                                                <Col lg={4} className="mb-3">
                                                                    <label htmlFor="contact_address">Address</label>
                                                                    <Input
                                                                        type="textarea"
                                                                        rows={3}
                                                                        id="contact_address"
                                                                        name={`contact_address-${formRow.id}`}
                                                                        value={formRow.contact_address}
                                                                        onChange={(e) => handleInputChange(formRow.id, 'contact_address', e.target.value)}
                                                                        className="form-control"

                                                                    />
                                                                </Col>



                                                                {formRows.length > 1 &&
                                                                    <Col lg={4} className="align-self-center">
                                                                        <div className="d-grid">
                                                                            <input
                                                                                type="button"
                                                                                className="btn btn-primary"
                                                                                value="Delete"
                                                                                onClick={() => onDeleteFormRow(formRow.id)}
                                                                            />
                                                                        </div>
                                                                    </Col>

                                                                }

                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                ))}
                                            </div>
                                            <input
                                                type="button"
                                                className="btn btn-primary mt-3 mt-lg-0"
                                                value="Add"
                                                onClick={() => onAddFormRow()}
                                            />







                                        </Col>
                                    </Row>




                                    <div className="mb-3">
                                        <Label>Status</Label>
                                        <FormGroup switch>
                                            <Label className="">
                                                 {/* {status === true ? 'Active' : 'Inactive'} */}
                                                <Input
                                                    name="status"
                                                    type="switch"
                                                    role="switch"
                                                    checked={status}

                                                    onClick={() => setStatus(!status)}

                                                    value={status}


                                                />

                                            </Label>

                                        </FormGroup>


                                    </div>
                                    <div>
                                        <button type="submit" className="btn btn-primary w-md" disabled={loading}>
                                            Submit
                                        </button>
                                    </div>
                                </Form>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }

                <ToastContainer/>
            </div>
        </div>
    )
}

export default EditVendor