const jwtDecodeFunc = () =>{

    const obj = JSON.parse(localStorage.getItem("authUser"));

    if(obj && obj.access_token){
      const { access_token } = obj;
      const arrayToken =  access_token.split('.');
      const tokenPayload = JSON.parse(atob(arrayToken[1]));

      return { ...tokenPayload }
    }

    return {}
}


export { jwtDecodeFunc }