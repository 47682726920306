/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Container, Fade } from "reactstrap";
import { Link, useParams } from 'react-router-dom';
import moment from "moment";

// Services
import { getOrderById } from "services/OrderService"

// Components
import { Spinner } from "reactstrap";

export default function ManageProducts(props) {
    
    const params = useParams()
    const [isLoading, setLoading] = useState(true)
    const [order, setOrders] = useState(null)

    const getOrderByID = async () => {
        setLoading(true)
        const id = params.id
        if (id) {
            await getOrderById(id)
            .then(res => {
                setOrders(res)
            })
        }
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    useEffect(() => {                                                   
        getOrderByID()
    }, [params.id])
    
    return (
        <React.Fragment>
            {isLoading && <div style={{ background: 'rgba(0,0,0,0.3)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 999999999 }}>
                <Spinner color="primary" className='position-absolute top-50 start-50' style={{ zIndex: 99 }} />
            </div>}
            <Container>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h6 className="border-heading">Order Information</h6>
                                        <div className="border-heading-body">
                                            <div className="row">
                                                <div className="col-lg-6 mb-2">
                                                    <div className="row">
                                                        <div className="col-lg-6 fw-semibold">Order ID </div>
                                                        <div className="col-lg-6">: { order && order.order_no }</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-2">
                                                    <div className="row">
                                                        <div className="col-lg-6 fw-semibold">Order Date </div>
                                                        <div className="col-lg-6">: { order && moment(order.order_date).format('DD/MM/YYYY') }</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-2">
                                                    <div className="row">
                                                        <div className="col-lg-6 fw-semibold">Delivery Date </div>
                                                        <div className="col-lg-6">: { order && moment(order.ordered_delivery_date).format('DD/MM/YYYY') }</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-2">
                                                    <div className="row">
                                                        <div className="col-lg-12 fw-semibold">Order Instructions: </div>
                                                        <div className="col-lg-12">{ order && order.order_instruction }</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr className="mb-3 mt-2" />

                                <h6 className="border-heading">Product Informations</h6>
                                <div className="border-heading-body">
                                    {order && order.orderLineItem && order.orderLineItem.map((item, i) => <div key={i} className="row">
                                        <div className="col-lg-12 mt-3">
                                            <div className="row">
                                                <div className="col-lg-3 mb-1">Name</div>
                                                <div className="col-lg-9">: { item.line_item_title}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 mb-1">SKU</div>
                                                <div className="col-lg-9">: { item.line_item_sku }</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 mb-1">Unit Price</div>
                                                <div className="col-lg-9">: { item.line_item_unit_price }</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 mb-1">Quantity</div>
                                                <div className="col-lg-9">: { item.quantity }</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 mb-1">Brand:</div>
                                                <div className="col-lg-9">: { item.product ? item.product.brand : '-' }</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 mb-1">Height:</div>
                                                <div className="col-lg-9">: { item.product ? item.product.height + '' + item.product.uom_length : '-' }</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 mb-1">Weight:</div>
                                                <div className="col-lg-9">: { item.product ? item.product.weight + '' + item.product.uom_weight : '-' }</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 mb-1">Order Description</div>
                                                <div className="col-lg-9">: { item.line_item_description }</div>
                                            </div>
                                        </div>
                                        <hr className="mt-4" />
                                    </div> )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </React.Fragment>
    );
};