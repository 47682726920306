import { del, get, patch, post } from "helpers/api_helper";

// Category
export const getCategoryDropdown = ({params}) => get(`/category`, {params});

export const createCategory = (payload)  => post('/category',payload);

export const updateCategory = async (id,payload) => await patch(`/category/${id}`,payload);

export const deleteCategory = async (id) =>await del(`/category/${id}`);


//Vendor Type 

export const getVendorType = (params) => get('/vendor-type',{params});

export const updateVendorType = async (id,payload) => await patch(`/vendor-type/${id}`,payload);

// Payment Method 

export const getPaymentMethod = () => get(`/payment-method`);

export const createPaymentMethod = (payload)  => post('/payment-method',payload);

export const updatePaymentMethod = async (id,payload) => await patch(`/payment-method/${id}`,payload);

export const deletePaymentMethod = async (id) =>await del(`/payment-method/${id}`);


// Payment Term 

export const getPaymentTerm = () => get(`/payment-term`);

export const createPaymentTerm = (payload)  => post('/payment-term',payload);

export const updatePaymentTerm = async (id,payload) => await patch(`/payment-term/${id}`,payload);

export const deletePaymentTerm = async (id) =>await del(`/payment-term/${id}`);



// Payment Account  

export const getPaymentAccountMethod = (params) => get(`/payment-method-provider`,{params});

export const getSinglePaymentAccountMethod = async(id) => await get(`/payment-method-provider/${id}`);

export const createPaymentAccountMethod = (payload)  => post('/payment-method-provider',payload);

export const updatePaymentAccountMethod = async (id,payload) => await patch(`/payment-method-provider/${id}`,payload);

export const deletePaymentAccountMethod = async (id) =>await del(`/payment-method-provider/${id}`);




// Deductions
export const getDeductionList = ({params}) => get(`/deduction-reason`,{params});

export const createDeductionReason = (payload)  => post('/deduction-reason',payload);

export const updateDeductedReason = async (id,payload) => await patch(`/deduction-reason/${id}`,payload);

export const deleteDeductionReason = async (id) =>await del(`/deduction-reason/${id}`);


// Deduction Setting
export const getDeductionSettingList = ({params}) => get(`/setting`,{params});
export const deleteDeductionSettingList = async (id) =>await del(`/setting/${id}`);
export const updateDeductedSettingList = async (id,payload) => await patch(`/setting/${id}`,payload);
