import React, { useEffect, useState } from "react"
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Label,
  Input,
  Button,
  Form,
  FormFeedback,
  Alert,
  FormGroup,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import * as Yup from "yup"
import { useFormik } from "formik"
import { Link, useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import { addNewUser, getRoleForUserAssign } from "services/UserServices"

const AddUser = () => {
  document.title = `Add User |  ${process.env.REACT_APP_SITE_NAME} - Admin Portal`

  // const navigate = useNavigate()
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [selectedRoles, setSelectedRoles] = useState({}) // To track the checked state of each role

  // const navigate=useNavigate()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  const [status, setStatus] = useState(true) // Initialize status as true (active)
  const [roleAssign, setRoleAssign] = useState([])
  const validation = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      primary_ph_no: "",
      addl_ph_no: "",
      address: "",
      gender: "",
      pan_no: "",
      aadhar_no: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      fname: Yup.string().required("First name is required"),
      lname: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      primary_ph_no: Yup.string()
        .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
        .notRequired(),
      whatsapp_no: Yup.string()
        .matches(/^[0-9]{10}$/, "Whatsapp number must be 10 digits")
        .notRequired(),
      addl_ph_no: Yup.string()
        .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
        .notRequired(),
      address: Yup.string().required("Address is required"),
      gender: Yup.string().required("Gender is required"),
      pan_no: Yup.string()
        .matches(
          /^[A-Z]{5}[0-9]{4}[A-Z]$/,
          "PAN number must follow the format: 5 uppercase letters, 4 digits, and 1 uppercase letter"
        )
        .notRequired(),
      aadhar_no: Yup.string()
        .length(12, "Aadhaar number must be 12 digits")
        .notRequired(),
      password: Yup.string().required("Password is required"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please confirm your password"),
    }),
    onSubmit: async values => {
      // console.log("New user data:", values)
      const errors = await validation.validateForm()
      if (Object.keys(errors).length > 0) {
        // If there are errors, display them in a toast and do not proceed with the submission
        const errorMessages = Object.values(errors)
          .flat() // Flatten the array of error messages
          .join(", ") // Combine all error messages into a single string
        toast.error(errorMessages, { autoClose: 1500 }) // Show toast for validation errors
        console.log("Validation errors:", errors)
        return // Stop the submission process
      }

      const newUser = {
        company_id: 1, // Example company ID
        first_name: values.fname,
        middle_name: "",
        last_name: values.lname,
        whatsapp_no: values.whatsapp_no,

        primary_ph_no: values.primary_ph_no,
        addl_ph_no: values.addl_ph_no,
        address: values.address,
        gender: values.gender,
        pan_no: values.pan_no,
        aadhar_no: values.aadhar_no,
        email: values.email,
        password: values.password,
        user_type: 2, // Add user type
        status: status ? 1 : 0, // Include the status value in the submission (true=1, false=0)
        image: "",
      }
      console.log("Submit users", newUser)
      try {
        const response = await addNewUser(newUser)
        console.log("User added successfully:", response)
        if (response.id) {
          // toast.success("User added successfully!", { autoClose: 1500 });
          setError(null)
          console.log("User Created Successfully:", response)

          // Generate selectedRolesData using response.id as user_id
          const selectedRolesData = roleAssign.map(role => ({
            role_id: role.id,
            user_id: response.id, // Assign the returned user ID here
            status: selectedRoles[role.id] ? 1 : 0,
          }))

          console.log("Roles with user ID:", selectedRolesData)

          // Second API call
          try {
            // Prepare the headers with the Bearer token
            const authToken = localStorage.getItem("authUser") // Replace with the actual key used for the token
            const headers = {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json", // Ensure the content type is set correctly
            }

            // Make the API call using Fetch
            const response = await fetch(
              `${process.env.REACT_APP_BASEPATH}/role-module/user-role-assign`,
              {
                method: "POST",
                headers: headers,
                body: JSON.stringify(selectedRolesData), // Convert finalPermissions to JSON
              }
            )

            // Check if the response is ok (status in the range 200-299)
            if (response.ok) {
              const moduleResponse = await response.json() // Parse JSON response
              console.log(
                "User Role Permission submitted successfully:",
                moduleResponse
              )
              toast.success("User and Roles Created Successfully", {
                autoClose: 1500,
              })
              setTimeout(() => {
                window.history.back(); // Navigate back to the previous page
              }, 2000);
              // window.location.href = "/manage-user"
              // Redirect or handle success as needed
              // history.push("/users")
              
            } else {
              const errorData = await response.json() // Parse error response
              console.log("Error in submitting permissions:", errorData)
              console.log("Status Code:", response.status)
              toast.error("Error in Assigning User Role", { autoClose: 1500 })
            }
          } catch (error) {
            toast.error("Error in Api Calling", { autoClose: 1500 })

            console.error("Error in API call:", error.message)
          }
        }

        setError(null)

        setTimeout(() => {
          // navigate("/manage-user")
        }, 2000)
      } catch (err) {
        const errorMessage = Array.isArray(err.response.data.message)
          ? err.response.data.message.join(", ") // Combine error messages
          : err.response.data.message // Fallback to single message if not an array

        console.log("Error adding user:", errorMessage)
        toast.error(errorMessage, { autoClose: 1500 })

        setSuccess(null)
      }
    },
  })

  useEffect(() => {
    fetchRolesForUser()
  }, [])

  const fetchRolesForUser = async () => {
    try {
      let params = {
        status: 1,
      }
      await getRoleForUserAssign(params)
        .then(response => {
          if (response) {
            console.log("Roles For Assigned Users is-", response)
            setRoleAssign(response)
          }
        })
        .catch(err => console.log(err))
    } catch (error) {
      // setLoading(false)
      console.log(error)
    }
  }

  const handleCheckboxChange = roleId => {
    setSelectedRoles(prev => ({
      ...prev,
      [roleId]: !prev[roleId], // Toggle the checked state
    }))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Manage User" breadcrumbItem="Add User" />
          <Row>
            <Col lg="12">
              <Card>
                
                
              <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">
                      Add User
                    </h5>
                    <div className="flex-shrink-0">
                      <Link
                        to="/manage-user"
                        className="btn btn-primary me-1"
                      >
                        Back
                      </Link>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                <Form
                    className="form-horizontal"
                    onSubmit={validation.handleSubmit}
                  >
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="fname">
                            First Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            name="fname"
                            id="fname"
                            placeholder="Enter Name"
                            value={validation.values.fname}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.fname &&
                              validation.errors.fname
                                ? true
                                : false
                            }
                          />
                          {validation.touched.fname &&
                            validation.errors.fname && (
                              <FormFeedback type="invalid">
                                {validation.errors.fname}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="lname">
                            Last Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            name="lname"
                            id="lname"
                            placeholder="Enter Name"
                            value={validation.values.lname}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.lname &&
                              validation.errors.lname
                                ? true
                                : false
                            }
                          />
                          {validation.touched.lname &&
                            validation.errors.lname && (
                              <FormFeedback type="invalid">
                                {validation.errors.lname}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="email">
                            Email <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Enter Email"
                            value={validation.values.email}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                            validation.errors.email && (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="gender">
                            Gender <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="select"
                            name="gender"
                            id="gender"
                            value={validation.values.gender}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.gender &&
                              validation.errors.gender
                                ? true
                                : false
                            }
                          >
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </Input>
                          {validation.touched.gender &&
                            validation.errors.gender && (
                              <FormFeedback type="invalid">
                                {validation.errors.gender}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="whatsapp_no">Whatsapp Number (Optional)</Label>
                          <Input
                            type="text"
                            name="whatsapp_no"
                            id="whatsapp_no"
                            placeholder="Enter Number"
                            value={validation.values.whatsapp_no}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.whatsapp_no &&
                              validation.errors.whatsapp_no
                                ? true
                                : false
                            }
                          />
                          {validation.touched.whatsapp_no &&
                            validation.errors.whatsapp_no && (
                              <FormFeedback type="invalid">
                                {validation.errors.whatsapp_no}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="primary_ph_no">
                            Primary Phone Number (Optional)
                          </Label>
                          <Input
                            type="text"
                            name="primary_ph_no"
                            id="primary_ph_no"
                            placeholder="Enter Number "
                            value={validation.values.primary_ph_no}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.primary_ph_no &&
                              validation.errors.primary_ph_no
                                ? true
                                : false
                            }
                          />
                          {validation.touched.primary_ph_no &&
                            validation.errors.primary_ph_no && (
                              <FormFeedback type="invalid">
                                {validation.errors.primary_ph_no}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="addl_ph_no">
                            Additional Phone Number (Optional)
                          </Label>
                          <Input
                            type="text"
                            name="addl_ph_no"
                            id="addl_ph_no"
                            placeholder="Enter Number "
                            value={validation.values.addl_ph_no}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.addl_ph_no &&
                              validation.errors.addl_ph_no
                                ? true
                                : false
                            }
                          />
                          {validation.touched.addl_ph_no &&
                            validation.errors.addl_ph_no && (
                              <FormFeedback type="invalid">
                                {validation.errors.addl_ph_no}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="pan_no">PAN Number</Label>
                          <Input
                            type="text"
                            name="pan_no"
                            id="pan_no"
                            placeholder="Enter Number"
                            value={validation.values.pan_no}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.pan_no &&
                              validation.errors.pan_no
                                ? true
                                : false
                            }
                          />
                          {validation.touched.pan_no &&
                            validation.errors.pan_no && (
                              <FormFeedback type="invalid">
                                {validation.errors.pan_no}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="aadhar_no">Aadhaar Number</Label>
                          <Input
                            type="text"
                            name="aadhar_no"
                            id="aadhar_no"
                            placeholder="Enter Number"
                            value={validation.values.aadhar_no}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.aadhar_no &&
                              validation.errors.aadhar_no
                                ? true
                                : false
                            }
                          />
                          {validation.touched.aadhar_no &&
                            validation.errors.aadhar_no && (
                              <FormFeedback type="invalid">
                                {validation.errors.aadhar_no}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Password <span className="text-danger">*</span>
                          </Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="password"
                              value={validation.values.password}
                              type={passwordVisible ? "text" : "password"}
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            <button
                              onClick={() =>
                                setPasswordVisible(!passwordVisible)
                              }
                              className="btn btn-light"
                              type="button"
                              id="password-addon"
                            >
                              <i
                                className={`mdi mdi-eye${
                                  passwordVisible ? "-off" : "-outline"
                                }`}
                              ></i>
                            </button>
                          </div>
                          {validation.touched.password &&
                            validation.errors.password && (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Confirm Password{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="confirm_password"
                              value={validation.values.confirm_password}
                              type={
                                confirmPasswordVisible ? "text" : "password"
                              }
                              placeholder="Re-enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.confirm_password &&
                                validation.errors.confirm_password
                                  ? true
                                  : false
                              }
                            />
                            <button
                              onClick={() =>
                                setConfirmPasswordVisible(
                                  !confirmPasswordVisible
                                )
                              }
                              className="btn btn-light"
                              type="button"
                              id="password-addon"
                            >
                              <i
                                className={`mdi mdi-eye${
                                  confirmPasswordVisible ? "-off" : "-outline"
                                }`}
                              ></i>
                            </button>
                          </div>
                          {validation.touched.confirm_password &&
                            validation.errors.confirm_password && (
                              <FormFeedback type="invalid">
                                {validation.errors.confirm_password}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="address">
                            Address <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="textarea"
                            name="address"
                            id="address"
                            placeholder="Enter Address"
                            value={validation.values.address}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.address &&
                              validation.errors.address
                                ? true
                                : false
                            }
                          />
                          {validation.touched.address &&
                            validation.errors.address && (
                              <FormFeedback type="invalid">
                                {validation.errors.address}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>
                    {/* Checkbox */}
                    <Row>
                      <Label htmlFor="address">Role</Label>
                      {roleAssign.map(role => (
                        <Col md={3} key={role.id}>
                          <div className="mb-3">
                            <Input
                              type="checkbox"
                              id={`role_${role.id}`}
                              checked={selectedRoles[role.id] || false}
                              onChange={() => handleCheckboxChange(role.id)}
                            />
                            <Label htmlFor={`role_${role.id}`}>
                              &nbsp;{role.role_name}
                            </Label>
                          </div>
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Status</Label>
                          <FormGroup switch>
                            <Label>
                              {status ? "" : ""}
                              <Input
                                name="status"
                                type="switch"
                                role="switch"
                                checked={status}
                                onClick={() => setStatus(!status)} // Toggle the status
                                value={status}
                              />
                            </Label>
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>

                    <button type="submit" className="btn btn-primary w-md">
                     Submit
                    </button>
                    {/* <Button type="submit" color="primary">
                  Add User
                </Button> */}
                  </Form>
                </CardBody>
                 
                
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default AddUser
