import React, { useEffect, useMemo, useState } from "react"
import "../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../../components/Common/TableContainer"
import { Link, useNavigate } from "react-router-dom"

//import components
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Badge,
  Button,
  Input,
} from "reactstrap"
import { ToastContainer } from "react-toastify"
import { getRoleList } from "services/AclServices"

const Role = () => {
  // meta title
  document.title = `Role List |  ${process.env.REACT_APP_SITE_NAME} - Admin Panel`
  const navigate = useNavigate()

  // Dummy data
  const [roleList, setRoleList] = useState([
    // { id: 1, role_name: "Admin", status: 1 },
    // { id: 2, role_name: "Editor", status: 1 },
    // { id: 3, role_name: "Viewer", status: 0 },
  ])
  const [searchQuery, setSearchQuery] = useState("") // New state for search input

  // getRoleModuleList

  useEffect(() => {
    document.title = `ACL-Manage Role-Modules | ${process.env.REACT_APP_SITE_NAME} Admin Panel`
    fetchAllRoleList()
  }, [navigate])

  const fetchAllRoleList = async () => {
    // setLoading(true) // Start loading

    try {
      const response = await getRoleList()
      console.log("Role Modules Fetched Successfully", response)
      setRoleList(response) // Store fetched users in state
      // setFilteredUsers(response.data) // Set filtered users to the fetched data
      // setLoading(false) // End loading regardless of success or failure
    } catch (error) {
      console.error("Error fetching user data:", error.message)
      // toast.error("Failed to fetch user data") // Notify error
      // setLoading(false) // End loading regardless of success or failure
    }
  }
  const filteredRoleList = roleList.filter(m =>
    m.role_name.toLowerCase().includes(searchQuery.toLowerCase())
  )
  const columns = useMemo(
    () => [
      {
        header: "Sl.No",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: cellProps => {
          return (
            <Link to="" className="text-body fw-bold">
              {cellProps.row.index + 1}
            </Link>
          )
        },
      },
      {
        header: "Role Name",
        accessorKey: "role_name", // Column for Role Name
        enableColumnFilter: false,
        enableSorting: false,
      },

      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: cellProps => {
          switch (cellProps.row.original.status) {
            case 1:
              return <span className="badge badge-soft-success">Active</span>
            case 0:
              return <span className="badge badge-soft-danger">Inactive</span>
            default:
              return <span className="badge badge-soft-warning">Unknown</span>
          }
        },
      },
      {
        header: () => <div style={{ textAlign: "right" }}>Action</div>,
        id: "module-action",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">
              <li>
                <Link
                  to="/role/edit"
                  className="btn btn-sm btn-soft-info"
                  state={{ roleId: cellProps.row.original.id }} // Correctly passing roleId in state
                  // onClick={() => {
                  //   const jobData = cellProps.row.original
                  //   console.log("Hello- edit---", jobData.id)
                  //   // handleEditClick(jobData)
                  // }}
                  id={`edittooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>

              {/* <li>
                <Link
                  to=""
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    onClickDelete(jobData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li> */}
            </ul>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACL Management" breadcrumbItem="Role" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">Role List</h5>
                    <div className="flex-shrink-0">
                      <Link to="/role/add" className="btn btn-primary me-1">
                        Add
                      </Link>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm={4}>
                      <Input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        className="form-control search-box me-2 mb-2 d-inline-block"
                      />
                    </Col>
                  </Row>
                  {filteredRoleList.length > 0 ? (
                    <TableContainer
                      columns={columns}
                      data={filteredRoleList || []}
                      // isCustomPageSize={true}
                      // isGlobalFilter={true}
                      // isJobListGlobalFilter={true}
                      // loading={loading}
                      // setLoading={setLoading}
                      isPagination={true}
                      // SearchPlaceholder="Search for Role"
                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline border-top"
                      pagination="pagination"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  ) : (
                    <div className="text-center mt-3">
                      <p>No matching records found.</p>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default Role
