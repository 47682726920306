import React, { useEffect, useMemo, useState } from "react";





import {
    Col,
    Row,
    UncontrolledTooltip,
    Card,
    CardBody,
    Button,
    ModalHeader,
    Modal,
    ModalBody,
    CardTitle,
    UncontrolledAccordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody,
    Table,

} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import moment from "moment";
import Breadcrumb from "components/Common/Breadcrumb";
import { getClientList } from "services/ClientManagementServices";
import Select from "react-select";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";





const ReceiptPaymentOrder = () => {

    //meta title
    document.title = `Payment Order |  ${process.env.REACT_APP_SITE_NAME} - Admin Panel`;

    const [clientList, setClientList] = useState([]);
    const [client, setClient] = useState(null)




    useEffect(() => {
        getClientListRecords()
    }, [])



    const getClientListRecords = async () => {

        try {
            let params = {
                limit: 200,
                page: 1
            }
            await getClientList(params).then(response => {
                console.log(response)
                if (response) {
                    const { data } = response;

                    let temp = data?.map(item => ({ label: item.client_name, value: item.id })) ?? []
                    setClientList(temp)
                }
            }).catch(err => console.log(err))
        } catch (error) {

        }

    }

    const handleClientChange = (item) => {
        setClient(item)
    }

    const handlePaymentDate = (date) => {
        console.log(date,"DAte")
    }



    return (
        <React.Fragment>

            <div className="page-content">
                <div className="container">
                    <Breadcrumb title="Amount" breadcrumbItem="Receipt" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">Receipt</h5>

                                    </div>
                                </CardBody>

                            </Card>

                        </Col>
                   
                    </Row>


                </div>
            </div>


            <ToastContainer />
        </React.Fragment>
    );
}


export default ReceiptPaymentOrder;