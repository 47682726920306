/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Input, FormGroup, Label } from "reactstrap";
import { getProductList } from "services/ProductServices";
import { get_file_full_path } from 'helpers/helpers'

export default function RetailProductDetails(props) {
    const { allcategories, subCategories, onSave, onDiscard, editing } = props
    const [ categories, saveCategories ] = useState([])
    const [ sub_categories, saveSubCategories ] = useState([])
    const [ filteredSubCategory, saveFilteredSubCategory ] = useState([])
    const [ selectedProductDetails, saveSelectedProductDetails ] = useState(props.product)
    const [ products, saveProducts ] = useState([])
    const [ productMedia, saveProductMedia ] = useState([])
    const [ category_id, saveCategoryID ] = useState(null)
    const [ sub_category_id, savecSubCtegoryID ] = useState(null)
    const [ showTitleBar, saveShowTitleBar ] = useState(selectedProductDetails.new_item == true ? false : editing)
    const [ productSaved, saveProductSaved ] = useState(selectedProductDetails.new_item == true ? false : editing)
    const [calculateSubTotalManually, saveCalculateSubTotalManually] = useState(false)
    const [ formSubmitted, saveFormSubmitted] = useState(false)

    const validateForm = () => {
        if (
            ( editing == false && (category_id == null ||
            sub_category_id == null)) ||
            // selectedProductDetails.prod_id == null ||
            selectedProductDetails.line_item_sku == null || selectedProductDetails.line_item_sku == "" ||
            selectedProductDetails.line_item_title == null || selectedProductDetails.line_item_sku == "" ||
            selectedProductDetails.quantity < 1 ||
            Number(selectedProductDetails.gross_sale_price) < 0 ||
            Number(selectedProductDetails.line_item_unit_price) < 1
        ) {
            return false
        }
        return true
    }

    const filterParentCategory = () => {
        const master_category = allcategories.filter((item) => {
            return item.parent_id == null
        })
        saveCategories(master_category)
    }

    const filterSubCategory = () => {
        let subCategories = []
        allcategories.forEach(item => {
            if(item.subcategories && item.subcategories.length > 0){
                item.subcategories.forEach(sub_item => {
                    subCategories.push(sub_item)
                });
            }
        });
        saveSubCategories(subCategories)
    }
    
    const onSaveClick = () => {
        saveFormSubmitted(true)
        if (validateForm()) {
            saveProductSaved(true)
            onSave(selectedProductDetails)
        }
    }

    const onDiscardClick = () => {
        onDiscard(selectedProductDetails)
    }

    const onEditButtonClick = () => {
        saveProductSaved(false)
    }

    const getProduct = async (sub_category_id) => {
        try {
            let params = {
                page: 1,
                limit: 200,
                search: '',
                cat_id: sub_category_id,
                status: 1
            }
            await getProductList(params)
                .then(response => {
                    if (response.data && response.data.length > 0) {
                        saveProducts(response.data)
                    }
                })
        } catch (error) {
            console.error(error);
        }
    }
    
    const calculateGrossSaleAmount = () => {
        const amount = Number(selectedProductDetails.quantity * selectedProductDetails.line_item_unit_price).toFixed(2)
        if (selectedProductDetails.gross_sale_price == null && amount != selectedProductDetails.gross_sale_price && calculateSubTotalManually == false ) {
            saveSelectedProductDetails({...selectedProductDetails, gross_sale_price:amount})
        }
    }

    const onSKUChange = (event) => {
        if (event.target.value == "") {
            saveSelectedProductDetails({ ...selectedProductDetails, line_item_sku: undefined })
            return
        }
        
        saveSelectedProductDetails({ ...selectedProductDetails, line_item_sku: event.target.value })
    }

    const onTitleChange = (event) => {
        if (event.target.value == "") {
            saveSelectedProductDetails({ ...selectedProductDetails, line_item_title: undefined })
            return
        }
        saveSelectedProductDetails({ ...selectedProductDetails, line_item_title: event.target.value })
    }

    const onDescriptionChange = (event) => {
        saveSelectedProductDetails({ ...selectedProductDetails, line_item_description: event.target.value })
    }

    const onCategorySelect = (event) => {
        const category_id = Number(event.target.value);
        if (category_id == 0) {
            saveCategoryID(null)
            return
        }
        
        saveCategoryID(category_id)
        const filtered_subcategory = sub_categories.filter(sub_cat => sub_cat.parent_id == category_id);
        saveFilteredSubCategory(filtered_subcategory)
        console.log(filteredSubCategory)
    }

    const onSubCategorySelect = (event) => {
        const sub_category_id = Number(event.target.value);
        if (sub_category_id == 0) {
            savecSubCtegoryID(null)
            return
        }
        savecSubCtegoryID(sub_category_id)
        getProduct(sub_category_id)
    }

    const onQuantityChange = (event) => {
        let value = Number(event.target.value)
        value = Math.max(0, value)
        
        saveSelectedProductDetails({ ...selectedProductDetails, quantity: value, gross_sale_price: Number(value * Number(selectedProductDetails.line_item_unit_price)).toFixed(2) })
    }

    const onUnitPriceChange = (event) => {
        let value = Number(event.target.value)
        value = Math.max(0, value)
        saveSelectedProductDetails({ ...selectedProductDetails, line_item_unit_price: value, gross_sale_price: Number(value * Number(selectedProductDetails.quantity)).toFixed(2) })
    }

    const onSubTotalChange = event => {
        if (event.target.value < 0) {
            saveSelectedProductDetails({...selectedProductDetails, gross_sale_price:0})
            return
        }
        saveCalculateSubTotalManually(true)
        saveSelectedProductDetails({...selectedProductDetails, gross_sale_price:event.target.value})
    }

    // TODO :: On edit or update fetch the data from DB and then update this part
    const onProductSelect = (event) => {
        const value = event.target.value
        let p = products.find(pp => pp.id == value)

        if (p == undefined) {
            p = {
                sale_price: 0,
                p_title: '',
                p_desc: '',
                sku: '',
                productMedia: []
            }
        }
        
        let prod_id = undefined
        if (value != "" && value == "others") {
            p.sale_price = 0
            prod_id = null
        } else if (value != "") {
            prod_id = value
        }
        
        saveProductMedia(p.productMedia)
        saveCalculateSubTotalManually(false)
        saveShowTitleBar(true)
        saveSelectedProductDetails({
            ...selectedProductDetails,
            prod_id : prod_id,
            line_item_sku : p.sku,
            line_item_title : p.p_title,
            line_item_description : p.p_desc,
            quantity : 1,
            line_item_unit_price : Number(p.sale_price),
            gross_sale_price : undefined,
            line_item_order_desc : undefined,
            shopify_order_id : undefined,
            delivery_status : 1,
            status :1,
        })
    }

    useEffect(() => {
        calculateGrossSaleAmount()
        if (selectedProductDetails && selectedProductDetails?.product?.productMedia && selectedProductDetails.product.productMedia.length > 0) {
            saveProductMedia(selectedProductDetails.product.productMedia)
        }
    }, [selectedProductDetails])

    useEffect(() => {
        filterParentCategory()
        filterSubCategory()
    }, [])

    return (
        <div className="border p-3 mb-3">

            {showTitleBar && <div className={productSaved == false ? "border-bottom mb-2 pb-2" : ""}>
                <Row>
                    <Col lg={11}>
                        <Row>
                            <Col lg={2}>
                                {
                                    productMedia.length > 0 ? (
                                        <img src={get_file_full_path(productMedia[0].file_name)} style={{ height:'90px', width: '100%', borderRadius: '3px' }}/>
                                    ) : (
                                        <div className="border w-full" style={{ minHeight: '120px', borderRadius: '3px', background: '#ededed !important' }}></div>
                                    )
                                }
                            </Col>
                            <Col lg={10}>
                                <Row>
                                    <Col lg={2}>Name</Col>
                                    <Col lg={10}>: {selectedProductDetails.line_item_title}</Col>
                                </Row>
                                <Row>
                                    <Col lg={2}>SKU</Col>
                                    <Col lg={10}>: {selectedProductDetails.line_item_sku}</Col>
                                </Row>
                                <Row>
                                    <Col lg={2}>Price</Col>
                                    <Col lg={10}>: {selectedProductDetails.gross_sale_price}₹</Col>
                                </Row>
                                <Row>
                                    <Col lg={2}>Quantity</Col>
                                    <Col lg={10}>: {selectedProductDetails.quantity}</Col>
                                </Row>
                                <Row>
                                    <Col lg={2}>Description</Col>
                                    <Col lg={10} className="d-flex">: &nbsp; <div>{selectedProductDetails.line_item_description}</div></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    {productSaved && <Col>
                        <div className="d-flex flex-column">
                            <Button size="sm" outline color="primary" onClick={onEditButtonClick}>
                                <i className="mdi mdi-grease-pencil" />
                            </Button>
                            <Button size="sm" outline color="danger" className="mt-1" onClick={onDiscardClick}>
                                <i className="mdi mdi-delete" />
                            </Button>
                        </div>
                    </Col>}
                </Row>
            </div>}

            {productSaved == false && <div>
                <Row>
                    {selectedProductDetails.new_item == true && <Col lg={12}>
                        <Row>
                            {/* categories */}
                            <Col lg={4} className="mb-2">
                                <Label >Select Category <span className="text-danger">*</span></Label>
                                <Input
                                    type="select"
                                    name="category_id"
                                    // disabled={showTitleBar}
                                    onChange={onCategorySelect}
                                    className="form-control"
                                    invalid={ formSubmitted && category_id == null }
                                >
                                    <option value="">Select Category</option>
                                    {categories.map((category, i) => <option key={i} value={category.id}>{category.category_name}</option>)}
                                </Input>
                            </Col>

                            {/* sub categories */}
                            <Col lg={4} className="mb-2">
                                <label htmlFor="email">Select Sub-category <span className="text-danger">*</span></label>
                                <Input
                                    type="select"
                                    className="form-control"
                                    disabled={category_id == null}
                                    invalid={formSubmitted && sub_category_id == null}
                                    onChange={onSubCategorySelect}
                                >
                                    <option value="">Select Sub-category</option>
                                    {filteredSubCategory.length > 0 ?
                                        (filteredSubCategory.map((sub_category, i) => <option key={i} value={sub_category.id}>{sub_category.category_name}</option>))
                                        :
                                        <option value="">No Sub-category found</option>
                                    }
                                </Input>
                            </Col>

                            {/* products */}
                            <Col lg={4} className="mb-2">
                                <label htmlFor="email">Select Product <span className="text-danger">*</span></label>
                                <Input
                                    type="select"
                                    className="form-control"
                                    disabled={sub_category_id == null}
                                    onChange={onProductSelect}
                                >
                                    <option value="">Select Product</option>
                                    {products.map((p, i) => <option key={i} value={p.id}>{p.p_title}</option>)}
                                    <option value="others">Others</option>
                                </Input>
                            </Col>
                        </Row>
                    </Col>}

                    <Col lg={12}>
                        <Row>
                            {/* SKU */}
                            {selectedProductDetails.prod_id == null && <Col lg={4} className="mb-2">
                                <label >SKU <span className="text-danger">*</span></label>
                                <Input type="text" className="form-control" invalid={formSubmitted && selectedProductDetails.line_item_sku == ""} value={ selectedProductDetails.line_item_sku} onChange={onSKUChange} />
                            </Col>}

                            {/* Title */}
                            {selectedProductDetails.prod_id == null && <Col lg={4} className="mb-2">
                                <label >Title <span className="text-danger">*</span></label>
                                <Input type="text" className="form-control" invalid={formSubmitted && selectedProductDetails.line_item_title == ""} value={selectedProductDetails.line_item_title} onChange={onTitleChange}/>
                            </Col>}
                        </Row>
                    </Col>

                    <Col lg={12}>
                        <Row>
                            {/* Quantity */}
                            <Col lg={4} className="mb-2">
                                <label htmlFor="email">Quantity <span className="text-danger">*</span></label>
                                <Input type="number" value={selectedProductDetails.quantity} invalid={ formSubmitted && selectedProductDetails.quantity < 1 } onChange={onQuantityChange} className="form-control" />
                            </Col>

                            {/* Unit Price */}
                            <Col lg={4} className="mb-2">
                                <label htmlFor="email">Unit Price <span className="text-danger">*</span></label>
                                <Input type="number" value={selectedProductDetails.line_item_unit_price} invalid={ formSubmitted && selectedProductDetails.line_item_unit_price < 1 } onChange={onUnitPriceChange} className="form-control" />
                            </Col>

                            {/* Sub Total */}
                            <Col lg={4} className="mb-2">
                                <label htmlFor="email">Sub Total </label>
                                <Input type="number" value={selectedProductDetails.gross_sale_price} onChange={onSubTotalChange} className="form-control" />
                            </Col>
                        </Row>
                    </Col>

                    {/* Expected Delivery Date */}
                    {/* <Col lg={4} className="mb-2">
                        <label htmlFor="email">Select Delivery Date <span className="text-danger">*</span></label>
                        <input type="date" className="form-control" />
                    </Col> */}

                    {/* Product Description */}
                    <Col lg={12} className="mb-2">
                        <label htmlFor="email">Product Note/Description</label>
                        <textarea type="date" className="form-control" rows={5} value={selectedProductDetails.line_item_description} onChange={onDescriptionChange}></textarea>
                    </Col>

                    {/* product Files */}
                    {/* <Col lg={12}>
                        <h6 className="card-title">Files</h6>
                        <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
                            {({ getRootProps, getInputProps }) => (
                                <div className="dropzone" style={{ minHeight: '100px !important' }}>
                                    <div
                                        className="dz-message needsclick mt-2"
                                        style={{ padding: '0 !important' }}
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                        <div className="mb-3">
                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h4>Drop files here or click to upload.</h4>
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                    </Col> */}

                    {/* Action buttons */}
                    <Col lg={12} className="mb-2 mt-3">
                        <div className="d-flex justify-content-end">
                            <div className="btn-group">
                                <Button type="button" size="sm" className="pull-right" color="danger" onClick={onDiscardClick}>
                                    <i className="mdi mdi-delete" /> Discard
                                </Button>
                                <Button size="sm" className="pull-right" color="primary" onClick={onSaveClick}>
                                    <i className="mdi mdi-content-save-outline" /> &nbsp; Save Product
                                </Button>
                            </div>  
                        </div>
                    </Col>
                </Row>
               
            </div>}
        </div>
    );
};