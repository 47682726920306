import React, { useEffect, useMemo, useState } from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Button,
  FormGroup,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
import { toast, ToastContainer } from "react-toastify"
import { Link } from "react-router-dom"
import {
  updateDeductedReason,
  getDeductionList,
  createDeductionReason,
  deleteDeductionReason,
} from "services/MasterServices"

const Deduction = () => {
  //meta title
  document.title = `Deduction Reasons |  ${process.env.REACT_APP_SITE_NAME} - Admin Panel`

  const [modal, setModal] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [deduction, setDeduction] = useState(null)
  const [deductionList, setDeductionList] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([]) // Filtered data state
  const [searchTerm, setSearchTerm] = useState("") // State for search term

  // validation
  const validationCreate = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      reason_title: "",
      status: "",
    },
    validationSchema: Yup.object({
      reason_title: Yup.string().trim().required("Please enter Reason"),
    }),
    onSubmit: async values => {
      const payload = {
        reason_title: values["reason_title"].trim(),
        status: values["status"] ? 1 : 0,
      }
      // save new Deduction

      console.log(payload, "payload")

      try {
        await createDeductionReason(payload)
          .then(response => {
            console.log(response, "response")
            toast.success(`Payment Deduction Reason is Added Successfully`, {
              autoClose: 2000,
            })
            validationCreate.resetForm()
            getReasonList()
            toggle()
          })
          .catch(err => {
            console.log(err)
            if (err?.status === 400 && err?.response?.data?.message) {
              const errorMessage = err?.response?.data?.message

              // Check for the specific error message
              if (errorMessage === "Deduction reason title already exists.") {
                toast.error("Duplicate Payment Deduction Reason", {
                  autoClose: 2000,
                })
              } else {
                // Show the default error message if it doesn't match
                toast.error(errorMessage, { autoClose: 2000 })
              }
            } else {
              toast.error("Payment Deduction Reason is Adding Failed", {
                autoClose: 2000,
              })
            }
          })
      } catch (error) {
        toast.error(`PaymentDeduction is Added Failed`, { autoClose: 2000 })
      }
    },
  })

  const validationEdit = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (deduction && deduction.id) || "",
      reason_title: (deduction && deduction.reason_title) || "",
      status: deduction && deduction.status === 1 ? true : false,
    },
    validationSchema: Yup.object({
      reason_title: Yup.string().trim().required("Please Name"),
    }),
    onSubmit: async values => {
      const payload = {
        id: values["id"],
        reason_title: values["reason_title"].trim(),
        status: values["status"] ? 1 : 0,
      }
      // update Job

      try {
        await updateDeductedReason(values["id"], payload)
          .then(response => {
            console.log(response, "response")
            toast.success(`Payment Deduction Reason is updated Successfully`, {
              autoClose: 2000,
            })
            validationEdit.resetForm()
            getReasonList()
            toggleEdit()
          })
          .catch(err => {
            console.log(err)
            if (err?.status === 400 && err?.response?.data?.message) {
              toast.error(err?.response?.data?.message, { autoClose: 2000 })
            } else {
              toast.error(`Deduction is Added Failed`, { autoClose: 2000 })
            }
          })
      } catch (error) {
        toast.error(`Deduction is updated Failed`, { autoClose: 2000 })
      }

      validationEdit.resetForm()
    },
  })

  const [loading, setLoading] = useState(false)
  const handleSearch = e => {
    const searchValue = e.target.value.toLowerCase()
    setSearchTerm(searchValue)
    if (searchValue === "") {
      setFilteredUsers(deductionList) // Reset to full list if search is cleared
    } else {
      const filtered = deductionList.filter(deduction =>
        deduction.reason_title.toLowerCase().includes(searchValue)
      )
      setFilteredUsers(filtered)
    }
  }
  useEffect(() => {
    getReasonList()
  }, [])

  const getReasonList = async () => {
    try {
      let params = {
        // parent_type: "parent",
      }

      setLoading(true)

      await getDeductionList({ params })
        .then(response => {
          console.log(response)

          if (response) {

            let arr = response;

            if(arr.find(item=>item.id===1)){
          
              // Remove the first element and store it
              const firstElement = arr.shift();
              
              // Add the removed element to the end of the array
              // arr.push(firstElement);

            }


            setDeductionList(arr)
            setFilteredUsers(arr)
            setLoading(false)
          }
        })
        .catch(err => {
          console.log(err)

          setLoading(false)
        })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setDeduction(null)
    } else {
      setModal(true)
    }
  }

  const toggleEdit = () => {
    if (modalEdit) {
      setModalEdit(false)
      setDeduction(null)
      validationEdit.resetForm()
    } else {
      setModalEdit(true)
    }
  }

  const handleEditClick = arg => {
    const deduction = arg
    setDeduction(deduction)

    toggleEdit()
  }

  //delete Job
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = deduction => {
    setDeduction(deduction)
    setDeleteModal(true)
  }

  const handleDeleteCategory = async () => {
    if (deduction && deduction.id) {
      try {
        await deleteDeductionReason(deduction.id)
          .then(response => {
            console.log(response, "response")
            getReasonList()
            setDeleteModal(false)
            toast.success(`Reason For Deduction is Deleted Successfully`, {
              autoClose: 2000,
            })
          })
          .catch(err => {
            console.log(err)

            toast.error(`Reason For Deduction is Deleted Failed`, {
              autoClose: 2000,
            })
          })
      } catch (error) {
        toast.error(` Deduction is Deleted Failed`, { autoClose: 2000 })
      }
    }
  }

  const columns = useMemo(
    () => [
      {
        header: "Sl.No",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: cellProps => {
          return (
            <Link to="" className="text-body fw-bold">
              {cellProps.row.index + 1}
            </Link>
          )
        },
      },
      {
        header: "Deduction Reason",
        accessorKey: "reason_title",
        enableColumnFilter: false,
        enableSorting: false,
      },

      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: cellProps => {
          switch (cellProps.row.original.status) {
            case 1:
              return <span className="badge badge-soft-success">Active</span>
            case 0:
              return <span className="badge badge-soft-danger">Inactive</span>
          }
        },
      },
      {
        header: () => <div style={{ textAlign: "right" }}>Action</div>,
        id: "deduction-action",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">
              <li>
                <Link
                  to=""
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    handleEditClick(jobData)
                  }}
                  id={`edittooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    onClickDelete(jobData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={() => setDeleteModal(false)}
        mainHeader="Are you sure you want to delete this deduction Reason?"
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Master"
            breadcrumbItem="Payment Deduction Reasons"
          />
          {
            // isLoading ? <Spinners setLoading={setLoading} />
            //     :
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                      <h5 className="mb-0 card-title flex-grow-1">
                        Payment Deduction Reasons
                      </h5>

                      <div className="flex-shrink-0">
                        <Link
                          to=""
                          onClick={() => setModal(true)}
                          className="btn btn-primary me-1"
                        >
                          Add
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <Row className="mb-2">
                      <Col sm={4}>
                        <Input
                          type="text"
                          placeholder="Search..."
                          value={searchTerm}
                          onChange={handleSearch} // Call search on input change
                          className="form-control search-box me-2 mb-2 d-inline-block"
                        />
                      </Col>
                    </Row>
                    {/* Initially below and above code was not there due to pagination implemented */}
                    {/* <Row className="mb-2">
                      <Col md={10}>
                        <Input
                          type="text"
                          placeholder="Search..."
                          value={searchTerm}
                          onChange={handleSearch} // Call search on input change
                          className="me-2 w-25"
                        />
                      </Col>
                    </Row>{" "} */}

                    {filteredUsers.length > 0 ? (
                      <TableContainer
                        columns={columns}
                        data={filteredUsers || []}
                        // isCustomPageSize={true}
                        // isGlobalFilter={true}
                        // isJobListGlobalFilter={true}
                        loading={loading}
                        setLoading={setLoading}
                        isPagination={true}
                        itemsPerPage={10}
                        // SearchPlaceholder="Keyword Search"
                        tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline border-top"
                        pagination="pagination"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                      />
                    ) : (
                      <div className="text-center mt-3">
                        <p>No matching records found.</p>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Add Deduction Reason
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validationCreate.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>
                        {" "}
                        Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="reason_title"
                        type="text"
                        placeholder="Enter Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validationCreate.handleChange}
                        onBlur={validationCreate.handleBlur}
                        value={validationCreate.values.reason_title || ""}
                        invalid={
                          validationCreate.touched.reason_title &&
                          validationCreate.errors.reason_title
                            ? true
                            : false
                        }
                      />
                      {validationCreate.touched.reason_title &&
                      validationCreate.errors.reason_title ? (
                        <FormFeedback type="invalid">
                          {validationCreate.errors.reason_title}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label>Status</Label>
                      <FormGroup switch>
                        <Label className="">
                          {" "}
                          {validationCreate.values.status === true ? "" : ""}
                          <Input
                            name="status"
                            type="switch"
                            role="switch"
                            checked={validationCreate.values.status === true}
                            onClick={validationCreate.handleChange}
                            onBlur={validationCreate.handleBlur}
                            value={validationCreate.values.status || true}
                          />
                        </Label>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="primary"
                        type="submit"
                        className="save-user"
                      >
                        Submit
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal isOpen={modalEdit} toggle={toggleEdit}>
            <ModalHeader toggle={toggleEdit} tag="h4">
              Edit Deduction Reason
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validationEdit.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>
                        {" "}
                        Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="reason_title"
                        type="text"
                        placeholder="Please enter name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validationEdit.handleChange}
                        onBlur={validationEdit.handleBlur}
                        value={validationEdit.values.reason_title || ""}
                        invalid={
                          validationEdit.touched.reason_title &&
                          validationEdit.errors.reason_title
                            ? true
                            : false
                        }
                      />
                      {validationEdit.touched.reason_title &&
                      validationEdit.errors.reason_title ? (
                        <FormFeedback type="invalid">
                          {validationEdit.errors.reason_title}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label>Status</Label>
                      <FormGroup switch>
                        <Label className="">
                          {" "}
                          {validationEdit.values.status === true ? "" : ""}
                          <Input
                            name="status"
                            type="switch"
                            role="switch"
                            checked={validationEdit.values.status === true}
                            onClick={validationEdit.handleChange}
                            onBlur={validationEdit.handleBlur}
                            value={validationEdit.values.status || true}
                          />
                        </Label>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="primary"
                        type="submit"
                        className="save-user"
                      >
                        Update
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default Deduction
