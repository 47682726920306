import React, { useEffect, useMemo, useState } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

//import components

import {
    Col,
    Row,
    UncontrolledTooltip,
    Card,
    CardBody,
    Button,
    ModalHeader,
    Modal,
    ModalBody,
    CardTitle,
    UncontrolledAccordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody,
    Table,
    Label,
    Input,

} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";

import moment from "moment";
import { isEmpty } from "lodash";

import DeleteModal from "components/Common/DeleteModal";
import Breadcrumb from "components/Common/Breadcrumb";
import TableContainer from "components/Common/TableContainer";
import { deletePaymentOrder, getPaymentOrderList } from "services/PaymentOrderServices";
import Paginations from "components/Common/Pagination";


const PaymentOrderList = () => {

    //meta title
    document.title = `Receipt Order List |  ${process.env.REACT_APP_SITE_NAME} - Admin Panel`;


    const [productOrders, setProductOrders] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [job, setJob] = useState(null);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getProductOrderListRecord()
    }, [limit, page])





    const getProductOrderListRecord = async (search) => {
        try {
            setLoading(true)


            let params = {
                page: page,
                limit: limit,
                ...search
            }

         

            await getPaymentOrderList(params).then(response => {

                console.log(response)

                if (response.data) {

                    const { meta, data } = response;
                    setProductOrders(data ?? [])
                    // setTotalCount(Math.ceil(meta?.itemCount / meta?.limit));
                    setTotalCount(meta?.itemCount);

                }
                setLoading(false)

            }).catch(err => console.log(err))

        } catch (error) {
            setLoading(false)
        }
    }




    //delete Job
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (job) => {
        setJob(job);
        setDeleteModal(true);
    };

    const handleDeletejob = async () => {
        if (job && job.id) {

            try {
                await deletePaymentOrder(job.id).then(response => {
                    console.log(response)
                    // if(response){
                    toast.success("Receipt Order is deleted successfully.", { autoClose: 500 });
                    // }
                    getProductOrderListRecord()
                }).catch(err => console.log(err))
            } catch (error) {
                toast.error("Something went wrong.", { autoClose: 500 });
            }


            setDeleteModal(false);
        }
    };


    const handleSearch = () => {
        setPage(1);
        getVendorListRecord(search)
    }

 

    const columns = useMemo(
        () => [
            {
                header: 'Sl.No.',
                accessorKey: "id",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    return <Link to="#" className="text-body fw-bold">{(page - 1) * limit + (cellProps.row.index + 1)}</Link>
                }
            },
            {
                header: "Client Name",
                accessorKey: "client.client_name",
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: 'Amount',
                enableColumnFilter: false,
                enableSorting: false,
                accessorKey: "amount",
                cell: (cellProps) => 
                <span>{cellProps.row.original.amount}</span>,
            },
            {
                header: 'Payment Date',
                enableColumnFilter: false,
                enableSorting: false,
                accessorKey: "payment_date",
                cell: (cellProps) => 
                <span>  {moment(cellProps.row.original.payment_date).format(process.env.REACT_APP_DATE_FORMAT)}</span>,
            },
          

            {
                header: 'Status',
                accessorKey: "status",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    switch (cellProps.row.original.status) {
                        case 1:
                            return <span className="badge badge-soft-success">Active</span>;
                        case 0:
                            return <span className="badge badge-soft-danger">Inactive</span>;
                    }
                },
            },
            {
                header: () => <div style={{ textAlign: "right" }}>Action</div>,
                id: "client-action",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
              
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">


                        
                            <li>
                                <Link
                                    to={`/receipt-order/edit/${btoa(cellProps.row.original.id)}`}
                                    className="btn btn-sm btn-soft-info"

                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        onClickDelete(jobData);
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete-outline" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        [page]
    );



    

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeletejob}
                onCloseClick={() => setDeleteModal(false)}
                mainHeader="Are you sure you want to delete this Receipt Order?"
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb title="Account" breadcrumbItem="Receipt Order" />
                    {
                        // isLoading ? <Spinners setLoading={setLoading} />
                        //     :
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody className="border-bottom">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0 card-title flex-grow-1">Receipt Order</h5>
                                            <div className="flex-shrink-0">
                                                <Link to="/receipt-order/add" className="btn btn-primary me-1">Add</Link>

                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        {/* <Row className="mb-2">
                                            <Col sm={4}>
                                                <input value={search} onChange={e => {
                                                    setSearch(e.target.value);
                                                    setPage(1)
                                                }} type="text" placeholder="Keyword Search" className="form-control search-box me-2 mb-2 d-inline-block" />{" "}

                                            </Col>
                                            <Col sm={2}>     <Button type="button" onClick={handleSearch} color="primary">Search</Button></Col>
                                        </Row> */}





                                        <TableContainer
                                            columns={columns}
                                            data={productOrders || []}
                                            loading={loading}
                                            setLoading={setLoading}

                                            isGlobalFilter={false}
                                            // isJobListGlobalFilter={true}
                                            isPagination={false}
                                            SearchPlaceholder="Search for ..."
                                            tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline border-top"

                                        />

                                        {
                                            totalCount > 0 &&
                                            <Paginations
                                                perPageData={limit}
                                                data={productOrders}
                                                currentPage={page}
                                                setCurrentPage={setPage}
                                                isShowingPageLength={!loading}
                                                totalCount={totalCount}
                                                paginationClass="pagination"
                                                paginationDiv="dataTables_paginate paging_simple_numbers pagination-rounded"

                                            />
                                        }

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }

                </div>
            </div>

         
            <ToastContainer />
        </React.Fragment>
    );
}


export default PaymentOrderList;