import React, { useState } from "react"
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Label,
  Input,
  Button,
  Form,
  FormFeedback,
  Alert,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Navigate, useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"

const ChangePassword = () => {
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)
  const [formError, setFormError] = useState("")
  const [newPasswordError, setNewPasswordError] = useState("")
  const [successMessage, setSuccessMessage] = useState("") // State for success message
  const navigate = useNavigate()
  // Form validation using Formik and Yup
  const validation = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Old password is required"),
      newPassword: Yup.string().required("New password is required"), // Removed min length validation
      confirmNewPassword: Yup.string().required(
        "Please confirm your new password"
      ),
    }),
    onSubmit: async values => {
      const { oldPassword, newPassword, confirmNewPassword } = values

      // Log the initial values received from the form
      console.log("Form values:", values)

      // Check if new password and confirm password match
      if (newPassword !== confirmNewPassword) {
        console.error("Password mismatch:", { newPassword, confirmNewPassword })
        setFormError("New password and Re-enter password do not match.")
        setSuccessMessage("") // Clear success message
        return
      }

      // Prepare data for the API request
      const passwordChangeData = {
        oldPassword,
        password: newPassword, // Adjusted to match API expectations
      }

      console.log("Prepared password change data:", passwordChangeData)

      try {
        // Retrieve access token from local storage
        const authUser = JSON.parse(localStorage.getItem("authUser"))
        const accessToken = authUser?.access_token

        if (!accessToken) {
          throw new Error("Access token not found. Please log in again.")
        }

        // Send the password change request
        const response = await fetch(
          `${process.env.REACT_APP_BASEPATH}/user/change-password`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`, // Include access token
            },
            body: JSON.stringify(passwordChangeData), // Send the new password data
          }
        )
        console.log("Response-86", response)
        if (!response.ok) {
          const errorMessage = `Failed to change password: ${response.status} ${response.statusText}`
          throw new Error(errorMessage) // Throw an error if the response is not OK
        }

        const result = await response.json() // Get the response data
        console.log("Password change response:", result)

        // Show success toast notification before navigating
        toast.success("Password changed successfully.", { autoClose: 1500 })

        // Set success message
        setSuccessMessage("Your password has been changed successfully!")
        setFormError("") // Clear any previous errors
        setNewPasswordError("") // Clear new password error

        setTimeout(() => {
          navigate("/")
        }, 2000)
      } catch (error) {
        setFormError(
          error.message || "Failed to change password. Please try again."
        )
        setSuccessMessage("") // Clear any previous success messages
      }
    },
  })

  const handleConfirmPasswordFocus = () => {
    if (!validation.values.newPassword) {
      setNewPasswordError("Please enter a new password before confirming.")
    } else {
      setNewPasswordError("")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4 ">Change Password</h4>

              <Form
                className="form-horizontal"
                onSubmit={validation.handleSubmit}
              >
                {formError && (
                  <Row>
                    <Col md={6}>
                      <Alert color="danger">{formError}</Alert>
                    </Col>
                  </Row>
                )}
                {newPasswordError && (
                  <Row>
                    <Col md={6}>
                      <Alert color="warning">{newPasswordError}</Alert>
                    </Col>
                  </Row>
                )}
                {successMessage && (
                  <Row>
                    <Col md={6}>
                      <Alert color="success">{successMessage}</Alert>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Old Password <span className="text-danger">*</span>
                      </Label>
                      <div className="input-group auth-pass-inputgroup">
                        <Input
                          name="oldPassword"
                          value={validation.values.oldPassword || ""}
                          type={showOldPassword ? "text" : "password"}
                          placeholder="Enter Old Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.oldPassword &&
                            validation.errors.oldPassword
                              ? true
                              : false
                          }
                        />
                        <button
                          onClick={() => setShowOldPassword(!showOldPassword)}
                          className="btn btn-light"
                          type="button"
                          id="password-addon"
                        >
                          <i
                            className={`mdi mdi-eye${
                              showOldPassword ? "-off" : "-outline"
                            }`}
                          ></i>
                        </button>
                      </div>
                      {validation.touched.oldPassword &&
                      validation.errors.oldPassword ? (
                        <FormFeedback type="invalid">
                          {validation.errors.oldPassword}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label className="form-label">
                        New Password <span className="text-danger">*</span>
                      </Label>
                      <div className="input-group auth-pass-inputgroup">
                        <Input
                          name="newPassword"
                          value={validation.values.newPassword || ""}
                          type={showNewPassword ? "text" : "password"}
                          placeholder="Enter New Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.newPassword &&
                            validation.errors.newPassword
                              ? true
                              : false
                          }
                        />
                        <button
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          className="btn btn-light"
                          type="button"
                          id="password-addon"
                        >
                          <i
                            className={`mdi mdi-eye${
                              showNewPassword ? "-off" : "-outline"
                            }`}
                          ></i>
                        </button>
                      </div>
                      {validation.touched.newPassword &&
                      validation.errors.newPassword ? (
                        <FormFeedback type="invalid">
                          {validation.errors.newPassword}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Re-enter New Password{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <div className="input-group auth-pass-inputgroup">
                        <Input
                          name="confirmNewPassword"
                          value={validation.values.confirmNewPassword || ""}
                          type={showConfirmNewPassword ? "text" : "password"}
                          placeholder="Confirm New Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          onFocus={handleConfirmPasswordFocus} // Check on focus
                          invalid={
                            validation.touched.confirmNewPassword &&
                            validation.errors.confirmNewPassword
                              ? true
                              : false
                          }
                        />
                        <button
                          onClick={() =>
                            setShowConfirmNewPassword(!showConfirmNewPassword)
                          }
                          className="btn btn-light"
                          type="button"
                          id="password-addon"
                        >
                          <i
                            className={`mdi mdi-eye${
                              showConfirmNewPassword ? "-off" : "-outline"
                            }`}
                          ></i>
                        </button>
                      </div>
                      {validation.touched.confirmNewPassword &&
                      validation.errors.confirmNewPassword ? (
                        <FormFeedback type="invalid">
                          {validation.errors.confirmNewPassword}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <button type="submit" className="btn btn-primary w-md">
                  Submit
                </button>
              </Form>
            </CardBody>
          </Card>
          <ToastContainer position="top-right" autoClose={2000} />

        </Container>
      </div>
    </React.Fragment>
  )
}

export default ChangePassword
