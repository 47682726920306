import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Label,
  Input,
  Button,
  Form,
  FormFeedback,
  Alert,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import { getSingleUser, submitEditSingleUser } from "services/UserServices"

const ViewUser = () => {
  const navigate = useNavigate()
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const location = useLocation()
  const userId = location.state?.userId // Access userId safely

  // Simulated existing user data for editing
  const existingUser = {
    fname: "",
    lname: "",
    email: "",
    primary_ph_no: "",
    addl_ph_no: "",
    address: "",
    gender: "",
    pan_no: "",
    aadhar_no: "",
  }

  const validation = useFormik({
    initialValues: existingUser,
    validationSchema: Yup.object({
      fname: Yup.string().required("First name is required"),
      lname: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      primary_ph_no: Yup.string()
        .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
        .notRequired(),
      addl_ph_no: Yup.string()
        .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
        .notRequired(),
      address: Yup.string().required("Address is required"),
      gender: Yup.string().required("Gender is required"),
      pan_no: Yup.string()
        .matches(
          /^[A-Z]{5}[0-9]{4}[A-Z]$/,
          "PAN number must follow the format: 5 uppercase letters, 4 digits, and 1 uppercase letter"
        )
        .notRequired(),
      aadhar_no: Yup.string()
        .length(12, "Aadhaar number must be 12 digits")
        .notRequired(),
    }),
    onSubmit: async values => {
      console.log("New user data:", values)

      const newUser = {
        company_id: 1, // Example company ID
        first_name: values.fname,
        middle_name: "",
        last_name: values.lname,
        primary_ph_no: values.primary_ph_no,
        addl_ph_no: values.addl_ph_no,
        address: values.address,
        gender: values.gender,
        pan_no: values.pan_no,
        aadhar_no: values.aadhar_no,
        email: values.email,

        user_type: 2, // Add user type
        // status: 1, // Example status
        image: "",
      }

      try {
        // Await the API call to add the new user
        const response = await submitEditSingleUser(newUser, userId)

        // Log the successful response
        console.log("User Updated successfully:", response)

        // Update success message and clear error
        setSuccess("User added successfully!")
        setError(null)

        // Navigate to a different route after a delay
        setTimeout(() => {
          navigate("/") // Redirect to the desired route
        }, 500) // 2 seconds delay
      } catch (error) {
        // console.error("Error adding user:", error.message);
        // setError("Failed to add user. Please try again.");
        // setSuccess(null);
      }
    },
  })
  useEffect(() => {
    console.log("Editing User with ID:", userId)
    // Fetch and populate user data based on the ID
    document.title = `Manage User | ${process.env.REACT_APP_SITE_NAME} Admin Panel`
    fetchSingleUserData(userId)
  }, [userId, navigate])

  const fetchSingleUserData = async () => {
    console.log("User if fetchsingleuser", userId)
    try {
      await getSingleUser(userId)
        .then(response => {
          console.log("Response Data Single User:", response)

          validation.setValues({
            fname: response.first_name || "",
            lname: response.last_name || "",
            email: response.email || "",
            primary_ph_no: response.primary_ph_no || "",
            addl_ph_no: response.addl_ph_no || "",
            address: response.address || "",
            gender: response.gender || "",
            pan_no: response.pan_no || "",
            aadhar_no: response.aadhar_no || "",
          })
        })
        .catch(err => {
          console.log(err)
        })
    } catch (error) {
      console.error("Error fetching user data:", error.message)
      setError("Failed to fetch user data. Please try again.") // Set error message for user
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Edit User</h4>
              {error && <Alert color="danger">{error}</Alert>}
              {success && <Alert color="success">{success}</Alert>}
              <Form
                className="form-horizontal"
                onSubmit={validation.handleSubmit}
              >
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="fname">
                        First Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="fname"
                        id="fname"
                        placeholder="Enter Your First Name"
                        value={validation.values.fname}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.fname && validation.errors.fname
                            ? true
                            : false
                        }
                        readOnly
                      />
                      {validation.touched.fname && validation.errors.fname && (
                        <FormFeedback type="invalid">
                          {validation.errors.fname}
                        </FormFeedback>
                      )}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="lname">
                        Last Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="lname"
                        id="lname"
                        placeholder="Enter Your Last Name"
                        value={validation.values.lname}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.lname && validation.errors.lname
                            ? true
                            : false
                        }
                        readOnly
                      />
                      {validation.touched.lname && validation.errors.lname && (
                        <FormFeedback type="invalid">
                          {validation.errors.lname}
                        </FormFeedback>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="email">
                        Email <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter Your Email"
                        value={validation.values.email}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                        readOnly
                      />
                      {validation.touched.email && validation.errors.email && (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      )}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="gender">
                        Gender <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="select"
                        name="gender"
                        id="gender"
                        value={validation.values.gender}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.gender && validation.errors.gender
                            ? true
                            : false
                        }
                        disabled
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </Input>
                      {validation.touched.gender &&
                        validation.errors.gender && (
                          <FormFeedback type="invalid">
                            {validation.errors.gender}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="primary_ph_no">
                        Primary Phone Number (Optional)
                      </Label>
                      <Input
                        type="text"
                        name="primary_ph_no"
                        id="primary_ph_no"
                        placeholder="Enter Number"
                        value={validation.values.primary_ph_no}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.primary_ph_no &&
                          validation.errors.primary_ph_no
                            ? true
                            : false
                        }
                        readOnly
                      />
                      {validation.touched.primary_ph_no &&
                        validation.errors.primary_ph_no && (
                          <FormFeedback type="invalid">
                            {validation.errors.primary_ph_no}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="addl_ph_no">
                        Additional Phone Number (Optional)
                      </Label>
                      <Input
                        type="text"
                        name="addl_ph_no"
                        id="addl_ph_no"
                        placeholder="Enter Number "
                        value={validation.values.addl_ph_no}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.addl_ph_no &&
                          validation.errors.addl_ph_no
                            ? true
                            : false
                        }
                        readOnly
                      />
                      {validation.touched.addl_ph_no &&
                        validation.errors.addl_ph_no && (
                          <FormFeedback type="invalid">
                            {validation.errors.addl_ph_no}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="pan_no">PAN Number</Label>
                      <Input
                        type="text"
                        name="pan_no"
                        id="pan_no"
                        placeholder="Enter Your PAN Number"
                        value={validation.values.pan_no}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.pan_no && validation.errors.pan_no
                            ? true
                            : false
                        }
                        readOnly
                      />
                      {validation.touched.pan_no &&
                        validation.errors.pan_no && (
                          <FormFeedback type="invalid">
                            {validation.errors.pan_no}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="aadhar_no">Aadhaar Number</Label>
                      <Input
                        type="text"
                        name="aadhar_no"
                        id="aadhar_no"
                        placeholder="Enter Your Aadhaar Number"
                        value={validation.values.aadhar_no}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.aadhar_no &&
                          validation.errors.aadhar_no
                            ? true
                            : false
                        }
                        readOnly
                      />
                      {validation.touched.aadhar_no &&
                        validation.errors.aadhar_no && (
                          <FormFeedback type="invalid">
                            {validation.errors.aadhar_no}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="address">
                        Address <span className="text-danger">*</span>
                      </Label>

                      <Input
                        type="textarea"
                        name="address"
                        id="address"
                        placeholder="Enter Your Address"
                        value={validation.values.address}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.address &&
                          validation.errors.address
                            ? true
                            : false
                        }
                        readOnly
                      />
                      {validation.touched.address &&
                        validation.errors.address && (
                          <FormFeedback type="invalid">
                            {validation.errors.address}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                </Row>

                <Button type="submit" color="primary">
                  Update User
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default ViewUser
