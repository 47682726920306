import React, { useEffect, useMemo, useState } from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"

import {
  getPaymentMethod as onGetPaymentMethodList,
  addNewPaymentMethodList as onAddNewPaymentMethodList,
  updatePaymentMethodList as onUpdatePaymentMethodList,
  deletePaymentMethodList as onDeletePaymentMethodList,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Button,
  FormGroup,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
import { toast, ToastContainer } from "react-toastify"
import { Link } from "react-router-dom"
import {
  createPaymentMethod,
  deletePaymentMethod,
  getPaymentMethod,
  updatePaymentMethod,
} from "services/MasterServices"

const PaymentMethod = () => {
  //meta title
  document.title = `Payment Method |  ${process.env.REACT_APP_SITE_NAME} Admin Panel`

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [searchQuery, setSearchQuery] = useState("") // New state for search input

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      method_name: (paymentMethod && paymentMethod.method_name) || "",
      status: !!isEdit
        ? paymentMethod && paymentMethod.status === 1
          ? true
          : false
        : true,
    },
    validationSchema: Yup.object({
      method_name: Yup.string().trim().required("Please Enter Name"),
    }),
    onSubmit: async values => {
      if (isEdit) {
        const payload = {
          method_name: values.method_name.trim(),
          status: values.status ? 1 : 0,
        }
        // update Job

        try {
          await updatePaymentMethod(paymentMethod.id, payload)
            .then(async response => {
              console.log(response, "response")
              toast.success(`Payment Method is updated Successfully`, {
                autoClose: 2000,
              })

              getPaymentMethodList()
              validation.resetForm()

              toggle()
            })
            .catch(err => {
              console.log(err)
              if (err?.status === 400 && err?.response?.data?.message) {
                toast.error(err?.response?.data?.message, { autoClose: 2000 })
              } else {
                toast.error(`Payment Method is updated Failed`, {
                  autoClose: 2000,
                })
              }
            })
        } catch (error) {
          toast.error(`Payment Method is updated Failed`, { autoClose: 2000 })
        }
      } else {
        const payload = {
          method_name: values.method_name.trim(),
          status: values.status ? 1 : 0,
        }
        // save new Job

        try {
          await createPaymentMethod(payload)
            .then(response => {
              console.log(response, "response")
              toast.success(`Payment Method is Added Successfully`, {
                autoClose: 2000,
              })
              validation.resetForm()
              getPaymentMethodList()
              toggle()
            })
            .catch(err => {
              console.log(err)
              if (err?.status === 400 && err?.response?.data?.message) {
                toast.error(err?.response?.data?.message, { autoClose: 2000 })
              } else {
                toast.error(`Payment Method is Added Failed`, {
                  autoClose: 2000,
                })
              }
            })
        } catch (error) {
          toast.error(`Payment Method  is Added Failed`, { autoClose: 2000 })
        }
      }
    },
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getPaymentMethodList()
  }, [])

  const getPaymentMethodList = async () => {
    try {
      setLoading(true)

      await getPaymentMethod()
        .then(response => {
          console.log(response)

          if (response) {
            setPaymentMethods(response)
            setLoading(false)
          }
        })
        .catch(err => {
          console.log(err)

          setLoading(false)
        })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setPaymentMethod(null)
      setIsEdit(false)
    } else {
      setModal(true)
    }
    validation.resetForm()
  }

  const handlePaymentMethodClick = arg => {
    console.log(arg, "arg")
    setPaymentMethod(arg)

    setIsEdit(true)

    toggle()
  }

  //delete Job
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = job => {
    setPaymentMethod(job)
    setDeleteModal(true)
  }

  const handleDeletePaymentMethod = async () => {
    if (paymentMethod && paymentMethod.id) {
      try {
        await deletePaymentMethod(paymentMethod.id)
          .then(response => {
            console.log(response, "response")
            getPaymentMethodList()
            setDeleteModal(false)
            toast.success(`Payment Method is Deleted Successfully`, {
              autoClose: 2000,
            })
          })
          .catch(err => {
            console.log(err)

            toast.error(`Payment Method is Deleted Failed`, { autoClose: 2000 })
          })
      } catch (error) {
        toast.error(`Payment Method is Deleted Failed`, { autoClose: 2000 })
      }
    }
  }
  const filteredPaymentList = paymentMethods.filter(m =>
    m.method_name.toLowerCase().includes(searchQuery.toLowerCase())
  )
  const columns = useMemo(
    () => [
      {
        header: "Sl.No.",
        accessorKey: "s.no.",
        enableColumnFilter: false,

        cell: (cellProps, ii) => {
          // console.log(cellProps,"cell",cellProps.row.getPinnedIndex())
          return (
            <Link to="#" className="text-body fw-bold">
              {cellProps.row.index + 1}
            </Link>
          )
        },
        // ({ row }) => row.index + 1 + pageIndex * pageSize
      },
      {
        header: "Method Name",
        accessorKey: "method_name",
        enableColumnFilter: false,
        enableSorting: false,
        filterFn: "fuzzy",
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: cellProps => {
          switch (cellProps.row.original.status) {
            case 1:
              return <span className="badge badge-soft-success">Active</span>
            case 0:
              return <span className="badge badge-soft-danger">Inactive</span>
          }
        },
      },
      // {
      //     header: () => <div style={{ textAlign: "right" }}>Action</div>,
      //     id:"pamentmethod-action",
      //     enableColumnFilter: false,
      //     cell: (cellProps) => {
      //         return (
      //             <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">

      //                 <li>
      //                     <Link
      //                         to="#"
      //                         className="btn btn-sm btn-soft-info"
      //                         onClick={() => {
      //                             const jobData = cellProps.row.original;
      //                             handlePaymentMethodClick(jobData);
      //                         }}
      //                         id={`edittooltip-${cellProps.row.original.id}`}
      //                     >
      //                         <i className="mdi mdi-pencil-outline" />
      //                         <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
      //                             Edit
      //                         </UncontrolledTooltip>
      //                     </Link>
      //                 </li>

      //             </ul>
      //         );
      //     }
      // },
    ],
    []
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePaymentMethod}
        onCloseClick={() => setDeleteModal(false)}
        mainHeader="Are you sure you want to delete this payment method?"
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Master" breadcrumbItem="Payment Method" />
          {
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                      <h5 className="mb-0 card-title flex-grow-1">
                        Payment Method
                      </h5>
                      <div className="flex-shrink-0">
                        <Link
                          to={void 0}
                          onClick={() => setModal(true)}
                          className="btn btn-primary me-1"
                        >
                          Add
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <Row className="mb-2">
                      <Col sm={4}>
                        <Input
                          type="text"
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={e => setSearchQuery(e.target.value)}
                          className="form-control search-box me-2 mb-2 d-inline-block"
                        />
                      </Col>
                    </Row>
                    {filteredPaymentList.length > 0 ? (
                      <TableContainer
                        columns={columns}
                        data={filteredPaymentList || []}
                        // isCustomPageSize={true}
                        //   isGlobalFilter={true}
                        // isJobListGlobalFilter={true}
                        loading={loading}
                        setLoading={setLoading}
                        isPagination={true}
                        //   SearchPlaceholder="Keyword Search"
                        tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline border-top"
                        pagination="pagination"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                      />
                    ) : (
                      <div className="text-center mt-3">
                        <p>No matching records found.</p>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit" : "Add"} Payment Method
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>
                        {" "}
                        Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="method_name"
                        type="text"
                        placeholder="Enter name."
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.method_name || ""}
                        invalid={
                          validation.touched.method_name &&
                          validation.errors.method_name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.method_name &&
                      validation.errors.method_name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.method_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Status</Label>
                      <FormGroup switch>
                        <Label className="">
                          {/* {validation.values.status === true?'Active':'Inactive'} */}
                          <Input
                            name="status"
                            type="switch"
                            role="switch"
                            checked={validation.values.status === true}
                            onClick={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.status}
                          />
                        </Label>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="primary"
                        type="submit"
                        className="save-user"
                      >
                        {!!isEdit ? "Update" : "Submit"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default PaymentMethod
