import React, { useState, useMemo, useEffect } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Link, useLocation } from "react-router-dom"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Dropdown,
} from "reactstrap"
import { toast, ToastContainer } from "react-toastify"
import TableContainer from "../../../../components/Common/TableContainer"
import {
  getSingleRoleModule,
  getModule,
  createRole,
  UpdateRole,
  getRoleNameModule,
  getRoleList,
} from "services/AclServices"

const EditRole = () => {
  // Meta title
  document.title = `Edit Role | ${process.env.REACT_APP_SITE_NAME} - Admin Panel`
  const location = useLocation()
  const [roleId, setRoleId] = useState(location.state?.roleId) // Initialize with location state if available

  // State to store dynamic modules from API
  const [modules, setModules] = useState([])
  const [singleRole, setSingleRole] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [dropDownRoleName, setDropDownRoleName] = useState([])
  // Fetch sub-module list from API on component mount
  const [singlebtn1, setSinglebtn1] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getsubModuleLists()
    fetchRoleName()
    getRoleModule()
    getRoleListDowpdwon()
  }, [roleId])

  // Fetch the role name
  const fetchRoleName = async () => {
    console.log("roleid", roleId)

    try {
      const response = await getRoleNameModule(roleId)
      // console.log("response",response);
      if (response) {
        formik.setFieldValue("role_Name", response.role_name)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getRoleModule = async () => {
    try {
      let params = {
        role_id: roleId,
        module_id: "",
      }

      // Fetch the sub-modules
      const role = await getSingleRoleModule({ params })
      console.log("Get Role Module-", role)
      setSingleRole(role) // Set the modules data
    } catch (error) {
      console.log(error)
    }
  }

  const getRoleListDowpdwon = async () => {
    try {
      setLoading(true)
      const dropDownRole = await getRoleList()
      setDropDownRoleName(dropDownRole)
      console.log("Dropdown Role Fetched Successfully", dropDownRole)
    } catch (error) {
      console.log("Dropdown Role Fetch Error", error)
    } finally {
      setLoading(false)
    }
  }

  const getsubModuleLists = async () => {
    try {
      let params = {
        parent_type: "parent",
        status: "active",
      }

      // Fetch the sub-modules
      const response = await getModule({ params })
      console.log("Get Module-", response)
      setModules(response) // Set the modules data
    } catch (error) {
      console.log(error)
    }
  }

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      role_Name: "",
      permissions: [], // Permissions will be populated once modules are fetched
    },
    validationSchema: Yup.object({
      role_Name: Yup.string().required("Role name is required"),
    }),

    onSubmit: async values => {
      const finalPermissions = []
      setIsSubmitting(true)
      // Iterate through the modules
      modules.forEach(module => {
        // Create the module object with permissions
        const modulePermissions = {
          role_id: roleId, // Placeholder for role_id, will be set later
          module_id: module.id, // Change 'id' to 'module_id'
          id: singleRole.find(perm => perm.module_id === module.id)?.id || null, // Set the id if matched

          view:
            values.permissions.find(perm => perm.id === module.id)?.view || 0,
          add: values.permissions.find(perm => perm.id === module.id)?.add || 0,
          edit:
            values.permissions.find(perm => perm.id === module.id)?.edit || 0,
          delete:
            values.permissions.find(perm => perm.id === module.id)?.delete || 0,
          show_contact_info: 1,
          show_financial_info: 1,
          status: 1,
        }

        // Add the main module permissions to the final permissions array
        finalPermissions.push(modulePermissions)

        // Check for sub-modules
        if (module.sub_module && module.sub_module.length > 0) {
          module.sub_module.forEach(sub => {
            // Create the sub-module object as a flat structure
            const subModulePermissions = {
              role_id: roleId, // Placeholder for role_id, will be set later
              module_id: sub.id, // Set module_id to sub-module's ID
              id:
                singleRole.find(perm => perm.module_id === sub.id)?.id || null, // Set the id if matched

              view:
                values.permissions.find(perm => perm.id === sub.id)?.view || 0,
              add:
                values.permissions.find(perm => perm.id === sub.id)?.add || 0,
              edit:
                values.permissions.find(perm => perm.id === sub.id)?.edit || 0,
              delete:
                values.permissions.find(perm => perm.id === sub.id)?.delete ||
                0,
              show_contact_info: 1,
              show_financial_info: 1,
              status: 1,
            }

            // Add the sub-module permissions as a separate entry
            finalPermissions.push(subModulePermissions)
          })
        }
      })

      // Combine role name and final permissions
      const rolePayload = {
        role_name: values.role_Name,
        status: 1,
      }
      console.log("New Payload---", rolePayload)
      console.log("New permissions---", finalPermissions)

      try {
        const response = await UpdateRole(roleId, rolePayload)

        if (response && response.id) {
          console.log("Role created successfully:", response)
          // toast.success("Role added successfully", { autoClose: 1500 })

          // Second API call
          try {
            // Prepare the headers with the Bearer token
            const authToken = localStorage.getItem("authUser") // Replace with the actual key used for the token
            const headers = {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json", // Ensure the content type is set correctly
            }

            // Make the API call using Fetch
            const response = await fetch(
              `${process.env.REACT_APP_BASEPATH}/role-module`,
              {
                method: "POST",
                headers: headers,
                body: JSON.stringify(finalPermissions), // Convert finalPermissions to JSON
              }
            )

            // Check if the response is ok (status in the range 200-299)
            if (response.ok) {
              const moduleResponse = await response.json() // Parse JSON response
              console.log(
                "Role Permission submitted successfully:",
                moduleResponse
              )
              toast.success("Role-Module Updated successfully", {
                autoClose: 1500,
              })
              getRoleListDowpdwon();
              // window.location.href = "/role";
              // setTimeout(() => {
              //   window.history.back(); // Navigate back to the previous page
              // }, 2000);
            } else {
              const errorData = await response.json() // Parse error response
              console.error("Error in submitting permissions:", errorData)
              console.error("Status Code:", response.status)
              toast.error("Error in Role-Module Submit", { autoClose: 1500 })
            }
          } catch (error) {
            console.error("Error in createRoleModule API call:", error.message)
          }
        }
      } catch (error) {
        console.error("Error in createRole API call: catch block", error)

        // Check if the error has a response with a message
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred."

        toast.error(errorMessage, { autoClose: 1500 })
      } finally {
        setIsSubmitting(false)
      }
    },
  })

  // Populate Formik permissions when modules data changes
  useEffect(() => {
    if (modules.length > 0) {
      const permissions = modules.map(module => {
        const existingPermission = singleRole.find(
          perm => perm.module_id === module.id
        )

        // Set the permission values for the module
        const permission = {
          id: module.id,
          name: module.module_name,
          hasSubModule: module.sub_module.length > 0,
          view: existingPermission?.view || 0,
          add: existingPermission?.add || 0,
          edit: existingPermission?.edit || 0,
          delete: existingPermission?.delete || 0,
        }

        // Check for sub-modules and set their permissions
        if (module.sub_module && module.sub_module.length > 0) {
          permission.subModules = module.sub_module.map(subModule => {
            const subModulePermission = singleRole.find(
              perm => perm.module_id === subModule.id
            )
            return {
              id: subModule.id,
              name: subModule.module_name,
              isSubModule: true,
              view: subModulePermission?.view || 0,
              add: subModulePermission?.add || 0,
              edit: subModulePermission?.edit || 0,
              delete: subModulePermission?.delete || 0,
            }
          })
        }

        return permission
      })

      // Flatten permissions with sub-modules for Formik
      const flatPermissions = permissions.reduce((acc, module) => {
        acc.push(module)
        if (module.subModules) acc.push(...module.subModules)
        return acc
      }, [])

      // Update Formik with the flattened permissions
      formik.setFieldValue("permissions", flatPermissions)
    }
  }, [modules, singleRole])
  // Handle permission change
  const handlePermissionChange = (moduleId, field, value) => {
    const updatedPermissions = formik.values.permissions.map(permission =>
      permission.id === moduleId
        ? { ...permission, [field]: value ? 1 : 0 }
        : permission
    )
    formik.setFieldValue("permissions", updatedPermissions)
  }

  // Define columns for TableContainer
  const columns = useMemo(
    () => [
      {
        header: "Modules",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
          <span className={row.original.isSubModule ? "" : "font-weight-bold"}>
            {row.original.isSubModule ? (
              <span style={{ paddingLeft: "20px" }}>
                <span style={{ marginRight: "5px" }}>•</span>{" "}
                {/* Bullet character */}
                {row.original.name}
              </span>
            ) : (
              <div >
                {/* style={{ color: "#204260" }} */}
                <strong>{row.original.name}</strong>
              </div>
            )}
          </span>
        ),
      },
      {
        header: "View",
        accessorKey: "view",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) =>
          !row.original.hasSubModule ? (
            <Input
              style={{ border: "1px solid #306DAA" }}
              type="checkbox"
              checked={row.original.view === 1}
              onChange={e =>
                handlePermissionChange(
                  row.original.id,
                  "view",
                  e.target.checked
                )
              }
            />
          ) : (
            <span className="text-muted"></span>
          ),
      },
      {
        header: "Add",
        accessorKey: "add",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) =>
          !row.original.hasSubModule ? (
            <Input
              type="checkbox"
              style={{ border: "1px solid #306DAA" }}
              checked={row.original.add === 1}
              onChange={e =>
                handlePermissionChange(row.original.id, "add", e.target.checked)
              }
            />
          ) : null,
      },
      {
        header: "Edit",
        accessorKey: "edit",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) =>
          !row.original.hasSubModule ? (
            <Input
              type="checkbox"
              style={{ border: "1px solid #306DAA" }}
              checked={row.original.edit === 1}
              onChange={e =>
                handlePermissionChange(
                  row.original.id,
                  "edit",
                  e.target.checked
                )
              }
            />
          ) : null,
      },
      {
        header: "Delete",
        accessorKey: "delete",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) =>
          !row.original.hasSubModule ? (
            <Input
              type="checkbox"
              style={{ border: "1px solid #306DAA" }}
              checked={row.original.delete === 1}
              onChange={e =>
                handlePermissionChange(
                  row.original.id,
                  "delete",
                  e.target.checked
                )
              }
            />
          ) : null,
      },
    ],
    [formik.values.permissions]
  )

  // Permission data for the table
  const data = formik.values.permissions

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACL Management" breadcrumbItem="Edit Role" />

          <Row>
            <Col lg="12">
              <Card>           
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">Edit Role</h5>
                    <div className="flex-shrink-0">
                      <Link to="/role" className="btn  btn-primary me-1">
                        Back
                      </Link>
                    </div>
                  </div>
                </CardBody>

                <CardBody>
                  <Form
                    className="form-horizontal"
                    onSubmit={formik.handleSubmit}
                  >
                    <Row className="align-items-center">
                      <Col md={2}>
                        <Label htmlFor="role_Name" className="form-label">
                          Role Name
                        </Label>
                      </Col>
                      <Col md={3}>
                        <Input
                          type="text"
                          name="role_Name"
                          className="form-control"
                          id="role_Name"
                          placeholder="Enter Name"
                          value={formik.values.role_Name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.role_Name && formik.errors.role_Name
                              ? true
                              : false
                          }
                        />
                        {formik.touched.role_Name &&
                          formik.errors.role_Name && (
                            <FormFeedback type="invalid">
                              {formik.errors.role_Name}
                            </FormFeedback>
                          )}
                      </Col>
                      <Col md={2} className="offset-md-4">
                        <Dropdown
                          isOpen={singlebtn1}
                          toggle={() => setSinglebtn1(!singlebtn1)}
                          // className="mt-4 mt-sm-0"
                          // onClick={() => getRoleListDowpdwon()}
                          
                        >
                          <DropdownToggle className="btn btn-secondary" caret style={{ minWidth: '100%' }}>
                          {dropDownRoleName.find(role => role.id === roleId)?.role_name || "Select Role Module"}
                          <i className="mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu style={{ minWidth: '100%' }}>
                            {loading ? (
                              <DropdownItem disabled>Loading...</DropdownItem>
                            ) : (
                              dropDownRoleName.map(role => (
                                <li key={role.id}>
                                  <DropdownItem
                                    onClick={() => {
                                      setRoleId(role.id) // Set the new role ID
                                    }}
                                  >
                                    {role.role_name}
                                  </DropdownItem>
                                </li>
                              ))
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    </Row>

                    {/* Use TableContainer for displaying the permissions table */}
                    <TableContainer
                      columns={columns}
                      data={data}
                      isPagination={false}
                      isGlobalFilter={false}
                      tableClass="align-middle table-nowrap mt-4"
                      theadClass="table-light"
                    />

                    <Row className="mt-4">
                      <Col>
                        <Button
                          type="submit"
                          color="primary"
                          className="w-md"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Submitting..." : "Submit"}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default EditRole
