import Breadcrumb from 'components/Common/Breadcrumb'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import { getCategoryDropdown } from 'services/MasterServices'
import { createProduct } from 'services/ProductServices'



const weightMeasureList = [
     {
label:"Gram",
value:"g"
},
     {
label:"Kilogram",
value:"kg"
}
];
const lengthMeasureList = [
     {
label:"Inch",
value:"in"
},
     {
label:"Centimeter",
value:"cm"
}
]

const AddProduct = () => {

 
    const [loading, setLoading] = useState(false)
    const [categoryDropdownList, setCategoryDropdownList] = useState([])
    const [categoryId, setCategoryId] = useState('')
    const [subCategoryDropdownList, setSubCategoryDropdownList] = useState([])
    const [subCategoryId, setSubCategoryId] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [sku, setSku] = useState('')
    const [price, setPrice] = useState('')
    const [salePrice, setSalePrice] = useState('')
    const [unitINRPrice, setUnitINRPrice] = useState('')
    const [unitUSDPrice, setUnitUSDPrice] = useState('')
    const [costPrice, setCostPrice] = useState('')
    const [brand, setBrand] = useState('')
    const [weight, setWeight] = useState('')
    const [height, setHeight] = useState('')
    const [shopifyLineItemId, setShopifyLineItemId] = useState('')
    const [shopifySku, setShopifySku] = useState('')
    const [uom_weight, setUom_weight] = useState('g')
    const [uom_length, setUomLength] = useState('in')
    const [status,setStatus] = useState(true)
    const [errorMessage, setErrorMessage] = useState(false)
    const navigate = useNavigate()



    //meta title
    document.title = `Product Add  | ${process.env.REACT_APP_SITE_NAME} Admin Panel`;


    useEffect(() => {
 
        getCategoryList()
    }, [])


    const getCategoryList = async () => {
        let params = {
            status: 'active',
            parent_type: 'parent',
      
        }

        try {
            await getCategoryDropdown({ params }).then(response => {
                console.log(response)

                setCategoryDropdownList(response)


            }).catch(err => console.log(err))
        } catch (error) {
            console.log(error)
        }


    }


    const getSubcategoryList = async (subcatId) => {
        let params = {
            status: 'active',
            parent_id:subcatId
        
        }

        try {
            await getCategoryDropdown({ params }).then(response => {
                console.log(response)

                setSubCategoryDropdownList(response)


            }).catch(err => console.log(err))
        } catch (error) {
            console.log(error)
        }


    }


    const handleCategory = (e) => {

        setCategoryId(e.target.value);
if(e.target.value !== ""){
    
    getSubcategoryList(e.target.value)
}

        setSubCategoryDropdownList([]);
        setSubCategoryId("")
    }



    const handleSubmit = async (e) =>{
        e.preventDefault()
       
        setErrorMessage(true)
        
        let submitFlag = true;

        if(title===""){
            submitFlag = false
        }
        if(categoryId==="" || subCategoryId === ""){
            submitFlag = false
        }
    

        if(submitFlag){
            try {
                let payload ={
                   
                    cat_id:Number(subCategoryId),
                    p_title:title,
                    p_desc:description,
                    sku:sku,
                    price:Number(price),
                    sale_price:Number(salePrice),
                    unit_inr_price:Number(unitINRPrice),
                    unit_usd_price:Number(unitUSDPrice),
                    cost_price:Number(costPrice),
                    brand:brand,
                    weight:weight,
                    height:height,
                    shopify_line_item_id:shopifyLineItemId,
                    shopify_sku:shopifySku,
                    uom_weight:uom_weight,
                    uom_length:uom_length,
                    status:status?1:0,
       
                }

                setLoading(true)
              await  createProduct(payload).then(response=>{
                    console.log(response)
                    if(response){
                        toast.success("Product is created successfully." )
                        setTimeout(() => {
                            if(response.id){
                                navigate(`/product/edit/${response.id}?tab=2`)
                            }else{
                                navigate(`/product`)
                            }
                        }, 2000);
                    }
                    setLoading(false)
                }).catch(err=>{console.log(err);
                    toast.error(`Product is created Failed`, { autoClose: 2000 });

                    setLoading(false)

                })
            } catch (error) {
                console.log(error)
                toast.error(`Something went wrong.`, { autoClose: 2000 });
                setLoading(false)
            }
        }
    }






    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumb title="Manage Product" to={`/product`}  breadcrumbItem="Add Product" />

                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody className="border-bottom">
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 card-title flex-grow-1">Add Product</h5>
                                    <div className="flex-shrink-0">
                                        <Link to="/product" className="btn btn-primary me-1">Back</Link>

                                    </div>
                                </div>
                            </CardBody>
                            <CardBody>
                                <Form onSubmit={handleSubmit}>


                                    <Row>
                                 
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label>Choose Category  <span className='text-danger'>*</span></Label>
                                                <Input
                                                    name="category_id"
                                                    type="select"
                                                    className="form-select"
                                                    onChange={handleCategory}

                                                    value={
                                                        categoryId
                                                    }
                                                    invalid={
                                                        errorMessage && categoryId === ""
                                                    }
                                                >

                                                    <option value="">Select</option>

                                                    {
                                                        categoryDropdownList?.map((item, key) =>
                                                            <option key={`dropdown-${key}`} value={item?.id}>{item?.category_name}</option>


                                                        )
                                                    }


                                                </Input>
                                                {errorMessage && categoryId === ""
                                                    ? (
                                                        <FormFeedback type="invalid">Please Choose category.</FormFeedback>
                                                    ) : null}
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label>Choose Subcategory  <span className='text-danger'>*</span></Label>
                                                <Input
                                                    name="category_id"
                                                    type="select"
                                                    className="form-select"
                                                    onChange={(e) => setSubCategoryId(e.target.value)}

                                                    value={
                                                        subCategoryId
                                                    }
                                                    invalid={
                                                        errorMessage && subCategoryId === ""
                                                    }
                                                >

                                                    <option value="">Select</option>

                                                    {
                                                        subCategoryDropdownList?.map((item, key) =>
                                                            <option key={`dropdown-${key}`} value={item?.id}>{item?.category_name}</option>


                                                        )
                                                    }


                                                </Input>
                                                {errorMessage && subCategoryId === ""
                                                    ? (
                                                        <FormFeedback type="invalid">Please Choose subcategory.</FormFeedback>
                                                    ) : null}
                                            </div>
                                        </Col>

                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-firstname-Input">Title <span className='text-danger'>*</span></Label>
                                                <Input
                                                    type="text"
                                                    name="title"
                                                    className="form-control"
                                                    id="formrow-cname-Input"
                                                    placeholder="Enter Title"
                                                    value={title}
                                                    onChange={(e)=>setTitle(e.target.value)}
                                                    invalid={
                                                      errorMessage && title === ''
                                                    }
                                                />
                                                {
                                                     errorMessage && title === ''? (
                                                        <FormFeedback type="invalid">{'This field is required'}</FormFeedback>
                                                    ) : null
                                                }
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-paymentTermId-Input">Product Sku </Label>
                                                <Input
                                                    type="text"
                                                    name="sku"
                                                    className="form-control"
                                                    id="formrow-paymentTermId-Input"
                                                    placeholder="Enter Product Sku"
                                                    value={sku}
                                                    onChange={(e)=>setSku(e.target.value)}
                                                   
                                                   
                                                />
                                                 
                                              
                                               
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-address-Input">Description  </Label>
                                                <Input
                                                    type="textarea"
                                                    name="description"
                                                    row={3}
                                                    className="form-control"
                                                    id="formrow-address-Input"
                                                    placeholder="Enter Product Description"
                                                    value={description}
                                                    onChange={(e)=>setDescription(e.target.value)}
                                                  
                                                   
                                                />
                                              
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Price </Label>
                                                <Input
                                                    type="text"
                                                    name="reg_date"
                                                   
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={price}
                                                    onChange={(e)=>setPrice(e.target.value)}
                                                    
                                                   
                                                />
                                           
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Sales Price </Label>
                                                <Input
                                                    type="text"
                                                    name="saleprice"
                                                   
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={salePrice}
                                                    onChange={(e)=>setSalePrice(e.target.value)}
                                                    
                                                   
                                                />
                                             
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Unit INR Price</Label>
                                                <Input
                                                    type="text"
                                                    name="saleprice"
                                                   
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={unitINRPrice}
                                                    onChange={(e)=>setUnitINRPrice(e.target.value)}
                                                    
                                                
                                                />
                                             
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Unit USD Price </Label>
                                                <Input
                                                    type="text"
                                                    name="saleprice"
                                                   
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={unitUSDPrice}
                                                    onChange={(e)=>setUnitUSDPrice(e.target.value)}
                                                    
                                                    // invalid={
                                                    //     errorMessage && unitUSDPrice === ""
                                                    // }
                                                />
                                                {/* {
                                                        errorMessage && unitUSDPrice === "" ? (
                                                        <FormFeedback type="invalid">{'This field is required'}</FormFeedback>
                                                    ) : null
                                                } */}
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Cost Price </Label>
                                                <Input
                                                    type="text"
                                                    name="saleprice"
                                                   
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={costPrice}
                                                    onChange={(e)=>setCostPrice(e.target.value)}
                                                    
                                                  
                                                />
                                             
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Brand </Label>
                                                <Input
                                                    type="text"
                                                    name="saleprice"
                                                   
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={brand}
                                                    onChange={(e)=>setBrand(e.target.value)}
                                                    
                                                  
                                                />
                                             
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">UOM weight </Label>
                                                <Input
                                                    type="select"
                                                    name="saleprice"
                                                   
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={uom_weight}
                                                    onChange={(e)=>setUom_weight(e.target.value)}
                                                    
                                                  
                                                
                                                >
                                                    {
                                                        weightMeasureList?.map((item,index)=>  <option value={item?.value} key={`mease-${index}`}>{item?.label}</option>)
                                                    }
                                                   
                                                </Input>
                                             
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Weight </Label>
                                                <Input
                                                    type="text"
                                                    name="saleprice"
                                                   
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={weight}
                                                    onChange={(e)=>setWeight(e.target.value)}
                                                    
                                                  
                                                />
                                             
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">UOM Height </Label>
                                                <Input
                                                    type="select"
                                                    name="saleprice"
                                                   
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={uom_length}
                                                    onChange={(e)=>setUomLength(e.target.value)}
                                                    
                                                  
                                                
                                                >
                                                    {
                                                        lengthMeasureList?.map((item,index)=>  <option value={item?.value} key={`mease-${index}`}>{item?.label}</option>)
                                                    }
                                                   
                                                </Input>
                                             
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Height </Label>
                                                <Input
                                                    type="text"
                                                    name="saleprice"
                                                   
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={height}
                                                    onChange={(e)=>setHeight(e.target.value)}
                                                    
                                                  
                                                />
                                             
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Shopify Line Item Id </Label>
                                                <Input
                                                    type="text"
                                                    name="saleprice"
                                                   
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={shopifyLineItemId}
                                                    onChange={(e)=>setShopifyLineItemId(e.target.value)}
                                                    
                                                  
                                                />
                                             
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Shopify Sku </Label>
                                                <Input
                                                    type="text"
                                                    name="saleprice"
                                                   
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={shopifySku}
                                                    onChange={(e)=>setShopifySku(e.target.value)}
                                                    
                                                  
                                                />
                                             
                                            </div>
                                        </Col>
                                    

                                    </Row>


                                

                                    <div className="mb-3">
                                        <Label>Status</Label>
                                        <FormGroup switch>
                                            <Label className=""> 
                                                {/* {status === true ? 'Active' : 'Inactive'} */}
                                                <Input
                                                    name="status"
                                                    type="switch"
                                                    role="switch"
                                                    checked={status }

                                                    onClick={()=>setStatus(!status)}
                                                  
                                                    value={status}


                                                />

                                            </Label>

                                        </FormGroup>


                                    </div>
                                    <div>
                                        <button type="submit" className="btn btn-primary w-md" disabled={loading}>
                                            Submit
                                        </button>
                                    </div>
                                </Form>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <ToastContainer />
            </div>
        </div>
    )
}

export default AddProduct