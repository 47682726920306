import React, { useState, useMemo, useEffect } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"
import { toast, ToastContainer } from "react-toastify"
import TableContainer from "../../../../components/Common/TableContainer"
import {
  getsubModuleList,
  getModule,
  createRole,
  createRoleModule,
} from "services/AclServices"

const AdRole = () => {
  // Meta title
  document.title = `Add Role | ${process.env.REACT_APP_SITE_NAME} - Admin Panel`

  // State to store dynamic modules from API
  const [modules, setModules] = useState([])

  // Fetch sub-module list from API on component mount
  useEffect(() => {
    getsubModuleLists()
  }, [])

  const getsubModuleLists = async () => {
    try {
      let params = {
        parent_type: "parent",
        status: "active",
      }

      // Fetch the sub-modules
      const response = await getModule({ params })
      console.log("Get Module-", response)
      setModules(response) // Set the modules data
    } catch (error) {
      console.log(error)
    }
  }

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      role_Name: "",
      permissions: [], // Permissions will be populated once modules are fetched
    },
    validationSchema: Yup.object({
      role_Name: Yup.string().required("Role name is required"),
    }),
    // onSubmit: async values => {
    //   // Prepare data for submission
    //   const finalPermissions = []

    //   // Iterate through the modules
    //   modules.forEach(module => {
    //     // Create the module object with permissions
    //     const modulePermissions = {
    //       module_id: module.id, // Change 'id' to 'module_id'
    //       parent_id: null,
    //       view: values.permissions.find(perm => perm.id === module.id)?.view || 0,
    //       add: values.permissions.find(perm => perm.id === module.id)?.add || 0,
    //       edit: values.permissions.find(perm => perm.id === module.id)?.edit || 0,
    //       delete: values.permissions.find(perm => perm.id === module.id)?.delete || 0,
    //       sub_module: [], // Initialize an empty array for sub-modules
    //     }

    //     // Check for sub-modules
    //     if (module.sub_module && module.sub_module.length > 0) {
    //       module.sub_module.forEach(sub => {
    //         // Create the sub-module object with permissions
    //         const subModulePermissions = {
    //           sub_module_id: sub.id, // Change 'id' to 'module_id'
    //           parent_id: modulePermissions.module_id, // Ensure parent_id is correct
    //           view: values.permissions.find(perm => perm.id === sub.id)?.view || 0,
    //           add: values.permissions.find(perm => perm.id === sub.id)?.add || 0,
    //           edit: values.permissions.find(perm => perm.id === sub.id)?.edit || 0,
    //           delete: values.permissions.find(perm => perm.id === sub.id)?.delete || 0,
    //         }

    //         // Add the sub-module permissions to the main module
    //         modulePermissions.sub_module.push(subModulePermissions)
    //       })
    //     }

    //     // Add the main module permissions to the final permissions array
    //     finalPermissions.push(modulePermissions)
    //   })

    //   // Combine role name and final permissions
    //   const formData = {
    //     // role_Name: values.role_Name,
    //     permissions: finalPermissions,
    //   }
    //   console.log("Form submitted with data like checkboxes: ", formData)

    //   const rolePayload = {
    //     role_name: values.role_Name,
    //     status: 1,
    //   };
    // // First Create the Role

    // try {
    //   console.log(rolePayload, "Before Api Call Role");

    //   const response = await createRole(rolePayload);
    //   console.log(response, "response");

    //   // Assuming the response contains the role ID
    //   const roleId = response.id; // Adjust based on actual response structure
    //   console.log("Role Created ID-",roleId)
    //   // Update formData to include role_id
    //   if(roleId){
    //     const payload = {
    //       ...formData,
    //       role_id: roleId,
    //     };

    //     console.log("Final form data to submit: with roleId and payload ", payload);

    //     // Now submit the finalFormData
    //     try {
    //       // Replace submitForm with your actual function to submit formData
    //       await createRoleModule(payload);
    //       console.log("Form submitted successfully");
    //     } catch (error) {
    //       console.log("Error submitting form data: ", error);
    //     }

    //   }

    // } catch (error) {
    //   console.log("Error creating role: ", error);
    // }

    //   // Log or send the final data
    // },
    // New submit
    onSubmit: async values => {
      const finalPermissions = []

      // Iterate through the modules
      modules.forEach(module => {
        // Create the module object with permissions
        const modulePermissions = {
          role_id: null, // Placeholder for role_id, will be set later
          module_id: module.id, // Change 'id' to 'module_id'
          view:
            values.permissions.find(perm => perm.id === module.id)?.view || 0,
          add: values.permissions.find(perm => perm.id === module.id)?.add || 0,
          edit:
            values.permissions.find(perm => perm.id === module.id)?.edit || 0,
          delete:
            values.permissions.find(perm => perm.id === module.id)?.delete || 0,
          show_contact_info: 1,
          show_financial_info: 1,
          status: 1,
        }

        // Add the main module permissions to the final permissions array
        finalPermissions.push(modulePermissions)

        // Check for sub-modules
        if (module.sub_module && module.sub_module.length > 0) {
          module.sub_module.forEach(sub => {
            // Create the sub-module object as a flat structure
            const subModulePermissions = {
              role_id: null, // Placeholder for role_id, will be set later
              module_id: sub.id, // Set module_id to sub-module's ID
              view:
                values.permissions.find(perm => perm.id === sub.id)?.view || 0,
              add:
                values.permissions.find(perm => perm.id === sub.id)?.add || 0,
              edit:
                values.permissions.find(perm => perm.id === sub.id)?.edit || 0,
              delete:
                values.permissions.find(perm => perm.id === sub.id)?.delete ||
                0,
              show_contact_info: 1,
              show_financial_info: 1,
              status: 1,
            }

            // Add the sub-module permissions as a separate entry
            finalPermissions.push(subModulePermissions)
          })
        }
      })

      // Combine role name and final permissions
      const rolePayload = {
        role_name: values.role_Name,
        status: 1,
      }

      try {
        const response = await createRole(rolePayload)

        if (response && response.id) {
          const roleId = response.id
          console.log("Role created successfully:", response)
          // toast.success("Role added successfully", { autoClose: 1500 })

          // Update role_id in each entry of the flattened finalPermissions array
          finalPermissions.forEach(permission => {
            permission.role_id = roleId
          })

          // Log the final permissions data
          console.log("Final permissions data to submit:", finalPermissions)

          // Second API call
          try {
            // Prepare the headers with the Bearer token
            const authToken = localStorage.getItem("authUser") // Replace with the actual key used for the token
            const headers = {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json", // Ensure the content type is set correctly
            }

            // Make the API call using Fetch
            const response = await fetch(
              `${process.env.REACT_APP_BASEPATH}/role-module`,
              {
                method: "POST",
                headers: headers,
                body: JSON.stringify(finalPermissions), // Convert finalPermissions to JSON
              }
            )

            // Check if the response is ok (status in the range 200-299)
            if (response.ok) {
              const moduleResponse = await response.json() // Parse JSON response
              console.log(
                "Role Permission submitted successfully:",
                moduleResponse
              )
              toast.success("Role-Module added successfully", {
                autoClose: 1500,
              })
              // window.location.href = "/role"
              setTimeout(() => {
                window.history.back(); // Navigate back to the previous page
              }, 2000);
            } else {
              const errorData = await response.json() // Parse error response
              console.error("Error in submitting permissions:", errorData)
              console.error("Status Code:", response.status)
              toast.error("Error in Role-Module Submit", { autoClose: 1500 })
            }
          } catch (error) {
            console.error("Error in createRoleModule API call:", error.message)
          }
        }
      } catch (error) {
        console.error("Error in createRole API call: catch block", error)

        // Check if the error has a response with a message
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred."

        toast.error(errorMessage, { autoClose: 1500 })
      }
    },
  })

  // Populate Formik permissions when modules data changes
  useEffect(() => {
    if (modules.length > 0) {
      const permissions = []
      modules.forEach(module => {
        // Add the module itself if no sub-modules exist
        permissions.push({
          id: module.id,
          name: module.module_name,
          hasSubModule: module.sub_module.length > 0,
          view: 0,
          add: 0,
          edit: 0,
          delete: 0,
        })

        // If there are sub-modules, add them with permissions
        if (module.sub_module && module.sub_module.length > 0) {
          module.sub_module.forEach(sub => {
            permissions.push({
              id: sub.id,
              name: sub.module_name,
              hasSubModule: false,
              view: 0,
              add: 0,
              edit: 0,
              delete: 0,
              isSubModule: true, // Additional flag to identify sub-modules
            })
          })
        }
      })
      formik.setFieldValue("permissions", permissions)
    }
  }, [modules])

  // Handle permission change
  const handlePermissionChange = (moduleId, field, value) => {
    const updatedPermissions = formik.values.permissions.map(permission =>
      permission.id === moduleId
        ? { ...permission, [field]: value ? 1 : 0 }
        : permission
    )
    formik.setFieldValue("permissions", updatedPermissions)
  }

  // Define columns for TableContainer
  const columns = useMemo(
    () => [
      {
        header: "Modules",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
          <span className={row.original.isSubModule ? "" : "font-weight-bold"}>
            {row.original.isSubModule ? (
              <span style={{ paddingLeft: "20px" }}>
                <span style={{ marginRight: "5px" }}>•</span>{" "}
                {/* Bullet character */}
                {row.original.name}
              </span>
            ) : (
              <div >
                {/* style={{ color: "#204260" }} */}
                <strong>{row.original.name}</strong>
              </div>
            )}
          </span>
        ),
      },
      {
        header: "View",
        accessorKey: "view",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) =>
          !row.original.hasSubModule ? (
            <Input
              style={{ border: "1px solid #306DAA" }}
              type="checkbox"
              checked={row.original.view === 1}
              onChange={e =>
                handlePermissionChange(
                  row.original.id,
                  "view",
                  e.target.checked
                )
              }
            />
          ) : (
            <span className="text-muted"></span>
          ),
      },
      {
        header: "Add",
        accessorKey: "add",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) =>
          !row.original.hasSubModule ? (
            <Input
              type="checkbox"
              style={{ border: "1px solid #306DAA" }}
              checked={row.original.add === 1}
              onChange={e =>
                handlePermissionChange(row.original.id, "add", e.target.checked)
              }
            />
          ) : null,
      },
      {
        header: "Edit",
        accessorKey: "edit",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) =>
          !row.original.hasSubModule ? (
            <Input
              type="checkbox"
              style={{ border: "1px solid #306DAA" }}
              checked={row.original.edit === 1}
              onChange={e =>
                handlePermissionChange(
                  row.original.id,
                  "edit",
                  e.target.checked
                )
              }
            />
          ) : null,
      },
      {
        header: "Delete",
        accessorKey: "delete",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) =>
          !row.original.hasSubModule ? (
            <Input
              type="checkbox"
              style={{ border: "1px solid #306DAA" }}
              checked={row.original.delete === 1}
              onChange={e =>
                handlePermissionChange(
                  row.original.id,
                  "delete",
                  e.target.checked
                )
              }
            />
          ) : null,
      },
    ],
    [formik.values.permissions]
  )

  // Permission data for the table
  const data = formik.values.permissions

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACL Management" breadcrumbItem="Add Role" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">Add Role</h5>
                    <div className="flex-shrink-0">
                      <Link
                        to="/role"
                        className="btn btn-primary me-1"
                      >
                        Back
                      </Link>
                    </div>
                  </div>
                </CardBody>

                <CardBody>
                  <Form
                    className="form-horizontal"
                    onSubmit={formik.handleSubmit}
                  >
                    <Row className="align-items-center">
                      <Col md={2}>
                        <Label htmlFor="role_Name" className="form-label">
                          Role Name
                        </Label>
                      </Col>
                      <Col md={4}>
                        <Input
                          type="text"
                          name="role_Name"
                          className="form-control"
                          id="role_Name"
                          placeholder="Enter Name"
                          value={formik.values.role_Name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.role_Name && formik.errors.role_Name
                              ? true
                              : false
                          }
                        />
                        {formik.touched.role_Name &&
                          formik.errors.role_Name && (
                            <FormFeedback type="invalid">
                              {formik.errors.role_Name}
                            </FormFeedback>
                          )}
                      </Col>
                    </Row>

                    {/* Use TableContainer for displaying the permissions table */}
                    <TableContainer
                      columns={columns}
                      data={data}
                      isPagination={false}
                      isGlobalFilter={false}
                      tableClass="align-middle table-nowrap mt-4"
                      theadClass="table-light"
                    />

                    <Row className="mt-4">
                      <Col>
                        <Button type="submit" color="primary" className="w-md">
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default AdRole
