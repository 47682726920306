/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import DatePicker from 'react-date-picker';
import * as Yup from "yup";
import moment from "moment";
import { toast as toastfy, ToastContainer } from "react-toastify";
import { useFormik, Field, FieldArray, setFormData } from "formik";

// Services
import { getShippingProviderList } from 'services/ShippingProviderServices'
import { addShippmentToOrder } from 'services/OrderService'

function makeid(length=20) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export default function AddShippment(props) {

    const { order, getRemainingQuantity, getOrderByID } = props
    const params = useParams()
    const [modal, setModal] = useState(false);
    const [is_loading, setLoading] = useState(true) 
    const [shippmentProviders, setShippmentProviders] = useState([]);

    // validation
    const validationCreate = useFormik({
        enableReinitialize: false,
        initialValues: {
            "order_id": null,
            "shipping_provider_id": null,
            "delivery_method": null,
            "delivery_date": null,
            "shipping_tracking_info": null,
            "shipping_note": null,
            "shipping_amount": null,
            "status": 1,
            "orderDelivery": []
        },
        validationSchema: Yup.object({
            shipping_provider_id: Yup.number().required("Please select Shipping provider"),
            delivery_method: Yup.string().trim().required("Please enter delivery method"),
            delivery_date: Yup.date().required("Please select delivery date"),
            shipping_tracking_info: Yup.string().trim().required("Please enter shipping tracking info."),
            shipping_amount: Yup.number().required("Please enter shipping amount"),
            orderDelivery: Yup.array()
                .min(1, "Please add atleast one shippment to continue...")
                .of(
                    Yup.object().shape({
                        uuid: Yup.string(),
                        line_item_id: Yup.number().required('Please select product'),
                        quantity: Yup.number().min(1, 'Quantity should be more than zero.').required('please enter quantity'),
                      })
                )
        }),
        onSubmit: async (values) => {
            const payload = {
                order_id: order.id,
                shipping_provider_id : Number(values.shipping_provider_id),
                delivery_method : values.delivery_method,
                delivery_date : values.delivery_date,
                shipping_tracking_info : values.shipping_tracking_info,
                shipping_note : values.shipping_note,
                shipping_amount : values.shipping_amount,
                status : 1,
                orderDelivery : values.orderDelivery.map(d => ({
                    line_item_id: Number(d.line_item_id),
                    quantity: d.quantity
                }))
            };
            // save new Category
            try {
                addShippmentToOrder(payload)
                    .then(response => {
                        getOrderByID()
                        toggle()
                    })
            } catch (error) {
                //
            }
        },
    });

    const toggle = () => {
        validationCreate.setFieldValue('order_id', null)
        validationCreate.setFieldValue('shipping_provider_id', null)
        validationCreate.setFieldValue('delivery_method', null)
        validationCreate.setFieldValue('delivery_date', null)
        validationCreate.setFieldValue('shipping_tracking_info', null)
        validationCreate.setFieldValue('shipping_note', null)
        validationCreate.setFieldValue('shipping_amount', null)
        validationCreate.setFieldValue('orderDelivery', [])
        validationCreate.setErrors([])
        setModal(!modal);
    }

    const getShipmentsProvider = async () => {
        setLoading(true)
        try {
            let params = {
                page: 1,
                limit: 200,
                search: ''
            }
            await getShippingProviderList(params)
                .then(response => {
                    setShippmentProviders(response.data)
                })
        } catch (error) {
            console.error(error);
        }
        setLoading(false)
    }

    const addItemToOrderDelivery = async () => {
        let items = validationCreate.values.orderDelivery
        items.push({
            "uuid": makeid(),
            "line_item_id": null,
            "quantity": 0
        })
        await validationCreate.setFieldValue('orderDelivery', items)
        
        
    }

    const removeItemFromOrderDelivery = async (delivery) => {
        let orderDeliveries = validationCreate.values.orderDelivery
        orderDeliveries = orderDeliveries.filter(function(item) {
            return item.uuid !== delivery.uuid
        })
        await validationCreate.setFieldValue('orderDelivery', orderDeliveries)
    }

    const validateQuantity = (e, product_id, index) => {
        const entered_quantity = e.target.value
        const remaining_quantity = getRemainingQuantity(product_id).remainingQuantity
        if (entered_quantity > remaining_quantity) {
            toastfy.error(`Max ${remaining_quantity} quantity is allowed to deliver.`)
            let orderDelivery = validationCreate.values.orderDelivery
            orderDelivery[index]['quantity'] = remaining_quantity
            validationCreate.setFieldValue('orderDelivery', orderDelivery)
        } else {
            const orderDelivery = validationCreate.values.orderDelivery
            orderDelivery[index]['quantity'] = entered_quantity
            validationCreate.setFieldValue('orderDelivery', orderDelivery)
        }
    }

    useEffect(() => {
        getShipmentsProvider()
    }, [])

    return (
        <div>
            <ToastContainer />
            <button type="button"  className="btn btn-dark btn-sm" onClick={toggle}>
                <i className="mdi mdi-plus" /> Add Delivery
            </button>
            <Modal isOpen={modal} toggle={toggle} fullscreen="xl" unmountOnClose={true}>
                <ModalHeader toggle={toggle}>
                    Add Delivery
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={6}>
                            <FormGroup>
                                <Label for="exampleEmail">
                                    Shipping Provider <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="select"
                                    name="shipping_provider_id"
                                    placeholder="Select Shipping Provider"
                                    onChange={validationCreate.handleChange}
                                    onBlur={validationCreate.handleBlur}
                                    value={validationCreate.values.shipping_provider_id || null}
                                    invalid={
                                        validationCreate.touched.shipping_provider_id && validationCreate.errors.shipping_provider_id ? true : false
                                    }
                                    validate={{
                                        required: { value: true },
                                    }}
                                >
                                    <option value="">Please select...</option>
                                    { shippmentProviders.map((provider, i) => <option value={provider.id} key={i}>{provider.shipping_comp_name}</option>) }
                                </Input>
                                { validationCreate.touched.shipping_provider_id && validationCreate.errors.shipping_provider_id && <FormFeedback type="invalid">{validationCreate?.errors.shipping_provider_id}</FormFeedback> }
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <FormGroup>
                                <Label for="exampleEmail">
                                    Delivery Method <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    name="delivery_method"
                                    placeholder="Delivery Method"
                                    onChange={validationCreate.handleChange}
                                    onBlur={validationCreate.handleBlur}
                                    value={validationCreate.values.delivery_method || null}
                                    invalid={
                                        validationCreate.touched.delivery_method && validationCreate.errors.delivery_method ? true : false
                                    }
                                    validate={{
                                        required: { value: true },
                                    }}
                                />
                                { validationCreate.touched.delivery_method && validationCreate.errors.delivery_method && <FormFeedback type="invalid">{validationCreate?.errors.delivery_method}</FormFeedback> }
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <FormGroup>
                                <Label for="exampleEmail">
                                    Delivery Date <span className="text-danger">*</span>
                                </Label>
                                <DatePicker 
                                    format="dd-MM-y" 
                                    name="delivery_date"
                                    onChange={(e) => validationCreate.setFieldValue('delivery_date', moment(e).format('YYYY-MM-DD'))}
                                    value={validationCreate.values.delivery_date || null}
                                    required={true}
                                />
                                { validationCreate.errors.delivery_date && <div type="invalid" className="invalid-feedback" style={{display: 'block'}}>{validationCreate?.errors.delivery_date}</div> }
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <FormGroup>
                                <Label for="exampleEmail">
                                    Shipping Tracking Info <span className="text-danger">*</span>
                                </Label>
                                <Input 
                                    name="shipping_tracking_info"
                                    type="text" 
                                    placeholder="Shipping Tracking Info" 
                                    onChange={validationCreate.handleChange}
                                    onBlur={validationCreate.handleBlur}
                                    value={validationCreate.values.shipping_tracking_info || null}
                                    invalid={
                                        validationCreate.touched.shipping_tracking_info && validationCreate.errors.shipping_tracking_info ? true : false
                                    }
                                    validate={{
                                        required: { value: true },
                                    }}
                                />
                                { validationCreate.touched.shipping_tracking_info && validationCreate.errors.shipping_tracking_info && <FormFeedback type="invalid">{validationCreate?.errors.shipping_tracking_info}</FormFeedback> }
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <FormGroup>
                                <Label for="exampleEmail">
                                    Shipping Note
                                </Label>
                                <Input 
                                    type="textarea" 
                                    row={5} 
                                    name="shipping_note"
                                    placeholder="Shipping Note" 
                                    onChange={validationCreate.handleChange}
                                    onBlur={validationCreate.handleBlur}
                                    value={validationCreate.values.shipping_note || null}
                                    invalid={ validationCreate.touched.shipping_note && validationCreate.errors.shipping_note ? true : false }
                                    validate={{
                                        required: { value: false },
                                    }}
                                />
                                { validationCreate.touched.shipping_note && validationCreate.errors.shipping_note && <FormFeedback type="invalid">{validationCreate?.errors.shipping_note}</FormFeedback> }
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <FormGroup>
                                <Label for="exampleEmail">
                                    Shipping Amount <span className="text-danger">*</span>
                                </Label>
                                <Input 
                                    type="number" 
                                    row={5} 
                                    name="shipping_amount"
                                    placeholder="Shipping Amount" 
                                    onChange={validationCreate.handleChange}
                                    onBlur={validationCreate.handleBlur}
                                    value={validationCreate.values.shipping_amount || null}
                                    invalid={
                                        validationCreate.touched.shipping_amount && validationCreate.errors.shipping_amount ? true : false
                                    }
                                    validate={{
                                        required: { value: false },
                                    }}
                                />
                                { validationCreate.touched.shipping_amount && validationCreate.errors.shipping_amount && <FormFeedback type="invalid">{validationCreate?.errors.shipping_amount}</FormFeedback> }
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className="border-heading d-flex justify-content-between align-items-center">
                                <div>
                                    <h6>Shipments</h6>
                                    { validationCreate.errors.orderDelivery && 
                                        Array.isArray(validationCreate.errors.orderDelivery) == false &&
                                        <div type="invalid" className="invalid-feedback" style={{display: 'block'}}><i className="mdi mdi-information-outline" /> {validationCreate?.errors.orderDelivery}</div>
                                    }
                                </div>
                                <div>
                                    <button className="btn btn-sm btn-dark" onClick={addItemToOrderDelivery}><i className="mdi mdi-plus" /> Add</button>
                                </div>
                            </div>
                            <div className="border-heading-body">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Product</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {JSON.stringify(validationCreate.errors.orderDelivery)} */}
                                        { validationCreate.values.orderDelivery.map((delivery, i) => <tr key={delivery.uuid}>
                                            <th scope="row">{i + 1}</th>
                                            <td>
                                                <Input
                                                    name={`orderDelivery[${i}].line_item_id`}
                                                    type="select"
                                                    placeholder="Select Product"
                                                    onChange={validationCreate.handleChange}
                                                    onBlur={validationCreate.handleBlur}
                                                    value={delivery.line_item_id || null}
                                                    invalid={ validationCreate?.errors?.orderDelivery && validationCreate?.errors?.orderDelivery.length == (i + 1) && validationCreate?.errors?.orderDelivery[i].line_item_id ? true : false}
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                >
                                                    <option value="">Please select...</option>
                                                    { order && order.orderLineItem && order.orderLineItem.map((item, i) => <option disabled={getRemainingQuantity(item.id).remainingQuantity < 1} value={item.id} key={i}>{item.line_item_title}</option>) }
                                                </Input>
                                                { validationCreate?.errors?.orderDelivery && validationCreate?.errors?.orderDelivery.length == (i + 1) && validationCreate?.errors?.orderDelivery[i].line_item_id && <FormFeedback type="invalid">{validationCreate?.errors?.orderDelivery[i].line_item_id}</FormFeedback> }
                                            </td>
                                            <td>
                                                <FormGroup>
                                                    <Input 
                                                        name={`orderDelivery[${i}].quantity`}
                                                        type="number" 
                                                        placeholder="Enter Quantity"
                                                        onChange={e => validateQuantity(e, delivery.line_item_id, i)}
                                                        value={delivery.quantity || null}
                                                        invalid={ validationCreate?.errors?.orderDelivery && validationCreate?.errors?.orderDelivery.length == (i + 1) && validationCreate?.errors?.orderDelivery[i].quantity ? true : false}
                                                        validate={{
                                                            required: { value: true },
                                                        }} 
                                                    />
                                                    { validationCreate?.errors?.orderDelivery && validationCreate?.errors?.orderDelivery.length == (i + 1) && validationCreate?.errors?.orderDelivery[i].quantity && <FormFeedback type="invalid">{validationCreate?.errors?.orderDelivery[i].quantity}</FormFeedback> }
                                                </FormGroup>
                                            </td>
                                            <td>
                                                <button className="btn btn-sm btn-danger" onClick={() => removeItemFromOrderDelivery(delivery)}>X</button>
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                    <Button color="primary" onClick={() => validationCreate.handleSubmit()}><i className="mdi mdi-plus" /> Save</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};