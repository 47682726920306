import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { get_file_full_path } from 'helpers/helpers'
import moment from "moment";
import { Spinner } from "reactstrap";

// Services
import { getOrderById } from "services/OrderService"

// Components
import ClientInformationCard from "../components/ClientInformationCard";
import AddShippment from '../components/AddShippment'

export default function ManageShippment() {
    
    const params = useParams()
    const [ order, setOrder ] = useState(null)
    const [ is_loading, setLoading ] = useState(null)
    
    const getOrderByID = async () => {
        const id = params.id
        if (id) {
            await getOrderById(id)
            .then(res => {
                setOrder(res)
            })
        }
    }

    const getRemainingQuantity = (lineItemId) => {
        // Find the order line item
        const orderData = order;
        const lineItem = orderData.orderLineItem.find(item => item.id === Number(lineItemId));
        if (!lineItem) {
            return  {
            totalOrdered: 0,
            totalDelivered: 0,
            remainingQuantity: 0
        };
        }

        // Get total ordered quantity
        const totalOrdered = lineItem.quantity;

        // Calculate total delivered quantity for this line item
        const totalDelivered = orderData.orderShipments.reduce((sum, shipment) => {
            // Find delivery entry for this line item in current shipment
            const delivery = shipment.orderDelivery.find(d => d.line_item_id == lineItemId);
            return sum + (delivery ? delivery.quantity : 0);
        }, 0);

        // Calculate remaining quantity
        const remaining = totalOrdered - totalDelivered;

        return  {
            totalOrdered: totalOrdered,
            totalDelivered: totalDelivered,
            remainingQuantity: remaining
        };
        // return {
        //     lineItemId,
        //     sku: lineItem.line_item_sku,
        //     title: lineItem.line_item_title,
        //     totalOrdered,
        //     totalDelivered,
        //     remainingQuantity: remaining
        // };
    }

    const initialize_component = async () => {
        setLoading(true)
        await getOrderByID()
        
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    useEffect(() => {
        initialize_component()
    }, [])

    return (
        <React.Fragment>
            {is_loading && <div style={{ background: 'rgba(0,0,0,0.3)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 99999 }}>
                <Spinner color="primary" className='position-absolute top-50 start-50' style={{ zIndex: 99 }} />
            </div>}
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row mb-3">
                                    {/* <div className="col-lg-12">
                                        <h6 className="border-heading">Order Information</h6>
                                        <div className="border-heading-body">
                                            <div className="row">
                                                <div className="col-lg-6 mb-2">
                                                    <div className="row">
                                                        <div className="col-lg-6 fw-semibold">Order ID </div>
                                                        <div className="col-lg-6">: { order && order.order_no }</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-2">
                                                    <div className="row">
                                                        <div className="col-lg-6 fw-semibold">Order Date </div>
                                                        <div className="col-lg-6">: { order && moment(order.order_date).format('DD/MM/YYYY') }</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-lg-12 mt-3">
                                        <h6 className="border-heading">Shipment Information</h6>
                                        <div className="border-heading-body">
                                            <div className="row">
                                                <div className="col-lg-6 mb-2">
                                                    <div className="row">
                                                        <div className="col-lg-6 fw-semibold">Delivery Date </div>
                                                        <div className="col-lg-6">: { order && moment(order.ordered_delivery_date).format('DD/MM/YYYY') }</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-2">
                                                    <div className="row">
                                                        <div className="col-lg-6 fw-semibold">Tracking No. </div>
                                                        <div className="col-lg-6">: { order && order.shipping_tracking_info }</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-2">
                                                    <div className="row">
                                                        <div className="col-lg-12 fw-semibold">Shipping Note :</div>
                                                        <div className="col-lg-12">{ order && order.shipping_note }</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-3">
                                        <h6 className="border-heading">Client Information</h6>
                                        <div className="border-heading-body">
                                            <div className="row">
                                                <div className="col-lg-3 mb-1 fw-semibold">Name</div>
                                                <div className="col-lg-9">: { order && order.client.client_name}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 mb-1 fw-semibold">Address</div>
                                                <div className="col-lg-9">: { order && order.client.address}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 mb-1 fw-semibold">Country</div>
                                                <div className="col-lg-9">: { order && (order.client.country ?? '-')}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-12">
                                        <div className="border-heading p-2 d-flex justify-content-between align-items-center">
                                            <h6 className="mb-0 ps-2">Orders</h6>
                                            <AddShippment order={order} getRemainingQuantity={getRemainingQuantity} getOrderByID={getOrderByID} />
                                            {/* <button className="btn btn-sm btn-dark"><i className="mdi mdi-plus" /> Add Delivery</button> */}
                                        </div>
                                        <div className="border-heading-body p-0">
                                            <table className="table table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Image</th>
                                                        <th scope="col">Item</th>
                                                        <th scope="col">Order Quantity</th>
                                                        <th scope="col">Delivered Quantity</th>
                                                        <th scope="col">Quantity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        order && order.orderLineItem.map((item, i) => (
                                                            <React.Fragment key={i}>
                                                                <tr>
                                                                    <th scope="row">{ i + 1 }</th>
                                                                    <th scope="row">
                                                                        {
                                                                            item.product && item.product.productMedia && item.product.productMedia.length > 0 ? (
                                                                                <img src={get_file_full_path(item.product.productMedia[0].file_name)} style={{ height:'50px', width: '50px', borderRadius: '3px' }}/>
                                                                            ) : (
                                                                                <div className="border w-full" style={{ minHeight: '50px', width:'50px', borderRadius: '3px', backgroundColor: '#ededed' }}></div>
                                                                            )
                                                                        }
                                                                    </th>
                                                                    <td>{ item.line_item_title }</td>
                                                                    <td>{ item.quantity }</td>
                                                                    <td>{ getRemainingQuantity(item.id).totalDelivered }</td>
                                                                    <td>{ getRemainingQuantity(item.id).remainingQuantity }</td>
                                                                </tr>
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}