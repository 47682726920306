import React, { useEffect, useMemo, useState } from "react";





import {
    Col,
    Row,
    UncontrolledTooltip,
    Card,
    CardBody,
    Button,
    ModalHeader,
    Modal,
    ModalBody,
    CardTitle,
    UncontrolledAccordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody,
    Table,
    CardHeader,
    Input,
    Label,
    FormFeedback,

} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Breadcrumb from "components/Common/Breadcrumb";
import { getClientList } from "services/ClientManagementServices";
import Select from "react-select";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import img4 from "../../assets/images/product/img-4.png"
import img7 from "../../assets/images/product/img-7.png"
import { getDeductionList, getPaymentAccountMethod, getPaymentMethod } from "services/MasterServices";
import { availableBalance, createPaymentOrder, getOrderList, getPaymentInfoOfClient } from "services/PaymentOrderServices";
import { getVendorList } from "services/VendorServices";
import { generateUniqueNumber, paymentTypeList } from "helpers/helpers";
import { isEmpty } from "lodash";


// const orderList = [
//     {
//         label: "Order-001",
//         value: 500,
//     },
//     {
//         label: "Order-002",
//         value: 400,
//     },
//     {
//         label: "Order-003",
//         value: 700,
//     },
//     {
//         label: "Order-004",
//         value: 800,
//     },
//     {
//         label: "Order-005",
//         value: 900,
//     },
//     {
//         label: "Order-006",
//         value: 500,
//     },
//     {
//         label: "Order-007",
//         value: 400,
//     },
// ]

const PaymentOrderAdd = () => {

    //meta title
    document.title = `Receipt Order - Add |  ${process.env.REACT_APP_SITE_NAME} - Admin Panel`;

    const [clientList, setClientList] = useState([]);
    const [client, setClient] = useState(null)

    const [vendorList, setVendorList] = useState([]);
    const [vendor, setVendor] = useState(null)
    const [paymentMethodList, setPaymentMethodList] = useState([]);
    const [selectPaymentMethod, setSelectPaymentMethod] = useState("")
    const [paymentAccountList, setPaymentAccountList] = useState([]);
    const [selectPaymentAccount, setSelectPaymentAccount] = useState("")
    const [note, setNote] = useState("")
    const [orderList, setOrderList] = useState([])
    const [deductionList, setDeductionList] = useState([])
    const [order, setOrder] = useState([])
    const [advanceDeposit, setAdvanceDeposit] = useState("");
    const [amountReceived, setAmountReceived] = useState("");
    const [amountDeducted, setAmountDeducted] = useState("");
    const [otherDeduction, setOtherDeduction] = useState("");
    const [paymentDeduction, setPaymentDeduction] = useState("");
    const [previousBalance, setPreviousBalance] = useState("");
    const [currentBalance, setCurrentBalance] = useState("");
    const [receiptDate, setReceiptDate] = useState(new Date())
    const [paymentDate, setPaymentDate] = useState(new Date())
    const [paymentType, setPaymentType] = useState('s')
    const [errorMessage, setErrorMessage] = useState(false)
    const [loading, setLoading] = useState(false)

    const [addOrder, setAddOrder] = useState([])
    const navigate = useNavigate()




    useEffect(() => {
        getClientListRecords();
        getPaymentMethodRecord();
        getDeductionListRecords();
        // getPaymentAccountRecord();
        // getVendorListRecords()
        // getOrderListRecords()
    }, [])




    const getClientListRecords = async () => {

        try {
            let params = {
                limit: 200,
                page: 1,
                //   status:"active"
            }
            await getClientList(params).then(response => {
                console.log(response)
                if (response) {
                    const { data } = response;

                    let temp = data?.map(item => ({ ...item, label: `${item.client_name}${item.country ? ' - ' + item.country : ''}`, value: item.id })) ?? []
                    setClientList(temp)
                }
            }).catch(err => console.log(err))
        } catch (error) {

        }

    }
    const getVendorListRecords = async () => {

        try {
            let params = {
                limit: 200,
                page: 1
            }
            await getVendorList(params).then(response => {
                console.log(response)
                if (response) {
                    const { data } = response;

                    let temp = data?.map(item => ({ label: item.vendor_name, value: item.id })) ?? []
                    setVendorList(temp)
                }
            }).catch(err => console.log(err))
        } catch (error) {

        }

    }

    const getDeductionListRecords = async () => {
        let params = {
            status: "active"
        }
        try {

            await getDeductionList({
                params

            }).then(response => {
                console.log(response)
                let arr = response;

                if(arr.find(item=>item.id===1)){
              
                  // Remove the first element and store it
                  const firstElement = arr.shift();
                  
                 // Add the removed element to the end of the array
                  arr.push(firstElement);
    
                }
    
                setDeductionList(arr)
            }).catch(err => console.log(err))

        } catch (error) {

        }

    }

    const handleClientChange = (item) => {
        console.log(item, "item", clientList)
        setClient(item);
        setAddOrder([]);
        setOrderList([]);

        if (item) {
            getOrderListRecords(item?.value)
            getPaymentInfoRecord(item?.value)
        }
    }

    const handleVendorChange = (item) => {
        setVendor(item)
    }

    const handlePaymentDate = ([date]) => {

        setPaymentDate(date)

        console.log(date, "DAte")
    }
    const handleReceiptDate = ([date]) => {
        console.log(date, "DAte")
        setReceiptDate(date)
    }


    const getPaymentMethodRecord = async () => {
        try {

            await getPaymentMethod().then(response => {
                setPaymentMethodList(response)
            }).catch(err => console.log(err))

        } catch (error) {

        }
    }

    const getPaymentAccountRecord = async (payment_method_id) => {
        try {


            let params = {
                limit: 200, page: 1,payment_method_id

            }
            await getPaymentAccountMethod(params).then(response => {
                if (response) {
                    const { data } = response;

                    setPaymentAccountList(data ?? [])
                }
            }).catch(err => console.log(err))

        } catch (error) {
            console.log(error, "error")
        }
    }


    const getOrderListRecords = async (data) => {



        try {

            let payload = {
                client_id: data,
                sort: "order_date",
                sortDirection: "asc",
                // status: 5
                is_paid: 0
            }
            await getOrderList(payload).then(res => {
                console.log(res)

                if (res) {
                    const { data } = res;
                    setOrderList(data)
                }
            })
        } catch (error) {
            console.log(error)
        }

    };


    const getPaymentInfoRecord = async (id) => {
        try {


            await availableBalance(id).then(response => {
                console.log(response)
                setPreviousBalance(response)
            }).catch(err => console.log(err))
            // await getPaymentInfoOfClient(id).then(response => {
            //     console.log(response)
            // }).catch(err => console.log(err))

        } catch (error) {

        }
    }

    const handleOrder = (data) => {
        setOrder(data)
    }

    const sumData = useMemo(() => {
        console.log("Calling memo")
        return order.reduce((accumulator, current) => accumulator + current.value, 0)
    }, [order]);




    const AddOrder = () => {


        if (orderList.length <= addOrder.length) {
            return;
        }

        const newRow = { id: generateUniqueNumber(addOrder), order: "", totalAmount: "", dueAmount: "", total: "" };


        setAddOrder([...addOrder, newRow]);

    }

    const onDeleteAddOrderRow = (id) => {
        const updatedRows = addOrder.filter((row) => row.id !== id);
        setAddOrder(updatedRows);
    };

    console.log("id2--", addOrder)

    const handleInputChange = (id, name, value, due_amount, total_amount) => {
        console.log(id, "id", addOrder, due_amount)
        const updatedRows = addOrder.map(row => {
            if (row.id === id) {
                if (name === "order") {
                    return { ...row, [name]: value, dueAmount: due_amount, totalAmount: total_amount,total:"" };
                } else {

                    return { ...row, [name]: value };
                }
            }
            return row;
        });
        setAddOrder(updatedRows);
    }



    const handleSubmit = async () => {

        console.log(paymentDate,"paymentDate")

        setErrorMessage((true))
        let submitFlag = true;

        if (isEmpty(client) || isEmpty(selectPaymentMethod) || isEmpty(selectPaymentAccount) || isEmpty(paymentDeduction) || (paymentDeduction == 1 && isEmpty(otherDeduction))) {
            submitFlag = false;

        }



        if (submitFlag) {
            try {

             
                let payload = {
                    client_id: client?.id ?? '',
                    payment_date:moment(paymentDate).format("YYYY-MM-DD") ??'',
                    payment_method_id: Number(selectPaymentMethod),
                    payment_method_account_id: Number(selectPaymentAccount),
                    amount: Number(amountReceived),
                    deduction_reason_id: Number(paymentDeduction),
                    deducted_amount: Number(amountDeducted),
                    payment_type: paymentType,
                    status: 1,


                }
                if (paymentDeduction == 1) {
                    payload['other_reason'] = otherDeduction;
                }


                if (addOrder.length > 0) {

                    let tempArr = addOrder.map(function (item) {

                        if (item?.order === "") {
                            return null;
                        }
                        if (item?.total === "") {
                            return null;
                        }

                        delete item.id;

                        return { order_id: Number( item.order), amount:Number( item.total) };
                    }).filter(a => a);
                    if (tempArr.length > 0) {
                        payload['paymentDetails'] = tempArr
                    } else {
                        toast.error("Please fill order properly.");
                        return;
                    }
                }else {
                    toast.error("Please add order.")
                    return;
                }


            

                    let tempAmount = 0;
                    addOrder.forEach(element=>{  
                        if(element.total && element.total!=="" && element.total!==0 ){
                        tempAmount +=  Number(element.total)
                        }
                    })

                    // current balance>=((previoius balance+amount)-(deducted balance+ sum of all product amount))

                    if( currentBalance >=   (previousBalance+Number(amountReceived)) - ( Number(amountDeducted) + tempAmount)){
                        toast.error("Your received amount or deducted amount or given order amount(s) is wrong.")
                        return;

                }

                setLoading(true)


                await createPaymentOrder(payload).then(response => {
                    console.log("Response", response);


                    toast.success("Receipt Order is added successfully.")
                    setTimeout(() => {
                        setLoading(false)
                        navigate("/receipt-order")
                        
                    }, 2000);


                }).catch(err => {
                    console.log(err?.response);

                    if (err?.status === 400) {
                        toast.error("Something went wrong.", { autoClose: 2000 });
                        setLoading(false)

                    } else {
                        toast.error(`Receipt Order is added Failed`, { autoClose: 2000 });
                        setLoading(false)

                    }


                })

            } catch (error) {
                setLoading(false)
                console.log(error)
            }
        }

    }


    const getCurrentBalance = useMemo(() => {
        console.log(previousBalance,amountReceived,amountDeducted)
        let calculation =  previousBalance+Number(amountReceived) - Number(amountDeducted);
        return calculation< 0 ? 0 :calculation;
      }, [previousBalance,amountDeducted,amountReceived])
      

const getAdvanceBalance = useMemo(()=>{
    let tempAmount = 0;
    addOrder.forEach(element=>{

        console.log(element,"element")
        if(element.total && element.total!=="" && element.total!==0 ){
            
        console.log( element.total,"element1")
tempAmount+=  Number(element.total)
        }
    })
    console.log(tempAmount,"acc",isNaN( tempAmount))
    let calculation =  Number(amountReceived) - Number(amountDeducted) - tempAmount;
    console.log(calculation,"cal", Number(amountReceived) - Number(amountDeducted))
    return calculation< 0 ? 0 :calculation;
},[amountDeducted,amountReceived,addOrder])


const handlePaymentMethod = async (e) => {
    setSelectPaymentMethod(e.target.value);
    setPaymentAccountList([]);
    setSelectPaymentAccount("");

    if(e.target.value !== ""){
        await getPaymentAccountRecord(e.target.value)
    }
}


    return (
        <React.Fragment>

            <div className="page-content">
                <div className="container">
                    <Breadcrumb title="Account" to={`/receipt-order`} breadcrumbItem="Add Receipt Order" />
                    <Card>
                        <Row>
                            <Col lg="12">
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">Add Receipt Order</h5>
                                        <div className="flex-shrink-0">
                                        <Link to="/receipt-order" className="btn btn-primary me-1">Back</Link>
                                        </div>

                                    </div>
                                </CardBody>



                            </Col>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="border-bottom">

                                        <Row className="mb-3">
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label className="control-label">
                                                        Please Choose Client <span className="text-danger">*</span>
                                                    </label>
                                                    <Select
                                                        value={client}
                                                        // isMulti={true}
                                                        placeholder={"Please choose Client"}
                                                        onChange={
                                                            handleClientChange
                                                        }
                                                        options={clientList}
                                                        className={`select2-selection ${errorMessage && isEmpty(client) ? "border border-danger rounded" : ""}`}

                                                    />


                                                    {errorMessage && isEmpty(client) ? (
                                                        <span className="text-danger" style={{fontSize:"80%"}}>  Please choose Client </span>
                                                    ) : null}
                                                </div>

                                            </Col>
                                            <Col md={6} />



                                     

                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label className="control-label">
                                                        Receipt Date
                                                    </label>
                                                    <Flatpickr
                                                        className="form-control d-block"
                                                        placeholder="DD-MM-YYYY"
                                                        value={receiptDate}
                                                        onChange={handleReceiptDate}
                                                        options={{
                                                            altInput: true,
                                                            dateFormat: "YYYY-MM-DD",
                                                            altFormat: "d-m-Y",
                                                            allowInput: true,
                                                        }}
                                                    />
                                                </div>

                                            </Col>


                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label className="control-label">
                                                        Payment Method <span className="text-danger">*</span>
                                                    </label>
                                                    <Input type="select" name="payment_method" value={selectPaymentMethod} onChange={handlePaymentMethod}

                                                        invalid={errorMessage && selectPaymentMethod === ""}
                                                    >
                                                        <option value="">Select</option>
                                                        {
                                                            paymentMethodList?.map((item, index) => <option value={item.id} key={`payment-methos-${index}`}>{item?.method_name}</option>)
                                                        }
                                                    </Input>

                                                    {
                                                        errorMessage && selectPaymentMethod === "" && <FormFeedback type="invalid">
                                                            Please Choose Payment Method
                                                        </FormFeedback>
                                                    }
                                                </div>

                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label className="control-label">
                                                        Payment Account <span className="text-danger">*</span>
                                                    </label>
                                                    <Input type="select" name="payment_method" value={selectPaymentAccount} onChange={e => setSelectPaymentAccount(e.target.value)} invalid={errorMessage && selectPaymentAccount === ""}>
                                                        <option value="">Select</option>
                                                        {
                                                            paymentAccountList?.map((item, index) => <option value={item.id} key={`payment-account-${index}`}>{item?.account_name}</option>)
                                                        }
                                                    </Input>

                                                    {
                                                        errorMessage && selectPaymentAccount === "" && <FormFeedback type="invalid">
                                                            Please Choose Payment Account
                                                        </FormFeedback>
                                                    }
                                                </div>

                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label className="control-label">
                                                       Entry Date
                                                    </label>
                                                    <Flatpickr
                                                        className="form-control d-block"
                                                        placeholder="DD-MM-YYYY"
                                                        value={paymentDate}
                                                        onChange={handlePaymentDate}
                                                        options={{
                                                            altInput: true,
                                                            dateFormat: "YYYY-MM-DD",
                                                            altFormat: "d-m-Y",
                                                            allowInput: true,
                                                        }}
                                                    />
                                                </div>

                                            </Col>
                                            {/* <Col md={6}>
                                                <div className="mb-3">
                                                    <label className="control-label">
                                                        Payment Type
                                                    </label>
                                                    <Input type="select" name="payment_method" value={paymentType} onChange={e => setPaymentType(e.target.value)}>
                                                      
                                                        {
                                                            paymentTypeList?.map((item, index) => <option value={item.value} key={`payment-type-${index}`}>{item?.label}</option>)
                                                        }
                                                    </Input>
                                                </div>

                                            </Col> */}
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label>Amount Received</Label>
                                                    <Input type="text" onChange={(e) => (/^\d*(\.\d{0,2})?$/.test(e.target.value) || e.target.value === "") && setAmountReceived(e.target.value)} value={amountReceived} />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label>Amount Deducted</Label>
                                                    <Input type="text" onChange={(e) => (/^\d*(\.\d{0,2})?$/.test(e.target.value) || e.target.value === "") && setAmountDeducted(e.target.value)} value={amountDeducted} />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label className="control-label">
                                                        Reason of deduction <span className="text-danger">*</span>
                                                    </label>
                                                    <Input type="select" name="payment_method" value={paymentDeduction} onChange={(e) => {
                                                        setPaymentDeduction(e.target.value)
                                                        if (e.target.value !== 1) {
                                                            setOtherDeduction("")
                                                        }
                                                    }}

                                                        invalid={errorMessage && paymentDeduction === ""}

                                                    >
                                                        <option value={""} >{`Select`}</option>

                                                        {
                                                            deductionList?.map((item, index) => <option value={item.id} key={`payment-methos-${index}`}>{item?.reason_title}</option>)
                                                        }
                                                        {/* <option value={"other"} >{`Other`}</option> */}
                                                    </Input>
                                                    {
                                                        errorMessage && paymentDeduction === "" && <FormFeedback type="invalid">
                                                            Please Choose Reason For Deduction
                                                        </FormFeedback>
                                                    }
                                                </div>

                                            </Col>
                                            <Col md={6}>
                                                {
                                                    paymentDeduction == 1 &&
                                                    <div className="mb-3">
                                                        <label className="control-label">
                                                            Other  Reason of deduction <span className="text-danger">*</span>
                                                        </label>
                                                        <Input type="textarea" value={otherDeduction} rows={2} onChange={(e) => setOtherDeduction(e.target.value)} invalid={  errorMessage && otherDeduction === ""}/>

                                                        {
                                                            errorMessage && otherDeduction === "" && <FormFeedback type="invalid">
                                                                Please fill other reason for deduction.
                                                            </FormFeedback>
                                                        }
                                                    </div>
                                                }
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label>Previous Balance</Label>
                                                    <Input type="text" value={previousBalance} readOnly />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label>Current Balance</Label>
                                                    <Input type="text" value={getCurrentBalance} readOnly />
                                                </div>
                                            </Col>
                                            {/* <Col md={6}>
                                            <div className="mb-3">
                                                <label className="control-label">
                                                    Note
                                                </label>
                                                <Input type="textarea" value={note} rows={4} onChange={(e) => setNote(e.target.value)} />
                                            </div>
                                        </Col> */}
                                        </Row>
                                   

                                    </CardBody>


                                    <CardBody >


                                    {
                                            // orderList.length >0 && 
                                            <Row className="my-4">
                                                <Col md={12}>

                                                    <div className="text-end">

                                                        <Button onClick={AddOrder} color="primary" className="same-btn" disabled={orderList.length === 0 || orderList.length === addOrder.length}>Add  Order
                                                            <i className="mdi mdi-plus me-1"></i>
                                                        </Button>

                                                    </div>



                                                </Col>
                                            </Row>
                                        }
                                                
                                     </CardBody>

                                    {
                                        addOrder.length > 0 &&
                                        <CardBody>
                                            <h5 className="mb-3">Order</h5>

                                            <div className="table-responsive">
                                                <Table className="table align-middle table-nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Sl. No.</th>
                                                            <th scope="col" colSpan={"2"}>Order Id</th>
                                                            <th scope="col">Order Amount</th>
                                                            <th scope="col">Due Amount</th>
                                                            <th scope="col"> Amount</th>
                                                            <th scope="col"> Delete</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody> {console.log(orderList, "order", addOrder, "----",

                                                    )}
                                                        {
                                                            addOrder.length > 0 ? <>
                                                                {

                                                                    addOrder?.map((item, index) => <tr key={`item-${index}`}>
                                                                        <th scope="row">
                                                                            <div>
                                                                                {index + 1}.
                                                                            </div>
                                                                        </th>
                                                                        <td colSpan={"2"}>
                                                                            <div>



                                                                                <Input type="select"

                                                                                    value={item.order}
                                                                                    onChange={(e) => handleInputChange(item.id, 'order', e.target.value, e.target.selectedOptions[0].getAttribute('data-due-amount'), e.target.selectedOptions[0].getAttribute('data-net-order-amount'))}
                                                                                >
                                                                                    <option value="" data-net-order-amount={0} data-due-amount={0} >Select</option>
                                                                                    {
                                                                                        orderList.filter(item1 => {
                                                                                            return !addOrder.some(item2 => item.id !== item2.id && item1.id == item2.order);
                                                                                        }).map((item, index) => <option value={item?.id} data-net-order-amount={item?.net_order_amount} data-due-amount={item?.due_amount} key={`oo-${index}`}>{item?.order_no}</option>)
                                                                                    }
                                                                                </Input>

                                                                            </div>
                                                                        </td>
                                                                        <td>

                                                                            <Input
                                                                                type="text"
                                                                                id="totalAmount"
                                                                                name={`totalAmount-${item.id}`}
                                                                                value={item.totalAmount}
                                                                                // onChange={(e) => handleInputChange(item.id, 'totalAmount', e.target.value)}
                                                                                className="form-control"

                                                                                readOnly
                                                                            />

                                                                        </td>
                                                                        <td>             <Input
                                                                            type="text"
                                                                            id="dueAmount"
                                                                            name={`dueAmount-${item.id}`}
                                                                            value={item.dueAmount}
                                                                            // onChange={(e) => handleInputChange(item.id, 'dueAmount', e.target.value)}
                                                                            className="form-control"

                                                                            readOnly
                                                                        /></td>
                                                                        <td>             <Input
                                                                            type="text"
                                                                            id="total"
                                                                            name={`total-${item.id}`}
                                                                            value={item.total}
                                                                            readOnly={item.order===""}
                                                                            onChange={(e) => ((/^\d*(\.\d{0,2})?$/.test(e.target.value) && e.target.value<= Number(item.dueAmount )) || e.target.value === "" )
                                                                                && handleInputChange(item.id, 'total', e.target.value)}
                                                                            className="form-control"
                                                                            placeholder="Enter Amount"

                                                                        /></td>
                                                                        <td>  <div className="d-grid">
                                                                            <Button type="button"
                                                                                color="primary"
                                                                                onClick={() => onDeleteAddOrderRow(item.id)}
                                                                            >

                                                                                <span className="mdi mdi-close"></span>

                                                                            </Button>
                                                                        </div></td>
                                                                    </tr>)
                                                                }





                                                            </>
                                                                :

                                                                <tr>
                                                                    <td colSpan="5">
                                                                        <h6 className="m-0 text-center">No order chose</h6>
                                                                    </td>

                                                                </tr>


                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>

                                        </CardBody>
                                    }


                                    <CardBody>

                                        <Row>
                                            <Col lg={12}>
                                                <Row>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label>Advance Deposit</Label>
                                                            <Input type="text" 
                                                            // onChange={(e) => (/^\d*(\.\d{0,2})?$/.test(e.target.value) || e.target.value === "") && setAdvanceDeposit(e.target.value)} value={advanceDeposit} 
                                                            readOnly
                                                            value={getAdvanceBalance}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col lg={12}>
                                                <div className="text-end mb-4">
                                                    <Button type="submit" color="primary" className="same-btn" disabled={loading} onClick={handleSubmit}>Submit <span className="mdi mdi-content-save-all"></span></Button>
                                                </div>
                                            </Col>

                                        </Row>
                                    </CardBody>


                                </Card>
                            </Col>




                        </Row>


                    </Card>


                </div>
            </div>


            <ToastContainer />
        </React.Fragment>
    );
}


export default PaymentOrderAdd;