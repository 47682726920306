import React, { useEffect, useMemo, useState } from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"

import {
  getCategory as onGetCategoryList,
  // addNewJobList as onAddNewJobList,
  addNewCategoryList as onAddNewCategoryList,
  updateCategoryList as onUpdateCategoryList,
  deleteCategoryList as onDeleteCategoryList,
} from "store/actions"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Button,
  FormGroup,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
import { toast, ToastContainer } from "react-toastify"
import { Link } from "react-router-dom"
import {
  createCategory,
  deleteCategory,
  getCategoryDropdown,
  updateCategory,
} from "services/MasterServices"

const SubCategory = () => {
  //meta title
  document.title = `Subcategory List |  ${process.env.REACT_APP_SITE_NAME} - Admin Portal`

  const [modal, setModal] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [category, setCategory] = useState(null)
  const [categoryDropdownList, setCategoryDropdownList] = useState([])
  const [subCategoryList, setSubCategoryList] = useState([])
  const [searchQuery, setSearchQuery] = useState("") // New state for search input

  // validation
  const validationCreate = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      category_id: "",
      subcategory_name: "",
      status: true,
    },
    validationSchema: Yup.object({
      category_id: Yup.string().required("Please Choose Category."),
      subcategory_name: Yup.string()
        .trim()
        .required("Please fill Subcategory Name."),
    }),
    onSubmit: async values => {
      const payload = {
        parent_id: Number(values["category_id"]),
        category_name: values["subcategory_name"].trim(),
        status: values["status"] ? 1 : 0,
      }
      // save new SubCategory

      try {
        await createCategory(payload)
          .then(response => {
            console.log(response, "response")
            toast.success(`Subcategory is Added Successfully`, {
              autoClose: 2000,
            })
            validationCreate.resetForm()
            getSubCategoryList()
            toggle()
          })
          .catch(err => {
            console.log(err)
            if (err?.status === 400 && err?.response?.data?.message) {
              toast.error(err?.response?.data?.message, { autoClose: 2000 })
            } else {
              toast.error(`Subcategory is Added Failed`, { autoClose: 2000 })
            }
          })
      } catch (error) {
        toast.error(`Subcategory is Added Failed`, { autoClose: 2000 })
      }
    },
  })

  const validationEdit = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (category && category.id) || "",
      category_id: category?.parent_id,
      subcategory_name: (category && category.category_name) || "",
      status: category && category.status === 1 ? true : false,
    },
    validationSchema: Yup.object({
      category_id: Yup.string().required("Please Choose Category."),
      subcategory_name: Yup.string()
        .trim()
        .required("Please fill Subcategory Name"),
    }),
    onSubmit: async values => {
      const payload = {
        id: values["id"],
        parent_id: Number(values["category_id"]),
        category_name: values["subcategory_name"].trim(),
        status: values["status"] ? 1 : 0,
      }
      // update Job

      try {
        await updateCategory(values["id"], payload)
          .then(response => {
            console.log(response, "response")
            toast.success(`Subcategory is updated Successfully`, {
              autoClose: 2000,
            })
            validationEdit.resetForm()
            getSubCategoryList()
            toggleEdit()
          })
          .catch(err => {
            console.log(err)
            if (err?.status === 400 && err?.response?.data?.message) {
              toast.error(err?.response?.data?.message, { autoClose: 2000 })
            } else {
              toast.error(`Subcategory is updated Failed`, { autoClose: 2000 })
            }
          })
      } catch (error) {
        toast.error(`Subcategory is updated Failed`, { autoClose: 2000 })
      }
    },
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getSubCategoryList()
    getCategoryList()
  }, [])

  const getSubCategoryList = async () => {
    try {
      let params = {
        parent_type: "subcategory",
        // status: "active"
      }

      setLoading(true)

      await getCategoryDropdown({ params })
        .then(response => {
          console.log(response)

          if (response) {
            setSubCategoryList(response)
            setLoading(false)
          }
        })
        .catch(err => {
          console.log(err)

          setLoading(false)
        })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const getCategoryList = async () => {
    let params = {
      status: "active",
      parent_type: "parent",
      limit: 100,
    }

    try {
      await getCategoryDropdown({ params })
        .then(response => {
          console.log(response)

          setCategoryDropdownList(response)
        })
        .catch(err => console.log(err))
    } catch (error) {
      console.log(error)
    }
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setCategory(null)
    } else {
      setModal(true)
    }
  }

  const toggleEdit = () => {
    if (modalEdit) {
      setModalEdit(false)
      setCategory(null)
      validationEdit.resetForm()
    } else {
      setModalEdit(true)
    }
  }

  const handleEditClick = arg => {
    const category = arg
    console.log(category, "category")
    setCategory(category)

    setIsEdit(true)

    toggleEdit()
  }

  //delete Job
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = category => {
    setCategory(category)
    setDeleteModal(true)
  }

  const handleDeleteCategory = async () => {
    if (category && category.id) {
      try {
        await deleteCategory(category.id)
          .then(response => {
            getSubCategoryList()
            setDeleteModal(false)
            toast.success(`Subcategory is Deleted Successfully`, {
              autoClose: 2000,
            })
          })
          .catch(err => {
            console.log(err)

            toast.error(`Subcategory is Deleted Failed`, { autoClose: 2000 })
          })
      } catch (error) {
        toast.error(`Subcategory is Deleted Failed`, { autoClose: 2000 })
      }
    }
  }
  const filteredSubCategoryList = subCategoryList.filter(m => {
    const searchLower = searchQuery.toLowerCase() // Convert the search query to lowercase

    return (
      m.parent.category_name.toLowerCase().includes(searchLower) || // Search in parent category name
      m.category_name.toLowerCase().includes(searchLower) // Search in category name
    )
  })
  const columns = useMemo(
    () => [
      {
        header: "Sl.No",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          return (
            <Link to="#" className="text-body fw-bold">
              {cellProps.row.index + 1}
            </Link>
          )
        },
      },
      {
        header: "Category Name",
        accessorKey: "parent.category_name",
        enableColumnFilter: false,
        // filterFn: 'fuzzy',
        filterFn: "includesString",
        enableSorting: false,
      },
      {
        header: "Subcategory Name",
        accessorKey: "category_name",
        filterFn: "includesString",
        enableColumnFilter: false,
        enableSorting: false,
      },

      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          switch (cellProps.row.original.status) {
            case 1:
              return <span className="badge badge-soft-success">Active</span>
            case 0:
              return <span className="badge badge-soft-danger">Inactive</span>
          }
        },
      },
      {
        header: () => <div style={{ textAlign: "right" }}>Action</div>,
        id: "subcategory-action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    handleEditClick(jobData)
                  }}
                  id={`edittooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    onClickDelete(jobData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={() => setDeleteModal(false)}
        mainHeader="Are you sure you want to delete this subcategory?"
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Master" breadcrumbItem="Subcategory List" />
          {
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                      <h5 className="mb-0 card-title flex-grow-1">
                        Subcategory List
                      </h5>
                      <div className="flex-shrink-0">
                        <Link
                          to="#!"
                          onClick={() => setModal(true)}
                          className="btn btn-primary me-1"
                        >
                          Add
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <Row className="mb-2">
                      <Col sm={4}>
                        <Input
                          type="text"
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={e => setSearchQuery(e.target.value)}
                          className="form-control search-box me-2 mb-2 d-inline-block"
                        />
                      </Col>
                    </Row>

                    {filteredSubCategoryList.length > 0 ? (
                      <TableContainer
                        columns={columns}
                        data={filteredSubCategoryList || []}
                        // isCustomPageSize={true}
                        //   isGlobalFilter={true}
                        // isJobListGlobalFilter={true}
                        loading={loading}
                        setLoading={setLoading}
                        isPagination={true}
                        //   SearchPlaceholder="Keyword Search"
                        tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline border-top"
                        pagination="pagination"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                      />
                    ) : (
                      <div className="text-center mt-3">
                        <p>No matching records found.</p>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Add Subcategory
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validationCreate.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>
                        Choose Category <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="category_id"
                        type="select"
                        className="form-select"
                        onChange={validationCreate.handleChange}
                        onBlur={validationCreate.handleBlur}
                        value={validationCreate.values.category_id || ""}
                        invalid={
                          validationCreate.touched.category_id &&
                          validationCreate.errors.category_id
                            ? true
                            : false
                        }
                      >
                        <option value="">Select</option>

                        {categoryDropdownList?.map((item, key) => (
                          <option key={`dropdown-${key}`} value={item?.id}>
                            {item?.category_name}
                          </option>
                        ))}
                      </Input>
                      {validationCreate.touched.category_id &&
                      validationCreate.errors.category_id ? (
                        <FormFeedback type="invalid">
                          {validationCreate.errors.category_id}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>
                        {" "}
                        Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="subcategory_name"
                        type="text"
                        placeholder="Enter name."
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validationCreate.handleChange}
                        onBlur={validationCreate.handleBlur}
                        value={validationCreate.values.subcategory_name || ""}
                        invalid={
                          validationCreate.touched.subcategory_name &&
                          validationCreate.errors.subcategory_name
                            ? true
                            : false
                        }
                      />
                      {validationCreate.touched.subcategory_name &&
                      validationCreate.errors.subcategory_name ? (
                        <FormFeedback type="invalid">
                          {validationCreate.errors.subcategory_name}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label>Status</Label>
                      <FormGroup switch>
                        <Label className="">
                          {/* {validationCreate.values.status === true?'Active':'Inactive'} */}
                          <Input
                            name="status"
                            type="switch"
                            role="switch"
                            checked={validationCreate.values.status === true}
                            onClick={validationCreate.handleChange}
                            onBlur={validationCreate.handleBlur}
                            value={validationCreate.values.status || true}
                          />
                        </Label>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="primary"
                        type="submit"
                        className="save-user"
                      >
                        Submit
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal isOpen={modalEdit} toggle={toggleEdit}>
            <ModalHeader toggle={toggleEdit} tag="h4">
              Edit SubCategory
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validationEdit.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>
                        Choose Category <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="category_id"
                        type="select"
                        className="form-select"
                        onChange={validationEdit.handleChange}
                        onBlur={validationCreate.handleBlur}
                        value={validationEdit.values.category_id}
                        invalid={
                          validationEdit.touched.category_id &&
                          validationEdit.errors.category_id
                            ? true
                            : false
                        }
                      >
                        <option value="">Select</option>

                        {categoryDropdownList?.map((item, key) => (
                          <option key={`dropdown-${key}`} value={item?.id}>
                            {item?.category_name}
                          </option>
                        ))}
                      </Input>
                      {validationEdit.touched.category_id &&
                      validationEdit.errors.category_id ? (
                        <FormFeedback type="invalid">
                          {validationEdit.errors.category_id}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>
                        {" "}
                        Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="subcategory_name"
                        type="text"
                        placeholder="Enter name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validationEdit.handleChange}
                        onBlur={validationEdit.handleBlur}
                        value={validationEdit.values.subcategory_name || ""}
                        invalid={
                          validationEdit.touched.subcategory_name &&
                          validationEdit.errors.subcategory_name
                            ? true
                            : false
                        }
                      />
                      {validationEdit.touched.subcategory_name &&
                      validationEdit.errors.subcategory_name ? (
                        <FormFeedback type="invalid">
                          {validationEdit.errors.subcategory_name}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label>Status</Label>
                      <FormGroup switch>
                        <Label className="">
                          {/* {validationEdit.values.status?'Active':'Inactive'} */}

                          <Input
                            name="status"
                            type="switch"
                            role="switch"
                            checked={validationEdit.values.status === true}
                            onClick={validationEdit.handleChange}
                            onBlur={validationEdit.handleBlur}
                            value={validationEdit.values.status || true}
                          />
                        </Label>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="primary"
                        type="submit"
                        className="save-user"
                      >
                        Update
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default SubCategory
