import { del, get, patch, post } from "helpers/api_helper";

export const getVendorList = async(params) => await get('/vendor',{params});

export const getSingleVendor = async(id) => await get(`/vendor/${id}`);

export const createVendor = (payload)  => post('/vendor',payload);

export const updateVendor = async (id,payload) => await patch(`/vendor/${id}`,payload);

export const deleteVendor = async (id) =>await del(`/vendor/${id}`);