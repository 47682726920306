import { del, get, patch, post } from "helpers/api_helper";

export const getProductList = async(params) => await get('/product',{params});

export const getSingleProduct = async(id) => await get(`/product/${id}`);

export const createProduct = (payload)  => post('/product',payload);

export const updateProduct = async (id,payload) => await patch(`/product/${id}`,payload);

export const deleteProduct = async (id) =>await del(`/product/${id}`);


// Product Media list

export const getProductMediaList = async(params) => await get('/product-media',{params});

export const createMediaProduct = async (data,headers) => await post('/product-media',data,{headers});

export const updateMediaProduct = async (id,payload,headers) => await patch(`/product-media/${id}`,payload,{headers});

export const deleteProductMedia = async (id) =>await del(`/product-media/${id}`);

