const { get, post, del, patch } = require("./api_helper");
import * as url from "./url_helper";

 // Login Method
 const postLogin = data => post(url.POST_LOGIN, data);

 // Category
 const getCategory = () => get(`${url.GET_CATEGORY}`);

 const getSingleCategory = (id) => get(`${url.GET_CATEGORY}/${id}`)

 const addNewCategory = (payload) => post(url.GET_CATEGORY,payload)

 const updateCategory = (payload) => patch(`${url.GET_CATEGORY}/${payload.id}`,payload)

 const deleteCategoryList = (id) => del(`${url.GET_CATEGORY}/${id}`);

 //Vendor Type

 const getVendorType = ()  => get(url.VENDOR_TYPE);

 const updateVendorType = ({id,data})=> patch(`${url.VENDOR_TYPE}/${id}`,data)


 //Payment Term

 const getPaymentTerm = () => get(url.PAYMENT_TERM);
 const addNewPaymentTerm = (payload) => post(url.PAYMENT_TERM,payload);
 const updatePaymentTerm =  ({id,data}) => patch(`${url.PAYMENT_TERM}/${id}`,data)
 const deletePaymentTermList =  (id) => del(`${url.PAYMENT_TERM}/${id}`);

 //Payment Method

 const getPaymentMethod = () => get(url.PAYMENT_METHOD);
 const addNewPaymentMethod = (payload) => post(url.PAYMENT_METHOD,payload);
 const updatePaymentMethod =  ({id,data}) => patch(`${url.PAYMENT_METHOD}/${id}`,data)
 const deletePaymentMethodList =  (id) => del(`${url.PAYMENT_METHOD}/${id}`);
 

 export { 
          postLogin,
          getCategory,
          getSingleCategory,
          addNewCategory,
          updateCategory,
          deleteCategoryList,
          getVendorType,
          updateVendorType,
          getPaymentTerm,
          addNewPaymentTerm,
          updatePaymentTerm,
          deletePaymentTermList,
          getPaymentMethod,
          addNewPaymentMethod,
          updatePaymentMethod,
          deletePaymentMethodList
        };
 