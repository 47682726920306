import React, { useEffect, useMemo, useState } from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import { Link, useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Col, Row, Card, CardBody, Button, Modal, Label } from "reactstrap"
import DeleteModal from "../../../components/Common/DeleteModal"
import {
  getSingleUser,
  getUsers,
  singleUserDelete,
} from "services/UserServices"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const User = () => {
  document.title = `User List | ${process.env.REACT_APP_SITE_NAME} - Admin Panel`
  const [viewSingleUser, setViewSingleUser] = useState([])
  const [users, setUsers] = useState([]) // Initialize with an empty array
  const [filteredUsers, setFilteredUsers] = useState([]) // State for filtered users
  const [deleteModal, setDeleteModal] = useState(false)
  const [user, setUser] = useState(null)
  const [search, setSearch] = useState("")
  const navigate = useNavigate()
  const [modal_center, setModalCenter] = useState(false) // Modal state
  const [selectedUser, setSelectedUser] = useState(null) // Selected user state
  const [loading, setLoading] = useState()
  const [load, setLoad] = useState()

  const onClickDelete = id => {
    console.log("Data delete id-", id)
    setUser(id)
    setDeleteModal(true)
  }

  const handleDeleteUser = async () => {
    console.log("Delete Now-", user)
    if (user && user.id) {
      try {
        const response = await singleUserDelete(user.id) // Pass the user ID
        // alert("Deleted")
        console.log("User deleted successfully:", response)

        toast.success("User Deleted successfully!")

        setDeleteModal(false)
        setUser(null)
        fetchAllUsers()
      } catch (error) {
        console.error("Error deleting user:", error.message)
        toast.error("Failed to delete user.") // Notify error
      }
    }
  }

  const handleSearch = () => {
    const searchTerm = search.trim().toLowerCase()

    setFilteredUsers(
      !searchTerm
        ? users // Reset if search is empty
        : users.filter(
            user =>
              `${user.first_name || ""} ${user.middle_name || ""} ${
                user.last_name || ""
              }`
                .toLowerCase()
                .includes(searchTerm) ||
              (user.email || "").toLowerCase().includes(searchTerm) ||
              (user.primary_ph_no || "").includes(searchTerm) ||
              (user.whatsapp_no || "").includes(searchTerm) || // Handle null/undefined whatsapp_no
              (user.gender || "").toLowerCase().includes(searchTerm) ||
              (user.address || "").toLowerCase().includes(searchTerm)
          )
    )
  }
  // const handleSearch = () => {
  //   if (search.trim() === "") {
  //     setFilteredUsers(users) // Reset to original user list if search is empty
  //   } else {
  //     const searchTerm = search.toLowerCase()
  //     const filtered = users.filter(
  //       user =>
  //         `${user.first_name} ${user.middle_name} ${user.last_name}`
  //           .toLowerCase()
  //           .includes(searchTerm) ||
  //         user.email.toLowerCase().includes(searchTerm) ||
  //         user.primary_ph_no.includes(searchTerm) ||
  //         (user.whatsapp_no && user.whatsapp_no.includes(searchTerm)) || // Check if whatsapp_no is not null before searching
  //         user.gender.toLowerCase().includes(searchTerm) ||
  //         user.address.toLowerCase().includes(searchTerm)
  //     )
  //     setFilteredUsers(filtered)
  //   }
  // }
  useEffect(() => {
    handleSearch()
  }, [search, users])
  const handleModal = data => {
    setModal(true)
    // setSelectedItem(data)
  }
  const handleView = user => {
    console.log("View Clicked --", user)
    setSelectedUser(user) // Set selected user for viewing
    setModalCenter(true) // Open modal
    fetchSingleUserData(user)
  }

  const tog_center = () => {
    setModalCenter(!modal_center) // Toggle modal state
  }

  const closeModal = () => {
    setModalCenter(false) // Close modal
    setSelectedUser(null) // Clear selected user after closing modal
    setViewSingleUser([])
  }

  const fetchSingleUserData = async user => {
    console.log("User if fetchsingleuser", user)
    setLoad(true)
    try {
      await getSingleUser(user)
        .then(response => {
          console.log("Response Data Single User:", response)
          setViewSingleUser(response)
          setLoad(false)
        })
        .catch(err => {
          console.log(err)
          setLoad(false)
        })
    } catch (error) {
      console.error("Error fetching user data:", error.message)
      setLoad(false)
    }
  }
  const columns = useMemo(
    () => [
      {
        header: "Sl.No.",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => (
          <Link to="#" className="text-body fw-bold">
            {cellProps.row.index + 1} {/* Adjusted for 1-based index */}
          </Link>
        ),
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          const { first_name, middle_name, last_name } = row.original
          return `${first_name} ${middle_name} ${last_name}`.trim() // Merge first and middle name
        },
      },
      {
        header: "Email",
        accessorKey: "email",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "User Type",
        accessorKey: "user-type",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          const usertype = row.original.user_type // Get user status
          return (
            <span
              className={`badge ${
                usertype === 1 ? "badge-soft-secondary" : "badge-soft-secondary"
              }`}
            >
              {usertype === 1 ? "Admin" : "Sales-Person"}
            </span>
          )
        },
      },
      {
        header: "Gender",
        accessorKey: "gender",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          const gender = row.original.gender
          return gender
            ? `${gender.charAt(0).toUpperCase()}${gender.slice(1)}`
            : "" // Capitalize first letter
        },
      },
      // {
      //   header: "Address",
      //   accessorKey: "address",
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   cell: ({ row }) => {
      //     const address = row.original.address
      //     return (
      //       <span
      //         title={address} // Tooltip for full address
      //         style={{
      //           whiteSpace: "nowrap",
      //           overflow: "hidden",
      //           textOverflow: "ellipsis",
      //         }}
      //       >
      //         {address.length > 25 ? `${address.substring(0, 25)}...` : address}
      //       </span>
      //     )
      //   },
      // },
      {
        header: "Whatsapp No", // Updated header
        accessorKey: "whatsapp_no",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          const whatsappNo = row.original.whatsapp_no
          return whatsappNo ? whatsappNo : "" // Show WhatsApp number or N/A if not available
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          const status = row.original.status // Get user status
          return (
            <span
              className={`badge ${
                status === 1 ? "badge-soft-success" : "badge-soft-danger"
              }`}
            >
              {status === 1 ? "Active" : "In-Active"}
            </span>
          )
        },
      },
      {
        header: () => <div style={{ textAlign: "right" }}>Action</div>,
        id: "user-action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => (
          <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">
            {/* <li>
              <Link
                // to={`/manage-user/view`}
                className="btn btn-sm btn-soft-primary"
                state={{ userId: cellProps.row.original.id }} // Pass userId in the state
                onClick={() => {
                  console.log("View User ID:", cellProps.row.original.id)
                  handleView(cellProps.row.original.id)
                  //   handleModal(cellProps.row.original);
                }}
              >
                <i className="mdi mdi-eye-outline" />
              </Link>
            </li> */}
            <li>
              <Link
                to={`/manage-user/edit`} // Navigate to edit user route without userId in the URL
                state={{ userId: cellProps.row.original.id }} // Pass userId in the state
                className="btn btn-sm btn-soft-info"
                onClick={() => {
                  console.log("Edit User ID:", cellProps.row.original.id) // Log user ID for debugging
                }}
              >
                <i className="mdi mdi-pencil-outline" /> {/* Edit icon */}
              </Link>
            </li>

            <li>
              <Link
                className="btn btn-sm btn-soft-danger"
                onClick={() => {
                  console.log("Delete User ID:", cellProps.row.original.id)
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete-outline" />
              </Link>
            </li>
          </ul>
        ),
      },
    ],
    []
  )

  useEffect(() => {
    document.title = `Manage-User | ${process.env.REACT_APP_SITE_NAME} Admin Panel`
    fetchAllUsers()
  }, [navigate])

  const fetchAllUsers = async () => {
    setLoading(true) // Start loading

    try {
      const response = await getUsers()
      console.log("Data Fetched Successfully", response.data)
      setUsers(response.data) // Store fetched users in state
      setFilteredUsers(response.data) // Set filtered users to the fetched data
      setLoading(false) // End loading regardless of success or failure
    } catch (error) {
      console.error("Error fetching user data:", error.message)
      toast.error("Failed to fetch user data") // Notify error
      setLoading(false) // End loading regardless of success or failure
    }
  }

  return (
    <React.Fragment>
      <Modal isOpen={modal_center} toggle={tog_center} centered>
        <div className="modal-header">
          <h3 className="modal-title mt-0">
            <strong>View User Details</strong>
          </h3>
          <button
            type="button"
            onClick={closeModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {load ? (
            "Loading "
          ) : //After loading the data checks for data if data present the show it else show no user data found
          viewSingleUser ? (
            <div>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="name">
                      <strong>Name:&nbsp;</strong>
                    </Label>

                    {`${viewSingleUser?.first_name} ${viewSingleUser?.middle_name} ${viewSingleUser?.last_name}`}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="email">
                      <strong>Email:&nbsp;</strong>
                    </Label>
                    {viewSingleUser?.email}{" "}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="gender">
                      <strong>Gender:&nbsp;</strong>
                    </Label>
                    {viewSingleUser?.gender
                      ? viewSingleUser.gender.charAt(0).toUpperCase() +
                        viewSingleUser.gender.slice(1).toLowerCase()
                      : "Not specified"}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="phone1">
                      <strong>Primary Phone Number:&nbsp;</strong>
                    </Label>
                    {viewSingleUser?.primary_ph_no}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="phone2">
                      Additional Phone Number:&nbsp;
                    </Label>
                    {viewSingleUser?.addl_ph_no}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="pan">
                      <strong>Pan Card No:&nbsp;</strong>
                    </Label>
                    {viewSingleUser?.pan_no}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="adhar">
                      <strong>Adhar Card No:&nbsp;</strong>
                    </Label>
                    {viewSingleUser?.aadhar_no}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="add">
                      <strong>Address:&nbsp;</strong>
                    </Label>
                    {viewSingleUser?.address}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="status">
                      <strong>Status:&nbsp;</strong>
                    </Label>

                    {viewSingleUser?.status === 1 ? (
                      <span className="badge badge-soft-success">Active</span>
                    ) : (
                      <></>
                    )}

                    {viewSingleUser?.status === 0 ? (
                      <span className="badge badge-soft-danger">In-Active</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <p>No user selected.</p>
          )}
        </div>
      </Modal>

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => {
          setUser(null)
          setDeleteModal(false)
        }}
        mainHeader="Are you sure you want to delete this user?"
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Manage User" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">
                      Manage Users
                    </h5>
                    <div className="flex-shrink-0">
                      <Link
                        to="/manage-user/add"
                        className="btn btn-primary me-1"
                      >
                        Add
                      </Link>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm={4}>
                      <input
                        value={search}
                        onChange={e => {
                          setSearch(e.target.value)
                          handleSearch(e.target.value) // Call handleSearch on input change
                        }}
                        type="text"
                        placeholder="Keyword Search"
                        className="form-control search-box me-2 mb-2 d-inline-block"
                      />
                    </Col>
                    {/* <Col sm={2}>
                      <Button
                        type="button"
                        onClick={handleSearch}
                        color="primary"
                      >
                        Search
                      </Button>
                    </Col> */}
                  </Row>

                  {filteredUsers.length > 0 ? (
                    <TableContainer
                      loading={loading}
                      setLoading={setLoading}
                      columns={columns}
                      data={filteredUsers} // Show all filtered users
                      isPagination={true}
                      itemsPerPage={10}
                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline border-top"
                      pagination="pagination"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  ) : (
                    <div className="text-center mt-3">
                      <p>No matching records found.</p>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer position="top-right" autoClose={1500} />
      </div>
    </React.Fragment>
  )
}

export default User
