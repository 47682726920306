import React, { useEffect, useMemo, useState } from "react";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
    getJobList as onGetJobList,
    addNewJobList as onAddNewJobList,
    updateJobList as onUpdateJobList,
    deleteJobList as onDeleteJobList,
} from "store/actions";


import {
    Col,
    Row,
    UncontrolledTooltip,
    Card,
    CardBody,
    Button,
    ModalHeader,
    Modal,
    ModalBody,
    CardTitle,
    UncontrolledAccordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody,
    Table,
    Label,
    Input,
    FormGroup,
    FormFeedback,
    Form,

} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import Paginations from "components/Common/Pagination";
import moment from "moment";
import { isEmpty } from "lodash";
import { createShippingProvider, deleteShippingProvider, getShippingProviderList, updateShippingProvider } from "services/ShippingProviderServices";
import { deletePaymentAccountMethod, getPaymentAccountMethod, updatePaymentAccountMethod } from "services/MasterServices";

const PaymentAccountList = () => {

    //meta title
    document.title = `Payment Account |  ${process.env.REACT_APP_SITE_NAME} - Admin Panel`;


    const [paymentAccountMethods, setPaymentAccountMethods] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [job, setJob] = useState(null);
    const [search, setSearch] = useState('');
    const [modalLoading, setModalLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState("")

    const [status, setStatus] = useState(true)
    const [shippingCompName, setShippingCompName] = useState('')
    const [phNo, setPhNo] = useState('')
    const [errorMessage, setErrorMessage] = useState(false)



    useEffect(() => {
        getPaymentAccountListRecord()
    }, [limit, page, search])


    const getPaymentAccountListRecord = async () => {
        try {
            setLoading(true)

            let params = {
                page: page,
                limit: limit,
                search: search ?? ''
            }

            await getPaymentAccountMethod(params).then(response => {

                console.log(response)

                if (response.data) {

                    const { meta, data } = response;
                    setPaymentAccountMethods(data ?? [])
                    // setTotalCount(Math.ceil(meta?.itemCount / meta?.limit));
                    setTotalCount(meta?.itemCount);

                }
                setLoading(false)

            }).catch(err => console.log(err))

        } catch (error) {
            setLoading(false)
        }
    }






    //delete Job
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (job) => {
        setJob(job);
        setDeleteModal(true);
    };

    const handleDeletejob = async () => {
        if (job && job.id) {

            try {
                await deletePaymentAccountMethod(job.id).then(response => {
                    console.log(response)
                    // if(response){
                    toast.success("Payment account method is deleted successfully.", { autoClose: 500 });
                    // }
                    getPaymentAccountListRecord()
                }).catch(err => console.log(err))
            } catch (error) {
                toast.error("Something went wrong.", { autoClose: 500 });
            }


            setDeleteModal(false);
        }
    };


    const handleSearch = () => {
        setPage(1);
        getPaymentAccountListRecord(search)
    }




    const columns = useMemo(
        () => [
            {
                header: 'Sl.No.',
                accessorKey: "id",
                enableColumnFilter: false,
                enableSorting: false,
                cell: 
                (cellProps) => {
                    return <Link to="#" className="text-body fw-bold">{(page - 1) * limit + (cellProps.row.index + 1)}</Link>
                }
                // ({ row, flatRows }) => {
                //     return flatRows.indexOf(row) + 1;
                //   },
            
            },
            {
                header: "Method",
                accessorKey: "paymentMethod.method_name",
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: "A/c Details",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    const { account_no, account_name } = cellProps.row.original
                    return (<div>
                        {account_name} {account_name && account_no ? " / ":null}   {account_no}
                    </div>)
                }

            },
            {
                header: "Bank Details",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    const { bank_name, branch_name, swift_code, ifsc_code } = cellProps.row.original
                    return (<div style={{    display: "block",
                        wordWrap: "break-word",
                        whiteSpace: "normal"}}>
                        {bank_name} {branch_name ? " / "+ branch_name :null}  {swift_code? " / " + swift_code :null }  {ifsc_code? " / "+ifsc_code:null}
                    </div>)
                }
            },
            {
                header: "Contact Details",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    const { contact_person_name, phone_primary } = cellProps.row.original
                    return (<div style={{    display: "block",
                        wordWrap: "break-word",
                        whiteSpace: "normal"}}>
                        {contact_person_name}  {phone_primary? " / "+phone_primary:null}
                    </div>)
                }
            },

            {
                header: 'Status',
                accessorKey: "status",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    switch (cellProps.row.original.status) {
                        case 1:
                            return <span className="badge badge-soft-success">Active</span>;
                        case 0:
                            return <span className="badge badge-soft-danger">Inactive</span>;
                    }
                },
            },
            {
                header: () => <div style={{ textAlign: "right" }}>Action</div>,
                id: "client-action",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">



                            <li>
                                <Link
                                    to={`/payment-account/edit/${cellProps.row.original.id}`}

                                    className="btn btn-sm btn-soft-info"



                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        onClickDelete(jobData);
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete-outline" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        [page, search]
    );



    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeletejob}
                onCloseClick={() => setDeleteModal(false)}
                mainHeader="Are you sure you want to delete this payment method account?"
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Master" breadcrumbItem="Payment Account" />
                    {
                        // isLoading ? <Spinners setLoading={setLoading} />
                        //     :
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody className="border-bottom">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0 card-title flex-grow-1">Payment Account</h5>
                                            <div className="flex-shrink-0">
                                                <Link to="/payment-account/add" className="btn btn-primary me-1">Add</Link>


                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        <Row className="mb-2">
                                            <Col sm={4}>
                                                <input value={search} onChange={e => {
                                                    setSearch(e.target.value);
                                                    setPage(1)
                                                }} type="text" placeholder="Keyword Search" className="form-control search-box me-2 mb-2 d-inline-block" />{" "}

                                            </Col>
                                            {/* <Col sm={2}>     <Button type="button" onClick={handleSearch} color="primary">Search</Button></Col> */}
                                        </Row>
                                        <TableContainer
                                            columns={columns}
                                            data={paymentAccountMethods || []}
                                            loading={loading}
                                            setLoading={setLoading}

                                            isGlobalFilter={false}
                                            // isJobListGlobalFilter={true}
                                            isPagination={false}
                                            SearchPlaceholder="Search for ..."
                                            tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline border-top"

                                        />

                                        {
                                            totalCount > 0 &&
                                            <Paginations
                                                perPageData={limit}
                                                data={paymentAccountMethods}
                                                currentPage={page}
                                                setCurrentPage={setPage}
                                                isShowingPageLength={!loading}
                                                totalCount={totalCount}
                                                paginationClass="pagination"
                                                paginationDiv="dataTables_paginate paging_simple_numbers pagination-rounded"

                                            />
                                        }

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }

                </div>
            </div>



            <ToastContainer />
        </React.Fragment>
    );
}


export default PaymentAccountList;