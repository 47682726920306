import React, { useEffect, useMemo, useState } from "react";
import TableContainer from 'components/Common/TableContainer';
import moment from "moment";
import { Col, Row, UncontrolledTooltip, Card, CardBody, Button, Fade } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";

//import components
import Breadcrumbs from 'components/Common/Breadcrumb';
import { getClientList } from "services/ClientManagementServices"
import { getOrders } from "services/OrderService";

const Category = () => {
    //meta title
    document.title = `Order List |  ${process.env.REACT_APP_SITE_NAME} - Admin Panel`;

    const [loading, setLoading] = useState(false)
    const [advanceSearch, setAdvanceSearch] = useState({
        open: false,
        client_id: null,
        sales_man_id: null,
        order_start_date: null,
        order_end_date: null,
        ordered_delivery_start_date: null,
        ordered_delivery_end_date: null,
        shipping_start_date: null,
        shipping_end_date: null,
    })
    const [orderList, setOrderList] = useState([])
    const [clients, setClients] = useState([])

    useEffect(() => {
        getOrderList()
        getClients()
    }, []);

    const getOrderList =  async () =>{
        try {
            let params={
                sort:'id',
                sortDirection:'desc'
            }
            setLoading(true)
            await getOrders({ params }).then(response=>{
                if(response){
                    setOrderList(response.data)
                    setLoading(false)
                }
            }).catch(err=>{console.log(err)
                setLoading(false)
            })
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const getClients = async () => {
        try {
            let params = {
                page: 1,
                limit: 200,
                search: ''
            }
            await getClientList(params).then(response => {
                if (response.data) {
                    const { meta, data } = response;
                    setClients(data)
                }
            }).catch(err => console.log(err))

        } catch (error) {
            // setLoading(false)
            console.log(error);
        }
    }

    const clearAdvanceSearchData = () => {

    }

    const columns = useMemo(
        () => [
            {
                header: 'Sl.No',
                accessorKey: "id",
                enableColumnFilter: false,
                enableSorting: false,
                enableGlobalFilter: false,
                cell: (cellProps) => {
                    return <Link to="#" className="text-body fw-bold">{cellProps.row.index + 1}</Link>
                }
            },
            {
                header: "Order No.",
                accessorKey: "order_no",
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: "Client Name",
                accessorKey: "client.client_name",
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: "Amount",
                accessorKey: "net_order_amount",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    console.log(cellProps.row.original);
                    
                    return '₹' + cellProps.row.original.net_order_amount
                },
            },
            {
                header: 'Status',
                accessorKey: "status",
                enableColumnFilter: false,
                enableSorting: false,
                enableGlobalFilter: false,
                cell: (cellProps) => {
                    switch (cellProps.row.original.status) {
                        case 1:
                            return <span className="badge badge-soft-success">Active</span>;
                        case 0:
                            return <span className="badge badge-soft-danger">Inactive</span>;
                    }
                },
            },
            {
                header: 'Order Date',
                accessorKey: "order_date",
                enableColumnFilter: false,
                enableSorting: false,
                enableGlobalFilter: false,
                cell: (cellProps) => {
                    return moment(cellProps.row.original.order_date).format('DD-MM-YYYY')
                },
            },
            {
                header: 'Delivery Date',
                accessorKey: "ordered_delivery_date",
                enableColumnFilter: false,
                enableSorting: false,
                enableGlobalFilter: false,
                cell: (cellProps) => {
                    return moment(cellProps.row.original.ordered_delivery_date).format('DD-MM-YYYY')
                },
            },
            {
                header: () => <div style={{ textAlign: "right" }}>Action</div>,
                id: "category-action",
                enableColumnFilter: false,
                enableSorting: false,
                enableGlobalFilter: false,
                cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">
                            <li>
                                <Link
                                    to={`/orders/${cellProps.row.original.id}`}
                                    className="btn btn-sm btn-soft-info"
                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >Edit Order</UncontrolledTooltip>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={`/orders/view/${cellProps.row.original.id}`}
                                    className="btn btn-sm btn-soft-info"
                                    id={`viewtooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-eye-outline" />
                                    <UncontrolledTooltip placement="top" target={`viewtooltip-${cellProps.row.original.id}`} >View Order</UncontrolledTooltip>
                                </Link> 
                            </li>
                        </ul>
                    );
                }
            },
        ],
        []
    );

    const onOrderDateSelect = ranges => {
        console.log(ranges);
        
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Master" breadcrumbItem="Orders" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">Order List</h5>
                                        <div className="flex-shrink-0">
                                            <Link to="/orders/add" className="btn btn-primary me-1"><i className="mdi mdi-plus" /> Add</Link>
                                            <Button type="secondary" onClick={() => setAdvanceSearch({...advanceSearch, open: advanceSearch.open ? false : true})}><i className="mdi mdi-magnify" /></Button>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>

                                    {/* Global Search */}
                                    <Fade>
                                        {advanceSearch.open && <Row>
                                            <Col lg={12}>
                                                <div className="border p-2 rounded">
                                                    <Row>
                                                        <Col lg={4} className="mb-3">
                                                            <label>Select Client</label>
                                                            <select className="form-control">
                                                                {clients.map((client, i) => <option value={client.id} key={i}>{client.client_name}</option>)}
                                                            </select>
                                                        </Col>
                                                        <Col lg={4} className="mb-3">
                                                            <label>Select Sales Man</label>
                                                            <select className="form-control">
                                                                {clients.map((client, i) => <option value={client.id} key={i}>{client.client_name}</option>)}
                                                            </select>
                                                        </Col>
                                                        <Col lg={4} className="mb-3">
                                                            <label>Order Date</label>
                                                            <select className="form-control">
                                                                <option value={1}>a</option>
                                                            </select>
                                                        </Col>
                                                        <Col lg={4} className="mb-3">
                                                            <label>Order Delivery Date</label>
                                                            <select className="form-control">
                                                                <option value={1}>a</option>
                                                            </select>
                                                        </Col>
                                                        <Col lg={4} className="mb-3">
                                                            <label>Shipping Date</label>
                                                            <select className="form-control">
                                                                <option value={1}>a</option>
                                                            </select>
                                                        </Col>
                                                        <Col lg={12} className="mb-3 d-flex align-items-end justify-content-end">
                                                            <Button color="danger" outline className="me-1" onClick={clearAdvanceSearchData}><i className="mdi mdi-close" /></Button>
                                                            <Button color="primary" ><i className="mdi mdi-cloud-search" /> Search</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>}
                                    </Fade>

                                    {/* Order Table */}
                                    <TableContainer
                                        columns={columns}
                                        data={orderList}
                                        isGlobalFilter={false}
                                        loading={loading}
                                        setLoading={setLoading}
                                        isPagination={true}
                                        tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline border-top"
                                        pagination="pagination"
                                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}


export default Category;