import Breadcrumb from 'components/Common/Breadcrumb'
import Spinners from 'components/Common/Spinner'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import { getClientList } from 'services/ClientManagementServices'
import { createPaymentAccountMethod, getPaymentMethod, getSinglePaymentAccountMethod, updatePaymentAccountMethod } from 'services/MasterServices'
import { getVendorList } from 'services/VendorServices'


const EditPaymentAccount = () => {


    const [mainLoading, setMainLoading] = useState(true)
    const [loading, setLoading] = useState(false)
    const [paymentMethodList, setPaymentMethodList] = useState([])
    const [accountName, setAccountName] = useState('')
    const [paymentMethodId, setPaymentMethodId] = useState('')
    const [accountNo, setAccountNo] = useState('')
    const [swiftCode, setSwiftCode] = useState('')
    const [ifscCode, setIfscCode] = useState('')
    const [bankName, setBankName] = useState('')
    const [branchName, setBranchName] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zipcode, setZipcode] = useState('')
    const [country, setCountry] = useState('')
    const [contactPersonName, setContactPersonName] = useState('')
    const [phonePrimary, setPhonePrimary] = useState('')
    const [addPhonePrimary, setAddPhonePrimary] = useState('')
    const [email, setEmail] = useState('')
    const [status, setStatus] = useState(true)
    const [errorMessage, setErrorMessage] = useState(false)
    const [clientList,setClientList] = useState([]);
    const [clientId,setClientId] = useState("")
    const [vendorList,setVendorList] = useState([]);
    const [vendorId,setVendorId] = useState("")
    const { id } = useParams()

    const navigate = useNavigate()



    //meta title
    document.title = `Product Add  | ${process.env.REACT_APP_SITE_NAME} Admin Panel`;


    useEffect(() => {
        getVendorListRecords()
        getClientListRecords()

        getSinglePaymentMethodAccount()

        getPaymentMethodList()
    }, [])



    const getSinglePaymentMethodAccount = async () => {
        try {
            setMainLoading(true)

            await getSinglePaymentAccountMethod(id).then(response => {
                console.log(response)
                if (response) {
                    const { payment_method_id, account_name, account_no, swift_code, ifsc_code, bank_name, branch_name, address,city, state, zipcode, country, contact_person_name, phone_primary, phone_addl, email, status,vendor_id,client_id } = response;

                    setAccountName(account_name ?? '')
                    setPaymentMethodId(payment_method_id ?? '')
                    setAccountNo(account_no ?? '')
                    setSwiftCode(swift_code ?? '')
                    setIfscCode(ifsc_code ?? '')
                    setBankName(bank_name ?? '')
                    setBranchName(branch_name ?? '')
                    setAddress(address ?? '')
                    setCity(city ?? '')
                    setState(state ?? '')
                    setZipcode(zipcode ?? '')
                    setCountry(country ?? '')
                    setContactPersonName(contact_person_name ?? '')
                    setPhonePrimary(phone_primary ?? '')
                    setAddPhonePrimary(phone_addl ?? '')
                    setEmail(email ?? '')
                    setStatus(status ? true : false ?? true)
                    setClientId(client_id)
                    setVendorId(vendor_id)

                }

                
                setMainLoading(false)
            }).catch(err => {
                
            setMainLoading(false)
                console.log(err)
            })

        } catch (error) {
            setMainLoading(false)
        }
    }


    const getPaymentMethodList = async () => {
        let params = {

        }

        try {
            await getPaymentMethod({ params }).then(response => {
                console.log(response)

                setPaymentMethodList(response)


            }).catch(err => console.log(err))
        } catch (error) {
            console.log(error)
        }


    }



    const handleSubmit = async (e) => {
        e.preventDefault()

        setErrorMessage(true)

        let submitFlag = true;

        if (accountName === "" || accountNo === "" || ifscCode === "" || paymentMethodId === "" || bankName === "" || branchName === "") {
            submitFlag = false
        }



        if (submitFlag) {
            try {
                let payload = {


                    payment_method_id: Number(paymentMethodId),
                    account_name: accountName,
                    account_no: accountNo,
                    swift_code: swiftCode,
                    ifsc_code: ifscCode,
                    bank_name: bankName,
                    branch_name: branchName,
                    address: address,
                    city: city,
                    state: state,
                    zipcode: zipcode,
                    country: country,
                    contact_person_name: contactPersonName,
                    phone_primary: phonePrimary,
                    phone_addl: addPhonePrimary,
                    email: email,
                    status: status ? 1 : 0,
                    vendor_id:vendorId===""?null:Number(vendorId),
                    client_id:clientId===""?null:Number(clientId)
                }

                setLoading(true)
                await updatePaymentAccountMethod(id,payload).then(response => {
                    console.log(response)
                    if (response) {
                        toast.success("Payment account is updated successfully.")
                        setTimeout(() => {

                            navigate(`/payment-account`)

                        }, 2000);
                    }
                    setLoading(false)
                }).catch(err => {
                    console.log(err);
                    if(err?.status===400 && err?.response?.data?.message){  
                        toast.error( err?.response?.data?.message, { autoClose: 2000 });
                    }else{
                    toast.error(`Payment account is updated Failed`, { autoClose: 2000 });
                    }
                    setLoading(false)

                })
            } catch (error) {
                console.log(error)
                toast.error(`Something went wrong.`, { autoClose: 2000 });
                setLoading(false)
            }
        }
    }


    const getVendorListRecords= async ()=>{

        try {
            let params = {
                limit:200,
                page:1
            }
            await getVendorList(params).then(response=>{

                if(response){
                    const {  data } = response;
                    setVendorList(data)
                }

            })
            .catch(err=>console.log(err))
        } catch (error) {
            
        }
    }


    const getClientListRecords = async () => {

        try {
            let params = {
                limit:200,
                page:1
            }
            await getClientList(params).then(response=>{
                console.log(response)
                if(response){
                    const {  data } = response;
                    setClientList(data)
                }
            }).catch(err=>console.log(err))
        } catch (error) {
            
        }

    }





    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumb title="Master" to={`/payment-account`}  breadcrumbItem="Edit Payment Account" />
                {
                       mainLoading ? <Spinners setLoading={setMainLoading} />
                            :
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody className="border-bottom">
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 card-title flex-grow-1">Edit Payment Account </h5>
                                    <div className="flex-shrink-0">
                                        <Link to="/payment-account" className="btn btn-primary me-1">Back</Link>

                                    </div>
                                </div>
                            </CardBody>
                            <CardBody>
                                <Form onSubmit={handleSubmit}>


                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-firstname-Input">Account Name <span className='text-danger'>*</span></Label>
                                                <Input
                                                    type="text"
                                                    name="title"
                                                    className="form-control"
                                                    id="formrow-cname-Input"
                                                    placeholder="Please Enter Account Name"
                                                    value={accountName}
                                                    onChange={(e) => setAccountName(e.target.value)}
                                                    invalid={
                                                        errorMessage && accountName === ''
                                                    }
                                                />
                                                {
                                                    errorMessage && accountName === '' ? (
                                                        <FormFeedback type="invalid">{'This field is required'}</FormFeedback>
                                                    ) : null
                                                }
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-firstname-Input">Account No. <span className='text-danger'>*</span></Label>
                                                <Input
                                                    type="text"
                                                    name="title"
                                                    className="form-control"
                                                    id="formrow-cname-Input"
                                                    placeholder="Please Enter Account Number"
                                                    value={accountNo}
                                                    onChange={(e) => (e.target.value.match(/^[0-9]*$/) || e.target.value === "") && setAccountNo(e.target.value)}
                                                    invalid={
                                                        errorMessage && accountNo === ''
                                                    }
                                                />
                                                {
                                                    errorMessage && accountNo === '' ? (
                                                        <FormFeedback type="invalid">{'This field is required'}</FormFeedback>
                                                    ) : null
                                                }
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label>Choose Payment Method  <span className='text-danger'>*</span></Label>
                                                <Input
                                                    name="category_id"
                                                    type="select"
                                                    className="form-select"
                                                    onChange={(e) => setPaymentMethodId(e.target.value)}

                                                    value={
                                                        paymentMethodId
                                                    }
                                                    invalid={
                                                        errorMessage && paymentMethodId === ""
                                                    }
                                                >

                                                    <option value="">Select</option>

                                                    {
                                                        paymentMethodList?.map((item, key) =>
                                                            <option key={`dropdown-${key}`} value={item?.id}>{item?.method_name}</option>


                                                        )
                                                    }


                                                </Input>
                                                {errorMessage && paymentMethodId === ""
                                                    ? (
                                                        <FormFeedback type="invalid">Please Choose Payment Method.</FormFeedback>
                                                    ) : null}
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-paymentTermId-Input">Swift Code</Label>
                                                <Input
                                                    type="text"
                                                    name="swift_code"
                                                    className="form-control"
                                                    id="formrow-paymentTermId-Input"
                                                    placeholder="Please Enter Swift Code"
                                                    value={swiftCode}
                                                    onChange={(e) => setSwiftCode(e.target.value)}


                                                />



                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-address-Input">Ifsc Code  <span className='text-danger'>*</span></Label>
                                                <Input
                                                    type="text"
                                                    name="ifscCode"
                                                    row={3}
                                                    className="form-control"
                                                    id="formrow-address-Input"
                                                    placeholder="Please Enter Ifsc Code"
                                                    value={ifscCode}
                                                    onChange={(e) => setIfscCode(e.target.value)}
                                                    invalid={
                                                        errorMessage && ifscCode === ''
                                                    }

                                                />

                                                {
                                                    errorMessage && ifscCode === '' ? (
                                                        <FormFeedback type="invalid">{'This field is required'}</FormFeedback>
                                                    ) : null
                                                }

                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Bank Name <span className='text-danger'>*</span></Label>
                                                <Input
                                                    type="text"
                                                    name="bank_name"
                                                    placeholder='Please Enter Bank Name '
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={bankName}
                                                    onChange={(e) => setBankName(e.target.value)}

                                                    invalid={
                                                        errorMessage && bankName === ''
                                                    }
                                                />

                                                {
                                                    errorMessage && bankName === '' ? (
                                                        <FormFeedback type="invalid">{'This field is required'}</FormFeedback>
                                                    ) : null
                                                }


                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Branch Name <span className='text-danger'>*</span></Label>
                                                <Input
                                                    type="text"
                                                    name="branchName"
                                                    placeholder='Please Enter Branch Name '
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={branchName}
                                                    onChange={(e) => setBranchName(e.target.value)}
                                                    invalid={
                                                        errorMessage && branchName === ''
                                                    }

                                                />
                                                {
                                                    errorMessage && branchName === '' ? (
                                                        <FormFeedback type="invalid">{'This field is required'}</FormFeedback>
                                                    ) : null
                                                }

                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Address</Label>
                                                <Input
                                                    type="textarea"
                                                    name="address"
                                                    rows={3}
                                                    placeholder='Please Enter Address '
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={address}
                                                    onChange={(e) => setAddress(e.target.value)}


                                                />

                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">City </Label>
                                                <Input
                                                    type="text"
                                                    name="city"
                                                    placeholder='Please Enter City '
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={city}
                                                    onChange={(e) => setCity(e.target.value)}


                                                />

                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">State </Label>
                                                <Input
                                                    type="text"
                                                    name="state"
                                                    placeholder='Please Enter State '
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={state}
                                                    onChange={(e) => setState(e.target.value)}


                                                />

                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Zipcode </Label>
                                                <Input
                                                    type="text"
                                                    name="zipcode"
                                                    placeholder='Please Enter Zipcode '
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={zipcode}
                                                    onChange={(e) => e.target.value.length < 10 && setZipcode(e.target.value)}


                                                />

                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Country </Label>
                                                <Input
                                                    type="text"
                                                    name="country"
                                                    placeholder='Please Enter Country '
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={country}
                                                    onChange={(e) => setCountry(e.target.value)}



                                                >

                                                </Input>

                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Contact Person Name </Label>
                                                <Input
                                                    type="text"
                                                    name="contactPersonName"
                                                    placeholder='Please Enter Contact Person Name '
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={contactPersonName}
                                                    onChange={(e) => setContactPersonName(e.target.value)}


                                                />

                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Primary Phone Number (Optional)</Label>
                                                <Input
                                                    type="text"
                                                    name="phonePrimary"
                                                    placeholder='Please Enter Primary Phone Number'
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={phonePrimary}
                                                    onChange={(e) => e.target.value.length < 15 && setPhonePrimary(e.target.value)}



                                                >

                                                </Input>

                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Additional Phone Number (Optional)</Label>
                                                <Input
                                                    type="text"
                                                    name="addPhonePrimary"
                                                    placeholder='Please Number'
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={addPhonePrimary}
                                                    onChange={(e) => e.target.value.length < 15 && setAddPhonePrimary(e.target.value)}


                                                />

                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Email </Label>
                                                <Input
                                                    type="text"
                                                    name="email"
                                                    placeholder='Please Enter email'
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}


                                                />

                                            </div>
                                        </Col>

                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Choose Vendor </Label>
                                                <Input
                                                    type="select"
                                                    name="vendor_id"
                                                 
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={vendorId}
                                                    onChange={(e)=>setVendorId(e.target.value)}
                                                    
                                                  
                                                >
                                                    <option value="">Select</option>
                                                    {
                                                        vendorList?.map((item,index)=><option key={`vendor-${index}`}  value={item.id}>{item?.vendor_name}</option>)
                                                    }
                                                </Input>
                                             
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-reg_date-Input">Choose Client </Label>
                                                <Input
                                                    type="select"
                                                    name="client_id"
                                                 
                                                    className="form-control"
                                                    id="formrow-reg_date-Input"
                                                    value={clientId}
                                                    onChange={(e)=>setClientId(e.target.value)}
                                                    
                                                  
                                                >
                                                    <option value="">Select</option>
                                                    {
                                                        clientList?.map((item,index)=><option key={`client-${index}`} value={item.id}>{item?.client_name}</option>)
                                                    }
                                                </Input>
                                             
                                            </div>
                                        </Col>
                                     


                                    </Row>




                                    <div className="mb-3">
                                        <Label>Status</Label>
                                        <FormGroup switch>
                                            <Label className=""> 
                                                {/* {status === true ? 'Active' : 'Inactive'} */}
                                                <Input
                                                    name="status"
                                                    type="switch"
                                                    role="switch"
                                                    checked={status}

                                                    onClick={() => setStatus(!status)}

                                                    value={status}


                                                />

                                            </Label>

                                        </FormGroup>


                                    </div>
                                    <div>
                                        <button type="submit" className="btn btn-primary w-md" disabled={loading}>
                                            Submit
                                        </button>
                                    </div>
                                </Form>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
}
                <ToastContainer />
            </div>
        </div>
    )
}

export default EditPaymentAccount