import DeleteModal from 'components/Common/DeleteModal'
import Paginations from 'components/Common/Pagination'
import TableContainer from 'components/Common/TableContainer'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { Card, CardBody, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap'
import { deleteProductMedia, getProductMediaList, updateMediaProduct } from 'services/ProductServices'
import MediaType from './MediaType'

const MediaTypeList = () => {
    const { id } = useParams();

    const [productsMediaList, setProductMediaList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [deleteModal, setDeleteModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [job, setJob] = useState(null);
    const [editForm, setEditForm] = useState({
        mediaId: '',
        status: 1,
        isPrimary: 0,
        fileName: "",
        file: ""
    })

    const [picture, setPicture] = useState('');

    const inputRefs = useRef()


    useEffect(() => {
        getProductMediaListRecord()
    }, [])

    const getProductMediaListRecord = () => {
        try {

            let params = {
                page: page,
                // limit: limit,
                prod_id: id
            }
            getProductMediaList(params).then(response => {
                console.log(response)

                if (response) {
                    const { meta, data } = response;
                    setProductMediaList(data ?? [])

                    setTotalCount(meta?.itemCount);
                }


            }).catch(err => console.log(err))
        } catch (error) {

        }
    }

    const toggle = () => {
        setModal(!modal)
    }
    const toggleEdit = () => {
        if (editModal) {
            setEditForm({
                mediaId: '',
                status: 1,
                isPrimary: 0,
                fileName: "", file: ""
            });
            setPicture("")
        }
        setEditModal(!editModal)

    }


    const handleClose = () => {
        setModal(false)
        getProductMediaListRecord()
    }



    const onClickDelete = (job) => {
        setJob(job);
        setDeleteModal(true);
    };

    const handleDeletejob = async () => {
        if (job && job.id) {

            try {
                await deleteProductMedia(job.id).then(response => {
                    console.log(response)
                    // if(response){
                    toast.success("Product media is deleted successfully.", { autoClose: 500 });
                    // }
                    getProductMediaListRecord()
                }).catch(err => console.log(err))
            } catch (error) {
                toast.error("Something went wrong.", { autoClose: 500 });
            }


            setDeleteModal(false);
        }
    };

    const handleEdit = (job) => {
        const { file_name, status, is_primary, id } = job

        setEditForm({
            mediaId: id,
            status: status ? true : false,
            fileName: file_name,
            isPrimary: is_primary ? true : false,
            file: ""
        })

        setEditModal(true)

    }

    const handleInputChange = (name, value) => {
        console.log(value, "value", name)
        if (name === 'file') {
            const file = value.files[0];


            if (file && (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg')) {
                toast.error("Please upload a PNG, JPG, JPEG file only", {
                    autoClose: 3000,
                    position: 'top-right',
                    hideProgressBar: true
                });
                inputRefs.current.value = null;
                return;
            }
            console.log(file, "file")
            setEditForm({
                ...editForm,
                [name]: file,
                fileName: file.name
            })
            setPicture(URL.createObjectURL(file));

        } else {
            console.log(value.checked)
            const { checked, type } = value

            setEditForm({ ...editForm, [name]: !value.checked })

        }


    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        let submitFlag = true;
        if (submitFlag) {

            try {
                const { status, isPrimary, mediaId, fileName, file } = editForm;

                setLoadingEdit(true)

                let payload = {
                    file_name: fileName,
                    file: file,
                    prod_id: id,
                    media_type: "image",
                    is_primary: isPrimary ? 1 : 0,
                    status: status ? 1 : 0,
                }

                const headers = {
                    'Content-Type': 'multipart/form-data'
                };


                await updateMediaProduct(mediaId, payload, headers).then(response => {
                    toast.success("Product media is updated successfully.", { autoClose: 500 });
                    toggleEdit()
                    getProductMediaListRecord()
                    setLoadingEdit(false)
                }).catch(err => {
                    toast.error("Something went wrong.", { autoClose: 500 });
                })
            } catch (error) {
                setLoadingEdit(false)
                toast.error("Something went wrong.", { autoClose: 500 });
            }
        }

    }


    const columns = useMemo(
        () => [
            {
                header: 'Sl.No.',
                accessorKey: "id",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    return <Link to="#" className="text-body fw-bold">{(page - 1) * limit + (cellProps.row.index + 1)}</Link>
                }
            },
            {
                header: "Product Media Name",
                accessorKey: "file_name",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {


                    return cellProps.row.original.media_type === 'image' ? <img src={`${process.env.REACT_APP_STORAGEPATH}/product_media/${cellProps.row.original.file_name}`} className="img-thumbnail" width="50" height="50" /> : <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                        <iframe
                            title="test"
                            className="embed-responsive-item img-thumbnai"
                            width="50" height="50"
                            src={`${process.env.REACT_APP_BASEPATH}/storage/product_media/${cellProps.row.original.file_name}`}
                        />
                    </div>
                }
            },
            {
                header: 'Is Primary ',
                accessorKey: "is_primary",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    switch (cellProps.row.original.is_primary) {
                        case 1:
                            return <span className="badge badge-soft-success">Active</span>;
                        case 0:
                            return <span className="badge badge-soft-danger">Inactive</span>;
                    }
                },
            },

            {
                header: 'Status',
                accessorKey: "status",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    switch (cellProps.row.original.status) {
                        case 1:
                            return <span className="badge badge-soft-success">Active</span>;
                        case 0:
                            return <span className="badge badge-soft-danger">Inactive</span>;
                    }
                },
            },
            {
                header: () => <div style={{ textAlign: "right" }}>Action</div>,
                id: "client-action",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">



                            <li>
                                <Link
                                    to={void (0)}
                                    className="btn btn-sm btn-soft-info"

                                    id={`edittooltip-${cellProps.row.original.id}`}
                                    onClick={() => handleEdit(cellProps.row.original)}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        onClickDelete(jobData);
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete-outline" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        [page]
    );




    return (
        <React.Fragment>


            <div className="d-flex align-items-center justify-content-end">

                <div className="flex-shrink-0">
                    <Link to={void (0)} onClick={() => setModal(true)} className="btn btn-primary me-1">Add</Link>

                </div>
            </div>

            {
                productsMediaList.length > 0 &&
                <>


                    <DeleteModal
                        show={deleteModal}
                        onDeleteClick={handleDeletejob}
                        onCloseClick={() => setDeleteModal(false)}
                        mainHeader="Are you sure you want to delete this product media?"
                    />




                    <TableContainer
                        columns={columns}
                        data={productsMediaList || []}
                        loading={loading}
                        setLoading={setLoading}

                        isGlobalFilter={false}
                        // isJobListGlobalFilter={true}
                        isPagination={false}
                        SearchPlaceholder="Search for ..."
                        tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline mt-4 border-top"

                    />

                    {/* {
                        totalCount > 0 &&
                        <Paginations
                            perPageData={limit}
                            data={productsMediaList}
                            currentPage={page}
                            setCurrentPage={setPage}
                            isShowingPageLength={true}
                            totalCount={totalCount}
                            paginationClass="pagination"
                            paginationDiv="dataTables_paginate paging_simple_numbers pagination-rounded"

                        />
                    } */}


                    {/* <hr className='my-4'/> */}


                </>
            }


            <Modal isOpen={modal} toggle={toggle} size='lg'>
                <ModalHeader toggle={toggle}>Add Media </ModalHeader>
                <ModalBody>
                    <MediaType fromType={1} handleClose={handleClose} />
                </ModalBody>
            </Modal>

            <Modal isOpen={editModal} toggle={toggleEdit} size='lg'>
                <ModalHeader toggle={toggleEdit}>Edit Media </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit} >
                        <Row>




                            <Col lg={12} className="mb-3">
                                <label htmlFor="resume">Media</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="file"
                                    name={`file`}
                                    // value={editForm.fileName}
                                    accept="image/png, image/jpg, image/jpeg"
                                    ref={inputRefs}
                                    onChange={(e) => handleInputChange('file', e.target)}
                                    onClick={(event) => {
                                        event.target.value = null,
                                        setPicture("")
                                    }
                                    }
                                />

                                {
                                    editForm.file == "" && editForm.fileName !== "" &&
                                    <img src={`${process.env.REACT_APP_STORAGEPATH}/product_media/${editForm.fileName}`} className="img-thumbnail mt-2" width="50" height="50" />
                                }
                                {
                                    editForm.file !== "" && picture !=="" &&
                                    <img src={`${picture}`} className="img-thumbnail mt-2" width="50" height="50" />
                                }



                            </Col>



                            <Col lg={6} className="mb-3">
                                <label htmlFor="isPrimary">Is Primary</label>

                                <FormGroup switch>
                                    <Label className="">
                                         {/* {editForm.isPrimary === true ? 'Active' : 'Inactive'} */}
                                        <Input
                                            name="isPrimary"
                                            type="switch"
                                            role="switch"
                                            checked={editForm.isPrimary}

                                            onClick={(e) => handleInputChange('isPrimary', e.target)}

                                            value={editForm.isPrimary}


                                        />

                                    </Label>
                                </FormGroup>
                            </Col>

                            <Col lg={6} className="mb-3">
                                <label htmlFor="status">Status</label>

                                <FormGroup switch>
                                    <Label className="">
                                         {/* {editForm.status === true ? 'Active' : 'Inactive'} */}
                                        <Input
                                            name="status"
                                            type="switch"
                                            role="switch"
                                            checked={editForm.status}

                                            onClick={(e) => handleInputChange('status', e.target)}

                                            value={editForm.status}


                                        />

                                    </Label>
                                </FormGroup>
                            </Col>









                            <Col md={12}>
                                <div className="my-3 text-end">
                                    <button type="submit" className="btn btn-primary w-md" disabled={loadingEdit}>
                                        Submit
                                    </button>
                                </div>
                            </Col>

                        </Row>

                    </Form>
                </ModalBody>
            </Modal>

            <ToastContainer />
        </React.Fragment>
    )
}

export default MediaTypeList