/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect } from "react";
import { Row, Col, Card, Fade, Container } from "reactstrap";
import { useParams } from 'react-router-dom';

import { Spinner } from "reactstrap";
import Tabs from 'components/Client/Cart/tabs/index.js'

const OrderView = (props) => {
    
    document.title = `Order View |  ${process.env.REACT_APP_SITE_NAME} - Admin Panel`;
    const params = useParams()
    const [ isLoading, setIsloading ] = useState(false)
    
    return (
        <React.Fragment>
            <Fade>
                {isLoading && <div style={{ background: 'rgba(0,0,0,0.3)', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 99999 }}>
                    <Spinner color="primary" className='position-absolute top-50 start-50' style={{ zIndex: 99 }} />
                </div>}
            </Fade>
            <div className="page-content">
                <Container>
                    <Card>
                        <Row>
                            <Col lg={12}>
                                <Tabs />
                            </Col>
                        </Row>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default OrderView